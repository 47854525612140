import api from "../../api";
import SideBar from "../SideBar";
import Header from "../Header";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import ReactLoading from "react-loading";
import APIMultipleSearchableSelect from "../payments/SearchableTableMultiSelect";
import SearchableSelect from "../payments/SearchableSelect";
import { Modal } from "react-bootstrap";
import PageHeading from "../ui/PageHeading/PageHeading";
import Button from "../ui/Button/Button";
import Heading from "../ui/Heading/Heading";
import Input from "../ui/Input/Input";
import UnauthorizedModal from "../Unauthorized"; // ✅ Импортируем модалку
import { useAuth } from "../../AuthContext"; // ✅ Импортируем контекст авторизации
import { Alert } from 'react-bootstrap';

const ALL_COLUMNS = [
    "Название",
    "Номер",
    "Организация",
    "Код",
    "Вид счета",
    "Банк",
    "Валюта",
    "Владелец (Орг.)",
    "Владелец (Контр.)",
    "Архивный",
];

const DEFAULT_COLUMN_WIDTHS = {"Название":209,"Номер":226,"Организация":285,"Код":148,"Вид счета":145,"Банк":313,"Валюта":135,"Владелец (Орг.)":308,"Владелец (Контр.)":275,"Архивный":144}

const BankAccounts = () => {
    const [bankaccs, setBankAccounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const containerRef = useRef(null);
    const limit = 25;
    const [checkedBankAccounts, setCheckedBankAccounts] = useState([]);
    const [nameFilter, setNameFilter] = useState("");
    const [nubmerFilter, setnumberFilter] = useState("");
    const [organFilter, setSelectedOrganization] = useState("");
    const [archivedFilterNotArchive, setArchivedFilterNotActive] = useState(false);
    const [archivedFilterActive, setArchivedFilterActive] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const searchIDofelem = location.search.replace("?", "");
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [errorMessage, setErrorMessage] = useState(""); // 🔴 Храним текст ошибки
    const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const initialFormData = {
        name: "",
        code: "",
        account_type: "",
        account_number: "",
        company: null,
        bank: null,
        currency: null,
        owner_organization: null,
        owner_counterparty: null,
    };
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});
    const [filtersVisible, setFiltersVisible] = useState(false);

    const [showSettings, setShowSettings] = useState(false);

    const [selectedColumns, setSelectedColumns] = useState(() => {
        const saved = localStorage.getItem("bankAccountsSelectedColumns");
        if (saved) return JSON.parse(saved);
        return [...ALL_COLUMNS];
    });

    useEffect(() => {
        localStorage.setItem(
            "bankAccountsSelectedColumns",
            JSON.stringify(selectedColumns)
        );
    }, [selectedColumns]);

    const [columnWidths, setColumnWidths] = useState(() => {
        const saved = localStorage.getItem("bankAccountsColumnWidths");
        if (saved) {
            return { ...DEFAULT_COLUMN_WIDTHS, ...JSON.parse(saved) };
        }
        return { ...DEFAULT_COLUMN_WIDTHS };
    });

    const resizingInfoRef = useRef({
        isResizing: false,
        startX: 0,
        columnKey: null,
        initialWidth: 0,
    });

    const onMouseDown = (e, column) => {
        e.preventDefault();
        e.stopPropagation();
        resizingInfoRef.current.isResizing = true;
        resizingInfoRef.current.startX = e.clientX;
        resizingInfoRef.current.columnKey = column;
        resizingInfoRef.current.initialWidth =
            columnWidths[column] || DEFAULT_COLUMN_WIDTHS[column] || 150;
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };

    const onMouseMove = (e) => {
        if (!resizingInfoRef.current.isResizing) return;
        const { startX, columnKey, initialWidth } = resizingInfoRef.current;
        const delta = e.clientX - startX;
        const newWidth = Math.max(50, initialWidth + delta);
        setColumnWidths((prev) => ({
            ...prev,
            [columnKey]: newWidth,
        }));
    };

    const onMouseUp = () => {
        resizingInfoRef.current.isResizing = false;
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
        setColumnWidths((prev) => {
            localStorage.setItem("bankAccountsColumnWidths", JSON.stringify(prev));
            return prev;
        });
    };

    const columnsInUse = ALL_COLUMNS.filter((col) => selectedColumns.includes(col));
    const totalTableWidth = columnsInUse.reduce((acc, col) => {
        return acc + (columnWidths[col] || DEFAULT_COLUMN_WIDTHS[col] || 100);
    }, 0);

    const handleRowClick = (bankacc) => {
        setIsEditing(true);
        setFormData({
            id: bankacc.id,
            name: bankacc.name,
            account_number: bankacc.account_number || "",
            code: bankacc.code || "",
            bank: bankacc.bank,
            company: bankacc.company,
            account_type: bankacc.account_type || "",
            currency: bankacc.currency,
            owner_organization: bankacc.owner_organization || "",
            owner_counterparty: bankacc.owner_counterparty || "",
        });
        setShowModal(true);
    };

    const fetchBankAccounts = async (offset = 0, filters = {}) => {
        setLoading(true);
        try {
            let response;
            if (searchIDofelem) {
                response = await api.post("api/v1/bank/bankaccountsfilter/", {
                    id: searchIDofelem,
                    is_active: [true, false],
                });
                setBankAccounts(response.data);
                setTotalCount(response.data.length);
            } else {
                response = await api.get(
                    `api/v1/bank/allbankaccounts/?limit=${limit}&offset=${offset}`,
                    {
                        name: nameFilter ? nameFilter.toLowerCase() : "",
                        number_of_check: nubmerFilter,
                        is_active: [!archivedFilterNotArchive, archivedFilterActive],
                        company: organFilter,
                        ...filters,
                    }
                );
                const newBankAccounts = response.data.results;
                setBankAccounts((prev) =>
                    offset === 0 ? newBankAccounts : [...prev, ...newBankAccounts]
                );
                setTotalCount(response.data.count);
                setHasMore(response.data.next !== null);
            }
        } catch (error) {
            console.error("Ошибка при загрузке банковских счетов:", error);
    
            if (error.response) {
                console.error("Ответ сервера:", error.response.status, error.response.data);

                if (error.response.status === 401) {
                    console.log("401 ошибка! Перезайдите, пожалуйста.");
                    openModal();
                } else if (error.response.status === 403) {
                    setErrorMessage("Доступ запрещен!");
                } else if (error.response.status === 400) {
                    setErrorMessage(error.response.data.detail || "Ошибка запроса.");
                } else if (error.response.status === 500) {
                    setErrorMessage("Ошибка сервера! Попробуйте позже.");
                } else {
                    setErrorMessage(`Неизвестная ошибка: ${error.response.status}`);
                }
            } else {
                console.error("Ошибка сети или сервера:", error);
                setErrorMessage("Ошибка соединения с сервером. Проверьте интернет.");
            }
        } finally {
            setLoading(false);
        }
    };
    
    const openModal = (open) => {
        if (open) { 
            setShowModal(false);
            setShowUnauthorizedModal(true);
        } else { 
            setShowUnauthorizedModal(false);
        }

    };

    useEffect(() => {
        if (hasMore) {
            fetchBankAccounts(offset);
        }
    }, [offset, hasMore]);
    
    // 🔄 Загрузка по ID
    useEffect(() => {
        const fetchBankAccountsById = async () => {
            if (searchIDofelem) {
                try {
                    const response = await api.post("api/v1/bank/bankaccountsfilter/", {
                        id: searchIDofelem,
                        is_active: [true, false],
                    });
                    if (response.data.length > 0) {
                        setNameFilter(response.data[0].name);
                        setBankAccounts(response.data);
                    }
                } catch (error) {
                    console.error("Ошибка при загрузке счета по ID:", error);
                }
            } else {
                setNameFilter("");
                setBankAccounts([]);
                setOffset(0);
                setHasMore(true);
                fetchBankAccounts();
            }
        };
        fetchBankAccountsById();
    }, [searchIDofelem]);
    

    const handleUpdateBankAccounts = async (isActive) => {
        setLoading(true);
        try {
            await Promise.all(
                checkedBankAccounts.map((id) =>
                    api.patch(`api/v1/bank/bankaccounts/${id}/update/`, { is_active: isActive })
                )
            );
            setBankAccounts([]);
            fetchBankAccounts();
            setOffset(0);
            setHasMore(true);
            setCheckedBankAccounts([]);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const handleReset = () => {
        const search = location.search;
        navigate(location.search.replace(search, ""));
        window.location.reload();
    };

    const handleArchiveBankAccounts = () => handleUpdateBankAccounts(false);
    const handleUnarchiveBankAccounts = () => handleUpdateBankAccounts(true);

    const handleCheckboxChange = (orgId) => {
        setCheckedBankAccounts((prevOrgs) => {
            if (prevOrgs.includes(orgId)) {
                return prevOrgs.filter((id) => id !== orgId);
            } else {
                return [...prevOrgs, orgId];
            }
        });
    };

    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const allOrgs = bankaccs.map((bankacc) => bankacc.id);
            setCheckedBankAccounts(allOrgs);
        } else {
            setCheckedBankAccounts([]);
        }
    };

    const allChecked =
        bankaccs.length > 0 && bankaccs.every((bankacc) => checkedBankAccounts.includes(bankacc.id));

    const handleSearch = async () => {
        try {
            const response = await api.post("api/v1/bank/bankaccountsfilter/", {
                name: nameFilter.toLowerCase(),
                number_of_check: nubmerFilter,
                is_active: [!archivedFilterNotArchive, archivedFilterActive],
                company: organFilter,
            });
            setBankAccounts(response.data);
        } catch (error) {}
    };

    const handleSubmit = async (e) => {
        e?.preventDefault();
        
        const requiredFields = [
            { key: "name", message: "Введите название" },
            { key: "account_number", message: "Введите номер счета" },
            { key: "bank", message: "Выберите банк" },
            { key: "company", message: "Выберите организацию" },
            { key: "currency", message: "Выберите валюту" },
            { key: "account_type", message: "Введите вид счета" },
            { key: "owner_counterparty", message: "Выберите контрагента" }
        ];
    
        const errors = {};
        requiredFields.forEach(field => {
            const value = formData[field.key];
            
            // Специальная проверка для полей с объектами
            if (['bank', 'company', 'currency', 'owner_counterparty'].includes(field.key)) {
                if (!value || (typeof value === 'object' && !value.id)) {
                    errors[field.key] = field.message;
                }
            } 
            // Проверка текстовых полей
            else if (!value || String(value).trim() === '') {
                errors[field.key] = field.message;
            }
        });
    
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }
    
        setLoading(true);
        try {
            const getIdOrValue = (obj) => obj && (obj.id || obj) || "";
            const preparedFormData = {
                ...formData,
                company: getIdOrValue(formData.company),
                bank: getIdOrValue(formData.bank),
                currency: getIdOrValue(formData.currency),
                owner_organization: getIdOrValue(formData.owner_organization),
                owner_counterparty: getIdOrValue(formData.owner_counterparty),
            };
    
            if (isEditing) {
                await api.patch(`/api/v1/bank/bankaccounts/${formData.id}/update/`, preparedFormData);
            } else {
                await api.post("api/v1/bank/createbankaccount/", preparedFormData);
            }
            
            handleCloseModal();
            fetchBankAccounts();
        } catch (error) {
            console.error("Ошибка при сохранении:", error);
            alert("Не удалось сохранить данные. Проверьте правильность заполнения полей.");
        } finally {
            setLoading(false);
        }
    };
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        if (errors[name]) {
            setErrors((prev) => ({ ...prev, [name]: "" }));
        }
    };

    const resetForm = () => {
        setFormData(initialFormData);
        setIsEditing(false);
    };

    const handleCloseModal = () => {
        resetForm();
        setShowModal(false);
        setErrors({});
    };

    const toggleColumn = (column) => {
        setSelectedColumns((prev) =>
            prev.includes(column) ? prev.filter((col) => col !== column) : [...prev, column]
        );
    };

    const selectAllColumns = () => {
        setSelectedColumns([...ALL_COLUMNS]);
    };

    const cancelSettings = () => {
        setShowSettings(false);
    };

    const saveSettings = () => {
        setShowSettings(false);
    };

    const title = isEditing ? "Редактирование банковского счета" : "Создание банковского счета";

    return (
        <div className="container-fluid" translate="no">
            <Header />
            <div className="row flex-nowrap">
                <SideBar />
                <div className="col py-3">
                    <div className="management">
                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex justify-content-between">
                                <div className="d-flex gap-2 mb-3">
                                    <PageHeading>Банковские счета</PageHeading>
                                    <Button
                                        bType="secondary"
                                        size="small"
                                        fixed
                                        onClick={() => {
                                            setFiltersVisible(!filtersVisible);
                                        }}
                                    >
                                        <i className="bi bi-funnel"></i>
                                    </Button>
                                    <Button bType="primary" size="small" fixed onClick={() => setShowModal(true)}>
                                        <i className="bi bi-plus-circle"></i>
                                    </Button>
                                </div>
                                    <Button bType="secondary" size="small" fixed onClick={() => setShowSettings(true)}>
                                        <i className="bi bi-gear"></i>
                                    </Button>
                                </div>
                                {errorMessage && (
                                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                                        {errorMessage}
                                    </Alert>
                                )}
                                <UnauthorizedModal 
                                    show={showUnauthorizedModal} 
                                    onClose={() => setShowUnauthorizedModal(false)} 
                                />
                                {filtersVisible && (
                                    <div className="mb-3 p-3 border rounded">
                                        <Heading level={6}>Фильтры:</Heading>
                                        <div className="row g-3">
                                            <div className="col-md-4 col-lg-3">
                                                <label className="form-label">Название счета</label>
                                                <Input
                                                    type="text"
                                                    placeholder="Введите название счета"
                                                    value={nameFilter}
                                                    onChange={(e) => setNameFilter(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <label className="form-label">Номер счета</label>
                                                <Input
                                                    type="text"
                                                    placeholder="Введите номер счета"
                                                    value={nubmerFilter}
                                                    onChange={(e) => setnumberFilter(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <label className="form-label">Организация</label>
                                                <APIMultipleSearchableSelect
                                                    endpoint="/api/v1/organization/search/"
                                                    value={organFilter}
                                                    onChange={(value) => setSelectedOrganization(value)}
                                                    placeholder="Выберите организацию"
                                                />
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <label className="form-label">Архивный</label>
                                                <div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="archivedCheckNotActive"
                                                            checked={archivedFilterNotArchive}
                                                            onChange={(e) => setArchivedFilterNotActive(e.target.checked)}
                                                        />
                                                        <label className="form-check-label" htmlFor="archivedCheckNotActive">
                                                            Да
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="archivedCheckActive"
                                                            checked={archivedFilterActive}
                                                            onChange={(e) => setArchivedFilterActive(e.target.checked)}
                                                        />
                                                        <label className="form-check-label" htmlFor="archivedCheckActive">
                                                            Нет
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center gap-2">
                                                <Button onClick={handleSearch} bType="primary" size="medium" fixed>
                                                    Поиск
                                                </Button>
                                                <Button onClick={handleReset} bType="secondary" size="medium" fixed>
                                                    Сбросить
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                
                                {checkedBankAccounts.length > 0 && (
                                    <div className="d-flex gap-2 mb-3">
                                        <Button
                                            onClick={handleArchiveBankAccounts}
                                            bType="secondary"
                                            size="medium"
                                            fixed
                                            disabled={checkedBankAccounts.length === 0}
                                        >
                                            Архивировать выбранные
                                        </Button>
                                        <Button
                                            onClick={handleUnarchiveBankAccounts}
                                            bType="secondary"
                                            size="medium"
                                            fixed
                                            disabled={checkedBankAccounts.length === 0}
                                        >
                                            Разархивировать выбранные
                                        </Button>
                                    </div>
                                )}
                                <div
                                    ref={containerRef}
                                    className="Payments__wrapper"
                                    style={{ maxHeight: "73vh", height: "73vh", overflowX: "auto", position: "relative" }}
                                >
                                    <table
                                        className="Payments__table"
                                        style={{
                                            tableLayout: "fixed",
                                        }}
                                    >
                                        <thead>
                                        <tr>
                                            <th style={{ width: 50 }}>
                                                <input type="checkbox" checked={allChecked} onChange={handleSelectAllChange} />
                                            </th>
                                            {columnsInUse.map((col) => {
                                                const w = columnWidths[col] || DEFAULT_COLUMN_WIDTHS[col];
                                                return (
                                                    <th
                                                        key={col}
                                                        style={{
                                                            width: w,
                                                            minWidth: w,
                                                            maxWidth: w,
                                                            position: "relative",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <span>{col}</span>
                                                            <div
                                                                style={{
                                                                    cursor: "col-resize",
                                                                    padding: "0 4px",
                                                                    marginRight: "-4px",
                                                                    userSelect: "none",
                                                                    zIndex: 9999
                                                                }}
                                                                onMouseDown={(e) => onMouseDown(e, col)}
                                                            >
                                                                ||
                                                            </div>
                                                        </div>
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {bankaccs.map((bankacc) => (
                                            <tr key={bankacc.id} onClick={() => handleRowClick(bankacc)}>
                                                <td style={{ width: 50 }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={checkedBankAccounts.includes(bankacc.id)}
                                                        onChange={(e) => {
                                                            e.stopPropagation();
                                                            handleCheckboxChange(bankacc.id);
                                                        }}
                                                    />
                                                </td>
                                                {selectedColumns.includes("Название") && (
                                                    <td
                                                        style={{
                                                            width: columnWidths["Название"] || DEFAULT_COLUMN_WIDTHS["Название"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                    >
                                                        {bankacc.name}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Номер") && (
                                                    <td
                                                        style={{
                                                            width: columnWidths["Номер"] || DEFAULT_COLUMN_WIDTHS["Номер"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                    >
                                                        {bankacc.account_number}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Организация") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Организация"] || DEFAULT_COLUMN_WIDTHS["Организация"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                    >
                                                        {bankacc.company?.name}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Код") && (
                                                    <td
                                                        style={{
                                                            width: columnWidths["Код"] || DEFAULT_COLUMN_WIDTHS["Код"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                    >
                                                        {bankacc.code}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Вид счета") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Вид счета"] || DEFAULT_COLUMN_WIDTHS["Вид счета"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                    >
                                                        {bankacc.account_type}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Банк") && (
                                                    <td
                                                        style={{
                                                            width: columnWidths["Банк"] || DEFAULT_COLUMN_WIDTHS["Банк"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                    >
                                                        {bankacc.bank?.name}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Валюта") && (
                                                    <td
                                                        style={{
                                                            width: columnWidths["Валюта"] || DEFAULT_COLUMN_WIDTHS["Валюта"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                    >
                                                        {bankacc.currency?.name}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Владелец (Орг.)") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Владелец (Орг.)"] ||
                                                                DEFAULT_COLUMN_WIDTHS["Владелец (Орг.)"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "normal",
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        {bankacc.owner_organization?.name || ""}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Владелец (Контр.)") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Владелец (Контр.)"] ||
                                                                DEFAULT_COLUMN_WIDTHS["Владелец (Контр.)"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "normal",
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        {bankacc.owner_counterparty?.name || ""}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Архивный") && (
                                                    <td
                                                        style={{
                                                            width: columnWidths["Архивный"] || DEFAULT_COLUMN_WIDTHS["Архивный"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                    >
                                                        {bankacc.is_active ? "Нет" : "Да"}
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                {loading && (
                                    <div className="position-absolute top-50 start-50 translate-middle">
                                        <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        {errorMessage && (
                        <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                            {errorMessage}
                        </Alert>
                        )}
                        <UnauthorizedModal 
                            show={showUnauthorizedModal} 
                            onClose={() => setShowUnauthorizedModal(false)} 
                        />
                    <form className="bankaccount-form" onSubmit={handleSubmit}>
                        <div className="form_input">
                            <div className="row g-1">
                                <div className="col-md-12">
                                <label className="form-label">Наименование банковского счета*</label>
                                    <Input
                                        type="text"
                                        name="name"
                                        value={formData.name || ""}
                                        placeholder="Введите наименование"
                                        onChange={handleChange}
                                    />
                                    {errors.name && <span className="invalid-feedback">{errors.name}</span>}
                                </div>
                                <div className="col-md-12">
                                <label className="form-label">Номер счета*</label>
                                    <Input
                                        type="text"
                                        name="account_number"
                                        value={formData.account_number || ""}
                                        onChange={handleChange}
                                        placeholder="Введите номер счета"
                                    />
                                    {errors.account_number && (
                                        <span className="invalid-feedback">{errors.account_number}</span>
                                    )}
                                </div>
                                <div className="col-md-12">
                                <label className="form-label">Банк*</label>
                                    <SearchableSelect
                                        endpoint="/api/v1/bank/"
                                        value={formData.bank || ""}
                                        onChange={(value) => handleChange({ target: { name: "bank", value } })}
                                        placeholder="Выберите банк"
                                        className={errors.bank ? "is-invalid" : ""}
                                        isClearable
                                        setShowUnauthorizedModal={openModal}
                                        isMulti={false}
                                    />
                                    {errors.bank && <div className="invalid-feedback d-block bg-white p-1 rounded text-danger">{errors.bank}</div>}
                                </div>
                                <div className="col-md-12">
                                <label className="form-label">Валюта*</label>
                                    <SearchableSelect
                                        endpoint="/api/v1/bank/currencies/"
                                        value={formData.currency || ""}
                                        onChange={(value) => handleChange({ target: { name: "currency", value } })}
                                        placeholder="Выберите валюту"
                                        className={errors.currency ? "is-invalid" : ""}
                                        isClearable
                                        isMulti={false}
                                        setShowUnauthorizedModal={openModal}
                                    />
                                    {errors.currency && <div className="invalid-feedback d-block bg-white p-1 rounded text-danger">{errors.currency}</div>}
                                </div>
                                <div className="col-md-12">
                                <label className="form-label">Вид счета*</label>
                                    <Input
                                        type="text"
                                        name="account_type"
                                        value={formData.account_type || ""}
                                        onChange={handleChange}
                                        placeholder="Введите вид счета"
                                    />
                                    {errors.account_type && <div className="invalid-feedback">{errors.account_type}</div>}
                                </div>
                                <div className="col-md-12">
                                <label className="form-label">Организация*</label>
                                    <SearchableSelect
                                        endpoint="/api/v1/organization/"
                                        value={formData.company || ""}
                                        onChange={(value) => handleChange({ target: { name: "company", value } })}
                                        placeholder="Выберите организацию"
                                        className={errors.company ? "is-invalid" : ""}
                                        isClearable
                                        isMulti={false}
                                        setShowUnauthorizedModal={openModal}
                                    />
                                    {errors.company && <div className="invalid-feedback d-block bg-white p-1 rounded text-danger">{errors.company}</div>}
                                </div>
                                <div className="col-md-12">
                                <label className="form-label">Владелец(Организация)*</label>
                                    <SearchableSelect
                                        endpoint="/api/v1/organization/"
                                        value={formData.owner_organization || ""}
                                        onChange={(value) =>
                                            handleChange({ target: { name: "owner_organization", value } })
                                        }
                                        placeholder="Выберите организацию"
                                        isClearable
                                        isMulti={false}
                                        setShowUnauthorizedModal={openModal}
                                    />
                                </div>
                                <div className="col-md-12">
                                <label className="form-label">Владелец(Контрагент)*</label>
                                    <SearchableSelect
                                        endpoint="api/v1/bills/counterparty/search/"
                                        value={formData.owner_counterparty || ""}
                                        onChange={(value) =>
                                            handleChange({ target: { name: "owner_counterparty", value } })
                                        }
                                        placeholder="Выберите контрагента"
                                        className={errors.owner_counterparty ? "invalid-feedback bg-white p-1 rounded text-danger" : ""}
                                        isClearable
                                        isMulti={false}
                                        setShowUnauthorizedModal={openModal}
                                    />
                                </div>
                                {/* <div className="col-md-12">
                                    <Input
                                        type="text"
                                        name="code"
                                        value={formData.code}
                                        onChange={handleChange}
                                        placeholder="Введите код"
                                    />
                                </div> */}
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button bType="secondary" size="medium" fixed type="button" onClick={handleCloseModal}>
                        Закрыть
                    </Button>
                    <Button bType="primary" size="medium" fixed onClick={handleSubmit}>
                        {isEditing ? "Сохранить" : "Создать"}
                    </Button>
                </Modal.Footer>
            </Modal>
            {showSettings && (
                <div className="modal" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}>
                    <div className="modal-dialog" style={{ maxWidth: "700px" }}>
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div className="modal-header" style={{ borderBottom: "2px solid #eee" }}>
                                <h5 className="modal-title">Настройка столбцов</h5>
                                <button className="btn-close" onClick={cancelSettings}></button>
                            </div>
                            <div className="modal-body" style={{ padding: "20px" }}>
                                <div className="container-fluid">
                                    <div className="row" style={{ gap: "10px", justifyContent: "space-evenly" }}>
                                        {ALL_COLUMNS.map((column) => (
                                            <div
                                                key={column}
                                                className={`col-4 p-2 d-flex align-items-center justify-content-start ${
                                                    selectedColumns.includes(column) ? "selected-column" : ""
                                                }`}
                                                onClick={() => toggleColumn(column)}
                                                style={{
                                                    cursor: "pointer",
                                                    border: selectedColumns.includes(column)
                                                        ? "2px solid #007BFF"
                                                        : "1px solid #ddd",
                                                    padding: "8px",
                                                    transition: "0.3s",
                                                    borderRadius: "5px",
                                                    width: "30%",
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={selectedColumns.includes(column)}
                                                    readOnly
                                                    className="me-2"
                                                />
                                                <span className={selectedColumns.includes(column) ? "text-primary" : ""}>
                          {column}
                        </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer" style={{ borderTop: "2px solid #eee" }}>
                                <Button bType="secondary" size="medium" fixed onClick={selectAllColumns}>
                                    Выбрать все
                                </Button>
                                <Button bType="secondary" size="medium" fixed onClick={cancelSettings}>
                                    Отмена
                                </Button>
                                <Button bType="primary" size="medium" fixed onClick={saveSettings}>
                                    Сохранить
                                </Button>
                            </div>
                        </div>
                    </div>
                    <UnauthorizedModal 
                        show={showUnauthorizedModal} 
                        onClose={() => setShowUnauthorizedModal(false)} 
                    />
                </div>
            )}
        </div>
    );
};

export default BankAccounts;
