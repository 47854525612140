import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import 'bootstrap-icons/font/bootstrap-icons.css';
import "../styles/homePageStyle.css";
import { Link } from 'react-router-dom';
import SideBar from "./SideBar";
import Header from "./Header";
import IntegrationContent from "./Integrations";
import SchemeFinmodel from "./SchemeFinmodel"
import UserList from "./UserList"
import RolesTable from "./roles/RolesTable";
import { Alert } from "react-bootstrap";

function Finmodel() {
    const [activeSection, setActiveSection] = useState("finmodel");
    const [errorMessage, setErrorMessage] = useState("");

    // Reset error message when active section changes
    useEffect(() => {
        setErrorMessage("");
    }, [activeSection]);

    // Handler for section change
    const handleSectionChange = (section) => {
        setActiveSection(section);
    };

    return (
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
            <SideBar/>
                <div className="col py-3">
                <h3>Создание финансовой модели</h3>
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <div className="container-fluid">
                            <div className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <Link 
                                            className={`nav-link ${activeSection === "finmodel" ? "active" : ""}`} 
                                            to="#" 
                                            onClick={() => handleSectionChange("finmodel")}
                                        >
                                            Схема фин.модели
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link 
                                            className={`nav-link ${activeSection === "integration" ? "active" : ""}`} 
                                            to="#" 
                                            onClick={() => handleSectionChange("integration")}
                                        >
                                            Интеграции
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link 
                                            className={`nav-link ${activeSection === "users" ? "active" : ""}`} 
                                            to="#" 
                                            onClick={() => handleSectionChange("users")}
                                        >
                                            Пользователи
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link 
                                            className={`nav-link ${activeSection === "roles" ? "active" : ""}`} 
                                            to="#" 
                                            onClick={() => handleSectionChange("roles")}
                                        >
                                            Группы доступов
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                    {errorMessage && (
                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                        {errorMessage}
                    </Alert>
                    )}
                    <div>
                        {activeSection === "finmodel" && <SchemeFinmodel setErrorMessage={setErrorMessage} />}                        
                        {activeSection === "integration" && <IntegrationContent />}
                        {activeSection === "users" && <UserList />}
                        {activeSection === "roles" && <RolesTable />}
                        {activeSection === "reports" && <div>Регулярные отчеты Page Content</div>}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Finmodel;