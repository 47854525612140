import React, { useState, useEffect } from "react";
import "../styles/homePageStyle.css";
import api from "../api";
import { Modal, Alert } from "react-bootstrap";
import UnauthorizedModal from "./Unauthorized"; 

const IntegrationContent = () => {
  const [isModal, setModal] = useState(false);
  const [integrations, setIntegrations] = useState([]);
  const [isAccessDenied, setIsAccessDenied] = useState(false); 
  const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false); 
  const [errorMessage, setErrorMessage] = useState(""); 

  useEffect(() => {
    fetchIntegrations();
  }, []);

  const fetchIntegrations = async () => {
    try {
      const response = await api.get("/api/v1/connection/");
      setIntegrations(response.data);
    } catch (error) {
      console.error("Ошибка загрузки интеграций", error);
      if (error.response) {
        if (error.response.status === 401) {
          console.log("401 ошибка! Требуется авторизация.");
          setShowUnauthorizedModal(true);
        } else if (error.response.status === 403) {
          setIsAccessDenied(true); 
          setErrorMessage("Доступ запрещен! Вы не можете добавлять интеграции.");
        }
      } else {
        setErrorMessage("Ошибка соединения с сервером.");
      }
    }
  };

  const handleCancel = () => {
    setModal(false);
  };

  const handleAddIntegration = async (e) => {
    e.preventDefault();
    const integrationName = document.getElementById("integrationName").value;
    try {
      const response = await api.post("/api/v1/connection/create/", {
        name: integrationName,
      });
      console.log("Response:", response.data);
      setModal(false);
      fetchIntegrations();
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          setErrorMessage("Недостаточно прав для добавления интеграции.");
          setIsAccessDenied(true);
        } else {
          setErrorMessage("Ошибка добавления интеграции.");
        }
      } else {
        setErrorMessage("Ошибка соединения с сервером.");
      }
      console.error("Ошибка:", error);
    }
  };

  return (
    <div>
      <button
        className="btn btn-secondary me-2 btn-orange text-black"
        onClick={() => setModal(true)}
        disabled={isAccessDenied}
        style={isAccessDenied ? { backgroundColor: "#6c757d", borderColor: "#6c757d", cursor: "not-allowed" } : {}}
      >
        + Добавить интеграцию
      </button>
      <div className="mt-3">
      {errorMessage && (
        <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
          {errorMessage}
        </Alert>
      )}

      <UnauthorizedModal 
        show={showUnauthorizedModal} 
        onClose={() => setShowUnauthorizedModal(false)} 
      />
      </div>
      <Modal show={isModal} onHide={handleCancel} centered>
        <Modal.Header closeButton>
          <Modal.Title>Добавить интеграцию</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="bankaccount-form" onSubmit={handleAddIntegration}>
            <div>
              <div className="mb-3">
                <label htmlFor="integrationName" className="form-label">Наименование</label>
                <input type="text" className="form-control" id="integrationName" placeholder="Введите наименование" />
              </div>
              <div className="mb-3"><a href="#">Скачать расширение 1С Бухгалтерия</a></div>
              <div className="mb-3"><a href="#">Скачать инструкцию для импорта данных</a></div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={handleCancel}>
            Закрыть
          </button>
          <button className="btn btn-primary" onClick={handleAddIntegration} disabled={isAccessDenied}>
            Сохранить
          </button>
        </Modal.Footer>
      </Modal>
      <div className="mt-4">
        {integrations.map((integration, index) => (
          <div key={index} className="card mb-3 integration-card">
            <div className="card-body d-flex justify-content-between align-items-left">
              <div className="d-flex align-items-center">
                <label className="switch">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                <div className="ms-3">
                  <h5 className="card-title mb-1">{integration.name}</h5>
                  <p className="card-text mb-1">Token: {integration.jwt_token}</p>
                  <p className="card-text mb-0">Дата создания: {new Date().toLocaleDateString()}</p>
                </div>
                <div className="ms-5">
                  <button className="btn btn-light me-2">
                    <i className="bi bi-files"></i>
                  </button>
                  <button className="btn btn-light me-2">
                    <i className="bi bi-eraser"></i>
                  </button>
                  <button className="btn btn-light">
                    <i className="bi bi-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IntegrationContent;
