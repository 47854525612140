import { useState, useEffect, useRef, useCallback } from "react";
import api from "../../api";
import { useLocation, useNavigate } from "react-router-dom";
import SideBar from "../SideBar";
import Header from "../Header";
import ReactLoading from "react-loading";
import PageHeading from "../ui/PageHeading/PageHeading";
import Heading from "../ui/Heading/Heading";
import Button from "../ui/Button/Button";
import UnauthorizedModal from "../Unauthorized"; 
import { Alert } from 'react-bootstrap';

const ALL_COLUMNS = ["Наименование организации", "БИН/ИИН", "Дата создания", "Архивный"];
const DEFAULT_COLUMN_WIDTHS = {
    "Наименование организации": 250,
    "БИН/ИИН": 120,
    "Дата создания": 150,
    "Архивный": 100,
};

const OrganizationList = () => {
    const [organizations, setOrganizations] = useState([]);
    const [checkedOrganizations, setCheckedOrganizations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const containerRef = useRef(null);
    const limit = 25;
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [nameFilter, setNameFilter] = useState("");
    const [iinbinFilter, setInnbinFilter] = useState("");
    const [archivedFilterYES, setArchivedFilterYES] = useState(false);
    const [archivedFilterNO, setArchivedFilterNO] = useState(false);
    const [startDateFilterOne, setStartOneDateFilter] = useState("");
    const [startDateFilterTwo, setStartTwoDateFilter] = useState("");
    const location = useLocation();
    const navigate = useNavigate();
    const searchIDofelem = location.search.replace("?", "");
    const [showSettings, setShowSettings] = useState(false);
    const [errorMessage, setErrorMessage] = useState(""); 
    const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false);

    const [selectedColumns, setSelectedColumns] = useState(() => {
        const saved = localStorage.getItem("organizationSelectedColumns");
        return saved ? JSON.parse(saved) : [...ALL_COLUMNS];
    });

    const [columnWidths, setColumnWidths] = useState(() => {
        const saved = localStorage.getItem("organizationColumnWidths");
        if (saved) return { ...DEFAULT_COLUMN_WIDTHS, ...JSON.parse(saved) };
        return { ...DEFAULT_COLUMN_WIDTHS };
    });

    useEffect(() => {
        localStorage.setItem(
            "organizationSelectedColumns",
            JSON.stringify(selectedColumns)
        );
    }, [selectedColumns]);

    const resizingInfoRef = useRef({
        isResizing: false,
        startX: 0,
        columnKey: null,
        initialWidth: 0,
    });

    const onMouseDown = (e, column) => {
        e.preventDefault();
        e.stopPropagation();
        resizingInfoRef.current.isResizing = true;
        resizingInfoRef.current.startX = e.clientX;
        resizingInfoRef.current.columnKey = column;
        resizingInfoRef.current.initialWidth =
            columnWidths[column] || DEFAULT_COLUMN_WIDTHS[column] || 100;
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };

    const onMouseMove = (e) => {
        if (!resizingInfoRef.current.isResizing) return;
        const { startX, columnKey, initialWidth } = resizingInfoRef.current;
        const delta = e.clientX - startX;
        const newWidth = Math.max(50, initialWidth + delta);
        setColumnWidths((prev) => ({
            ...prev,
            [columnKey]: newWidth,
        }));
    };

    const onMouseUp = () => {
        resizingInfoRef.current.isResizing = false;
        // document.removeEventListener("mousemove", onMouseMove);
        // document.removeEventListener("mouseup", onMouseUp);
        setColumnWidths((prev) => {
            localStorage.setItem("organizationColumnWidths", JSON.stringify(prev));
            return prev;
        });
    };

    const columnsInUse = ALL_COLUMNS.filter((col) => selectedColumns.includes(col));
    const totalTableWidth = columnsInUse.reduce((acc, col) => {
        return acc + (columnWidths[col] || DEFAULT_COLUMN_WIDTHS[col]);
    }, 0);

    // const openModal = (open) => {
    //     if (open) { 
    //         setShowModal(false);
    //         setShowUnauthorizedModal(true);
    //     } else { 
    //         setShowUnauthorizedModal(false);
    //     }

    // };

    const fetchOrganizations = async (currentOffset = 0) => {
        setLoading(true);
        try {
            if (searchIDofelem) {
                const response = await api.post("/api/v1/organization/organizationfilter/", {
                    id: searchIDofelem,
                    is_active: [true, false],
                });
                setOrganizations(response.data);
                setHasMore(false);
            } else {
                const response = await api.get(
                    `/api/v1/organization/?limit=${limit}&offset=${currentOffset}`
                );
                setOrganizations((prev) => {
                    const newItems = response.data.results.filter(
                        (item) => !prev.some((org) => org.id === item.id)
                    );
                    return [...prev, ...newItems];
                });
                setHasMore(!!response.data.next);
            }
        } catch (error) {
            console.error("Ошибка при загрузке контрагентов:", error);
    
            if (error.response) {
                console.error("Ответ сервера:", error.response.status, error.response.data);

                if (error.response.status === 401) {
                    console.log("401 ошибка! Перезайдите, пожалуйста.");
                    // openModal();
                } else if (error.response.status === 403) {
                    setErrorMessage("Доступ запрещен! У вас недостаточно прав на просмотр организаций.");
                } else if (error.response.status === 400) {
                    setErrorMessage(error.response.data.detail || "Ошибка запроса.");
                } else if (error.response.status === 500) {
                    setErrorMessage("Ошибка сервера! Попробуйте позже.");
                } else {
                    setErrorMessage(`Неизвестная ошибка: ${error.response.status}`);
                }
            } else {
                console.error("Ошибка сети или сервера:", error);
                setErrorMessage("Ошибка соединения с сервером. Проверьте интернет.");
            }

            setHasMore(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setOrganizations([]);
        setOffset(0);
        setHasMore(true);
        fetchOrganizations();
    }, [location]);

    useEffect(() => {
        if (offset > 0 && hasMore) {
            fetchOrganizations(offset);
        }
    }, [offset, hasMore]);

    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
                setOffset((prev) => prev + limit);
            }
        }
    }, [hasMore, loading]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener("scroll", handleScroll);
            // return () => container.removeEventListener("scroll", handleScroll);
        }
    }, [handleScroll]);

    const handleArchiveOrganizations = async () => {
        await handleUpdateOrganizations(false);
    };

    const handleUnarchiveOrganizations = async () => {
        await handleUpdateOrganizations(true);
    };

    const handleUpdateOrganizations = async (isActive) => {
        setLoading(true);
        try {
            await Promise.all(
                checkedOrganizations.map((id) =>
                    api.patch(`/api/v1/organization/${id}/update`, { is_active: isActive })
                )
            );
            setOrganizations([]);
            setOffset(0);
            setHasMore(true);
            setCheckedOrganizations([]);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const handleCheckboxChange = (orgId) => {
        setCheckedOrganizations((prev) =>
            prev.includes(orgId) ? prev.filter((id) => id !== orgId) : [...prev, orgId]
        );
    };

    const handleSearch = async () => {
        setOrganizations([]);
        setOffset(0);
        setHasMore(false);
        setLoading(true);
        try {
            const response = await api.post("/api/v1/organization/organizationfilter/", {
                name: nameFilter.toLowerCase() || undefined,
                is_active: [!archivedFilterYES, archivedFilterNO],
                startDateOne: startDateFilterOne || undefined,
                startDateTwo: startDateFilterTwo || undefined,
                iinbin: iinbinFilter || undefined,
            });
            setOrganizations(response.data);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const handleReset = () => {
        const search = location.search;
        navigate(location.search.replace(search, ""));
        window.location.reload();
    };

    const handleSelectAllChange = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            const allIds = organizations.map((org) => org.id);
            setCheckedOrganizations(allIds);
        } else {
            setCheckedOrganizations([]);
        }
    };

    const allChecked =
        organizations.length > 0 &&
        organizations.every((org) => checkedOrganizations.includes(org.id));

    const toggleColumn = (column) => {
        setSelectedColumns((prev) =>
            prev.includes(column) ? prev.filter((c) => c !== column) : [...prev, column]
        );
    };

    const selectAllColumns = () => {
        setSelectedColumns([...ALL_COLUMNS]);
    };

    const cancelSettings = () => {
        setShowSettings(false);
    };

    const saveSettings = () => {
        setShowSettings(false);
    };

    return (
        <div className="container-fluid" translate="no">
            <Header />
            <div className="row flex-nowrap">
                <SideBar />
                <div className="col py-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between mb-3">
                                    <div className="d-flex align-items-center gap-2">
                                        <PageHeading>Организации</PageHeading>
                                        <Button
                                            bType="secondary"
                                            size="small"
                                            fixed
                                            onClick={() => setFiltersVisible(!filtersVisible)}
                                        >
                                            <i className="bi bi-funnel"></i>
                                        </Button>
                                    </div>
                                    <Button
                                        bType="secondary"
                                        size="small"
                                        fixed
                                        onClick={() => setShowSettings(true)}
                                    >
                                        <i className="bi bi-gear"></i>
                                    </Button>
                                </div>
                                {errorMessage && (
                                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                                        {errorMessage}
                                    </Alert>
                                )}
                                <UnauthorizedModal 
                                    show={showUnauthorizedModal} 
                                    onClose={() => setShowUnauthorizedModal(false)} 
                                />
                                {filtersVisible && (
                                    <div className="mb-3 p-3 border rounded">
                                        <Heading level={6} style={{ marginBottom: "10px" }}>
                                            Фильтры:
                                        </Heading>
                                        <div className="row g-3">
                                            <div className="col-md-3">
                                                <label className="form-label">Наименование организации</label>
                                                <input
                                                    type="text"
                                                    placeholder="Введите наименование"
                                                    value={nameFilter}
                                                    onChange={(e) => setNameFilter(e.target.value)}
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label className="form-label">БИН/ИИН</label>
                                                <input
                                                    type="text"
                                                    placeholder="БИН/ИИН"
                                                    value={iinbinFilter}
                                                    onChange={(e) => setInnbinFilter(e.target.value)}
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label className="form-label">Дата создания</label>
                                                <div className="d-flex align-items-center">
                                                    <span style={{ marginRight: "5px" }}>с</span>
                                                    <input
                                                        type="date"
                                                        value={startDateFilterOne}
                                                        onChange={(e) => setStartOneDateFilter(e.target.value)}
                                                        className="form-control"
                                                        style={{ maxWidth: "120px" }}
                                                    />
                                                    <span style={{ margin: "0 8px" }}>—</span>
                                                    <span style={{ marginRight: "5px" }}>до</span>
                                                    <input
                                                        type="date"
                                                        value={startDateFilterTwo}
                                                        onChange={(e) => setStartTwoDateFilter(e.target.value)}
                                                        className="form-control"
                                                        style={{ maxWidth: "120px" }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label>Архивный</label>
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="archivedCheckYES"
                                                        checked={archivedFilterYES}
                                                        onChange={(e) => setArchivedFilterYES(e.target.checked)}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="archivedCheckYES"
                                                    >
                                                        Да
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="archivedCheckNO"
                                                        checked={archivedFilterNO}
                                                        onChange={(e) => setArchivedFilterNO(e.target.checked)}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="archivedCheckNO"
                                                    >
                                                        Нет
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center gap-3 mt-3">
                                            <Button onClick={handleSearch} bType="primary" size="medium" fixed>
                                                Поиск
                                            </Button>
                                            <Button onClick={handleReset} bType="secondary" size="medium" fixed>
                                                Сбросить
                                            </Button>
                                        </div>
                                    </div>
                                )}
                                <div className="d-flex align-items-center gap-2 mb-3">
                                    {checkedOrganizations.length > 0 && (
                                        <>
                                            <Button
                                                onClick={handleArchiveOrganizations}
                                                bType="secondary"
                                                size="medium"
                                                fixed
                                                style={{ marginRight: "10px" }}
                                            >
                                                Архивировать выбранные
                                            </Button>
                                            <Button
                                                onClick={handleUnarchiveOrganizations}
                                                bType="success"
                                                size="medium"
                                                fixed
                                                style={{ marginRight: "10px" }}
                                            >
                                                Разархивировать выбранные
                                            </Button>
                                        </>
                                    )}
                                </div>
                                <div
                                    ref={containerRef}
                                    className="Payments__wrapper"
                                    style={{ height: "75vh", overflowY: "scroll", position: "relative" }}
                                >
                                    <table
                                        className="Payments__table"
                                        style={{
                                            tableLayout: "fixed",
                                        }}
                                    >
                                        <thead>
                                        <tr>
                                            <th style={{ width: 50 }}>
                                                <input
                                                    type="checkbox"
                                                    checked={allChecked}
                                                    onChange={handleSelectAllChange}
                                                />
                                            </th>
                                            {columnsInUse.map((column) => {
                                                const w = columnWidths[column] || DEFAULT_COLUMN_WIDTHS[column];
                                                return (
                                                    <th
                                                        key={column}
                                                        style={{
                                                            width: w,
                                                            minWidth: w,
                                                            position: "relative",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <span>{column}</span>
                                                            <div
                                                                style={{
                                                                    cursor: "col-resize",
                                                                    padding: "0 4px",
                                                                    marginRight: "-4px",
                                                                    userSelect: "none",
                                                                }}
                                                                onMouseDown={(e) => onMouseDown(e, column)}
                                                            >
                                                                ||
                                                            </div>
                                                        </div>
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {organizations.map((org) => (
                                            <tr key={org.id}>
                                                <td style={{ width: 50 }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={checkedOrganizations.includes(org.id)}
                                                        onChange={() => handleCheckboxChange(org.id)}
                                                    />
                                                </td>
                                                {selectedColumns.includes("Наименование организации") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Наименование организации"] ||
                                                                DEFAULT_COLUMN_WIDTHS["Наименование организации"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "normal",
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        {org.name || ""}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("БИН/ИИН") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["БИН/ИИН"] ||
                                                                DEFAULT_COLUMN_WIDTHS["БИН/ИИН"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        {org.bin || ""}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Дата создания") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Дата создания"] ||
                                                                DEFAULT_COLUMN_WIDTHS["Дата создания"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        {org.created || ""}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Архивный") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Архивный"] ||
                                                                DEFAULT_COLUMN_WIDTHS["Архивный"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                    >
                                                        {org.is_active ? "Нет" : "Да"}
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    {loading && (
                                        <div className="position-absolute top-50 start-50 translate-middle">
                                            <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showSettings && (
                <div className="modal" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}>
                    <div className="modal-dialog" style={{ maxWidth: "700px" }}>
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div className="modal-header" style={{ borderBottom: "2px solid #eee" }}>
                                <h5 className="modal-title">Настройка столбцов</h5>
                                <button className="btn-close" onClick={cancelSettings}></button>
                            </div>
                            <div className="modal-body" style={{ padding: "20px" }}>
                                <div className="container-fluid">
                                    <div className="row" style={{ gap: "10px", justifyContent: "space-evenly" }}>
                                        {ALL_COLUMNS.map((column) => (
                                            <div
                                                key={column}
                                                className={`col-4 p-2 d-flex align-items-center justify-content-start ${
                                                    selectedColumns.includes(column) ? "selected-column" : ""
                                                }`}
                                                onClick={() => toggleColumn(column)}
                                                style={{
                                                    cursor: "pointer",
                                                    border: selectedColumns.includes(column)
                                                        ? "2px solid #007BFF"
                                                        : "1px solid #ddd",
                                                    padding: "8px",
                                                    transition: "0.3s",
                                                    borderRadius: "5px",
                                                    width: "30%",
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={selectedColumns.includes(column)}
                                                    readOnly
                                                    className="me-2"
                                                />
                                                <span className={selectedColumns.includes(column) ? "text-primary" : ""}>
                          {column}
                        </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer" style={{ borderTop: "2px solid #eee" }}>
                                <Button bType="secondary" size="medium" fixed onClick={selectAllColumns}>
                                    Выбрать все
                                </Button>
                                <Button bType="secondary" size="medium" fixed onClick={cancelSettings}>
                                    Отмена
                                </Button>
                                <Button bType="primary" size="medium" fixed onClick={saveSettings}>
                                    Сохранить
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OrganizationList;
