import "./Button.css"

const Button = ({
                    bType,
    size,
    children,
    fixed,
    ...options
                }) => {
    return (
        <>
            <button className={`Button Button__type__${bType} Button__size__${size} ${fixed ? "Button__fixed" : ""}`} {...options}>
                {children}
            </button>
        </>
    )
}

export default Button;