import { useState, useEffect } from "react";
import api from "../api";
import SideBar from "./SideBar";
import ReactLoading from 'react-loading';
import Header from "./Header";
import { useNavigate } from 'react-router-dom';
import useFetchData  from '../functions/UprData.jsx'
import Button from "../components/ui/Button/Button";

const CreateUserPage = () => {
    const initialFormData = {
        id: '',
        username: '',
        first_name: '',
        last_name: '',
        middle_name: '',
        email: '',
        phone_number: '',
        position: '',
        groups: [],
        password: '',
        confirmPassword: '',
    };
    const [formData, setFormData] = useState(initialFormData);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [isUsernameValid, setIsUsernameValid] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isCheckingUsername, setIsCheckingUsername] = useState(false);
    const [isCheckingEmail, setIsCheckingEmail] = useState(false);
    const finmodel= JSON.parse(localStorage.getItem('selectedFinModel'))
    const finModelId = finmodel.id;
    const finModelName = finmodel.name;
    const [selectedBlockRoles, setSelectedBlockRoles] = useState([]);
    const [availableRoles, setAvailableRoles] = useState([]);
    const [blocks, setBlocks] = useState([]);

    useEffect(() => {
        try {
            setLoading(true);
            fetchBlocks();
            fetchRoles();
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }, [])
    const fetchRoles = async () => {
        try {
            const response = await api.get('/api/v1/role/groups/');
            setAvailableRoles(response.data);
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    };
    const fetchBlocks = async () => {
        try {
            const response = await api.get(`/api/v1/finmodel/getBlockByFinModel/${finModelId}/`);
            const groupsData = response.data;
            setBlocks(groupsData);
            return groupsData;
        } catch (error) {
            console.error('Error fetching groups', error);
            return [];
        }
    };
    const handleAddBlockRole = () => {
        setSelectedBlockRoles([...selectedBlockRoles, { blockId: '', roleId: '' }]);
    };

    const handleRemoveBlockRole = (index) => {
        const updatedBlockRoles = selectedBlockRoles.filter((_, i) => i !== index);
        setSelectedBlockRoles(updatedBlockRoles);
    };

    const handleBlockRoleChange = (index, field, value) => {
        const updatedBlockRoles = [...selectedBlockRoles];
        updatedBlockRoles[index] = {
            ...updatedBlockRoles[index],
            [field]: value
        };
        setSelectedBlockRoles(updatedBlockRoles);
    };
    const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
          clearTimeout(timeout);
          timeout = setTimeout(() => func.apply(this, args), wait);
        };
      };
    
      // Check username uniqueness
        const checkUsername = async (username) => {
            if (!username.trim()) {
            setIsUsernameValid(false);
            setErrors((prev) => ({
                ...prev,
            }));
            return;
            }
      
        try {
          setIsCheckingUsername(true);
          const response = await api.get(`/api/v1/username/search/?search=${username}`);
          const usernameExists = response.data.exists;
      
          setIsUsernameValid(!usernameExists);
      
          if (usernameExists) {
            setErrors((prev) => ({
              ...prev,
              username: 'Данный пользователь зарегистрирован в системе',
            }));
          } else {
            setErrors((prev) => {
              const newErrors = { ...prev };
              delete newErrors.username;
              return newErrors;
            });
          }
        } catch (error) {
          console.error('Error checking username:', error);
          setErrors((prev) => ({
            ...prev,
            username: 'Ошибка при проверке логина',
          }));
        } finally {
          setIsCheckingUsername(false);
        }
      };
      // Check email uniqueness
      const checkEmail = async (email) => {
        if (!email.trim()) {
          setIsEmailValid(false);
          setErrors((prev) => ({
            ...prev,
          }));
          return;
        }
      
        try {
          setIsCheckingEmail(true);
          const response = await api.get(`/api/v1/email/search/?search=${email}`);
          const emailExists = response.data.exists;
      
          setIsEmailValid(!emailExists);
      
          if (emailExists) {
            setErrors((prev) => ({
              ...prev,
              email: 'Данный email зарегистрирован в системе',
            }));
            setFormData(response.data.user);
          } else {
            setErrors((prev) => {
              const newErrors = { ...prev };
              delete newErrors.email;
              return newErrors;
            });
            setFormData(prev => ({
                ...initialFormData,
                email: prev.email
            }));
          }
        } catch (error) {
          console.error('Error checking email:', error);
          setErrors((prev) => ({
            ...prev,
            username: 'Ошибка при проверке email',
          }));
        } finally {
          setIsCheckingEmail(false);
        }
      };
      const debouncedCheckUsername = debounce(checkUsername, 500);
      const debouncedCheckEmail = debounce(checkEmail, 500);
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setIsUsernameValid(false);
        setFormData(prev => ({
          ...prev,
          [name]: value
        }));
    
        if (name === 'username') {
            if (value.length >= 3) {
                debouncedCheckUsername(value);
            }
        }
        if (name === 'email') {
            if (value.length >= 3) {
                debouncedCheckEmail(value);
            }
        }
      };
      const createUserRoles = async (employeeId, blockRoles) => {
        try {
            const promises = blockRoles.map(({ blockId, roleId }) => {
                return api.post('/api/v1/finmodel/user_role/create/', {
                    user: employeeId,
                    fin_model: finModelId,
                    custom_group: roleId,
                    block: blockId
                });
            });
            await Promise.all(promises);
        } catch (error) {
            console.error('Error creating user roles:', error);
            throw error;
        }
    };

    const updateBlockEmployees = async (employeeId, blockRoles) => {
        try {
            const uniqueBlocks = [...new Set(blockRoles.map(br => br.blockId))];
            
            const promises = uniqueBlocks.map(async (blockId) => {
                // Получаем текущий блок
                const blockResponse = await api.get(`/api/v1/finmodel/block/${blockId}/`);
                const currentBlock = blockResponse.data;
                
                // Добавляем нового сотрудника к существующим
                const updatedEmployees = [...new Set([...currentBlock.employees, employeeId])];
                
                // Создаем payload для обновления блока
                const blockPayload = {
                    ...currentBlock,
                    employees: updatedEmployees,
                    fin_model: finModelId
                };

                // Обновляем блок
                return api.put(`/api/v1/finmodel/block/update/${blockId}/`, blockPayload);
            });

            await Promise.all(promises);
        } catch (error) {
            console.error('Error updating block employees:', error);
            throw error;
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Form validation
        const { username, first_name, last_name, phone_number, email, password, confirmPassword } = formData;
        const errors = {};
        if (!username) {
            errors.username = 'Username is required';
        }
        if (!email) {
            errors.email = 'Email is required';
        }
        if (!password) {
            errors.password = 'Password is required';
        }
        if (password !== confirmPassword) {
            errors.confirmPassword = 'Passwords do not match';
        }
        if (!first_name) {
            errors.first_name = 'First name is required';
        }
        if (!last_name) {
            errors.last_name = 'Last name is required';
        }
        if (!phone_number) {
            errors.phone_number = 'Phone number is required'
        }
        if (selectedBlockRoles.length === 0) {
            errors.blockRoles = 'Пожалуйста, выберите хотя бы один блок и роль';
        }
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }

        // Submit the form
        try {
            setLoading(true);
            
            // 1. Создаем пользователя
            const userData = {
                ...formData,
                groups: [] // Если группы больше не используются
            };
            const userResponse = await api.post('/api/v1/worker/', userData);
            const newEmployeeId = userResponse.data.id;

            // 2. Создаем роли для пользователя
            await createUserRoles(newEmployeeId, selectedBlockRoles);

            // 3. Добавляем пользователя в блоки
            await updateBlockEmployees(newEmployeeId, selectedBlockRoles);

            // 4. Обновляем финансовую модель
            const finModelResponse = await api.get(`/api/v1/finmodel/${finModelId}/`);
            const currentEmployees = finModelResponse.data.employees || [];
            const updatedEmployees = [...new Set([...currentEmployees, newEmployeeId])];
            
            const finModelPayload = {
                name: finModelName,
                employees: updatedEmployees,
                fin_model: finModelId
            };
            
            await api.put(`/api/v1/finmodel/update/${finModelId}/`, finModelPayload);

            // 5. Перенаправляем на страницу финансовой модели
            navigate('/finmodel');
        } catch (error) {
            console.error('Registration failed:', error.response?.data);
            setErrors(prev => ({
                ...prev,
                general: 'Ошибка при создании пользователя'
            }));
        } finally {
            setLoading(false);
        }
    };

    const handleUpdateFinModel = async () => {
        // Валидация выбранных блоков и ролей
        if (selectedBlockRoles.length === 0) {
            setErrors(prev => ({
                ...prev,
                blockRoles: 'Пожалуйста, выберите хотя бы один блок и роль'
            }));
            return;
        }

        try {
            setLoading(true);
            
            // Получаем текущую финансовую модель
            const finModelResponse = await api.get(`/api/v1/finmodel/${finModelId}/`);
            const currentEmployees = finModelResponse.data.employees || [];
            
            // Проверяем, что пользователь еще не добавлен
            if (currentEmployees.includes(formData.id)) {
                setErrors(prev => ({
                    ...prev,
                    general: 'Пользователь уже добавлен в финансовую модель'
                }));
                return;
            }

            // Создаем роли для пользователя
            await createUserRoles(formData.id, selectedBlockRoles);

            // Обновляем блоки, добавляя пользователя
            await updateBlockEmployees(formData.id, selectedBlockRoles);

            // Обновляем финансовую модель
            const updatedEmployees = [...new Set([...currentEmployees, formData.id])];
            
            const payload = {
                name: finModelName,
                employees: updatedEmployees,
                fin_model: finModelId
            };
            
            await api.put(`/api/v1/finmodel/update/${finModelId}/`, payload);
            
            navigate('/finmodel');
        } catch (error) {
            console.error('Error updating financial model:', error);
            setErrors(prev => ({
                ...prev,
                general: 'Ошибка при обновлении финансовой модели'
            }));
        } finally {
            setLoading(false);
        }
    };
    if (loading) {
        return (
            <div className="position-absolute top-50 start-50 translate-middle">
                <ReactLoading type="spin" color="#0000FF" height={50} width={50} />
            </div>);
    }


    return (
        <>
        <div className="container-fluid" translate="no">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col">
                    <div className="create-user-header mt-3">
                        <h2>Создание пользователя</h2>
                    </div>
                    <div className="create-user-content mt-5">
                        <form className="user-form">
                            <div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mb-5">
                                            <label className='form-label'>Email</label>
                                            <div className="input-group">
                                            <input
                                                className={`form-control ${errors.email ? 'is-invalid' : isEmailValid ? 'is-valid' : ''}`}
                                                type="text"
                                                name="email"
                                                placeholder="Введите email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                            />
                                            {isCheckingEmail && (
                                                <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <ReactLoading type="spin" color="#0000FF" height={20} width={20} />
                                                </span>
                                                </div>
                                            )}
                                            </div>
                                            {errors.email && <div className="invalid-feedback d-block">{errors.email}</div>}
                                        </div>
                                        <div className="mb-5">
                                            <label className="form-label">ИИН</label>
                                            <div className="input-group">
                                            <input
                                                className={`form-control ${errors.username ? 'is-invalid' : isUsernameValid ? 'is-valid' : ''}`}
                                                type="text"
                                                name="username"
                                                placeholder="Введите ИИН"
                                                value={formData.username}
                                                onChange={handleInputChange}
                                                disabled={!isEmailValid}
                                            />
                                            {isCheckingUsername && (
                                                <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <ReactLoading type="spin" color="#0000FF" height={20} width={20} />
                                                </span>
                                                </div>
                                            )}
                                            </div>
                                            {errors.username && <div className="invalid-feedback d-block">{errors.username}</div>}
                                        </div>
                                        <div className="mb-5">
                                            <label className="form-label">Фамилия</label>
                                            <input
                                            className={`form-control ${errors.first_name ? 'is-invalid' : ''}`}
                                            type="text"
                                            name="first_name"
                                            placeholder="Введите фамилию"
                                            value={formData.first_name}
                                            onChange={handleInputChange}
                                            disabled={!isEmailValid}
                                            />
                                            {errors.first_name && <div className="invalid-feedback">{errors.first_name}</div>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-5">
                                            <label className="form-label">Имя</label>
                                            <input
                                            className={`form-control ${errors.last_name ? 'is-invalid' : ''}`}
                                            type="text"
                                            name="last_name"
                                            placeholder="Введите имя"
                                            value={formData.last_name}
                                            onChange={handleInputChange}
                                            disabled={!isEmailValid}
                                            />
                                            {errors.last_name && <div className="invalid-feedback">{errors.last_name}</div>}
                                        </div>
                                        <div className="mb-5">
                                            <label className="form-label">Отчество</label>
                                            <input className={`form-control ${errors.middle_name ? 'is-invalid' : ''}`} type="text" name="middle_name" placeholder='Введите отчество' value={formData.middle_name} onChange={handleInputChange} disabled={!isEmailValid} />
                                            {errors.middle_name && <div className="invalid-feedback">{errors.middle_name}</div>}
                                        </div>
                                        <div className="mb-5">
                                            <label className="form-label">Номер телефона</label>
                                            <input className={`form-control ${errors.phone_number ? 'is-invalid' : ''}`} type="text" name="phone_number" placeholder='+7 777 77 77 777' value={formData.phone_number} onChange={handleInputChange} disabled={!isEmailValid}/>
                                            {errors.phone_number && <div className="invalid-feedback">{errors.phone_number}</div>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className='mb-5'>
                                            <label className='form-label'>Должность</label>
                                            <input className={`form-control ${errors.position ? 'is-invalid' : ''}`} type="text" name="position" placeholder='Введите должность' value={formData.position} onChange={handleInputChange} disabled={!isEmailValid}/>
                                            {errors.position && <div className="invalid-feedback">{errors.position}</div>}
                                        </div>
                                        <div className='mb-5'>
                                            <label className='form-label'>Пароль</label>
                                            <input className={`form-control ${errors.password ? 'is-invalid' : ''}`} type="password" name="password" value={formData.password} onChange={handleInputChange} placeholder='********' disabled={!isEmailValid}/>
                                            {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                                        </div>
                                        <div className='mb-5'>
                                            <label className='form-label'>Повторить пароль</label>
                                            <input className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`} type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleInputChange} placeholder='********' disabled={!isEmailValid}/>
                                            {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center mt-4">
                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Блок фин.модели</th>
                                                    <th>Роль</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedBlockRoles.map((blockRole, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <select 
                                                                className="form-select"
                                                                value={blockRole.blockId}
                                                                onChange={(e) => handleBlockRoleChange(index, 'blockId', e.target.value)}
                                                            >
                                                                <option value="">Выберите блок</option>
                                                                {blocks.map(block => (
                                                                    <option key={block.id} value={block.id}>
                                                                        {block.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select 
                                                                className="form-select"
                                                                value={blockRole.roleId}
                                                                onChange={(e) => handleBlockRoleChange(index, 'roleId', e.target.value)}
                                                            >
                                                                <option value="">Выберите роль</option>
                                                                {availableRoles.map(role => (
                                                                    <option key={role.id} value={role.id}>
                                                                        {role.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <button 
                                                                className="btn btn-danger btn-sm"
                                                                onClick={() => handleRemoveBlockRole(index)}
                                                                type="button"
                                                            >
                                                                <i className="bi bi-trash"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {errors.blockRoles && (
                                            <div className="alert alert-danger mt-2" role="alert">
                                                {errors.blockRoles}
                                            </div>
                                        )}
                                        <div className="text-center mt-3">
                                            <button 
                                                className="btn btn-success btn-sm" 
                                                onClick={handleAddBlockRole}
                                                type="button"
                                            >
                                                <i className="bi bi-plus-lg"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                            </div>
                            <Button bType="primary" size="medium" fixed onClick={formData.id ? handleUpdateFinModel : handleSubmit}>
                            {formData.id ? "Добавить в фин.модель" : "Сохранить"}
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default CreateUserPage;