import React from 'react';
import { Route, Routes, Navigate} from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import RegistrationForm from './components/RegistrationForm';
import LoginForm from './components/LoginForm';
import Home from './components/Home';
import NotFound from './components/NotFound';
import Navigation from './components/Navigation';
import ProtectedRoute from './components/ProtectedRoute';
import PaymentEdit from './components/payments/PaymentEdit';
import PaymentManagement from './components/payments/PaymentManagement';
import CreatePayment from './components/payments/CreatePayment';
import BankAccounts from './components/bankaccounts/BankAccounts';
import CaptionManagement from './components/captions/CaptionManagement';
import Finmodel from './components/Finmodel';
import SalaryManagement from './components/salaries/SalaryManagement.jsx';
import ProjectMonthPage from './components/salaries/ProjectMonthPage.jsx';
import MatrixPage from './components/salaries/MatrixPage.jsx';
import Counterparties from './components/Counterparties/Counterparties.jsx';
import Projects from './components/projects/Projects.jsx'
import Employees from './components/employees/Employee.jsx'
import CreateUserPage from './components/CreateUserPage.jsx';
import CreateGroup from './components/roles/CreateGroup.jsx';
import EditGroup from './components/roles/EditGroup.jsx';
import OrganizationPage from './components/organizations/OrganizationPage.jsx';
import BudgetsPage from './components/budgets/BudgetsPage.jsx';
import UserEditPage from './components/UserEditPage.jsx';
import BudgetPlanPage from './components/budgets/BudgetPlanPage.jsx';
import { useAuth } from './AuthContext';
import Nomenclature from './components/payments/nomenclature.jsx';
import DocumentTypes from './components/documentTypes/DocumentTypes.jsx';
import OperationTypes from './components/operationTypes/OperationTypes.jsx';
import Analytics from "./components/Analytics";
import PowerBI from "./components/PowerBI";
import Knowledge from "./components/knowledge/Knowledge";
import Agreements from "./components/agreements/Agreements";
import AccountingTypes from './components/accounting/AccountingTypes.jsx'
// import InstructionsPage from './components/instructions/KnowledgeBase.jsx';
// import CategoryPage from "./components/instructions/CategoryPage";
// import KnowledgeBase from "./components/instructions/KnowledgeBase.jsx";
// import   from './components/';
import OSTable from "./components/documents/os.jsx";
import NMAtable from "./components/documents/nma.jsx";
import TMZTable from "./components/documents/tmz.jsx";

function Logout() {
  const { logout } = useAuth();
  localStorage.removeItem('selectedFinModel');
  localStorage.removeItem('allFinModel');

  return <Navigate to="/login"/>
}

function RegisterAndLogout() {
  localStorage.clear()
  return <RegistrationForm />
}

class App extends React.Component {
  render(){

  
  return (
        <div>
            <Routes>
              <Route path='/' element={<ProtectedRoute><Home /></ProtectedRoute>}></Route>
                <Route path='/analytics' element={<ProtectedRoute><Analytics /></ProtectedRoute>}></Route>
              <Route path='/powerBI' element={<ProtectedRoute><PowerBI /></ProtectedRoute>}></Route>
              <Route path='/navigation' element={<Navigation/>}></Route>
              <Route path='/logout' element={<Logout/>}></Route>
              <Route path="/login" element={<LoginForm/>}></Route>
              <Route path='*' element={<NotFound/>}></Route>
              <Route path="/register" element={<RegistrationForm/>}></Route>
              <Route path="/edit-payment/:id" 
                    element={
                        <ProtectedRoute>
                          <PaymentEdit/>
                        </ProtectedRoute>
                      }>
              </Route>
              <Route path="/payment-registry" 
                    element={
                        <ProtectedRoute>
                          <PaymentManagement/>
                        </ProtectedRoute>
                      }>
              </Route>    
              <Route path="/docOs" 
                    element={
                        <ProtectedRoute>
                          <OSTable/>
                        </ProtectedRoute>
                      }>
              </Route> 
              <Route path="/docNMA" 
                    element={
                        <ProtectedRoute>
                          <NMAtable/>
                        </ProtectedRoute>
                      }>
              </Route>         
              <Route path="/docTMZ" 
                    element={
                        <ProtectedRoute>
                          <TMZTable/>
                        </ProtectedRoute>
                      }>
              </Route>                       
              <Route path="/create-payment" 
                    element={
                        <ProtectedRoute>
                          <CreatePayment/>
                        </ProtectedRoute>
                      }>
              </Route>
              <Route path="/bankacc" 
                    element={
                        <ProtectedRoute>
                          <BankAccounts/>
                        </ProtectedRoute>
                      }>
              </Route>
                <Route path="/agreements"
                       element={
                           <ProtectedRoute>
                               <Agreements/>
                           </ProtectedRoute>
                       }>
                </Route>
              <Route path='/captions' 
                    element={
                        <ProtectedRoute>
                          <CaptionManagement/>
                        </ProtectedRoute>
                      }>
              </Route>             
              <Route path='/finmodel' element={<ProtectedRoute><Finmodel/></ProtectedRoute>}></Route>      
              <Route path="/salaries/:month" 
                    element={
                        <ProtectedRoute>
                          <ProjectMonthPage/>
                        </ProtectedRoute>
                      }>
              </Route>   
              <Route path="/salaries" 
                    element={
                        <ProtectedRoute>
                          <SalaryManagement/>
                        </ProtectedRoute>
                      }>
              </Route>    
              <Route path="/salaries/:month/:id" 
                    element={
                        <ProtectedRoute>
                          <MatrixPage/>
                        </ProtectedRoute>
                      }>
              </Route>
              <Route path="/counterparty" 
                    element={
                        <ProtectedRoute>
                          <Counterparties/>
                        </ProtectedRoute>
                      }>
              </Route>
              <Route path="/projects" 
                    element={
                        <ProtectedRoute>
                          <Projects/>
                        </ProtectedRoute>
                      }>
              </Route>
              <Route path="/employees" 
                    element={
                        <ProtectedRoute>
                          <Employees/>
                        </ProtectedRoute>
                      }>
              </Route>
              <Route path="/create-user" 
                    element={
                        <ProtectedRoute>
                          <CreateUserPage/>
                        </ProtectedRoute>
                      }>
              </Route>
              <Route path="/edit-user/:id/" 
                    element={
                        <ProtectedRoute>
                          <UserEditPage/>
                        </ProtectedRoute>
                      }>
              </Route>
              <Route path="/create-group" 
                    element={
                        <ProtectedRoute>
                          <CreateGroup/>
                        </ProtectedRoute>
                      }>
              </Route>
              <Route path="/edit-group/:id/" 
                    element={
                        <ProtectedRoute>
                          <EditGroup/>
                        </ProtectedRoute>
                      }>
              </Route>
              <Route path="/organizations" 
                    element={
                        <ProtectedRoute>
                          <OrganizationPage/>
                        </ProtectedRoute>
                      }>
              </Route>
              <Route path="/budgets" 
                    element={
                        <ProtectedRoute>
                          <BudgetsPage/>
                        </ProtectedRoute>
                      }>
              </Route>
                <Route path="/knowledge/payments"
                       element={

                               <Knowledge/>
                       }>
                </Route>
              <Route path="/budgeting" 
                    element={
                        <ProtectedRoute>
                          <BudgetPlanPage/>
                        </ProtectedRoute>
                      }>
              </Route>
              <Route path="/nomenclature" 
                    element={
                        <ProtectedRoute>
                          <Nomenclature/>
                        </ProtectedRoute>
                    }>
              </Route>
              <Route path="/documentTypes" 
                    element={
                        <ProtectedRoute>
                          <DocumentTypes/>
                        </ProtectedRoute>
                      }>
              </Route>
              <Route path="/operationTypes" 
                    element={
                        <ProtectedRoute>
                          <OperationTypes/>
                        </ProtectedRoute>
                      }>
              </Route>
              {/* <Route path="/" 
              element={
              <KnowledgeBase/>
              }>
              </Route>
              <Route path="/instructions" 
                    element={
                    <InstructionsPage />
                    }>
              </Route>
              <Route 
                path="/instructions/category/:category" 
                element={
                  <ProtectedRoute>
                    <CategoryPage/>
                  </ProtectedRoute>
                }>
              </Route> */}
              <Route path="/accounting-accounts" 
                element={
                    <ProtectedRoute>
                    <AccountingTypes/>
                    </ProtectedRoute>
                  }>
              </Route>
              </Routes>
        </div>
        
  );
}
};
export default App;
