import { useState, useEffect, useRef, useCallback } from "react";
import { Modal, Form } from "react-bootstrap";
import Select from "react-select";
import api from "../../api";
import SideBar from "../SideBar";
import Header from "../Header";
import APIMultipleSearchableSelect from "../payments/SearchableTableMultiSelect";
import APISearchableSelect from "../payments/SearchableSelect";
import { useLocation, useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import PageHeading from "../ui/PageHeading/PageHeading";
import Heading from "../ui/Heading/Heading";
import Button from "../ui/Button/Button";
import Input from "../ui/Input/Input";
import UnauthorizedModal from "../Unauthorized"; 
import { Alert } from 'react-bootstrap';

const ALL_COLUMNS = [
    "Наименование бюджета",
    "Проект",
    "Вид бюджета",
    "Период",
    "Ответственный",
    "Дата создания",
    "Архивный",
];
const DEFAULT_COLUMN_WIDTHS = {
    "Наименование бюджета": 200,
    "Проект": 150,
    "Вид бюджета": 150,
    "Период": 180,
    "Ответственный": 150,
    "Дата создания": 150,
    "Архивный": 80,
};

const BudgetsPage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [budgets, setBudgets] = useState([]);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [checkedBudgets, setCheckedBudgets] = useState([]);
    const containerRef = useRef(null);
    const limit = 15;
    const searchIDofelem = location.search.replace("?", "");
    const [newBudget, setNewBudget] = useState({
        name: "",
        project: "",
        period_start: "",
        period_end: "",
        user_responsible: "",
        type_of_budgets: "",
    });
    const [newUserResponsible, setNewUserResponsible] = useState({});
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [nameFilter, setNameFilter] = useState("");
    const [projectFilter, setSelectedProject] = useState("");
    const [archivedFilterYES, setArchivedFilterYES] = useState(false);
    const [archivedFilterNO, setArchivedFilterNO] = useState(false);
    const [startDateFilter, setStartDateFilter] = useState("");
    const [endDateFilter, setEndDateFilter] = useState("");
    const [openFilter, setOpenedFilter] = useState(false);
    const [closeFilter, setCLosedFilter] = useState(false);
    const [type_of_budget, setTypeOfBudget] = useState([]);
    const [errors, setErrors] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isEditUserOpen, setIsEditUserOpen] = useState(false);
    const [editingBudget, setEditingBudget] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false);

    const showModal = () => {
        if (loading) return;
        if (type_of_budget.length === 0) fetchTypes();
        setIsOpen(true);
    };
    const hideModal = () => setIsOpen(false);
    const showEditModal = (budget) => {
        if (type_of_budget.length === 0) fetchTypes();
        setEditingBudget(budget);
        setIsEditOpen(true);
    };
    const hideEditModal = () => setIsEditOpen(false);
    const showEditUserModal = () => setIsEditUserOpen(true);
    const hideEditUserModal = () => setIsEditUserOpen(false);

    const handleEditBudgetChange = (e) => {
        const { name, value } = e.target;
        setEditingBudget((prev) => ({ ...prev, [name]: value }));
    };

    const formatDate = (date) => {
        if (!date) return "";
        const regex = /^\d{4}-\d{2}-\d{2}$/;
        if (regex.test(date)) return date;
        const [day, month, year] = date.split(".");
        const formattedDate = `${year}-${month}-${day}`;
        const d = new Date(formattedDate);
        if (isNaN(d.getTime())) return "";
        return formattedDate;
    };
    
    const openModal = (open) => {
        if (open) { 
            setIsOpen(false);
            setShowUnauthorizedModal(true);
        } else { 
            setShowUnauthorizedModal(false);
        }

    };

    const fetchBudgetsData = async () => {
        setLoading(true);
        try {
            if (searchIDofelem) {
                const response = await api.post("api/v1/budgets/budgetfilter/", {
                    id: searchIDofelem,
                    is_active: [true, false],
                    is_open: [true, false],
                });
                setBudgets(response.data);
                setHasMore(false);
            } else {
                const response = await api.get(`api/v1/budgets/?limit=${limit}&offset=${offset}`);
                setBudgets((prev) => {
                    const newItems = response.data.results.filter((item) => !prev.some((b) => b.id === item.id));
                    return [...prev, ...newItems];
                });
                if (!response.data.next) setHasMore(false);
            }
        } catch (error) {
            console.error("Ошибка при загрузке бюджетов:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleBudgetUpdate = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const data = {
                id: editingBudget.id,
                name: editingBudget.name,
                period_end: formatDate(editingBudget.period_end),
                period_start: formatDate(editingBudget.period_start),
                project: editingBudget.project.id,
                user_responsible: editingBudget.user_responsible.id
                    ? editingBudget.user_responsible.id
                    : editingBudget.user_responsible,
            };
            await api.patch(`api/v1/budgets/update/${editingBudget.id}`, data);
            hideEditModal();
            setEditingBudget({});
            fetchBudgetsData();
        } catch (error) {
            console.error("Error updating budget:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewBudget((prev) => ({ ...prev, [name]: value }));
    };

    const fetchTypes = async () => {
        try {
            const response = await api.get("/api/v1/budgets/type_of_budgets/");
            const options = response.data.map((type) => ({
                value: type.id,
                label: type.name,
            }));
            setTypeOfBudget(options);
        } catch (error) {
            console.error("Error fetching budget types:", error);
        }
    };

    const fetchBudgets = useCallback(
        async (currentOffset = 0) => {
            try {
                if (searchIDofelem) {
                    const response = await api.post("api/v1/budgets/budgetfilter/", {
                        id: searchIDofelem,
                        is_active: [true, false],
                        is_open: [true, false],
                    });
                    setBudgets(response.data);
                    setHasMore(false);
                } else {
                    setLoading(true);
                    const response = await api.get(`api/v1/budgets/?limit=${limit}&offset=${currentOffset}`);
                    setBudgets((prev) => {
                        const newItems = response.data.results.filter((item) => !prev.some((b) => b.id === item.id));
                        return [...prev, ...newItems];
                    });
                    if (!response.data.next) setHasMore(false);
                }
            } catch (error) {
                console.error("Ошибка при загрузке бюджетов:", error);
        
            if (error.response) {
                console.error("Ответ сервера:", error.response.status, error.response.data);

                if (error.response.status === 401) {
                    console.log("401 ошибка! Открываю модальное окно.");
                    openModal();
                } else if (error.response.status === 403) {
                    setErrorMessage("Доступ запрещен! У вас недостаточно прав на просмотр бюджетов.");
                } else if (error.response.status === 400) {
                    setErrorMessage(error.response.data.detail || "Ошибка запроса.");
                } else if (error.response.status === 500) {
                    setErrorMessage("Ошибка сервера! Попробуйте позже.");
                } else {
                    setErrorMessage(`Ошибка: ${error.response.status}`);
                }
            } else {
                console.error("Ошибка сети или сервера:", error);
                setErrorMessage("Ошибка соединения с сервером. Проверьте интернет.");
            }
                setLoading(false);
            } finally {
                setLoading(false);
            }
        },
        [location, searchIDofelem, limit]
    );

    useEffect(() => {
        setBudgets([]);
        setOffset(0);
        setHasMore(true);
        fetchBudgets(0);
    }, [location, fetchBudgets]);

    useEffect(() => {
        if (hasMore && !loading) {
            fetchBudgets(offset);
        }
    }, [offset, hasMore, fetchBudgets]);

    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
                setOffset((prev) => prev + limit);
            }
        }
    }, [hasMore, loading]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener("scroll", handleScroll);
            // return () => container.removeEventListener("scroll", handleScroll);
        }
    }, [handleScroll]);

    useEffect(() => {
        const fetchBudgetsById = async () => {
            if (searchIDofelem) {
                try {
                    const response = await api.post("api/v1/budgets/budgetfilter/", {
                        id: searchIDofelem,
                        is_active: [true, false],
                        is_open: [true, false],
                    });
                    if (response.data.length > 0) {
                        setNameFilter(response.data[0].name);
                        setBudgets(response.data);
                    }
                } catch (error) {}
            } else {
                setNameFilter("");
                setBudgets([]);
                setOffset(0);
                setHasMore(true);
                fetchBudgets();
            }
        };
        fetchBudgetsById();
    }, [searchIDofelem, fetchBudgets]);

    const handleClosing = async () => {
        setLoading(true);
        try {
            await api.patch("api/v1/budgets/close/", { budget_ids: checkedBudgets });
            setCheckedBudgets([]);
            fetchBudgetsData();
        } catch (error) {
            console.error("Error closing budgets:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleOpening = async () => {
        setLoading(true);
        try {
            await api.patch("api/v1/budgets/open/", { budget_ids: checkedBudgets });
            setCheckedBudgets([]);
            fetchBudgetsData();
        } catch (error) {
            console.error("Error opening budgets:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleChangeResponsible = async () => {
        setLoading(true);
        try {
            await api.patch("api/v1/budgets/user/responsible/", {
                budgets_Id: checkedBudgets,
                user_responsible: newUserResponsible,
            });
            hideEditUserModal();
            setNewUserResponsible({});
            setCheckedBudgets([]);
            fetchBudgetsData();
        } catch (error) {
            console.error("Error changing responsible user:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleArchiving = async (isActive) => {
        setLoading(true);
        try {
            await api.patch("api/v1/budgets/is_active/update/", {
                budgets: checkedBudgets,
                activity: isActive,
            });
            setCheckedBudgets([]);
            fetchBudgetsData();
        } catch (error) {
            console.error("Error archiving budgets:", error);
        } finally {
            setLoading(false);
        }
    };
    const handleArchiveBudgets = (e) => {
        e.preventDefault();
        handleArchiving(false);
    };
    const handleUnarchiveBudgets = (e) => {
        e.preventDefault();
        handleArchiving(true);
    };

    const handleCheckboxChange = (budgetId) => {
        setCheckedBudgets((prev) =>
            prev.includes(budgetId) ? prev.filter((id) => id !== budgetId) : [...prev, budgetId]
        );
    };

    const handleSelectAllChange = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            const allIds = budgets.map((b) => b.id);
            setCheckedBudgets(allIds);
        } else {
            setCheckedBudgets([]);
        }
    };
    const allChecked =
        budgets.length > 0 && budgets.every((b) => checkedBudgets.includes(b.id));

    const handleModalSave = async () => {
        setLoading(true);
        try {
            const { name, project, user_responsible, period_start, period_end } = newBudget;
            const newErrors = {};
            if (!name) newErrors.name = "Название бюджета обязательно";
            if (!project) newErrors.project = "Проект обязателен";
            if (!user_responsible) newErrors.user_responsible = "Ответственный обязателен";
            if (!period_end || !period_start) newErrors.period = "Период обязателен";

            if (Object.keys(newErrors).length > 0) {
                setErrors(newErrors);
                setLoading(false);
                return;
            }
            await api.post("/api/v1/budgets/create/", newBudget);
            hideModal();
            setNewBudget({
                name: "",
                project: "",
                period_start: "",
                period_end: "",
                user_responsible: "",
                type_of_budgets: "",
            });
            fetchBudgetsData();
        } catch (error) {
            console.error("Error creating budget:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleCopyBudget = async () => {
        setLoading(true);
        try {
            await api.post("api/v1/budgets/copy/", { budgetIds: checkedBudgets });
            setCheckedBudgets([]);
            fetchBudgetsData();
        } catch (error) {
            console.error("Error copying budgets:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleOpen = async (budget) => {
        try {
            const response = await api.patch(`api/v1/budgets/update/${budget.id}`, {
                is_open: !budget.is_open,
            });
            if (response.status === 200) {
                setBudgets((prev) =>
                    prev.map((b) => (b.id === budget.id ? { ...b, is_open: !b.is_open } : b))
                );
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleReset = () => {
        const search = location.search;
        navigate(location.search.replace(search, ""));
        window.location.reload();
    };

    const handleSearch = async () => {
        setLoading(true);
        try {
            const response = await api.post("api/v1/budgets/budgetfilter/", {
                name: nameFilter.toLowerCase(),
                is_active: [!archivedFilterYES, archivedFilterNO],
                project: projectFilter,
                is_open: [!openFilter, closeFilter],
                startDate: startDateFilter,
                endDate: endDateFilter,
            });
            setBudgets(response.data);
            setHasMore(false);
        } catch (error) {
            console.error("Error searching budgets:", error);
        } finally {
            setLoading(false);
        }
    };

    // ======== COLUMN SETTINGS AND RESIZING ========

    const [showSettings, setShowSettings] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState(() => {
        const saved = localStorage.getItem("budgetsSelectedColumns");
        return saved ? JSON.parse(saved) : [...ALL_COLUMNS];
    });
    const [columnWidths, setColumnWidths] = useState(() => {
        const saved = localStorage.getItem("budgetsColumnWidths");
        if (saved) return { ...DEFAULT_COLUMN_WIDTHS, ...JSON.parse(saved) };
        return { ...DEFAULT_COLUMN_WIDTHS };
    });

    useEffect(() => {
        localStorage.setItem("budgetsSelectedColumns", JSON.stringify(selectedColumns));
    }, [selectedColumns]);

    const resizingInfoRef = useRef({
        isResizing: false,
        startX: 0,
        columnKey: null,
        initialWidth: 0,
    });

    const onMouseDown = (e, column) => {
        e.preventDefault();
        e.stopPropagation();
        resizingInfoRef.current.isResizing = true;
        resizingInfoRef.current.startX = e.clientX;
        resizingInfoRef.current.columnKey = column;
        resizingInfoRef.current.initialWidth =
            columnWidths[column] || DEFAULT_COLUMN_WIDTHS[column] || 100;
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };

    const onMouseMove = (e) => {
        if (!resizingInfoRef.current.isResizing) return;
        const { startX, columnKey, initialWidth } = resizingInfoRef.current;
        const delta = e.clientX - startX;
        const newWidth = Math.max(50, initialWidth + delta);
        setColumnWidths((prev) => ({
            ...prev,
            [columnKey]: newWidth,
        }));
    };

    const onMouseUp = () => {
        resizingInfoRef.current.isResizing = false;
        // document.removeEventListener("mousemove", onMouseMove);
        // document.removeEventListener("mouseup", onMouseUp);
        setColumnWidths((prev) => {
            localStorage.setItem("budgetsColumnWidths", JSON.stringify(prev));
            return prev;
        });
    };

    const columnsInUse = ALL_COLUMNS.filter((col) => selectedColumns.includes(col));
    const totalTableWidth = columnsInUse.reduce((acc, col) => {
        return acc + (columnWidths[col] || DEFAULT_COLUMN_WIDTHS[col]);
    }, 0);

    const toggleColumn = (column) => {
        setSelectedColumns((prev) =>
            prev.includes(column) ? prev.filter((c) => c !== column) : [...prev, column]
        );
    };
    const selectAllColumns = () => {
        setSelectedColumns([...ALL_COLUMNS]);
    };
    const cancelSettings = () => {
        setShowSettings(false);
    };
    const saveSettings = () => {
        setShowSettings(false);
    };

    // ======== RENDER ========

    return (
        <div className="container-fluid" translate="no">
            <Header />
            <div className="row flex-nowrap">
                <SideBar current="/budgeting" />
                <div className="col py-3">
                    <div className="budgets-management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between mb-3">
                                    <div className="d-flex gap-2">
                                        <PageHeading>Бюджеты</PageHeading>
                                        <Button
                                            bType="secondary"
                                            size="small"
                                            fixed
                                            onClick={() => setFiltersVisible(!filtersVisible)}
                                        >
                                            <i className="bi bi-funnel"></i>
                                        </Button>
                                        <Button bType="primary" size="small" fixed onClick={showModal}>
                                            <i className="bi bi-plus-circle"></i>
                                        </Button>
                                    </div>

                                    <Button
                                        bType="secondary"
                                        size="small"
                                        fixed
                                        onClick={() => setShowSettings(true)}
                                    >
                                        <i className="bi bi-gear"></i>
                                    </Button>
                                </div>
                                {errorMessage && (
                                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                                        {errorMessage}
                                    </Alert>
                                )}
                                <UnauthorizedModal 
                                    show={showUnauthorizedModal} 
                                    onClose={() => setShowUnauthorizedModal(false)} 
                                />
                                {filtersVisible && (
                                    <div className="mb-3 p-3 border rounded">
                                        <Heading level={6} style={{ marginBottom: "10px" }}>
                                            Фильтры:
                                        </Heading>
                                        <div className="row g-3">
                                            <div className="col-md-3">
                                                <label className="form-label">Наименование бюджета</label>
                                                <Input
                                                    type="text"
                                                    placeholder="Введите название бюджета"
                                                    value={nameFilter}
                                                    onChange={(e) => setNameFilter(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label className="form-label">Проект</label>
                                                <APIMultipleSearchableSelect
                                                    endpoint="/api/v1/organization/projects/search/"
                                                    value={projectFilter}
                                                    onChange={(val) => setSelectedProject(val)}
                                                    placeholder="Выберите проект"
                                                    setShowUnauthorizedModal={setShowUnauthorizedModal}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label className="form-label">Период действия</label>
                                                <div className="d-flex align-items-center">
                                                    <Input
                                                        type="date"
                                                        value={startDateFilter}
                                                        onChange={(e) => setStartDateFilter(e.target.value)}
                                                        style={{ maxWidth: "130px" }}
                                                    />
                                                    <span style={{ margin: "0 5px" }}>—</span>
                                                    <Input
                                                        type="date"
                                                        value={endDateFilter}
                                                        onChange={(e) => setEndDateFilter(e.target.value)}
                                                        style={{ maxWidth: "130px" }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label>Архивный бюджет</label>
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="archivedCheckYES"
                                                        checked={archivedFilterYES}
                                                        onChange={(e) => setArchivedFilterYES(e.target.checked)}
                                                    />
                                                    <label className="form-check-label" htmlFor="archivedCheckYES">
                                                        Да
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="archivedCheckNO"
                                                        checked={archivedFilterNO}
                                                        onChange={(e) => setArchivedFilterNO(e.target.checked)}
                                                    />
                                                    <label className="form-check-label" htmlFor="archivedCheckNO">
                                                        Нет
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label>Бюджет закрыт</label>
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="openedCheck"
                                                        checked={openFilter}
                                                        onChange={(e) => setOpenedFilter(e.target.checked)}
                                                    />
                                                    <label className="form-check-label" htmlFor="openedCheck">
                                                        Да
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="closedCheck"
                                                        checked={closeFilter}
                                                        onChange={(e) => setCLosedFilter(e.target.checked)}
                                                    />
                                                    <label className="form-check-label" htmlFor="closedCheck">
                                                        Нет
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex gap-3 mt-3">
                                            <Button onClick={handleSearch} bType="primary" size="medium" fixed>
                                                Поиск
                                            </Button>
                                            <Button onClick={handleReset} bType="secondary" size="medium" fixed>
                                                Сбросить
                                            </Button>
                                        </div>
                                    </div>
                                )}

                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    {checkedBudgets.length > 0 && (
                                        <div className="dropdown">
                                            <Button
                                                bType="secondary"
                                                size="medium"
                                                fixed
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{ marginRight: "10px" }}
                                            >
                                                Действие <i className="bi bi-caret-down-fill"></i>
                                            </Button>
                                            <ul className="dropdown-menu" style={{ minWidth: "160px" }}>
                                                <li>
                          <span className="dropdown-item" onClick={handleOpening}>
                            Открыть редактирование
                          </span>
                                                </li>
                                                <li>
                          <span className="dropdown-item" onClick={handleClosing}>
                            Закрыть редактирование
                          </span>
                                                </li>
                                                <li>
                          <span className="dropdown-item" onClick={showEditUserModal}>
                            Поменять ответственного
                          </span>
                                                </li>
                                                <li>
                          <span className="dropdown-item" onClick={handleCopyBudget}>
                            Копировать бюджет
                          </span>
                                                </li>
                                                <li>
                          <span className="dropdown-item" onClick={handleArchiveBudgets}>
                            Архивировать бюджет
                          </span>
                                                </li>
                                                <li>
                          <span className="dropdown-item" onClick={handleUnarchiveBudgets}>
                            Разархивировать бюджет
                          </span>
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </div>

                                <div
                                    ref={containerRef}
                                    className="Payments__wrapper"
                                    style={{ height: "75vh", overflowY: "scroll", position: "relative" }}
                                >
                                    <table
                                        className="Payments__table"
                                        style={{
                                            tableLayout: "fixed"
                                        }}
                                    >
                                        <thead>
                                        <tr>
                                            <th style={{ width: 50 }}>
                                                <input
                                                    type="checkbox"
                                                    checked={allChecked}
                                                    onChange={handleSelectAllChange}
                                                />
                                            </th>
                                            <th style={{ width: 50 }}></th>
                                            {columnsInUse.map((column) => {
                                                const w = columnWidths[column] || DEFAULT_COLUMN_WIDTHS[column];
                                                return (
                                                    <th
                                                        key={column}
                                                        style={{
                                                            width: w,
                                                            minWidth: w,
                                                            position: "relative",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <span>{column}</span>
                                                            <div
                                                                style={{
                                                                    cursor: "col-resize",
                                                                    padding: "0 4px",
                                                                    marginRight: "-4px",
                                                                    userSelect: "none",
                                                                    zIndex: 999
                                                                }}
                                                                onMouseDown={(e) => onMouseDown(e, column)}
                                                            >
                                                                ||
                                                            </div>
                                                        </div>
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {budgets.map((budget) => (
                                            <tr
                                                key={budget.id}
                                                className={!budget.is_open ? "table-secondary" : ""}
                                                onClick={() => {
                                                    if (budget.is_open) showEditModal(budget);
                                                }}
                                            >
                                                <td style={{ width: 50 }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={checkedBudgets.includes(budget.id)}
                                                        onChange={() => handleCheckboxChange(budget.id)}
                                                    />
                                                </td>
                                                <td style={{ width: 50 }}>
                                                    <i
                                                        className={
                                                            !budget.is_open ? "bi bi-lock-fill" : "bi bi-unlock-fill"
                                                        }
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleOpen(budget);
                                                        }}
                                                        style={{ cursor: "pointer" }}
                                                    ></i>
                                                </td>
                                                {selectedColumns.includes("Наименование бюджета") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Наименование бюджета"] ||
                                                                DEFAULT_COLUMN_WIDTHS["Наименование бюджета"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "normal",
                                                            wordWrap: "break-word",
                                                        }}
                                                        className="text-start"
                                                    >
                                                        {budget?.name || ""}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Проект") && (
                                                    <td
                                                        style={{
                                                            width: columnWidths["Проект"] || DEFAULT_COLUMN_WIDTHS["Проект"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        {budget.project?.name || ""}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Вид бюджета") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Вид бюджета"] ||
                                                                DEFAULT_COLUMN_WIDTHS["Вид бюджета"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        {budget?.type_of_budget?.name || ""}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Период") && (
                                                    <td
                                                        style={{
                                                            width: columnWidths["Период"] || DEFAULT_COLUMN_WIDTHS["Период"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        {`${budget.period_start} - ${budget.period_end}` || ""}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Ответственный") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Ответственный"] ||
                                                                DEFAULT_COLUMN_WIDTHS["Ответственный"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        {budget.user_responsible
                                                            ? `${budget.user_responsible.first_name} ${budget.user_responsible.last_name}`
                                                            : ""}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Дата создания") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Дата создания"] ||
                                                                DEFAULT_COLUMN_WIDTHS["Дата создания"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        {budget.created || ""}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Архивный") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Архивный"] || DEFAULT_COLUMN_WIDTHS["Архивный"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                    >
                                                        {budget.is_active ? "Нет" : "Да"}
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>

                                    {loading && (
                                        <div className="position-absolute top-50 start-50 translate-middle">
                                            <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={isOpen} onHide={hideModal} centered>
                <Modal.Header className="justify-content-center">
                    <Modal.Title>Добавление бюджета</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {errorMessage && (
                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                        {errorMessage}
                    </Alert>
                    )}
                    <UnauthorizedModal 
                        show={showUnauthorizedModal} 
                        onClose={() => setShowUnauthorizedModal(false)} 
                    />  
                    <Form>
                        <Form.Group controlId="formNewBudget">
                            <Form.Label>Наименование бюджета</Form.Label>
                            <Input
                                type="text"
                                name="name"
                                value={newBudget.name}
                                onChange={handleChange}
                                placeholder="Введите название бюджета"
                            />
                            {errors.name && <div style={{ color: "red" }}>{errors.name}</div>}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Проект</Form.Label>
                            <APISearchableSelect
                                endpoint="/api/v1/organization/projects/search/"
                                value={newBudget.project}
                                onChange={(value) => handleChange({ target: { name: "project", value } })}
                                placeholder="Выберите проект"
                                setShowUnauthorizedModal={openModal}
                            />
                            {errors.project && <div style={{ color: "red" }}>{errors.project}</div>}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Вид бюджета</Form.Label>
                            <Select
                                options={type_of_budget}
                                onChange={(option) =>
                                    handleChange({
                                        target: { name: "type_of_budgets", value: option?.value || "" },
                                    })
                                }
                                placeholder="Выберите вид бюджета"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Период действия</Form.Label>
                            <div className="d-flex align-items-center gap-2">
                                <Input
                                    type="date"
                                    name="period_start"
                                    value={newBudget.period_start}
                                    onChange={handleChange}
                                />
                                <span>—</span>
                                <Input
                                    type="date"
                                    name="period_end"
                                    value={newBudget.period_end}
                                    onChange={handleChange}
                                />
                            </div>
                            {errors.period && <div style={{ color: "red" }}>{errors.period}</div>}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Ответственный</Form.Label>
                            <APISearchableSelect
                                endpoint="/api/v1/user/search/"
                                value={newBudget.user_responsible}
                                onChange={(value) => handleChange({ target: { name: "user_responsible", value } })}
                                placeholder="Выберите ответственного"
                                displayKey="full_name"
                                setShowUnauthorizedModal={openModal}
                            />
                            {errors.user_responsible && (
                                <div style={{ color: "red" }}>{errors.user_responsible}</div>
                            )}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="justify-content-evenly">
                    <Button bType="secondary" size="medium" fixed onClick={hideModal}>
                        Отмена
                    </Button>
                    <Button bType="primary" size="medium" fixed onClick={handleModalSave}>
                        Сохранить
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isEditOpen} onHide={hideEditModal} centered>
                <Modal.Header className="justify-content-center">
                    <Modal.Title>Редактирование бюджета</Modal.Title>
                </Modal.Header>
                {errorMessage && (
                                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                                        {errorMessage}
                                    </Alert>
                                )}
                                <UnauthorizedModal 
                                    show={showUnauthorizedModal} 
                                    onClose={() => setShowUnauthorizedModal(false)} 
                                />
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formNewBudget">
                            <Form.Label>Наименование бюджета</Form.Label>
                            <Input
                                type="text"
                                name="name"
                                value={editingBudget.name}
                                onChange={handleEditBudgetChange}
                                placeholder="Введите название бюджета"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Вид бюджета</Form.Label>
                            <Select
                                styles={{
                                    control: (base) => ({
                                        ...base,
                                        height: "38px",
                                        minHeight: "38px",
                                    }),
                                }}
                                value={type_of_budget.find(
                                    (option) => option.value === editingBudget?.type_of_budget?.id
                                )}
                                options={type_of_budget}
                                isDisabled
                                placeholder="Вид бюджета"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Проект</Form.Label>
                            <APISearchableSelect
                                endpoint="/api/v1/organization/projects/search/"
                                value={editingBudget?.project}
                                onChange={(value) =>
                                    handleEditBudgetChange({ target: { name: "project", value } })
                                }
                                placeholder="Выберите проект"
                                disabled
                                setShowUnauthorizedModal={openModal}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Период действия</Form.Label>
                            <div className="d-flex align-items-center gap-2">
                                <Input
                                    type="date"
                                    name="period_start"
                                    value={
                                        editingBudget.period_start ? formatDate(editingBudget.period_start) : ""
                                    }
                                    onChange={handleEditBudgetChange}
                                />
                                <span>—</span>
                                <Input
                                    type="date"
                                    name="period_end"
                                    value={editingBudget.period_end ? formatDate(editingBudget.period_end) : ""}
                                    onChange={handleEditBudgetChange}
                                />
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Ответственный</Form.Label>
                            <APISearchableSelect
                                endpoint="/api/v1/user/search/"
                                value={editingBudget?.user_responsible}
                                onChange={(value) =>
                                    handleEditBudgetChange({ target: { name: "user_responsible", value } })
                                }
                                placeholder="Выберите ответственного"
                                displayKey="full_name"
                                setShowUnauthorizedModal={openModal}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="justify-content-evenly">
                    <Button bType="secondary" size="medium" fixed onClick={hideEditModal}>
                        Отмена
                    </Button>
                    <Button bType="primary" size="medium" fixed onClick={handleBudgetUpdate}>
                        Сохранить
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isEditUserOpen} onHide={hideEditUserModal} centered>
                <Modal.Header className="justify-content-center">
                    <Modal.Title>Смена ответственного</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Ответственный</Form.Label>
                            <APISearchableSelect
                                endpoint="/api/v1/user/search/"
                                value={
                                    editingBudget.user_responsible
                                        ? editingBudget.user_responsible.full_name
                                        : ""
                                }
                                onChange={(value) => setNewUserResponsible(value)}
                                placeholder="Выберите ответственного"
                                displayKey="full_name"
                                setShowUnauthorizedModal={openModal}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="justify-content-evenly">
                    <Button bType="secondary" size="medium" fixed onClick={hideEditUserModal}>
                        Отмена
                    </Button>
                    <Button bType="primary" size="medium" fixed onClick={handleChangeResponsible}>
                        Сохранить
                    </Button>
                </Modal.Footer>
            </Modal>

            {showSettings && (
                <div className="modal" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}>
                    <div className="modal-dialog" style={{ maxWidth: "700px" }}>
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div
                                className="modal-header"
                                style={{ borderBottom: "2px solid #eee" }}
                            >
                                <h5 className="modal-title">Настройка столбцов</h5>
                                <button className="btn-close" onClick={cancelSettings}></button>
                            </div>
                            <div className="modal-body" style={{ padding: "20px" }}>
                                <div className="container-fluid">
                                    <div
                                        className="row"
                                        style={{ gap: "10px", justifyContent: "space-evenly" }}
                                    >
                                        {ALL_COLUMNS.map((column) => (
                                            <div
                                                key={column}
                                                className={`col-4 p-2 d-flex align-items-center justify-content-start ${
                                                    selectedColumns.includes(column) ? "selected-column" : ""
                                                }`}
                                                onClick={() => toggleColumn(column)}
                                                style={{
                                                    cursor: "pointer",
                                                    border: selectedColumns.includes(column)
                                                        ? "2px solid #007BFF"
                                                        : "1px solid #ddd",
                                                    padding: "8px",
                                                    transition: "0.3s",
                                                    borderRadius: "5px",
                                                    width: "30%",
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={selectedColumns.includes(column)}
                                                    readOnly
                                                    className="me-2"
                                                />
                                                <span
                                                    className={
                                                        selectedColumns.includes(column) ? "text-primary" : ""
                                                    }
                                                >
                          {column}
                        </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="modal-footer"
                                style={{ borderTop: "2px solid #eee" }}
                            >
                                <Button bType="secondary" size="medium" fixed onClick={selectAllColumns}>
                                    Выбрать все
                                </Button>
                                <Button bType="secondary" size="medium" fixed onClick={cancelSettings}>
                                    Отмена
                                </Button>
                                <Button bType="primary" size="medium" fixed onClick={saveSettings}>
                                    Сохранить
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BudgetsPage;
