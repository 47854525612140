import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SideBar from '../SideBar';
import Header from "../Header";
import useFetchData from '../../functions/UprData';
import MonthPercentTable from './MonthPercentTable';
import ModalOfProjectList from './ModalOfProjectList';
import ReactLoading from 'react-loading';
import PageHeading from "../ui/PageHeading/PageHeading";
import Button from "../ui/Button/Button";

const SalaryManagement = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);
    const [monthPercents, setMonthPercents] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false);

    const fetchMonthPercent = useFetchData(
    '/api/v1/salary/month-percent/', 
    setMonthPercents, 
    () => setShowUnauthorizedModal(true), 
    setErrorMessage
);

    const fetchData = async () => {
        setLoading(true);
        try {
            await Promise.all([
                fetchMonthPercent(),
            ]);
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
            setLoading(false);
        }
    };

    const showModal = () => {
        setIsOpen(true);
      };
    
    const hideModal = () => {
        setIsOpen(false);
    };

    const handleCreateMatrixCopy = (project, month) => {
        navigate(`/salaries/${month}/${project}`)
    }

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return (<div className="position-absolute top-50 start-50 translate-middle">
        <ReactLoading type="spin" color="#0000FF" height={50} width={50} />
        </div>);
    }

    return (
        <div className="container-fluid" translate="no">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar current="/salaries"/>
                <div className="col py-3">
                    <div className="salary-management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div className="d-flex align-items-center gap-2">
                                    <PageHeading>Заработные платы</PageHeading>
                                        <Button size="small" bType="secondary" onClick={() => {
                                            window.location.reload();
                                        }}>
                                            <i className="bi-5 bi-arrow-clockwise"></i>
                                        </Button>
                                    </div>
                                    <div>
                                            <Button bType="primary" size="medium" fixed onClick={showModal}>+ Добавить матрицу</Button>
                                    </div>
                                </div>
                                {errorMessage && (
                                    <div className="alert alert-danger" role="alert">
                                        {errorMessage}
                                    </div>
                                )}
                                <div style={{ height: '75vh' }}>
                                    <MonthPercentTable monthPercents={monthPercents} />
                                </div>
                                {loading && <div className="text-center">Loading more...</div>}
                            </div>
                        </div>
                    </div>
                </div>
                <ModalOfProjectList 
                    isOpen={isOpen}
                    hideModal={hideModal}
                    onProjectSelect={handleCreateMatrixCopy}
                    month={1}
                />
            </div>
        </div>
        
    );
}

export default SalaryManagement;