import { useEffect, useState } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import api from "../../api";
import { useNavigate } from "react-router-dom";
import useFetchData from "../../functions/UprData";
import Button from "../ui/Button/Button";
import Input from "../ui/Input/Input";
import PageHeading from "../ui/PageHeading/PageHeading";
import Heading from "../ui/Heading/Heading";
import Card from "../ui/Card/Card";
import UnauthorizedModal from "../Unauthorized"; 
import { Alert } from 'react-bootstrap';

const PermissionGroup = ({ groupName, permissions, handlePermissionChange, groupPermissions }) => {
    return (
      <Card style={{
          marginBottom: 20
      }}>
        <div className="card-header bg-transparent">
            <h5 className="mb-2">{groupName}</h5>
        </div>
        <div className="card-body">
          <div>
            {permissions.map(permission => (
              <div key={permission.id} className="col-md-12">
                <div className="form-check">
                  <input
                        className="form-check-input"
                        type="checkbox" 
                        id={`permission-${permission.id}`} 
                        checked={groupPermissions.includes(permission.id)}
                        onChange={(e) => handlePermissionChange(permission.id, e.target.checked)}
                        />
                  <label className="form-check-label" htmlFor={`permission-${permission.id}`}>
                    {permission.name}
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
    );
  };

const CreateGroup = () => {

    const [loading, setLoading] = useState(false);
    const [group, setGroup] = useState({
        "name": '',
        "permissions": []
    });
    const [permissions, setPermissions] = useState([]);
    const navigate = useNavigate()
    const [errors, setErrors] = useState({});
    const openModal = (open) => {
        setShowUnauthorizedModal(open);
    };
    const [errorMessage, setErrorMessage] = useState(""); 
    const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false);
    const [selectAll, setSelectAll] = useState(false);

    const handleInputChange = (e) => {
        setGroup({ ...group, [e.target.name]: e.target.value });
    }

    const handlePermissionChange = (permissionId, isChecked) => {
        setGroup(prevGroup => {
            let updatedPermissions;
            if (isChecked) {
                // Add permission to the array
                updatedPermissions = [...prevGroup.permissions, permissionId];
            } else {
                // Remove permission from the array
                updatedPermissions = prevGroup.permissions.filter(id => id !== permissionId);
            }
            return {
                ...prevGroup,
                permissions: updatedPermissions
            };
        });
    };

    const fetchPermissions = useFetchData('/api/v1/permissions/', setPermissions, openModal, setErrorMessage);

    useEffect(() => {
        fetchPermissions();
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const { name, permissions } = group;
        const errors = {};
        if (!name) {
            errors.name = 'Group name is required';
        }
        if (permissions.length === 0) {
            errors.permissions = 'Permission is required';
        }
    
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }
    
        try {
            setLoading(true);
            const response = await api.post('/api/v1/role/groups/create/', group);
            setLoading(false);
            navigate('/finmodel');
        } catch (error) {
            console.error("Ошибка при создании группы:", error);
            
            if (error.response) {
                console.error("Ответ сервера:", error.response.status, error.response.data);
    
                if (error.response.status === 401) {
                    console.log("401 ошибка! Перезайдите, пожалуйста.");
                    setShowUnauthorizedModal(true);
                } else if (error.response.status === 403) {
                    setErrorMessage("Доступ запрещен! У вас недостаточно прав на просмотр групп доступа");
                } else if (error.response.status === 400) {
                    setErrorMessage(error.response.data.detail || "Ошибка запроса.");
                } else if (error.response.status === 500) {
                    setErrorMessage("Ошибка сервера! Попробуйте позже.");
                } else {
                    setErrorMessage(`Неизвестная ошибка: ${error.response.status}`);
                }
            } else {
                console.error("Ошибка сети или сервера:", error);
                setErrorMessage("Ошибка соединения с сервером. Проверьте интернет.");
            }
        } finally {
            setLoading(false);
        }
    };
    
    
    const handleSelectAllChange = (isChecked) => {
        setSelectAll(isChecked);
        if (isChecked) {
            const allPermissionIds = Object.values(permissions).flat().map(permission => permission.id);
            setGroup(prevGroup => ({
                ...prevGroup,
                permissions: allPermissionIds
            }));
        } else {
            setGroup(prevGroup => ({
                ...prevGroup,
                permissions: []
            }));
        }
    };

    useEffect(() => {
        const allPermissionIds = Object.values(permissions).flat().map(permission => permission.id);
        if (group.permissions.length === allPermissionIds.length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [group.permissions, permissions]);

    return (
        <>
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                    <div className="col">
                    {errorMessage && (
                        <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                            {errorMessage}
                        </Alert>
                    )}
                    <UnauthorizedModal 
                        show={showUnauthorizedModal} 
                        onClose={() => setShowUnauthorizedModal(false)} 
                    />
                    <div className="create-group-header mt-3">
                        <PageHeading>Создание группы</PageHeading>
                    </div>
                    <div className="create-group-content mt-4">
                        <form className="group-form" onSubmit={handleSubmit}>
                            <div>
                                <div className="row mb-3">
                                    <div className="col" style={{
                                        paddingRight: 0,
                                        paddingLeft: 0
                                    }}>
                                        <div>
                                            <label className='form-label'>Наименование</label>
                                            <Input type="text" name="name" placeholder='Введите название' value={group.name} onChange={handleInputChange} />
                                            {errors.name && <span>{errors.name}</span>}
                                        </div>
                                    </div>
                                </div>
                                <Heading level={5}>Группы доступа</Heading>
                                <div className="form-check mb-3">
                                    <input 
                                        className="form-check-input" 
                                        type="checkbox" 
                                        id="selectAll" 
                                        checked={selectAll}
                                        onChange={(e) => handleSelectAllChange(e.target.checked)}
                                        />
                                    <label className="form-check-label" htmlFor="selectAll">
                                    Выбрать все
                                    </label>
                                </div>
                                <div className="row row-cols-5">
                                    {Object.keys(permissions).map(groupName => (
                                    <div key={groupName} className="col">
                                        <PermissionGroup
                                        groupName={groupName}
                                        permissions={permissions[groupName]}
                                        handlePermissionChange={handlePermissionChange}
                                        groupPermissions={group.permissions}
                                        />
                                    </div>
                                    ))}
                                </div>
                                {errors.permissions && <span>{errors.permissions}</span>}
                            </div>
                            <Button bType="primary" size="medium" fixed type="submit">Сохранить</Button>
                            <br />
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default CreateGroup;