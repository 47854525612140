// import { useState, useEffect, useRef } from "react";
// import SideBar from "./SideBar";
// import Header from "./Header";
// import PageHeading from "./ui/PageHeading/PageHeading";
// // https://app.powerbi.com/reportEmbed?reportId=5544a319-0b1c-4580-8aa9-b433d30f6e58&autoAuth=true&ctid=158f15f3-83e0-4906-824c-69bdc50d9d61
// // https://app.powerbi.com/reportEmbed?reportId=797b51b3-a371-4e0c-ba73-c9dc06c0b5e1&autoAuth=true&ctid=158f15f3-83e0-4906-824c-69bdc50d9d61
// function PowerBI() {
//     const [dashboardUrl, setDashboardUrl] = useState("");
//     const [dashboards, setDashboards] = useState([]);
//     const [newDashboardName, setNewDashboardName] = useState("");
//     const [newDashboardUrl, setNewDashboardUrl] = useState("");
//     const [isAdding, setIsAdding] = useState(false);
//     const [errorMessage, setErrorMessage] = useState("");
//     const [activeDashboard, setActiveDashboard] = useState(null);
//     const [isFullScreen, setIsFullScreen] = useState(false);
    
//     const iframeRef = useRef(null);
//     const dashboardContainerRef = useRef(null);

//     useEffect(() => {
//         const savedDashboards = localStorage.getItem("powerbi-dashboards");
//         if (savedDashboards) {
//             setDashboards(JSON.parse(savedDashboards));
//         }
//     }, []);

//     useEffect(() => {
//         localStorage.setItem("powerbi-dashboards", JSON.stringify(dashboards));
//     }, [dashboards]);

//     useEffect(() => {
//         const handleFullScreenChange = () => {
//             setIsFullScreen(
//                 document.fullscreenElement || 
//                 document.webkitFullscreenElement || 
//                 document.mozFullScreenElement || 
//                 document.msFullscreenElement
//             );
//         };

//         document.addEventListener('fullscreenchange', handleFullScreenChange);
//         document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
//         document.addEventListener('mozfullscreenchange', handleFullScreenChange);
//         document.addEventListener('MSFullscreenChange', handleFullScreenChange);

//         return () => {
//             document.removeEventListener('fullscreenchange', handleFullScreenChange);
//             document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
//             document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
//             document.removeEventListener('MSFullscreenChange', handleFullScreenChange);
//         };
//     }, []);

//     const handleAddDashboard = () => {
//         // Validate inputs
//         if (!newDashboardName.trim() || !newDashboardUrl.trim()) {
//             setErrorMessage("Пожалуйста, заполните оба поля");
//             return;
//         }

//         // Validate URL format
//         try {
//             new URL(newDashboardUrl);
//         } catch (e) {
//             setErrorMessage("Пожалуйста, введите корректный URL");
//             return;
//         }

//         // Add new dashboard
//         const newDashboard = {
//             id: Date.now(),
//             name: newDashboardName.trim(),
//             url: newDashboardUrl.trim()
//         };

//         setDashboards([...dashboards, newDashboard]);
        
//         // Reset form
//         setNewDashboardName("");
//         setNewDashboardUrl("");
//         setIsAdding(false);
//         setErrorMessage("");
//     };

//     const handleRemoveDashboard = (idToRemove) => {
//         if (window.confirm("Вы уверены, что хотите удалить этот дэшборд?")) {
//             setDashboards(dashboards.filter(dashboard => dashboard.id !== idToRemove));
//             if (dashboards.find(d => d.id === idToRemove)?.url === dashboardUrl) {
//                 setDashboardUrl("");
//                 setActiveDashboard(null);
//             }
//         }
//     };

//     const selectDashboard = (dashboard) => {
//         setDashboardUrl(dashboard.url);
//         setActiveDashboard(dashboard);
//     };

//     const refreshDashboard = () => {
//         if (iframeRef.current) {
//             iframeRef.current.src = iframeRef.current.src;
//         }
//     };

//     const toggleFullScreen = () => {
//         if (!isFullScreen) {
//             const element = dashboardContainerRef.current;
            
//             if (element) {
//                 if (element.requestFullscreen) {
//                     element.requestFullscreen();
//                 } else if (element.webkitRequestFullscreen) {
//                     element.webkitRequestFullscreen();
//                 } else if (element.mozRequestFullScreen) {
//                     element.mozRequestFullScreen();
//                 } else if (element.msRequestFullscreen) {
//                     element.msRequestFullscreen();
//                 }
//             }
//         } else {
//             if (document.exitFullscreen) {
//                 document.exitFullscreen();
//             } else if (document.webkitExitFullscreen) {
//                 document.webkitExitFullscreen();
//             } else if (document.mozCancelFullScreen) {
//                 document.mozCancelFullScreen();
//             } else if (document.msExitFullscreen) {
//                 document.msExitFullscreen();
//             }
//         }
//     };

//     return (
//         <div className="container-fluid">
//             <Header/>
//             <div className="row flex-nowrap">
//                 <SideBar current={"/powerBI"}/>
//                 <div className="col py-4 px-4 bg-light">
//                     <div className="d-flex justify-content-between align-items-center mb-4">
//                         <PageHeading>
//                             <i className="bi bi-bar-chart-fill me-2 text-primary"></i>
//                             Power BI Дэшборды
//                         </PageHeading>
//                         {!isAdding && (
//                             <button 
//                                 className="btn rounded-pill px-4 py-2 d-flex align-items-center shadow-sm"
//                                 onClick={() => setIsAdding(true)}
//                                 style={{ backgroundColor: "#5584CE", color: "white" }}
//                             >
//                                 <i className="bi bi-plus-lg me-2"></i> Добавить дэшборд
//                             </button>
//                         )}
//                     </div>
                    
//                     {isAdding && (
//                         <div className="card shadow mb-4 border-0 rounded-4 overflow-hidden">
//                             <div className="card-header py-3" style={{ backgroundColor: "#5584CE", color: "white" }}>
//                                 <h5 className="mb-0 d-flex align-items-center">
//                                     <i className="bi bi-plus-circle me-2"></i>
//                                     Новый дэшборд
//                                 </h5>
//                             </div>
//                             <div className="card-body p-4 bg-white">
//                                 <div className="add-dashboard-form">
//                                     <div className="row g-3">
//                                         <div className="col-md-5">
//                                             <label className="form-label fw-semibold">Название дэшборда</label>
//                                             <div className="input-group input-group-lg shadow-sm rounded-4 overflow-hidden">
//                                                 <span className="input-group-text bg-light border-0">
//                                                     <i className="bi bi-card-heading text-muted"></i>
//                                                 </span>
//                                                 <input
//                                                     type="text"
//                                                     className="form-control form-control-lg border-0 bg-light"
//                                                     placeholder="Введите название..."
//                                                     value={newDashboardName}
//                                                     onChange={(e) => setNewDashboardName(e.target.value)}
//                                                 />
//                                             </div>
//                                         </div>
//                                         <div className="col-md-5">
//                                             <label className="form-label fw-semibold">URL дэшборда</label>
//                                             <div className="input-group input-group-lg shadow-sm rounded-4 overflow-hidden">
//                                                 <span className="input-group-text bg-light border-0">
//                                                     <i className="bi bi-link text-muted"></i>
//                                                 </span>
//                                                 <input
//                                                     type="text"
//                                                     className="form-control form-control-lg border-0 bg-light"
//                                                     placeholder="https://..."
//                                                     value={newDashboardUrl}
//                                                     onChange={(e) => setNewDashboardUrl(e.target.value)}
//                                                 />
//                                             </div>
//                                         </div>
//                                         <div className="col-md-2 d-flex align-items-end">
//                                             <div className="d-flex gap-2 w-100">
//                                                 <button 
//                                                     className="btn px-4 py-2 flex-grow-1 shadow-sm rounded-pill"
//                                                     onClick={handleAddDashboard}
//                                                     style={{ backgroundColor: "#5584CE", color: "white" }}
//                                                 >
//                                                     <i className="bi bi-check-lg me-2"></i> Сохранить
//                                                 </button>
//                                                 <button 
//                                                     className="btn btn-light px-3 py-2 shadow-sm rounded-pill"
//                                                     onClick={() => {
//                                                         setIsAdding(false);
//                                                         setErrorMessage("");
//                                                     }}
//                                                 >
//                                                     Отмена
//                                                 </button>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     {errorMessage && (
//                                         <div className="alert alert-danger mt-3 rounded-4 shadow-sm" role="alert">
//                                             <i className="bi bi-exclamation-triangle-fill me-2"></i>
//                                             {errorMessage}
//                                         </div>
//                                     )}
//                                 </div>
//                             </div>
//                         </div>
//                     )}

//                     <div className="row mb-4">
//                         <div className="col-lg-3">
//                             <div className="card border-0 shadow rounded-4 h-100">
//                                 <div className="card-header bg-white py-3 border-bottom">
//                                     <h5 className="mb-0 d-flex align-items-center">
//                                         <i className="bi bi-collection me-2" style={{ color: "#5584CE" }}></i>
//                                         Доступные дэшборды
//                                     </h5>
//                                 </div>
//                                 <div className="card-body p-2">
//                                     {dashboards.length === 0 ? (
//                                         <div className="text-center p-4 text-muted">
//                                             <div className="p-3 mb-2 rounded-circle d-inline-flex" style={{ backgroundColor: "#f0f4fa" }}>
//                                                 <i className="bi bi-bar-chart-line fs-1" style={{ color: "#5584CE" }}></i>
//                                             </div>
//                                             <p className="mt-2">Нет доступных дэшбордов</p>
//                                         </div>
//                                     ) : (
//                                         <div className="dashboard-list p-2">
//                                             {dashboards.map(dashboard => (
//                                                 <div 
//                                                     key={dashboard.id} 
//                                                     className={`d-flex justify-content-between align-items-center py-3 px-3 mb-2 ${activeDashboard?.id === dashboard.id ? 'text-white' : ''}`}
//                                                     style={{
//                                                         cursor: 'pointer',
//                                                         backgroundColor: activeDashboard?.id === dashboard.id ? '#5584CE' : '#f8f9fa',
//                                                         borderRadius: '12px',
//                                                         transition: 'all 0.2s ease'
//                                                     }}
//                                                     onClick={() => selectDashboard(dashboard)}
//                                                 >
//                                                     <div className="dashboard-name text-truncate me-2 d-flex align-items-center">
//                                                         <i className={`bi ${activeDashboard?.id === dashboard.id ? 'bi-file-earmark-bar-graph-fill' : 'bi-file-earmark-bar-graph'} me-2`}></i>
//                                                         {dashboard.name}
//                                                     </div>
//                                                     <button 
//                                                         className={`btn btn-sm rounded-circle ${activeDashboard?.id === dashboard.id ? 'btn-light text-primary' : 'btn-outline-danger'}`}
//                                                         style={{ width: '32px', height: '32px', padding: '0', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}
//                                                         onClick={(e) => {
//                                                             e.stopPropagation();
//                                                             handleRemoveDashboard(dashboard.id);
//                                                         }}
//                                                     >
//                                                         <i className="bi bi-trash"></i>
//                                                     </button>
//                                                 </div>
//                                             ))}
//                                         </div>
//                                     )}
//                                 </div>
//                             </div>
//                         </div>
                        
//                         <div className="col-lg-9 mt-4 mt-lg-0">
//                             {dashboardUrl ? (
//                                 <div className="card border-0 shadow rounded-4 overflow-hidden h-100" ref={dashboardContainerRef}>
//                                     {activeDashboard && (
//                                         <div className="card-header bg-white py-3 d-flex justify-content-between align-items-center">
//                                             <h5 className="mb-0 d-flex align-items-center">
//                                                 <i className="bi bi-file-earmark-bar-graph-fill me-2" style={{ color: "#5584CE" }}></i>
//                                                 {activeDashboard.name}
//                                             </h5>
//                                             <div className="dashboard-controls">
//                                                 <button 
//                                                     className="btn btn-sm btn-light rounded-pill px-3 me-2 shadow-sm"
//                                                     onClick={refreshDashboard}
//                                                 >
//                                                     <i className="bi bi-arrow-clockwise me-1"></i> Обновить
//                                                 </button>
//                                                 <button 
//                                                     className="btn btn-sm rounded-pill px-3 shadow-sm" 
//                                                     style={{ backgroundColor: "#5584CE", color: "white" }}
//                                                     onClick={toggleFullScreen}
//                                                 >
//                                                     <i className={`bi ${isFullScreen ? 'bi-fullscreen-exit' : 'bi-fullscreen'} me-1`}></i> 
//                                                     {isFullScreen ? 'Выйти' : 'Полный экран'}
//                                                 </button>
//                                             </div>
//                                         </div>
//                                     )}
//                                     <div className="card-body p-0 position-relative">
//                                         <div className="ratio ratio-16x9" style={{minHeight: '700px'}}>
//                                             <iframe 
//                                                 ref={iframeRef}
//                                                 title="Power BI Dashboard"
//                                                 src={dashboardUrl} 
//                                                 frameBorder="0" 
//                                                 allowFullScreen
//                                                 className="position-absolute top-0 left-0 w-100 h-100"
//                                             ></iframe>
//                                         </div>
//                                     </div>
//                                 </div>
//                             ) : (
//                                 <div className="card border-0 shadow rounded-4 h-100">
//                                     <div className="card-body d-flex flex-column align-items-center justify-content-center p-5 text-center" style={{minHeight: '400px'}}>
//                                         <div 
//                                             className="mb-4 rounded-circle d-flex align-items-center justify-content-center"
//                                             style={{ 
//                                                 width: '100px', 
//                                                 height: '100px', 
//                                                 backgroundColor: '#EBF1FA' 
//                                             }}
//                                         >
//                                             <i className="bi bi-bar-chart-line display-5" style={{ color: "#5584CE" }}></i>
//                                         </div>
//                                         <h3 className="mb-3">Выберите дэшборд для просмотра</h3>
//                                         <p className="text-muted mb-4 w-75">Выберите дэшборд из списка слева или добавьте новый для начала работы с аналитикой</p>
//                                         <button 
//                                             className="btn rounded-pill px-4 py-2 shadow-sm"
//                                             onClick={() => setIsAdding(true)}
//                                             style={{ backgroundColor: "#5584CE", color: "white" }}
//                                         >
//                                             <i className="bi bi-plus-lg me-2"></i> Добавить дэшборд
//                                         </button>
//                                     </div>
//                                 </div>
//                             )}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default PowerBI;
import { useState, useEffect, useRef } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import PageHeading from "./ui/PageHeading/PageHeading";

function PowerBI() {
    const [dashboardUrl, setDashboardUrl] = useState("");
    const [dashboards, setDashboards] = useState([]);
    const [newDashboardName, setNewDashboardName] = useState("");
    const [newDashboardUrl, setNewDashboardUrl] = useState("");
    const [isAdding, setIsAdding] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [activeDashboard, setActiveDashboard] = useState(null);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isDashboardListCollapsed, setIsDashboardListCollapsed] = useState(false);
    
    const iframeRef = useRef(null);
    const dashboardContainerRef = useRef(null);

    useEffect(() => {
        const savedDashboards = localStorage.getItem("powerbi-dashboards");
        if (savedDashboards) {
            setDashboards(JSON.parse(savedDashboards));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem("powerbi-dashboards", JSON.stringify(dashboards));
    }, [dashboards]);

    useEffect(() => {
        const handleFullScreenChange = () => {
            setIsFullScreen(
                document.fullscreenElement || 
                document.webkitFullscreenElement || 
                document.mozFullScreenElement || 
                document.msFullscreenElement
            );
        };

        document.addEventListener('fullscreenchange', handleFullScreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
        document.addEventListener('mozfullscreenchange', handleFullScreenChange);
        document.addEventListener('MSFullscreenChange', handleFullScreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
            document.removeEventListener('MSFullscreenChange', handleFullScreenChange);
        };
    }, []);

    const handleAddDashboard = () => {
        // Validate inputs
        if (!newDashboardName.trim() || !newDashboardUrl.trim()) {
            setErrorMessage("Пожалуйста, заполните оба поля");
            return;
        }

        // Validate URL format
        try {
            new URL(newDashboardUrl);
        } catch (e) {
            setErrorMessage("Пожалуйста, введите корректный URL");
            return;
        }

        // Add new dashboard
        const newDashboard = {
            id: Date.now(),
            name: newDashboardName.trim(),
            url: newDashboardUrl.trim()
        };

        setDashboards([...dashboards, newDashboard]);
        
        // Reset form
        setNewDashboardName("");
        setNewDashboardUrl("");
        setIsAdding(false);
        setErrorMessage("");
    };

    const handleRemoveDashboard = (idToRemove) => {
        if (window.confirm("Вы уверены, что хотите удалить этот дэшборд?")) {
            setDashboards(dashboards.filter(dashboard => dashboard.id !== idToRemove));
            if (dashboards.find(d => d.id === idToRemove)?.url === dashboardUrl) {
                setDashboardUrl("");
                setActiveDashboard(null);
            }
        }
    };

    const selectDashboard = (dashboard) => {
        setDashboardUrl(dashboard.url);
        setActiveDashboard(dashboard);
    };

    const refreshDashboard = () => {
        if (iframeRef.current) {
            iframeRef.current.src = iframeRef.current.src;
        }
    };

    const toggleFullScreen = () => {
        if (!isFullScreen) {
            const element = dashboardContainerRef.current;
            
            if (element) {
                if (element.requestFullscreen) {
                    element.requestFullscreen();
                } else if (element.webkitRequestFullscreen) {
                    element.webkitRequestFullscreen();
                } else if (element.mozRequestFullScreen) {
                    element.mozRequestFullScreen();
                } else if (element.msRequestFullscreen) {
                    element.msRequestFullscreen();
                }
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
    };

    const toggleDashboardList = () => {
        setIsDashboardListCollapsed(!isDashboardListCollapsed);
    };

    return (
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar current={"/powerBI"}/>
                <div className="col py-4 px-4 bg-light">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <PageHeading>
                            <i className="bi bi-bar-chart-fill me-2 text-primary"></i>
                            PRO-отчеты
                        </PageHeading>
                        {!isAdding && (
                            <button 
                                className="btn rounded-pill px-4 py-2 d-flex align-items-center shadow-sm"
                                onClick={() => setIsAdding(true)}
                                style={{ backgroundColor: "#5584CE", color: "white" }}
                            >
                                <i className="bi bi-plus-lg me-2"></i> Добавить дэшборд
                            </button>
                        )}
                    </div>
                    
                    {isAdding && (
                        <div className="card shadow mb-4 border-0 rounded-4 overflow-hidden">
                            <div className="card-header py-3" style={{ backgroundColor: "#5584CE", color: "white" }}>
                                <h5 className="mb-0 d-flex align-items-center">
                                    <i className="bi bi-plus-circle me-2"></i>
                                    Новый дэшборд
                                </h5>
                            </div>
                            <div className="card-body p-4 bg-white">
                                <div className="add-dashboard-form">
                                    <div className="row g-3">
                                        <div className="col-md-5">
                                            <label className="form-label fw-semibold">Название дэшборда</label>
                                            <div className="input-group input-group-lg shadow-sm rounded-4 overflow-hidden">
                                                <span className="input-group-text bg-light border-0">
                                                    <i className="bi bi-card-heading text-muted"></i>
                                                </span>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-lg border-0 bg-light"
                                                    placeholder="Введите название..."
                                                    value={newDashboardName}
                                                    onChange={(e) => setNewDashboardName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <label className="form-label fw-semibold">URL дэшборда</label>
                                            <div className="input-group input-group-lg shadow-sm rounded-4 overflow-hidden">
                                                <span className="input-group-text bg-light border-0">
                                                    <i className="bi bi-link text-muted"></i>
                                                </span>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-lg border-0 bg-light"
                                                    placeholder="https://..."
                                                    value={newDashboardUrl}
                                                    onChange={(e) => setNewDashboardUrl(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2 d-flex align-items-end">
                                            <div className="d-flex gap-2 w-100">
                                                <button 
                                                    className="btn px-4 py-2 flex-grow-1 shadow-sm rounded-pill"
                                                    onClick={handleAddDashboard}
                                                    style={{ backgroundColor: "#5584CE", color: "white" }}
                                                >
                                                    <i className="bi bi-check-lg me-2"></i> Сохранить
                                                </button>
                                                <button 
                                                    className="btn btn-light px-3 py-2 shadow-sm rounded-pill"
                                                    onClick={() => {
                                                        setIsAdding(false);
                                                        setErrorMessage("");
                                                    }}
                                                >
                                                    Отмена
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {errorMessage && (
                                        <div className="alert alert-danger mt-3 rounded-4 shadow-sm" role="alert">
                                            <i className="bi bi-exclamation-triangle-fill me-2"></i>
                                            {errorMessage}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="row mb-4">
                        <div className={`col-lg-${isDashboardListCollapsed ? '1' : '3'} transition-width`} style={{
                            transition: 'width 0.3s ease'
                        }}>
                            <div className="card border-0 shadow rounded-4 h-100">
                                <div className="card-header bg-white py-3 border-bottom d-flex justify-content-between align-items-center">
                                    {!isDashboardListCollapsed && (
                                        <h5 className="mb-0 d-flex align-items-center">
                                            <i className="bi bi-collection me-2" style={{ color: "#5584CE" }}></i>
                                            Доступные дэшборды
                                        </h5>
                                    )}
                                    <button 
                                        className="btn btn-sm btn-light rounded-circle"
                                        onClick={toggleDashboardList}
                                        style={{ width: '32px', height: '32px', padding: '0', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        <i className={`bi ${isDashboardListCollapsed ? 'bi-chevron-right' : 'bi-chevron-left'}`}></i>
                                    </button>
                                </div>
                                {!isDashboardListCollapsed && (
                                    <div className="card-body p-2">
                                        {dashboards.length === 0 ? (
                                            <div className="text-center p-4 text-muted">
                                                <div className="p-3 mb-2 rounded-circle d-inline-flex" style={{ backgroundColor: "#f0f4fa" }}>
                                                    <i className="bi bi-bar-chart-line fs-1" style={{ color: "#5584CE" }}></i>
                                                </div>
                                                <p className="mt-2">Нет доступных дэшбордов</p>
                                            </div>
                                        ) : (
                                            <div className="dashboard-list p-2">
                                                {dashboards.map(dashboard => (
                                                    <div 
                                                        key={dashboard.id} 
                                                        className={`d-flex justify-content-between align-items-center py-3 px-3 mb-2 ${activeDashboard?.id === dashboard.id ? 'text-white' : ''}`}
                                                        style={{
                                                            cursor: 'pointer',
                                                            backgroundColor: activeDashboard?.id === dashboard.id ? '#5584CE' : '#f8f9fa',
                                                            borderRadius: '12px',
                                                            transition: 'all 0.2s ease'
                                                        }}
                                                        onClick={() => selectDashboard(dashboard)}
                                                    >
                                                        <div className="dashboard-name text-truncate me-2 d-flex align-items-center">
                                                            <i className={`bi ${activeDashboard?.id === dashboard.id ? 'bi-file-earmark-bar-graph-fill' : 'bi-file-earmark-bar-graph'} me-2`}></i>
                                                            {dashboard.name}
                                                        </div>
                                                        <button 
                                                            className={`btn btn-sm rounded-circle ${activeDashboard?.id === dashboard.id ? 'btn-light text-primary' : 'btn-outline-danger'}`}
                                                            style={{ width: '32px', height: '32px', padding: '0', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleRemoveDashboard(dashboard.id);
                                                            }}
                                                        >
                                                            <i className="bi bi-trash"></i>
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                )}
                                {isDashboardListCollapsed && dashboards.length > 0 && (
                                    <div className="card-body p-2">
                                        <div className="dashboard-list-collapsed p-1">
                                            {dashboards.map(dashboard => (
                                                <div 
                                                    key={dashboard.id} 
                                                    className={`d-flex justify-content-center align-items-center py-2 px-2 mb-2 rounded-circle mx-auto ${activeDashboard?.id === dashboard.id ? 'text-white' : ''}`}
                                                    style={{
                                                        cursor: 'pointer',
                                                        backgroundColor: activeDashboard?.id === dashboard.id ? '#5584CE' : '#f8f9fa',
                                                        width: '40px',
                                                        height: '40px',
                                                        transition: 'all 0.2s ease'
                                                    }}
                                                    onClick={() => selectDashboard(dashboard)}
                                                    title={dashboard.name}
                                                >
                                                    <i className={`bi ${activeDashboard?.id === dashboard.id ? 'bi-file-earmark-bar-graph-fill' : 'bi-file-earmark-bar-graph'}`}></i>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        
                        <div className={`col-lg-${isDashboardListCollapsed ? '11' : '9'} mt-4 mt-lg-0 transition-width`} style={{
                            transition: 'width 0.3s ease'
                        }}>
                            {dashboardUrl ? (
                                <div className="card border-0 shadow rounded-4 overflow-hidden h-100" ref={dashboardContainerRef}>
                                    {activeDashboard && (
                                        <div className="card-header bg-white py-3 d-flex justify-content-between align-items-center">
                                            <h5 className="mb-0 d-flex align-items-center">
                                                <i className="bi bi-file-earmark-bar-graph-fill me-2" style={{ color: "#5584CE" }}></i>
                                                {activeDashboard.name}
                                            </h5>
                                            <div className="dashboard-controls">
                                                <button 
                                                    className="btn btn-sm btn-light rounded-pill px-3 me-2 shadow-sm"
                                                    onClick={refreshDashboard}
                                                >
                                                    <i className="bi bi-arrow-clockwise me-1"></i> Обновить
                                                </button>
                                                <button 
                                                    className="btn btn-sm rounded-pill px-3 shadow-sm" 
                                                    style={{ backgroundColor: "#5584CE", color: "white" }}
                                                    onClick={toggleFullScreen}
                                                >
                                                    <i className={`bi ${isFullScreen ? 'bi-fullscreen-exit' : 'bi-fullscreen'} me-1`}></i> 
                                                    {isFullScreen ? 'Выйти' : 'Полный экран'}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                    <div className="card-body p-0 position-relative">
                                        <div className="ratio ratio-16x9" style={{minHeight: '700px'}}>
                                            <iframe 
                                                ref={iframeRef}
                                                title="Power BI Dashboard"
                                                src={dashboardUrl} 
                                                frameBorder="0" 
                                                allowFullScreen
                                                className="position-absolute top-0 left-0 w-100 h-100"
                                            ></iframe>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="card border-0 shadow rounded-4 h-100">
                                    <div className="card-body d-flex flex-column align-items-center justify-content-center p-5 text-center" style={{minHeight: '400px'}}>
                                        <div 
                                            className="mb-4 rounded-circle d-flex align-items-center justify-content-center"
                                            style={{ 
                                                width: '100px', 
                                                height: '100px', 
                                                backgroundColor: '#EBF1FA' 
                                            }}
                                        >
                                            <i className="bi bi-bar-chart-line display-5" style={{ color: "#5584CE" }}></i>
                                        </div>
                                        <h3 className="mb-3">Выберите дэшборд для просмотра</h3>
                                        <p className="text-muted mb-4 w-75">Выберите дэшборд из списка слева или добавьте новый для начала работы с аналитикой</p>
                                        <button 
                                            className="btn rounded-pill px-4 py-2 shadow-sm"
                                            onClick={() => setIsAdding(true)}
                                            style={{ backgroundColor: "#5584CE", color: "white" }}
                                        >
                                            <i className="bi bi-plus-lg me-2"></i> Добавить дэшборд
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PowerBI;