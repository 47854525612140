import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Row, Col } from "react-bootstrap";
import "../../styles/paymentStyle.css";
import Button from "../ui/Button/Button";

const DEFAULT_COLUMN_WIDTHS = {
    "Статус": { min: 80, default: 122, max: 130 },
    "Документ": { min: 130, default: 317, max: 450 },
    "Номер документа": { min: 90, default: 271, max: 400 },
    "Виды операции": { min: 150, default: 317, max: 450 },
    "Дата платежа": { min: 140, default: 198, max: 250 },
    "Организация": { min: 150, default: 308, max: 450 },
    "Сумма": { min: 100, default: 199, max: 300 },
    "Банковский счет организации": { min: 150, default: 297, max: 400 },
    "Контрагент": { min: 150, default: 419, max: 600 },
    "Банковский счет контрагента": { min: 150, default: 517, max: 700 },
    "Комментарий": { min: 100, default: 293, max: 450 },
    "Назначение платежа": { min: 100, default: 508, max: 700 },
    "НДС": { min: 50, default: 149, max: 200 },
    "Счет БУ": { min: 50, default: 100, max: 200 },
    "Валюта": { min: 50, default: 139, max: 200 },
    "Проект": { min: 50, default: 100, max: 250 },
    "Статья": { min: 50, default: 100, max: 250 },
    "Автор": { min: 50, default: 100, max: 250 }
};

const PaymentsTable = ({ payments, showSettings, setShowSettings }) => {
    const navigate = useNavigate();

    const [selectedColumns, setSelectedColumns] = useState(() => {
        const savedColumns = localStorage.getItem("selectedColumns");
        return savedColumns
            ? JSON.parse(savedColumns)
            : ["Статус", "Документ", "Номер документа", "Дата платежа", "Организация", "Сумма"];
    });

    const allColumns = [
        "Статус",
        "Документ",
        "Номер документа",
        "Виды операции",
        "Дата платежа",
        "Организация",
        "Сумма",
        "Банковский счет организации",
        "Контрагент",
        "Банковский счет контрагента",
        "Комментарий",
        "Назначение платежа",
        "НДС",
        "Счет БУ",
        "Валюта",
        "Проект",
        "Статья",
        "Автор",
    ];

    useEffect(() => {
        localStorage.setItem("selectedColumns", JSON.stringify(selectedColumns));
    }, [selectedColumns]);

    const [columnWidths, setColumnWidths] = useState(() => {
        const saved = localStorage.getItem("columnWidths");
        if (saved) {
            const parsed = JSON.parse(saved);
            return { ...DEFAULT_COLUMN_WIDTHS, ...parsed };
        }
        return { ...DEFAULT_COLUMN_WIDTHS };
    });

    const saveColumnWidthsToLS = (newWidths) => {
        localStorage.setItem("columnWidths", JSON.stringify(newWidths));
    };

    const resizingInfoRef = useRef({
        isResizing: false,
        startX: 0,
        columnKey: null,
        initialWidth: 0,
    });

    const onMouseDown = (e, column) => {
        e.preventDefault();
        e.stopPropagation();
        
        // Создаем новый объект, чтобы избежать мутации
        resizingInfoRef.current = {
            isResizing: true,
            startX: e.clientX,
            columnKey: column,
            initialWidth: columnWidths[column] || DEFAULT_COLUMN_WIDTHS[column]?.default || 150
        };
    
        // Добавляем обработчики глобально
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };
    
    const onMouseMove = (e) => {
        if (!resizingInfoRef.current.isResizing) return;
    
        const { startX, columnKey, initialWidth } = resizingInfoRef.current;
        const delta = e.clientX - startX;
        
        const columnConfig = DEFAULT_COLUMN_WIDTHS[columnKey] || { min: 50, max: 500 };
        
        const newWidth = Math.min(
            Math.max(columnConfig.min || 50, initialWidth + delta),
            columnConfig.max || 500
        );
    
        setColumnWidths(prevWidths => ({
            ...prevWidths,
            [columnKey]: newWidth
        }));
    };
    
    const onMouseUp = () => {
        if (resizingInfoRef.current.isResizing) {
            // Сохраняем новые ширины в localStorage
            saveColumnWidthsToLS(columnWidths);
    
            // Сбрасываем состояние ресайза
            resizingInfoRef.current = {
                isResizing: false,
                startX: 0,
                columnKey: null,
                initialWidth: 0
            };
    
            // Удаляем обработчики событий
            document.removeEventListener("mousemove", onMouseMove);
            document.removeEventListener("mouseup", onMouseUp);
        }
    };

    const columnsInUse = allColumns.filter((col) => selectedColumns.includes(col));
    const totalTableWidth = columnsInUse.reduce((acc, col) => {
        return acc + (columnWidths[col] || DEFAULT_COLUMN_WIDTHS[col] || 100);
    }, 0);

    const toggleColumn = (column) => {
        setSelectedColumns((prevColumns) =>
            prevColumns.includes(column)
                ? prevColumns.filter((col) => col !== column)
                : [...prevColumns, column]
        );
    };

    const selectAllColumns = () => {
        setSelectedColumns([...allColumns]);
    };

    const saveSettings = () => {
        setShowSettings(false);
    };

    const cancelSettings = () => {
        const savedColumns = localStorage.getItem("selectedColumns");
        setSelectedColumns(savedColumns ? JSON.parse(savedColumns) : []);
        setShowSettings(false);
    };

    const handleRowClick = (payment) => {
        window.open(`/edit-payment/${payment.id}`, "_blank");
    };

    const formatDate = (isoDateString) => {
        const date = new Date(isoDateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };

    const getStatusDisplay = (payment) => {
        const { is_spaced, is_auto_spaced } = payment;
        const bgColor = is_spaced ? "#75EA27" : "#FB7E24";
        const showA = is_auto_spaced;
        return (
            <div className="position-relative">
                <div
                    className="d-flex align-items-center justify-content-center rounded-circle"
                    style={{
                        backgroundColor: bgColor,
                        width: "16px",
                        height: "16px",
                        color: "white",
                        margin: "auto",
                    }}
                >
                    {showA && <span className="fw-bold" style={{ fontSize: "10px" }}>A</span>}
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="Payments__wrapper">
                <table
                    className="Payments__table"
                    style={{
                        tableLayout: "fixed",
                        width: totalTableWidth,
                    }}
                >
                    <thead>
                    <tr>
                        <th style={{ width: 40 }}>№</th>
                        {columnsInUse.map((column) => {
                            const columnWidthConfig = DEFAULT_COLUMN_WIDTHS[column] || { min: 50, default: 150, max: 500 };
                            return (
                                <th
                                    key={column}
                                    style={{
                                        position: "relative", // ВАЖНО: делаем th позиционированным
                                        width: columnWidths[column] || columnWidthConfig.default,
                                        minWidth: columnWidthConfig.min,
                                        maxWidth: columnWidthConfig.max,
                                        borderBottom: "1px solid #ccc",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    <div
                                        style={{
                                            // Можно оставить display: flex для выравнивания заголовка и иконки
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            height: "100%",
                                            width: "100%",
                                            paddingRight: "10px", // Отступ, чтобы текст не заходил под ручку
                                            boxSizing: "border-box",
                                        }}
                                    >
                                        <span>{column}</span>
                                    </div>
                                    {/* Ручка для ресайза */}
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "50%",
                                            transform: "translate(-50%, -25%)",
                                            right: 0,
                                            height: "100%",
                                            width: "6px",
                                            cursor: "col-resize",
                                            zIndex: 9999, // большой z-index, чтобы ручка была всегда сверху
                                        }}
                                        onMouseDown={(e) => onMouseDown(e, column)}
                                    >
                                        ||
                                    </div>
                                </th>
                            );
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {payments.map((payment, index) => (
                        <tr key={payment.id} onClick={() => handleRowClick(payment)}>
                            <td style={{ width: 40, textAlign: 'center' }}>
                                {index + 1}
                            </td>
                            {selectedColumns.includes("Статус") && (
                                <td
                                    style={{
                                        width: columnWidths["Статус"] || DEFAULT_COLUMN_WIDTHS["Статус"],
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {getStatusDisplay(payment)}
                                </td>
                            )}
                            {selectedColumns.includes("Документ") && (
                                <td
                                    style={{
                                        width: columnWidths["Документ"] || DEFAULT_COLUMN_WIDTHS["Документ"].default,
                                        minWidth: DEFAULT_COLUMN_WIDTHS["Документ"].min,
                                        maxWidth: DEFAULT_COLUMN_WIDTHS["Документ"].max,
                                        wordBreak: "break-word",
                                        whiteSpace: "normal",
                                        overflowWrap: "break-word",
                                    }}
                                >
                                    {payment.document_type?.name || ""}
                                </td>
                            )}
                            {selectedColumns.includes("Номер документа") && (
                                <td
                                    style={{
                                        width: columnWidths["Номер документа"] || DEFAULT_COLUMN_WIDTHS["Номер документа"],
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {payment.number || ""}
                                </td>
                            )}
                            {selectedColumns.includes("Виды операции") && (
                                <td
                                    style={{
                                        width: columnWidths["Виды операции"] || DEFAULT_COLUMN_WIDTHS["Виды операции"],
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {payment.type_of_operation?.name || ""}
                                </td>
                            )}
                            {selectedColumns.includes("Дата платежа") && (
                                <td
                                    style={{
                                        width: columnWidths["Дата платежа"] || DEFAULT_COLUMN_WIDTHS["Дата платежа"],
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {formatDate(payment.date)}
                                </td>
                            )}
                            {selectedColumns.includes("Организация") && (
                                <td
                                    style={{
                                        width: columnWidths["Организация"] || DEFAULT_COLUMN_WIDTHS["Организация"],
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {payment.company?.name || ""}
                                </td>
                            )}
                            {selectedColumns.includes("Сумма") && (
                                <td
                                    style={{
                                        width: columnWidths["Сумма"] || DEFAULT_COLUMN_WIDTHS["Сумма"],
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {new Intl.NumberFormat("ru-RU").format(payment.total_sum)}
                                </td>
                            )}
                            {selectedColumns.includes("Банковский счет организации") && (
                                <td
                                    style={{
                                        width:
                                            columnWidths["Банковский счет организации"] ||
                                            DEFAULT_COLUMN_WIDTHS["Банковский счет организации"],
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {payment.company_account?.name || ""}
                                </td>
                            )}
                            {selectedColumns.includes("Контрагент") && (
                                <td
                                    style={{
                                        width: columnWidths["Контрагент"] || DEFAULT_COLUMN_WIDTHS["Контрагент"],
                                        wordBreak: "break-word",
                                        whiteSpace: "normal",
                                        overflowWrap: "break-word",
                                    }}
                                >
                                    {payment.counterparty?.name || ""}
                                </td>
                            )}
                            {selectedColumns.includes("Банковский счет контрагента") && (
                                <td
                                    style={{
                                        width:
                                            columnWidths["Банковский счет контрагента"] ||
                                            DEFAULT_COLUMN_WIDTHS["Банковский счет контрагента"],
                                            wordBreak: "break-word",
                                            whiteSpace: "normal",
                                            overflowWrap: "break-word",
                                    }}
                                >
                                    {payment.counterparty_account?.name || ""}
                                </td>
                            )}
                            {selectedColumns.includes("Комментарий") && (
                                <td
                                    style={{
                                        width: columnWidths["Комментарий"] || DEFAULT_COLUMN_WIDTHS["Комментарий"],
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {payment.comment || ""}
                                </td>
                            )}
                            {selectedColumns.includes("Назначение платежа") && (
                                <td
                                    style={{
                                        width:
                                            columnWidths["Назначение платежа"] ||
                                            DEFAULT_COLUMN_WIDTHS["Назначение платежа"],
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {payment.purpose_of_payment || ""}
                                </td>
                            )}
                            {selectedColumns.includes("НДС") && (
                                <td
                                    style={{
                                        width: columnWidths["НДС"] || DEFAULT_COLUMN_WIDTHS["НДС"],
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {payment.commission_sum || ""}
                                </td>
                            )}
                            {selectedColumns.includes("Счет БУ") && (
                                <td
                                    style={{
                                        width: columnWidths["Счет БУ"] || DEFAULT_COLUMN_WIDTHS["Счет БУ"],
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {payment.accounting_account?.code || ""}
                                </td>
                            )}
                            {selectedColumns.includes("Валюта") && (
                                <td
                                    style={{
                                        width: columnWidths["Валюта"] || DEFAULT_COLUMN_WIDTHS["Валюта"],
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {payment.currency?.name || ""}
                                </td>
                            )}
                            {selectedColumns.includes("Проект") && (
                                <td
                                    style={{
                                        width: columnWidths["Проект"] || DEFAULT_COLUMN_WIDTHS["Проект"],
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {payment.project?.name || ""}
                                </td>
                            )}
                            {selectedColumns.includes("Статья") && (
                                <td
                                    style={{
                                        width: columnWidths["Статья"] || DEFAULT_COLUMN_WIDTHS["Статья"],
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {payment.caption?.name || ""}
                                </td>
                            )}
                            {selectedColumns.includes("Автор") && (
                                <td style={{ 
                                    width: columnWidths["Автор"] || DEFAULT_COLUMN_WIDTHS["Автор"],
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                                > 
                                    {payment.author ? (
                                        <>
                                            {payment.author.first_name || ""}
                                            {payment.author.first_name && payment.author.last_name ? " " : ""}
                                            {payment.author.last_name || ""}
                                            
                                        </>
                                    ) : "1C - Автор"}
                                </td>
                            )}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            {showSettings && (
                <div className="modal" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}>
                    <div className="modal-dialog" style={{ maxWidth: "700px" }}>
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div className="modal-header" style={{ borderBottom: "2px solid #eee" }}>
                                <h5 className="modal-title">Настройка отображения столбцов</h5>
                                <button className="btn-close" onClick={cancelSettings}></button>
                            </div>
                            <div className="modal-body" style={{ padding: "20px" }}>
                                <div className="container-fluid">
                                    <Row className="gy-2">
                                        {allColumns.slice(0, -2).map((column) => (
                                            <Col xs={4} key={column}>
                                                <div
                                                    className={`p-2 d-flex align-items-center justify-content-start ${
                                                        selectedColumns.includes(column) ? "selected-column" : ""
                                                    }`}
                                                    onClick={() => toggleColumn(column)}
                                                    style={{
                                                        cursor: "pointer",
                                                        border: selectedColumns.includes(column)
                                                            ? "2px solid #007BFF"
                                                            : "1px solid #ddd",
                                                        padding: "8px",
                                                        transition: "0.3s",
                                                        borderRadius: "5px",
                                                    }}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedColumns.includes(column)}
                                                        onChange={() => {}}
                                                        className="me-2"
                                                    />
                                                    <span className={`column-label ${
                                                        selectedColumns.includes(column) ? "text-primary" : ""
                                                    }`}>
                                                        {column}
                                                    </span>
                                                </div>
                                            </Col>
                                        ))}
                                        <Col xs={4}>
                                            <div
                                                className={`p-2 d-flex align-items-center justify-content-start ${
                                                    selectedColumns.includes("Статья") ? "selected-column" : ""
                                                }`}
                                                onClick={() => toggleColumn("Статья")}
                                                style={{
                                                    cursor: "pointer",
                                                    border: selectedColumns.includes("Статья")
                                                        ? "2px solid #007BFF"
                                                        : "1px solid #ddd",
                                                    padding: "8px",
                                                    transition: "0.3s",
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={selectedColumns.includes("Статья")}
                                                    onChange={() => {}}
                                                    className="me-2"
                                                />
                                                <span className={`column-label ${
                                                    selectedColumns.includes("Статья") ? "text-primary" : ""
                                                }`}>
                                                    Статья
                                                </span>
                                            </div>
                                        </Col>
                                        <Col xs={4}>
                                            <div
                                                className={`p-2 d-flex align-items-center justify-content-start ${
                                                    selectedColumns.includes("Автор") ? "selected-column" : ""
                                                }`}
                                                onClick={() => toggleColumn("Автор")}
                                                style={{
                                                    cursor: "pointer",
                                                    border: selectedColumns.includes("Автор")
                                                        ? "2px solid #007BFF"
                                                        : "1px solid #ddd",
                                                    padding: "8px",
                                                    transition: "0.3s",
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={selectedColumns.includes("Автор")}
                                                    onChange={() => {}}
                                                    className="me-2"
                                                />
                                                <span className={`column-label ${
                                                    selectedColumns.includes("Автор") ? "text-primary" : ""
                                                }`}>
                                                    Автор
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className="modal-footer" style={{ borderTop: "2px solid #eee" }}>
                                <Button
                                    bType="secondary"
                                    size="medium"
                                    fixed
                                    onClick={selectAllColumns}
                                >
                                    Выбрать все
                                </Button>
                                <Button
                                    bType="secondary"
                                    size="medium"
                                    fixed
                                    onClick={cancelSettings}
                                >
                                    Отмена
                                </Button>
                                <Button 
                                    bType="primary" 
                                    size="medium" 
                                    fixed 
                                    onClick={saveSettings}
                                >
                                    Сохранить
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PaymentsTable;
