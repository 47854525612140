import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import "../styles/header.css";
import logo from '../assets/pp_logo.png';
import avatar from '../assets/avatar.png';
import { jwtDecode } from 'jwt-decode';
import api from "../api";
import 'bootstrap-icons/font/bootstrap-icons.css';
import Button from "./ui/Button/Button";
import Input from "./ui/Input/Input";

const Header = () => {
    const nav = useNavigate()
    const [allFinModels, setAllFinModels] = useState([]); // состояние для хранения списка моделей
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [groups, setGroups] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState({});
    const [isSearching, setIsSearching] = useState(false);
    const [showSearchResults, setShowSearchResults] = useState(false);
    const [showModal, setShowModal] = useState(false); // Для модального окна 
    const searchRef = useRef(null);
    const profileRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [modelName, setModelName] = useState('');
    const [error, setError] = useState(''); 
    // console.log(typeof allFinModels, allFinModels.length,allFinModels);
    const handleProfileClick = () => {
        setIsProfileOpen(!isProfileOpen);
        fetchGroups();
    };

    const handleInputChange = (event) => {
        setModelName(event.target.value);
    };

    const fetchGroups = async () => {
        try {
            const response = await api.get('/api/v1/finmodel/block/');
            setGroups(response.data);
        } catch (error) {
            console.error('Error fetching groups', error);
        }
    };
    useEffect(() => {
        fetchGroups();
    }, []);
    
    const FinmodelCreate = async (name) => {
        if (!name) {
            setError('Заполните поле'); // Устанавливаем ошибку, если поле пустое
            return;
        }

        try {
            const response = await api.post('/api/v1/finmodel/create/', { name });
            console.log('Financial model created:', response.data);
            const allFinModels = JSON.parse(localStorage.getItem('allFinModel')) || [];

            // console.log(response.data.id,"---id")
            allFinModels.push({id:response.data.id,name:response.data.name});
            localStorage.setItem('allFinModel', JSON.stringify(allFinModels));
            localStorage.setItem('selectedFinModel', JSON.stringify({id:response.data.id,name:response.data.name}));
            
            if (window.location.pathname === "/finmodel") {
                window.location.reload(); 
                // nav(0); 
            } else {
                nav("/finmodel"); 
            }
            // setShowModal(false); // Закрываем модальное окно после успешного создания
            // window.location.reload();
            
        } catch (error) {
            console.error('Error creating financial model:', error);
            setError('Ошибка при создании модели. Попробуйте снова.');
        }
    };

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                setLoading(true);
                // Ваш код для загрузки групп
            } catch (error) {
                console.error('Error fetching groups:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchGroups();
    }, []);

    useEffect(() => {
        // Загрузка списка финансовых моделей из localStorage
        const storedModels = JSON.parse(localStorage.getItem('allFinModel')) || [];
        setAllFinModels(storedModels);

        fetchGroups();
    }, []);

    const handleSearch = async (e) => {
        e.preventDefault();
        if (!searchQuery.trim()) return;

        setIsSearching(true);
        try {
            const response = await api.get(`/api/v1/search/?q=${encodeURIComponent(searchQuery)}`);
            setSearchResults(response.data);
            setShowSearchResults(true);
        } catch (error) {
            console.error('Error performing search:', error);
        } finally {
            setIsSearching(false);
        }
    };

    const handleClickOutside = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            setShowSearchResults(false);
        }
        if (profileRef.current && !profileRef.current.contains(event.target)) {
            setIsProfileOpen(false);
        }
    };
    useEffect(() => {
        setShowSearchResults(false);
    }, [location]);

    useEffect(() => {
        const token = localStorage.getItem('access');
        if (token) {
            const decodedToken = jwtDecode(token);
            setUserData(decodedToken);
        }

        setLoading(false);

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    if (loading) {
        return <div><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>Loading...</div>;
    }

    const userGroups = groups.filter(group =>
        group.employees.some(user => user.id === userData.user_id)
    );

    const renderSearchResults = () => {
        const hasResults = Object.values(searchResults).some(category =>
            Array.isArray(category.data) && category.data.length > 0
        );

        if (!hasResults) {
            return (
                <div className="search-results position-absolute w-100 mt-1 p-2 bg-white border rounded">
                    <p className="text-center mb-0">Ничего не найдено</p>
                </div>
            );
        }

        return (
            <div className="search-results position-absolute w-100 mt-1 p-2 bg-white border rounded">
                {Object.entries(searchResults).map(([categoryKey, category]) => {
                    if (!Array.isArray(category.data) || category.data.length === 0) {
                        return null;
                    }
                    return (
                        <div key={categoryKey}>
                            <h6 className="mt-2 mb-1">{category.name}</h6>
                            <ul className="list-unstyled mb-0">
                                {category.data.map((item, index) => (
                                    <li key={index} className="py-1">
                                        <Link
                                            to={{
                                                pathname: `/${categoryKey.toLowerCase()}`,
                                                search: `${item.id}`,
                                                state: "aloha"
                                            }}
                                            className="text-decoration-none text-dark"
                                        >
                                            {item.name || item.code || `${item.id}`}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <>
            <header className="header d-flex justify-content-between align-items-center p-3 bg-white">
                <div className="d-flex align-items-center">
                    <img src={logo} alt="Logo" className="logo me-2" />
                    <div ref={searchRef} className="position-relative">
                        <form onSubmit={handleSearch} className="d-flex ms-3">
                            <Input
                                type="text"
                                placeholder="Глобальный поиск..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                style={{
                                    marginRight: '10px',
                                }}
                            />
                            <Button bType="primary" size="small" type="submit">
                                {isSearching ? (
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                ) : (
                                    <i className="bi bi-search"></i>
                                )}
                            </Button>
                        </form>
                        {showSearchResults && renderSearchResults()}
                    </div>
                </div>
                <div ref={profileRef} className="position-relative">
                    <button
                        onClick={handleProfileClick}
                        className="btn btn-link text-decoration-none text-dark"
                    >
                        <img src={avatar} alt="User Avatar" className="avatar me-2" />
                        <span>{userData.first_name + ' ' + userData.last_name}</span>
                        <i className="bi bi-chevron-down" style={{
                            marginLeft: 6
                        }}></i>
                    </button>
                    {isProfileOpen && (
                        <div className="profile-dropdown position-absolute end-0 mt-2 p-3 bg-white border" style={{
                            borderRadius: "12px"
                        }}>
                            <div className="Header__Profile">
                                <img src={avatar} alt="User Avatar" className="Header__Profile__image" />
                                <div className="Header__Profile__info">
                                    <div className="Header__Profile__name">
                                        {userData.first_name + ' ' + userData.last_name}
                                    </div>
                                    <div className="Header__Profile__email">
                                        {userData.email}
                                    </div>
                                </div>
                            </div>

                            <div className="Header__List">
                            <div className="fin-model-list">
                            <p style={{
                                marginBottom: 4,
                                fontSize: 14,
                                color: "rgba(0,0,0,0.6)"
                            }}>Фин.модели</p>
                            {allFinModels.length > 1 ? (
                                Object.values(allFinModels).map((model, index) => (
                                    <div
                                        className="Header__List__item"
                                        onClick={() => {
                                            localStorage.setItem('selectedFinModel', JSON.stringify(model));
                                            nav("/finmodel");
                                            window.location.reload();
                                        }}
                                        key={index}
                                        style={{
                                            background: JSON.parse(localStorage.getItem('selectedFinModel')).id == model.id ? "#cd853f": "",
                                            color: JSON.parse(localStorage.getItem('selectedFinModel')).id == model.id ? "#fff": "",
                                        }}
                                    >
                                        <div className="Header__List__icon">
                                            <i className="bi bi-cash"></i>
                                        </div>
                                        <a>{model.name}</a>
                                    </div>
                                ))

                                
                            ) : allFinModels.length===0? (
                                <div className="Header__List__item" >
                                    <a>Нет доступных моделей</a>
                                </div>
                               
                            ) : (
                                <div className="Header__List__item" onClick={() => {
                                    const selectedModel = allFinModels; // Используем сам объект
                                    localStorage.setItem('selectedFinModel', JSON.stringify(selectedModel));
                                    nav("/finmodel");
                                    window.location.reload();
                                }}>
                                    <div className="Header__List__icon">
                                        <i className="bi bi-cash"></i>
                                    </div>
                                    <a>{allFinModels.name}</a>
                                </div>
                                
                            )}
                        </div>


           <div className="Header__List__item" onClick={() =>  {
                                    setShowModal(true)
                                }}>
                                    <div className="Header__List__icon">
                                        <i className="bi bi-cash"></i>
                                    </div>
                                    + Добавить фин. модель
                                </div>

                                <div className="Header__List__item destructive" onClick={() => {
                                    nav("/logout")
                                }}>
                                    <div className="Header__List__icon">
                                        <i className="bi bi-door-open"></i>
                                    </div>
                                    Выйти
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </header>
                {showModal && (
                <>
                <div 
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)", 
                        zIndex: 1040, 
                    }}
                ></div>
                <div className="modal d-block" tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-center w-100">Добавить Фин. Модель</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => setShowModal(false)}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="mb-3">
                                        <label htmlFor="modelName" className="form-label">
                                            Название модели<span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className={`form-control ${error ? 'is-invalid' : ''}`}
                                            value={modelName}
                                            onChange={handleInputChange}
                                            id="modelName"
                                            placeholder="Введите название модели"
                                        />
                                        {error && <div className="invalid-feedback">{error}</div>}
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => setShowModal(false)}
                                >
                                    Закрыть
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => FinmodelCreate(modelName)}
                                >
                                    Сохранить
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                </>
            )}
        </>
    );
};

export default Header;

