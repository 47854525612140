import React, { useState, useRef, useEffect } from 'react';
import api from "../../api";
import UnauthorizedModal from "../Unauthorized"; 

const SearchableRow = ({ onAdd, onCancel, existingIndividuals}) => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dropdownRef = useRef(null);
    const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(""); 
  
    useEffect(() => {
      const searchIndividuals = async () => {
        if (query.length < 2) {
          setResults([]);
          return;
        }
  
        setIsLoading(true);
        try {
          const response = await api.get(`/api/v1/bills/individual/search/?search=${query}`);
          // Filter out individuals already in the matrix
          const filteredResults = response.data.filter(
            person => !existingIndividuals.includes(person.id)
          );
          setResults(filteredResults);
        } catch (error) {
          console.error("Ошибка при загрузке контрагентов:", error);
    
            if (error.response) {
                console.error("Ответ сервера:", error.response.status, error.response.data);

                if (error.response.status === 401) {
                    console.log("401 ошибка! Перезайдите, пожалуйста.");
                    setShowUnauthorizedModal(true);
                } else if (error.response.status === 403) {
                    setErrorMessage("Доступ запрещен! У вас недостаточно прав на просмотр контрагентов.");
                } else if (error.response.status === 400) {
                    setErrorMessage(error.response.data.detail || "Ошибка запроса.");
                } else if (error.response.status === 500) {
                    setErrorMessage("Ошибка сервера! Попробуйте позже.");
                } else {
                    setErrorMessage(`Неизвестная ошибка: ${error.response.status}`);
                }
            } else {
                console.error("Ошибка сети или сервера:", error);
                setErrorMessage("Ошибка соединения с сервером. Проверьте интернет.");
            }
          setResults([]);
        } finally {
          setIsLoading(false);
        }
      };
  
      const debounceTimer = setTimeout(searchIndividuals, 300);
  
      return () => clearTimeout(debounceTimer);
    }, [query, existingIndividuals]);
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setShowDropdown(false);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

  const handleSelect = (person) => {
    onAdd(person);
    setQuery('');
    setShowDropdown(false);
  };

  return (
    <>
        <tr>
            <td colSpan="2">
                <div className="position-relative" ref={dropdownRef}>
                    <input
                        type="text"
                        value={query}
                        onChange={(e) => {
                            setQuery(e.target.value);
                            setShowDropdown(true);
                        }}
                        onFocus={() => setShowDropdown(true)}
                        className="form-control"
                        placeholder="Поиск физ. лица..."
                    />
                    {showDropdown && (
                        <ul className="list-group position-absolute w-100" style={{ zIndex: 1000 }}>
                            {isLoading ? (
                                <li className="list-group-item">Загрузка...</li>
                            ) : results.length > 0 ? (
                                results.map((person) => (
                                    <li
                                        key={person.id}
                                        className="list-group-item list-group-item-action"
                                        onClick={() => handleSelect(person)}
                                    >
                                        {person.name}
                                    </li>
                                ))
                            ) : (
                                <li className="list-group-item">Не найдено</li>
                            )}
                        </ul>
                    )}
                </div>
            </td>
            <td>
                <button onClick={onCancel} className="btn btn-secondary btn-sm me-2">Отменить</button>
            </td>
        </tr>
        <UnauthorizedModal 
            show={showUnauthorizedModal} 
            onClose={() => setShowUnauthorizedModal(false)} 
        />
        {errorMessage && (
            <div className="alert alert-danger mt-2">{errorMessage}</div>
        )}
    </>
);
}

export default SearchableRow;