import { useState, useEffect, useRef } from "react";

const EditableCell = ({ value, onSave }) => {
    const [editing, setEditing] = useState(false);
    const [editValue, setEditValue] = useState(value);
    const inputRef = useRef(null);

    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    useEffect(() => {
        setEditValue(value);
    }, [value]);

    const handleBlur = () => {
        setEditing(false);
        setEditValue(value);  // Reset to original value
    };

    const handleSave = () => {
        const numericValue = parseFloat(editValue);
        if (!isNaN(numericValue) && numericValue >= 0 && numericValue !== value) {
            onSave(numericValue);
        } else {
            setEditValue(value); // Reset to original value if invalid
        }
        setEditing(false);
    };

    const handleChange = (e) => {
        const newValue = e.target.value;
        if (newValue === '' || (parseFloat(newValue) >= 0)) {
            setEditValue(newValue);
        }
    };

    if (editing) {
        return (
            <input
                ref={inputRef}
                type="number"
                className="form-control form-control-sm"
                min="0"
                value={editValue}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleSave();
                    }
                }}
            />
        );
    }

    return <span onClick={() => setEditing(true)}>{value}</span>;
};


export default EditableCell;