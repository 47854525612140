import React, { useState, useEffect, useRef, useCallback } from "react";
import api from "../../api";
import { useLocation, useNavigate } from "react-router-dom";
import SideBar from "../SideBar";
import Header from "../Header";
import ReactLoading from "react-loading";
import APIMultipleSearchableSelect from "../payments/SearchableTableMultiSelect";
import APISearchableSelect from "../payments/SearchableSelect";
import PageHeading from "../ui/PageHeading/PageHeading";
import Heading from "../ui/Heading/Heading";
import Button from "../ui/Button/Button";
import Input from "../ui/Input/Input";
import UnauthorizedModal from "../Unauthorized"; 
import { Alert } from 'react-bootstrap';

const ALL_COLUMNS = ["ФИО физического лица", "ИИН", "Организация", "Архивный"];
const DEFAULT_COLUMN_WIDTHS = {
    "ФИО физического лица": 300,
    "ИИН": 120,
    "Организация": 200,
    "Архивный": 100,
};

const Employees = () => {
    const [errorMessage, setErrorMessage] = useState(""); 
    const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const limit = 25;
    const containerRef = useRef(null);
    const [checkedEmployees, setCheckedEmployees] = useState([]);
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [nameFilter, setNameFilter] = useState("");
    const [iinFilter, setInnFilter] = useState("");
    const [organFilter, setSelectedOrganization] = useState("");
    const [archivedFilterNotArchive, setArchivedFilterNotActive] = useState(false);
    const [archivedFilterActive, setArchivedFilterActive] = useState(false);
    const [isFiltering, setIsFiltering] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        name: "",
        lastName: "",
        firstName: "",
        middleName: "",
        iin: "",
        company: "",
    });
    const navigate = useNavigate();
    const location = useLocation();
    const searchIDofelem = location.search.replace("?", "");
    const [showSettings, setShowSettings] = useState(false);

    const [selectedColumns, setSelectedColumns] = useState(() => {
        const saved = localStorage.getItem("employeesSelectedColumns");
        return saved ? JSON.parse(saved) : [...ALL_COLUMNS];
    });

    const [columnWidths, setColumnWidths] = useState(() => {
        const saved = localStorage.getItem("employeesColumnWidths");
        if (saved) return { ...DEFAULT_COLUMN_WIDTHS, ...JSON.parse(saved) };
        return { ...DEFAULT_COLUMN_WIDTHS };
    });

    const resizingInfoRef = useRef({
        isResizing: false,
        startX: 0,
        columnKey: null,
        initialWidth: 0,
    });

    const onMouseDown = (e, column) => {
        e.preventDefault();
        e.stopPropagation();
        resizingInfoRef.current.isResizing = true;
        resizingInfoRef.current.startX = e.clientX;
        resizingInfoRef.current.columnKey = column;
        resizingInfoRef.current.initialWidth =
            columnWidths[column] || DEFAULT_COLUMN_WIDTHS[column] || 150;
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };

    const onMouseMove = (e) => {
        if (!resizingInfoRef.current.isResizing) return;
        const { startX, columnKey, initialWidth } = resizingInfoRef.current;
        const delta = e.clientX - startX;
        const newWidth = Math.max(50, initialWidth + delta);
        setColumnWidths((prev) => ({
            ...prev,
            [columnKey]: newWidth,
        }));
    };

    const onMouseUp = () => {
        resizingInfoRef.current.isResizing = false;
        // document.removeEventListener("mousemove", onMouseMove);
        // document.removeEventListener("mouseup", onMouseUp);
        setColumnWidths((prev) => {
            localStorage.setItem("employeesColumnWidths", JSON.stringify(prev));
            return prev;
        });
    };

    useEffect(() => {
        localStorage.setItem(
            "employeesSelectedColumns",
            JSON.stringify(selectedColumns)
        );
    }, [selectedColumns]);

    const columnsInUse = ALL_COLUMNS.filter((col) => selectedColumns.includes(col));
    const totalTableWidth = columnsInUse.reduce((acc, col) => {
        return acc + (columnWidths[col] || DEFAULT_COLUMN_WIDTHS[col] || 100);
    }, 0);

    const openModal = (open) => {
        if (open) { 
            setShowModal(false);
            setShowUnauthorizedModal(true);
        } else { 
            setShowUnauthorizedModal(false);
        }

    };

    const fetchEmployees = async (currentOffset = 0, isNewSearch = false) => {
        setLoading(true);
        try {
            let response;
            const filterParams = {
                id: searchIDofelem || undefined,
                name: nameFilter ? nameFilter.toLowerCase() : undefined,
                iin: iinFilter || undefined,
                is_active: [!archivedFilterNotArchive, archivedFilterActive],
                company: organFilter || undefined,
                limit,
                offset: currentOffset,
            };
            if (isFiltering || searchIDofelem) {
                response = await api.post("/api/v1/bills/employeefilter/", filterParams);
                const newData = response.data.results || response.data;
                if (isNewSearch) {
                    setEmployees(newData);
                } else {
                    setEmployees((prev) => [...prev, ...newData]);
                }
                setHasMore(response.data.next !== null);
            } else {
                response = await api.get(`/api/v1/bills/employee/?limit=${limit}&offset=${currentOffset}`);
                if (isNewSearch) {
                    setEmployees(response.data.results);
                } else {
                    setEmployees((prev) => [...prev, ...response.data.results]);
                }
                setHasMore(response.data.next !== null);
            }
        } catch (error) {
            console.error("Ошибка при загрузке контрагентов:", error);
    
            if (error.response) {
                console.error("Ответ сервера:", error.response.status, error.response.data);

                if (error.response.status === 401) {
                    console.log("401 ошибка! Перезайдите, пожалуйста.");
                    openModal();
                } else if (error.response.status === 403) {
                    setErrorMessage("Доступ запрещен! У вас недостаточно прав на просмотр физических лиц.");
                } else if (error.response.status === 400) {
                    setErrorMessage(error.response.data.detail || "Ошибка запроса.");
                } else if (error.response.status === 500) {
                    setErrorMessage("Ошибка сервера! Попробуйте позже.");
                } else {
                    setErrorMessage(`Неизвестная ошибка: ${error.response.status}`);
                }
            } else {
                console.error("Ошибка сети или сервера:", error);
                setErrorMessage("Ошибка соединения с сервером. Проверьте интернет.");
            }

            setHasMore(false);
        } finally {
            setLoading(false);
        }
    };

    const fetchCompanies = async () => {
        try {
            const response = await api.get("/api/v1/organization/");
            setCompanies(response.data);
        } catch (error) {}
    };

    useEffect(() => {
        fetchCompanies();
    }, []);

    useEffect(() => {
        setEmployees([]);
        setOffset(0);
        setHasMore(true);
        fetchEmployees(0, true);
    }, [location]);

    useEffect(() => {
        if (offset > 0) {
            fetchEmployees(offset, false);
        }
    }, [offset]);

    useEffect(() => {
        if (searchIDofelem) {
            setIsFiltering(true);
        }
        fetchEmployees(0, true);
    }, [searchIDofelem]);

    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            const scrollThreshold = 50;
            if (scrollHeight - (scrollTop + clientHeight) <= scrollThreshold && hasMore && !loading) {
                setOffset((prevOffset) => prevOffset + limit);
            }
        }
    }, [hasMore, loading]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener("scroll", handleScroll);
            return () => container.removeEventListener("scroll", handleScroll);
        }
    }, [handleScroll]);

    const handleUpdateEmployees = async (isActive) => {
        setLoading(true);
        try {
            await Promise.all(
                checkedEmployees.map((id) =>
                    api.patch(`/api/v1/bills/employee/${id}/update/`, { is_active: isActive })
                )
            );
            setEmployees([]);
            setOffset(0);
            setHasMore(true);
            setCheckedEmployees([]);
            fetchEmployees(0, true);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const handleArchiveEmployees = () => handleUpdateEmployees(false);
    const handleUnarchiveEmployees = () => handleUpdateEmployees(true);

    const handleCheckboxChange = (empId) => {
        setCheckedEmployees((prev) =>
            prev.includes(empId) ? prev.filter((id) => id !== empId) : [...prev, empId]
        );
    };

    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const allIds = employees.map((emp) => emp.id);
            setCheckedEmployees(allIds);
        } else {
            setCheckedEmployees([]);
        }
    };

    const allChecked = employees.length > 0 && employees.every((emp) => checkedEmployees.includes(emp.id));

    const handleSearch = async () => {
        setIsFiltering(true);
        setOffset(0);
        setHasMore(true);
        await fetchEmployees(0, true);
    };

    const handleReset = () => {
        setIsFiltering(false);
        setNameFilter("");
        setInnFilter("");
        setSelectedOrganization("");
        setArchivedFilterNotActive(false);
        setArchivedFilterActive(false);
        setOffset(0);
        setHasMore(true);
        setEmployees([]);
        fetchEmployees(0, true);
        navigate(location.pathname);
    };

    const handleModalClose = () => {
        setShowModal(false);
        setFormData({
            lastName: "",
            firstName: "",
            middleName: "",
            iin: "",
            company: "",
        });
        setErrors({});
    };

    const handleModalShow = () => {
        setFormData({
            lastName: "",
            firstName: "",
            middleName: "",
            iin: "",
            company: "",
        });
        setErrors({});
        setShowModal(true);
    };

    const validateForm = async () => {
        const newErrors = {};
        if (!formData.lastName || !formData.lastName.trim()) {
            newErrors.name = JSON.stringify({
                lastName: "Фамилия обязательна",
                firstName: formData.firstName ? "" : "Имя обязательно",
            });
        }
        if (!formData.firstName || !formData.firstName.trim()) {
            newErrors.name = JSON.stringify({
                lastName: formData.lastName ? "" : "Фамилия обязательна",
                firstName: "Имя обязательно",
            });
        }
        if (!formData.iin) {
            newErrors.iin = "ИИН обязателен";
        } else if (formData.iin.length !== 12) {
            newErrors.iin = "ИИН должен содержать ровно 12 цифр";
        } else if (!/^\d+$/.test(formData.iin)) {
            newErrors.iin = "ИИН должен содержать только цифры";
        } else {
            try {
                const response = await api.post("/api/v1/bills/employeefilter/", {
                    iin: formData.iin,
                    limit: 1,
                });
                if (response.data.results && response.data.results.length > 0) {
                    newErrors.iin = "Пользователь с таким ИИН уже существует";
                }
            } catch (error) {}
        }
        if (!formData.company) {
            newErrors.company = "Организация обязательна";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const isFormValid = () => {
        if (!formData.lastName?.trim() || !formData.firstName?.trim() || !formData.company || !formData.iin) {
            return false;
        }
        if (formData.iin.length !== 12 || !/^\d+$/.test(formData.iin)) {
            return false;
        }
        if (Object.keys(errors).length > 0) {
            return false;
        }
        return true;
    };

    const handleSubmit = async () => {
        if (loading || !isFormValid()) return;
        const isValid = await validateForm();
        if (isValid) {
            try {
                setLoading(true);
                const submissionData = {
                    ...formData,
                    name: [formData.lastName, formData.firstName, formData.middleName].filter(Boolean).join(" "),
                };
                await api.post("/api/v1/bills/individual/create/", submissionData);
                handleModalClose();
                fetchEmployees(0, true);
            } catch (error) {
                if (error.response?.data?.detail?.includes("ИИН")) {
                    setErrors((prev) => ({
                        ...prev,
                        iin: "Пользователь с таким ИИН уже существует",
                    }));
                } else {
                    setErrors((prev) => ({
                        ...prev,
                        apiError: "Доступ запрещен! У вас недостаточно прав для создания физического лица",
                    }));
                }
            } finally {
                setLoading(false);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "iin") {
            const numericValue = value.replace(/\D/g, "").slice(0, 12);
            setFormData((prev) => ({ ...prev, [name]: numericValue }));
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
        if (errors[name]) {
            setErrors((prev) => ({ ...prev, [name]: undefined }));
        }
    };

    const toggleColumn = (column) => {
        setSelectedColumns((prev) =>
            prev.includes(column) ? prev.filter((col) => col !== column) : [...prev, column]
        );
    };

    const selectAllColumns = () => {
        setSelectedColumns([...ALL_COLUMNS]);
    };

    const cancelSettings = () => {
        setShowSettings(false);
    };

    const saveSettings = () => {
        setShowSettings(false);
    };

    return (
        <div className="container-fluid" translate="no">
            <Header />
            <div className="row flex-nowrap">
                <SideBar />
                <div className="col py-3">
                    <div className="management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between mb-3">
                                    <div className="d-flex gap-2">
                                        <PageHeading>Физические лица</PageHeading>
                                        <Button
                                            bType="secondary"
                                            size="small"
                                            fixed
                                            onClick={() => setFiltersVisible(!filtersVisible)}
                                        >
                                            <i className="bi bi-funnel"></i>
                                        </Button>
                                        <Button bType="primary" size="small" fixed onClick={handleModalShow}>
                                            <i className="bi bi-plus-circle"></i>
                                        </Button>
                                    </div>
                                    <Button bType="secondary" size="small" fixed onClick={() => setShowSettings(true)}>
                                        <i className="bi bi-gear"></i>
                                    </Button>
                                </div>
                                {errorMessage && (
                                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                                        {errorMessage}
                                    </Alert>
                                )}
                                <UnauthorizedModal 
                                    show={showUnauthorizedModal} 
                                    onClose={() => setShowUnauthorizedModal(false)} 
                                />
                                {filtersVisible && (
                                    <div className="mb-3 p-3 border rounded">
                                        <Heading level={6} style={{ marginBottom: "10px" }}>
                                            Фильтры:
                                        </Heading>
                                        <div className="row g-3">
                                            <div className="col-md-3">
                                                <label className="form-label">ФИО сотрудника</label>
                                                <input
                                                    type="text"
                                                    placeholder="Введите ФИО"
                                                    value={nameFilter}
                                                    onChange={(e) => setNameFilter(e.target.value)}
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label className="form-label">ИИН</label>
                                                <input
                                                    type="text"
                                                    placeholder="Введите ИИН"
                                                    value={iinFilter}
                                                    onChange={(e) => setInnFilter(e.target.value)}
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label className="form-label">Организация</label>
                                                <APIMultipleSearchableSelect
                                                    endpoint="/api/v1/organization/search/"
                                                    value={organFilter}
                                                    onChange={(value) => setSelectedOrganization(value)}
                                                    placeholder="Выберите организацию"
                                                    setShowUnauthorizedModal={openModal}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label>Архивный сотрудник</label>
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="archivedCheckNotActive"
                                                        checked={archivedFilterNotArchive}
                                                        onChange={(e) => setArchivedFilterNotActive(e.target.checked)}
                                                    />
                                                    <label className="form-check-label" htmlFor="archivedCheckNotActive">
                                                        Да
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="archivedCheckActive"
                                                        checked={archivedFilterActive}
                                                        onChange={(e) => setArchivedFilterActive(e.target.checked)}
                                                    />
                                                    <label className="form-check-label" htmlFor="archivedCheckActive">
                                                        Нет
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center gap-3 mt-3">
                                            <Button onClick={handleSearch} bType="primary" size="medium" fixed>
                                                Поиск
                                            </Button>
                                            <Button onClick={handleReset} bType="secondary" size="medium" fixed>
                                                Сбросить
                                            </Button>
                                        </div>
                                    </div>
                                )}
                                <div className="d-flex justify-content-start align-items-center mb-3 gap-2">
                                    {checkedEmployees.length > 0 && (
                                        <>
                                            <Button
                                                onClick={handleArchiveEmployees}
                                                bType="secondary"
                                                size="medium"
                                                fixed
                                                style={{ marginRight: "10px" }}
                                            >
                                                Архивировать выбранные
                                            </Button>
                                            <Button
                                                onClick={handleUnarchiveEmployees}
                                                bType="success"
                                                size="medium"
                                                fixed
                                                style={{ marginRight: "10px" }}
                                            >
                                                Разархивировать выбранные
                                            </Button>
                                        </>
                                    )}
                                </div>
                                <div
                                    ref={containerRef}
                                    className="Payments__wrapper"
                                    style={{ height: "70vh", minHeight: "70vh", overflowY: "auto", position: "relative" }}
                                >
                                    <table
                                        className="Payments__table"
                                        style={{
                                            tableLayout: "fixed"
                                        }}
                                    >
                                        <thead>
                                        <tr>
                                            <th style={{ width: 50 }}>
                                                <input type="checkbox" checked={allChecked} onChange={handleSelectAllChange} />
                                            </th>
                                            {columnsInUse.map((col) => {
                                                const w = columnWidths[col] || DEFAULT_COLUMN_WIDTHS[col];
                                                return (
                                                    <th
                                                        key={col}
                                                        style={{
                                                            width: w,
                                                            minWidth: w,
                                                            position: "relative",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <span>{col}</span>
                                                            <div
                                                                style={{
                                                                    cursor: "col-resize",
                                                                    padding: "0 4px",
                                                                    marginRight: "-4px",
                                                                    userSelect: "none",
                                                                    zIndex: 999
                                                                }}
                                                                onMouseDown={(e) => onMouseDown(e, col)}
                                                            >
                                                                ||
                                                            </div>
                                                        </div>
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {employees.map((employee) => (
                                            <tr key={employee.id}>
                                                <td style={{ width: 50 }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={checkedEmployees.includes(employee.id)}
                                                        onChange={() => handleCheckboxChange(employee.id)}
                                                    />
                                                </td>
                                                {selectedColumns.includes("ФИО физического лица") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["ФИО физического лица"] ||
                                                                DEFAULT_COLUMN_WIDTHS["ФИО физического лица"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "normal",
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        {employee.name}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("ИИН") && (
                                                    <td
                                                        style={{
                                                            width: columnWidths["ИИН"] || DEFAULT_COLUMN_WIDTHS["ИИН"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        {employee.iin}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Организация") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Организация"] ||
                                                                DEFAULT_COLUMN_WIDTHS["Организация"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "normal",
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        {companies.find((c) => c.id === employee.company)?.name || ""}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Архивный") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Архивный"] || DEFAULT_COLUMN_WIDTHS["Архивный"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                    >
                                                        {employee.is_active ? "Нет" : "Да"}
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    {loading && (
                                        <div className="position-absolute top-50 start-50 translate-middle">
                                            <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showModal && (
                <div className="modal d-block" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header d-flex justify-content-center position-relative">
                                <h5 className="modal-title">Добавить физическое лицо</h5>
                                <button type="button" className="btn-close" onClick={handleModalClose} />
                            </div>
                            <div className="modal-body">
                                {errorMessage && (
                                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                                        {errorMessage}
                                    </Alert>
                                )}
                                    <UnauthorizedModal 
                                        show={showUnauthorizedModal} 
                                        onClose={() => setShowUnauthorizedModal(false)} 
                                    />
                                {errors.apiError && (
                                    <div className="alert alert-danger mx-3 mt-3" role="alert">
                                        {errors.apiError}
                                    </div>
                                )}
                                <form className="addindividual-form">
                                    <div className="mb-3">
                                        <label className="form-label">Фамилия *</label>
                                        <Input
                                            type="text"
                                            name="lastName"
                                            value={formData.lastName}
                                            onChange={handleInputChange}
                                        />
                                        {errors.name && JSON.parse(errors.name).lastName && (
                                            <div className="invalid-feedback">{JSON.parse(errors.name).lastName}</div>
                                        )}
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Имя *</label>
                                        <Input
                                            type="text"
                                            name="firstName"
                                            value={formData.firstName}
                                            onChange={handleInputChange}
                                        />
                                        {errors.name && JSON.parse(errors.name).firstName && (
                                            <div className="invalid-feedback">{JSON.parse(errors.name).firstName}</div>
                                        )}
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Отчество</label>
                                        <Input
                                            type="text"
                                            name="middleName"
                                            value={formData.middleName}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">ИИН *</label>
                                        <Input
                                            type="text"
                                            name="iin"
                                            value={formData.iin}
                                            onChange={handleInputChange}
                                            maxLength={12}
                                            required
                                        />
                                        {errors.iin && <div className="invalid-feedback">{errors.iin}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Организация *</label>
                                        <APISearchableSelect
                                            name="company"
                                            endpoint="/api/v1/organization/search/"
                                            value={formData.company || ""}
                                            onChange={(value) => handleInputChange({ target: { name: "company", value } })}
                                            placeholder="Выберите организацию"
                                            className={errors.company ? "is-invalid" : ""}
                                            setShowUnauthorizedModal={openModal}
                                        />
                                        {errors.company && <div className="invalid-feedback d-block">{errors.company}</div>}
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <Button bType="secondary" size="medium" fixed onClick={handleModalClose}>
                                    Закрыть
                                </Button>
                                <Button bType="primary" size="medium" fixed onClick={handleSubmit} disabled={loading || !isFormValid()}>
                                    {loading ? "Сохранение..." : "Сохранить"}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showSettings && (
                <div className="modal" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}>
                    <div className="modal-dialog" style={{ maxWidth: "700px" }}>
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div className="modal-header" style={{ borderBottom: "2px solid #eee" }}>
                                <h5 className="modal-title">Настройка столбцов</h5>
                                <button className="btn-close" onClick={cancelSettings}></button>
                            </div>
                            <div className="modal-body" style={{ padding: "20px" }}>
                                <div className="container-fluid">
                                    <div className="row" style={{ gap: "10px", justifyContent: "space-evenly" }}>
                                        {ALL_COLUMNS.map((column) => (
                                            <div
                                                key={column}
                                                className={`col-4 p-2 d-flex align-items-center justify-content-start ${
                                                    selectedColumns.includes(column) ? "selected-column" : ""
                                                }`}
                                                onClick={() => toggleColumn(column)}
                                                style={{
                                                    cursor: "pointer",
                                                    border: selectedColumns.includes(column)
                                                        ? "2px solid #007BFF"
                                                        : "1px solid #ddd",
                                                    padding: "8px",
                                                    transition: "0.3s",
                                                    borderRadius: "5px",
                                                    width: "30%",
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={selectedColumns.includes(column)}
                                                    readOnly
                                                    className="me-2"
                                                />
                                                <span className={selectedColumns.includes(column) ? "text-primary" : ""}>
                          {column}
                        </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer" style={{ borderTop: "2px solid #eee" }}>
                                <Button bType="secondary" size="medium" fixed onClick={selectAllColumns}>
                                    Выбрать все
                                </Button>
                                <Button bType="secondary" size="medium" fixed onClick={cancelSettings}>
                                    Отмена
                                </Button>
                                <Button bType="primary" size="medium" fixed onClick={saveSettings}>
                                    Сохранить
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Employees;
