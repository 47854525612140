import SideBar from "../SideBar";
import { useEffect, useState } from "react";
import api from "../../api";
import { useNavigate } from "react-router-dom";
import useFetchData from "../../functions/UprData";
import { CaptionForm } from "../captions/CaptionForm";
import APISearchableSelect from "./SearchableSelect";
import "../../styles/createPayment.css"
import Header from "../Header";
import ReactLoading from 'react-loading'
import { Alert } from 'react-bootstrap';
import Checkbox from "../ui/Checkbox/Checkbox";
import Input from "../ui/Input/Input";
import Button from "../ui/Button/Button";
import PageHeading from "../ui/PageHeading/PageHeading";
import { ProjectForm } from "../projects/ProjectForm";
import UnauthorizedModal from "../Unauthorized";
const CreatePayment = () => {
    const [formData, setFormData] = useState({
        decipher: [],
        origin: '',
        number: '',
        date: '',
        type_of_operation: '',
        total_sum: 0,
        commission_sum: 0,
        purpose_of_payment: '',
        comment: '',
        company: '',
        document_type: '',
        currency: '',
        company_account: '',
        counterparty: '',
        counterparty_account: '',
        project: '',
        caption: '',
        agreement: '',
        accounting_account: ''
    });

    const [errorMessage, setErrorMessage] = useState(""); // 🔴 Храним текст ошибки
    const [captions, setCaptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currencies, setCurrencies] = useState([]);
    const [showDecipher, setShowDecipher] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [caption_types, setCaptionTypes] = useState([]);
    const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false);

    const navigate = useNavigate()

    const fetchCaptionTypes = useFetchData('/api/v1/bills/caption-types/', setCaptionTypes);
    const fetchCaption = useFetchData('api/v1/bills/caption/', setCaptions);
    const fetchCurrency = useFetchData('/api/v1/bank/currencies/', setCurrencies);

    const fetchData = async () => {
        setLoading(true);
        try {
            await Promise.all([
                fetchCaption(),
                fetchCurrency(),
                fetchCaptionTypes(),
            ]);
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    // ------------
    // Обработчики
    // ------------
    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log('handleChange', name,value)
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleDecipherChange = (index, e) => {
        const currentTotal = formData.decipher.reduce((acc, param, i) => {
            return i === index ? acc : acc + (param.sum || 0);
        }, 0);

        const newSum = parseFloat(e.value) || 0;
        const newTotal = currentTotal + newSum;

        if (e.name === "sum" && newTotal <= formData.total_sum) {
            setShowAlert(false);
        }

        const { name, value } = e;
        const updatedDecipher = formData.decipher.map((item, i) => {
            if (i === index) {
                const updatedItem = { ...item, [name]: value };
                if (name === "sum" || name === "nds_rate") {
                    updatedItem.nds_sum = (updatedItem.sum || 0) * (updatedItem.nds_rate || 0) / 100;
                }
                return updatedItem;
            }
            return item;
        });

        setFormData(prev => ({
            ...prev,
            decipher: updatedDecipher
        }));
    };

    const [decipherFields, setDecipherFields] = useState({
        sum: true,
        nds_sum: false,
        nds_rate: false,
        counterparty: false,
        caption: false,
        project: false,
        employee: false,
        agreement: false
    });

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('ru-RU', { style: 'decimal' }).format(value);
    };

    const parseCurrency = (value) => {
        return parseFloat(value.replace(/\s+/g, '').replace(',', '.')) || 0;
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        // При отключении чекбокса — обнуляем поля, чтобы не «зависали»
        if (name === 'counterparty') {
            handleChange({ target: { name: "counterparty", value: '' } });
            handleChange({ target: { name: "counterparty_account", value: '' } });
        } else if (name === 'caption') {
            handleChange({ target: { name: "caption", value: '' } });
        } else if (name === 'project') {
            handleChange({ target: { name: "project", value: '' } });
        } else if (name === 'agreement') {
            handleChange({ target: { name: "agreement", value: '' } });
        }
        setDecipherFields(prev => ({ ...prev, [name]: checked }));
    };

    const addDecipher = () => {
        setFormData(prev => ({
            ...prev,
            decipher: [
                ...prev.decipher,
                {
                    sum: 0,
                    nds_sum: 0,
                    nds_rate: 0,
                    employee: null,
                    counterparty: null,
                    caption: '',
                    project: '',
                    accountable: null,
                    agreement: null
                }
            ]
        }));
    };

    const removeDecipher = (index) => {
        setFormData(prev => ({
            ...prev,
            decipher: prev.decipher.filter((_, i) => i !== index)
        }));
    };

    const handleShowDecipher = (checkbox) => {
        if (checkbox) {
            addDecipher();
            setShowDecipher(true);
        } else {
            setFormData(prev => ({ ...prev, decipher: [] }));
            setDecipherFields({
                sum: true,
                nds_sum: false,
                nds_rate: false,
                counterparty: false,
                caption: false,
                project: false,
                employee: false,
                agreement: false
            });
            setShowDecipher(false);
        }
    };

    // ------------
    // Отправка формы
    // ------------
    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage("");

        try {
            const response = await api.post(`/api/v1/bills/payment-create/`, formData, {
                headers: { "Content-Type": "application/json" }
            });

            console.log("Response:", response.data);
            navigate("/payment-registry");

        } catch (error) {
            if (error.response) {
                const { status, data } = error.response;
                console.error("Ошибка API:", error.response);

                if (status === 401) {
                    setShowUnauthorizedModal(true); // ✅ Показываем модальное окно при 401
                } else if (status === 403) {
                    setErrorMessage("Доступ запрещен! У вас недостаточно прав для создания платежа.");
                } else if (status === 400) {
                    setErrorMessage(data.detail || "Некорректные данные. Проверьте заполненные поля.");
                } else if (status === 500) {
                    setErrorMessage("Ошибка сервера! Попробуйте позже.");
                } else {
                    setErrorMessage(`Неизвестная ошибка: ${status}`);
                }
            } else {
                console.error("Ошибка сети или сервера:", error);
                setErrorMessage("🌐 Ошибка соединения с сервером. Проверьте интернет.");
            }
        }
    };

    // ------------
    // Рендер
    // ------------
    if (loading) {
        return (
            <div className="position-absolute top-50 start-50 translate-middle">
                <ReactLoading type="spin" color="#0000FF" height={50} width={50} />
            </div>
        );
    }

    return (
        <>
            <div className="container-fluid">
                <Header />
                <div className="row flex-nowrap">
                    <SideBar />
                    <div className="col py-4">
                        <div className="create-header mb-5">
                            <center><PageHeading>Добавление нового платежа</PageHeading></center>
                        </div>
                        <div className="create-payment-content">
                        {/* 🔴 Сообщение об ошибке (если есть) */}
                        {errorMessage && (
                            <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                                {errorMessage}
                            </Alert>
                        )}
                            <form className="payment-form" onSubmit={handleSubmit}>
                                {/* Две основные колонки */}
                                <div className="row g-3">
                                    {/* Левая колонка */}
                                    <div className="col-md-6">
                                        {/* 1. Тип документа */}
                                        <div className="mb-3">
                                            <label className='form-label'>Тип документа*</label>
                                            <APISearchableSelect
                                                endpoint="/api/v1/bills/document-types/search/"
                                                value={formData.document_type}
                                                onChange={(value) =>
                                                    handleChange({ target: { name: "document_type", value } })
                                                }
                                                placeholder="Выберите тип документа"
                                            />
                                        </div>

                                        {/* 2. Тип операции */}
                                        <div className="mb-3">
                                            <label className='form-label'>Тип операции</label>
                                            <APISearchableSelect
                                                endpoint={`/api/v1/bills/operation-types/search/?doc_type=${formData.document_type}`}
                                                value={formData.type_of_operation}
                                                disabled={!formData.document_type}
                                                onChange={(value) =>
                                                    handleChange({ target: { name: "type_of_operation", value } })
                                                }
                                                placeholder="Выберите вид операции"
                                            />
                                        </div>

                                        {/* 3. Дата платежа */}
                                        <div className="mb-3">
                                            <label className='form-label'>Дата платежа*</label>
                                            <Input
                                                type="date"
                                                name="date"
                                                value={formData.date}
                                                onChange={handleChange}
                                            />
                                        </div>

                                        {/* 4. Сумма */}
                                        <div className="mb-3">
                                            <label className='form-label'>Сумма*</label>
                                            <Input
                                                type="text"
                                                name="total_sum"
                                                placeholder='Введите сумму'
                                                value={formatCurrency(formData.total_sum)}
                                                onChange={(e) =>
                                                    handleChange({
                                                        target: {
                                                            name: "total_sum",
                                                            value: parseCurrency(e.target.value),
                                                        },
                                                    })
                                                }
                                            />
                                        </div>

                                        {/* 5. Организация */}
                                        <div className="mb-3">
                                            <label className='form-label'>Организация*</label>
                                            <APISearchableSelect
                                                endpoint="/api/v1/organization/search/"
                                                value={formData.company}
                                                onChange={(value) =>
                                                    handleChange({ target: { name: "company", value } })
                                                }
                                                placeholder="Выберите организацию"
                                            />
                                        </div>

                                        {/* 6. Банковский счет */}
                                        <div className="mb-3">
                                            <label className='form-label'>Банковский счет</label>
                                            <APISearchableSelect
                                                endpoint="/api/v1/bank/bankaccounts/search/"
                                                value={formData.company_account}
                                                onChange={(value) =>
                                                    handleChange({ target: { name: "company_account", value } })
                                                }
                                                placeholder="Выберите счет организации"
                                            />
                                        </div>

                                        {/* 7. Сумма комиссии */}
                                        <div className="mb-3">
                                            <label className='form-label'>Сумма комиссии</label>
                                            <Input
                                                type="text"
                                                name="commission_sum"
                                                placeholder='Введите сумму'
                                                value={formatCurrency(formData.commission_sum)}
                                                onChange={(e) =>
                                                    handleChange({
                                                        target: {
                                                            name: "commission_sum",
                                                            value: parseCurrency(e.target.value),
                                                        },
                                                    })
                                                }
                                            />
                                        </div>

                                        {/* 8. Валюта */}
                                        <div className="mb-3">
                                            <label className='form-label'>Валюта</label>
                                            <select
                                                className="Input"
                                                value={formData.currency || ''}
                                                name="currency"
                                                onChange={handleChange}
                                                
                                            >
                                                <option value="">
                                                    {formData.currency
                                                        ? currencies.find((c) => c.id === formData.currency)?.name
                                                        : ''}
                                                </option>
                                                {currencies.map((currency) => (
                                                    <option key={currency.id} value={currency.id}>
                                                        {currency.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        {/* 9. Проект */}
                                        {formData.decipher.length === 0 && (
                                            <div className="mb-3">
                                                {/* <label className="form-label">Проект</label>
                                                <APISearchableSelect
                                                    endpoint="/api/v1/organization/projects/search/"
                                                    value={formData.project || ''}
                                                    onChange={(value) =>
                                                        handleChange({ target: { name: "project", value } })
                                                    }
                                                    placeholder="Выберите проект"
                                                /> */}
                                                <ProjectForm
                                                    param={formData.project}
                                                    handleSelectChange={(index, selectedOption) =>                                                         
                                                        handleChange({ target: { name: "project", value: selectedOption.value } })                                                     
                                                    }
                                                />
                                            </div>
                                        )}

                                        {/* 10. Статья */}
                                        {formData.decipher.length === 0 && (
                                            <div className="mb-3">
                                                {/* <label className="form-label">Статья</label>
                                                <APISearchableSelect
                                                    endpoint="/api/v1/bills/caption/search/"
                                                    value={formData.caption || ''}
                                                    onChange={(value) =>
                                                        handleChange({ target: { name: "caption", value } })
                                                    }
                                                    placeholder="Выберите статью"
                                                /> */}
                                                <CaptionForm
                                                    captions={captions}
                                                    setCaptions={setCaptions}
                                                    param={formData.caption}
                                                    handleSelectChange={(index, selectedOption) =>                                                         
                                                        handleChange({ target: { name: "caption", value: selectedOption.value } })                                                     
                                                    }
                                                    caption_types={caption_types}
                                                />
                                            </div>
                                        )}

                                        {/* 11. Договор */}
                                        {!decipherFields.agreement && (
                                            <div className="mb-3">
                                                <label className="form-label">Договор</label>
                                                <APISearchableSelect
                                                    endpoint={`/api/v1/organization/agreements/search/?counterparty=${formData.counterparty}&company=${formData.company}`}
                                                    value={formData.agreement || ''}
                                                    onChange={(value) =>
                                                        handleChange({ target: { name: "agreement", value } })
                                                    }
                                                    placeholder="Выберите договор"
                                                />
                                            </div>
                                        )}
                                    </div>

                                    {/* Правая колонка */}
                                    <div className="col-md-6">
                                        {/* 1. Контрагент */}
                                        {!decipherFields.counterparty && (
                                            <div className="mb-3">
                                                <label className='form-label'>Контрагент</label>
                                                <APISearchableSelect
                                                    endpoint="/api/v1/bills/counterparty/search/"
                                                    value={formData.counterparty}
                                                    onChange={(value) =>
                                                        handleChange({ target: { name: "counterparty", value } })
                                                    }
                                                    placeholder="Выберите контрагента"
                                                />
                                            </div>
                                        )}

                                        {/* 2. Банковский счет контрагента */}
                                        {!decipherFields.counterparty && (
                                            <div className="mb-3">
                                                <label className='form-label'>Банковский счет контрагента</label>
                                                <APISearchableSelect
                                                    endpoint={`/api/v1/bank/counterparty-accounts/search/?counterparty=${formData.counterparty}`}
                                                    value={formData.counterparty_account}
                                                    onChange={(value) =>
                                                        handleChange({
                                                            target: { name: "counterparty_account", value },
                                                        })
                                                    }
                                                    placeholder="Выберите счет контрагента"
                                                />
                                            </div>
                                        )}

                                        {/* 3. Назначение платежа */}
                                        <div className="mb-3">
                                            <label className='form-label'>Назначение платежа</label>
                                            <Input
                                                type="text"
                                                name="purpose_of_payment"
                                                placeholder='Назначение платежа'
                                                onChange={handleChange}
                                                value={formData.purpose_of_payment}
                                            />
                                        </div>

                                        {/* 4. Комментарий */}
                                        <div className="mb-3">
                                            <label className='form-label'>Комментарий</label>
                                            <Input
                                                type="text"
                                                name="comment"
                                                placeholder='Напишите комментарий'
                                                onChange={handleChange}
                                                value={formData.comment}
                                            />
                                        </div>

                                        {/* 5. Счет БУ */}
                                        <div className="mb-3">
                                            <label className="form-label">Счет БУ</label>
                                            <APISearchableSelect
                                                endpoint="/api/v1/bills/accounting-accounts/search/"
                                                value={formData?.accounting_account}
                                                displayKey="code"
                                                onChange={(value) =>
                                                    handleChange({ target: { name: 'accounting_account', value } })
                                                }
                                                placeholder="Выберите Счет БУ"
                                            />
                                    </div>
                                    </div>
                                </div>

                                {/* Блок "Разнести" (decipher) */}
                                <div className="decipher-form mt-4">
                                    <div>
                                        <label className='me-2 d-flex align-items-center gap-1' style={{ maxWidth: "fit-content" }}>
                                            <Checkbox
                                                type="checkbox"
                                                checked={showDecipher}
                                                onChange={(e) => handleShowDecipher(e.target.checked)}
                                            />
                                            Разнести
                                        </label>
                                    </div>
                                    {showDecipher && (
                                        <div className="deciphercontent">
                                            <h6>Выберите параметр распределения</h6>
                                            <div className="params gap-3 d-flex flex-wrap">
                                                <label className='me-2 d-flex align-items-center gap-1'>
                                                    <Checkbox
                                                        type="checkbox"
                                                        name="employee"
                                                        checked={decipherFields.employee}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    Физ. лицо
                                                </label>
                                                <label className='me-2 d-flex align-items-center gap-1'>
                                                    <Checkbox
                                                        type="checkbox"
                                                        name="counterparty"
                                                        checked={decipherFields.counterparty}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    Контрагент
                                                </label>
                                                <label className="me-2 d-flex align-items-center gap-1">
                                                    <Checkbox
                                                        type="checkbox"
                                                        name="nds_rate"
                                                        checked={decipherFields.nds_rate}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    Ставка НДС
                                                </label>
                                                <label className="me-2 d-flex align-items-center gap-1">
                                                    <Checkbox
                                                        type="checkbox"
                                                        name="agreement"
                                                        checked={decipherFields.agreement}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    Договор
                                                </label>
                                            </div>

                                            {/* Динамическое рендеринг строк decipher */}
                                            <div className="deciphers">
                                                {formData.decipher.map((decipher, index) => (
                                                    <div key={index} className="decipher row py-3">
                                                        <div className="col-md-11 row">
                                                            {/* Сумма */}
                                                            {decipherFields.sum && (
                                                                <div className="col-md-4">
                                                                    <label className="form-label">Сумма:</label>
                                                                    <Input
                                                                        type="text"
                                                                        name="sum"
                                                                        min="0"
                                                                        step="any"
                                                                        value={formatCurrency(decipher.sum)}
                                                                        onChange={(e) =>
                                                                            handleDecipherChange(index, {
                                                                                name: "sum",
                                                                                value: parseCurrency(e.target.value),
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                            )}

                                                            {/* Физ. лицо */}
                                                            {decipherFields.employee && (
                                                                <div className="col-md-4">
                                                                    <label className="form-label">Физ. лицо:</label>
                                                                    <APISearchableSelect
                                                                        endpoint="/api/v1/bills/individual/search/"
                                                                        size=""
                                                                        value={decipher.employee || ''}
                                                                        onChange={(value) =>
                                                                            handleDecipherChange(index, {
                                                                                name: "employee",
                                                                                value,
                                                                            })
                                                                        }
                                                                        placeholder="Выберите сотрудника"
                                                                    />
                                                                </div>
                                                            )}

                                                            {/* Ставка НДС */}
                                                            {decipherFields.nds_rate && (
                                                                <div className="col-md-4">
                                                                    <label className="form-label">Ставка НДС:</label>
                                                                    <Input
                                                                        type="number"
                                                                        name="nds_rate"
                                                                        min="0"
                                                                        step="any"
                                                                        value={decipher.nds_rate}
                                                                        onChange={(e) => handleDecipherChange(index, e.target)}
                                                                    />
                                                                </div>
                                                            )}

                                                            {/* Сумма НДС */}
                                                            {decipherFields.nds_rate && (
                                                                <div className="col-md-4">
                                                                    <label className="form-label">Сумма НДС:</label>
                                                                    <Input
                                                                        type="number"
                                                                        min="0"
                                                                        step="any"
                                                                        name="nds_sum"
                                                                        value={decipher.nds_sum || ''}
                                                                        onChange={(e) => handleDecipherChange(index, e.target)}
                                                                    />
                                                                </div>
                                                            )}

                                                            {/* Контрагент */}
                                                            {decipherFields.counterparty && (
                                                                <div className="col-md-4">
                                                                    <label className="form-label">Контрагент:</label>
                                                                    <APISearchableSelect
                                                                        endpoint="/api/v1/bills/counterparty/"
                                                                        size=""
                                                                        value={decipher.counterparty || ''}
                                                                        onChange={(value) =>
                                                                            handleDecipherChange(index, {
                                                                                name: "counterparty",
                                                                                value,
                                                                            })
                                                                        }
                                                                        placeholder="Выберите контрагента"
                                                                    />
                                                                </div>
                                                            )}

                                                            {/* Статья через CaptionForm */}
                                                            <CaptionForm
                                                                key={index}
                                                                captions={captions}
                                                                setCaptions={setCaptions}
                                                                param={decipher?.caption}
                                                                index={index}
                                                                handleSelectChange={handleDecipherChange}
                                                                caption_types={caption_types}
                                                            />

                                                            {/* Проект */}
                                                            {/* <div className="col-md-4">
                                                                <label className="form-label">Проект:</label>
                                                                <APISearchableSelect
                                                                    endpoint="/api/v1/organization/projects/search/"
                                                                    value={decipher.project || ''}
                                                                    size=""
                                                                    onChange={(value) =>
                                                                        handleDecipherChange(index, {
                                                                            name: "project",
                                                                            value,
                                                                        })
                                                                    }
                                                                    placeholder="Выберите проект"
                                                                />
                                                            </div> */}
                                                            <ProjectForm
                                                                index={index}
                                                                param={decipher?.project}
                                                                handleSelectChange={handleDecipherChange}
                                                            />

                                                            {/* Договор */}
                                                            {decipherFields.agreement && (
                                                                <div className="col-md-4">
                                                                    <label className="form-label">Договор:</label>
                                                                    <APISearchableSelect
                                                                        endpoint={`/api/v1/organization/agreements/search/?counterparty=${
                                                                            decipherFields.counterparty
                                                                                ? decipher.counterparty
                                                                                : formData.counterparty
                                                                        }&company=${formData.company}`}
                                                                        size=""
                                                                        value={decipher.agreement || ''}
                                                                        onChange={(value) =>
                                                                            handleDecipherChange(index, {
                                                                                name: "agreement",
                                                                                value,
                                                                            })
                                                                        }
                                                                        placeholder="Выберите договор"
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>

                                                        {/* Кнопка удаления строки */}
                                                        <div className="col-md-1 d-flex align-items-center">
                                                            <i
                                                                className="fs-5 bi bi-trash3"
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => removeDecipher(index)}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                            {/* Предупреждение, если сумма превышает */}
                                            <div className='d-flex mt-4'>
                                                <Alert
                                                    show={showAlert}
                                                    variant='danger'
                                                    onClose={() => setShowAlert(false)}
                                                    dismissible
                                                >
                                                    <p>
                                                        Общая сумма элементов превышает общую сумму платежа.
                                                        Пожалуйста, скорректируйте суммы.
                                                    </p>
                                                </Alert>
                                            </div>

                                            {/* Кнопка "Добавить платеж" внутри расшифровки */}
                                            <Button
                                                bType="primary"
                                                size="medium"
                                                fixed
                                                type="button"
                                                onClick={addDecipher}
                                            >
                                                Добавить строку
                                            </Button>
                                        </div>
                                    )}
                                </div>

                                {/* Кнопка "Сохранить" */}
                                <Button
                                    style={{ marginTop: '20px' }}
                                    bType="primary"
                                    fixed
                                    size="medium"
                                    type="submit"
                                >
                                    Сохранить
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <UnauthorizedModal 
            show={showUnauthorizedModal} 
            onClose={() => setShowUnauthorizedModal(false)} 
        />
        </>
    );
}

export default CreatePayment;
