import { useState, useEffect } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import api from "../api";
import "../styles/finmodel.css";
import EmployeeSearchableSelect from './finmodelSelect/SearchableSelectUsers';
import { Card } from 'react-bootstrap';
import APISearchableSelect from "./payments/SearchableSelect";
import { Modal, Tab, Tabs } from "react-bootstrap";
import Button from "../components/ui/Button/Button";
import UnauthorizedModal from "./Unauthorized";
const GroupTable = ({ setErrorMessage }) => { 
    const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false);
    const [groups, setGroups] = useState([]);
    const [users, setUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [newGroupName, setNewGroupName] = useState("");
    const [parentGroupId, setParentGroupId] = useState("");
    const [selectedManager, setSelectedManager] = useState("");
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [isMainGroup, setIsMainGroup] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [groupToDelete, setGroupToDelete] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [typeOfBlock, setTypeOfBlock] = useState([]);
    const [connection, setConnection] = useState(null);
    const [project, setProject] = useState(null);
    const finmodel= JSON.parse(localStorage.getItem('selectedFinModel'))
    const finModelId = finmodel.id;
    const finModelName = finmodel.name;
    const [activeTab, setActiveTab] = useState("info");
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [availableGroups, setAvailableGroups] = useState([]);
    const [selectedBlockRoles, setSelectedBlockRoles] = useState([]);
    const [fieldErrors, setFieldErrors] = useState({
        name: '',
        parent: '',
        blockType: '',
        project: '',
        connection: '',
        employees: ''
    });
    
    useEffect(() => {
        if (selectedGroupId && showModal) {
            fetchRoles(selectedGroupId);
        }
    }, [selectedGroupId, showModal]);
    const fetchRoles = async (selectedGroupId) => {
        setLoading(true);
        try {
            const response = await api.get(`/api/v1/finmodel/user_role/${selectedGroupId}/`);
            setRoles(response.data);
        } catch (error) {
            console.error('Error fetching roles:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchUserGroups = async () => {
        try {
            const response = await api.get('/api/v1/role/groups/');
            setAvailableGroups(response.data);
        } catch (error) {
            console.error('Error fetching groups:', error);
        }
    };

    const handleDeleteRole = async (roleId) => {
        try {
            await api.delete(`/api/v1/finmodel/user_role/${roleId}/delete/`);
            await fetchRoles(selectedGroupId);
        } catch (error) {
            console.error('Error deleting role:', error);
            alert('Ошибка при удалении роли');
        }
    };

    const fetchUsers = async () => {
        try {
            const response = await api.get('/api/v1/users/');
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users', error);
        }
    };

    const fetchGroups = async () => {
        try {
            const response = await api.get(`/api/v1/finmodel/getBlockByFinModel/${finModelId}/`);
            const groupsData = response.data;
    
            const mainGroup = groupsData.find(group => group.parent === null);
    
            if (!mainGroup) {
                const newGroup = {
                    "name": "Главная группа",
                    "parent": null,
                    "is_info_block": true,
                    "fin_model": finModelId
                };
                const createResponse = await api.post('/api/v1/finmodel/block/create/', newGroup, {
                    headers: {
                      'Content-Type': 'application/json'
                    }
                });
                setGroups([createResponse.data]);
                return [createResponse.data];
            } else {
                setGroups(groupsData);
                return groupsData;
            }
        }   catch (error) {
            console.error("Ошибка при загрузке:", error);

            if (error.response) {
                console.error("Ответ сервера:", error.response.status, error.response.data);
                
                if (error.response.status === 401) {
                    console.log("Ошибка 401 обнаружена!");
                    setShowUnauthorizedModal(true);
                } else if (error.response.status === 403) {
                    setErrorMessage("Доступ запрещен!");
                } else if (error.response.status === 400) {
                    setErrorMessage(error.response.data.detail || "Ошибка запроса.");
                } else if (error.response.status === 500) {
                    setErrorMessage("Ошибка сервера! Попробуйте позже.");
                } else {
                    setErrorMessage(`Неизвестная ошибка: ${error.response.status}`);
                }
            } else {
                console.error("Ошибка сети или сервера:", error);
                setErrorMessage("Ошибка соединения с сервером. Проверьте интернет.");
            }
            return [];
        }
    };

    useEffect(() => {
        fetchGroups(setErrorMessage);
        fetchUsers();
        fetchUserGroups();
    }, [setErrorMessage]);

    const buildTree = (nodes, parent = null) => {
        return nodes
            .filter(node => node.parent === parent)
            .map(node => ({ ...node, children: buildTree(nodes, node.id) }));
    };

    const createUserRoles = async (employeeId, roles, blockId) => {
        try {
            const promises = roles.map(role => {
                const roleId = typeof role === 'object' ? role.id : role;
                return api.post('/api/v1/finmodel/user_role/create/', {
                    user: employeeId,
                    fin_model: finModelId,
                    custom_group: roleId,
                    block: blockId
                });
            });
            await Promise.all(promises);
        } catch (error) {
            console.error('Error creating user roles:', error);
        }
    };

    const handleEmployeeSelect = (index, selectedUser) => {
        const updatedBlockRoles = [...selectedBlockRoles];
        updatedBlockRoles[index] = {
            ...updatedBlockRoles[index],
            user: selectedUser
        };
        setSelectedBlockRoles(updatedBlockRoles);
    };
    const handleAddBlockRole = () => {
        setSelectedBlockRoles([...selectedBlockRoles, { blockId: '', roleId: '' }]);
    };

    const handleRemoveBlockRole = (index) => {
        const updatedBlockRoles = selectedBlockRoles.filter((_, i) => i !== index);
        setSelectedBlockRoles(updatedBlockRoles);
    };
    const handleBlockRoleChange = (index, field, value) => {
        const updatedBlockRoles = [...selectedBlockRoles];
        updatedBlockRoles[index] = {
            ...updatedBlockRoles[index],
            [field]: value
        };
        setSelectedBlockRoles(updatedBlockRoles);
    };
    const validateForm = () => {
        const newErrors = {};
        
        // Validate group name
        if (!newGroupName.trim()) {
            newErrors.name = 'Название блока обязательно для заполнения';
        }

        // Validate employee roles
        if (selectedBlockRoles.length > 0) {
            const roleErrors = [];
            selectedBlockRoles.forEach((role, index) => {
                if (!role.user || !role.roleId) {
                    roleErrors.push(`Строка ${index + 1}: необходимо выбрать сотрудника и роль`);
                }
            });
            if (roleErrors.length > 0) {
                newErrors.employees = roleErrors;
            }
        }

        setFieldErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const handleSave = async () => {
        if (!validateForm()) {
            return;
        }
        if (selectedGroupId) {
            // Edit existing group
            try {
                const supervisorId = typeof selectedManager === 'object' 
                    ? selectedManager.id 
                    : selectedManager;
    
                // Собираем ID всех сотрудников (существующих и новых)
                const employeeIds = new Set([
                    ...roles.map(role => role.user.id), // существующие сотрудники
                    ...selectedBlockRoles.map(role => role.user.id) // новые сотрудники
                ]);
    
                const blockPayload = {
                    name: newGroupName,
                    parent: parentGroupId || null,
                    supervisor: supervisorId || null,
                    employees: Array.from(employeeIds),
                    project: typeOfBlock === "project" ? project?.id || project : null,
                    connection: typeOfBlock === "organization" ? connection?.id || connection : null,
                    is_info_block: typeOfBlock === "informational",
                    fin_model: finModelId
                };
    
                await api.put(`/api/v1/finmodel/block/update/${selectedGroupId}/`, blockPayload);
    
                // Создаем новые роли
                for (const pendingRole of selectedBlockRoles) {
                    await createUserRoles(pendingRole.user.id, [pendingRole.roleId], selectedGroupId);
                }
    
                // Обновляем финансовую модель
                try {
                    // Получаем текущие данные финансовой модели
                    const finModelResponse = await api.get(`/api/v1/finmodel/${finModelId}/`);
                    const currentEmployees = finModelResponse.data.employees || [];
    
                    // Собираем всех новых сотрудников, которых нужно добавить в модель
                    const newEmployees = Array.from(employeeIds).filter(
                        empId => !currentEmployees.includes(empId)
                    );
    
                    // Если есть новые сотрудники, обновляем финансовую модель
                    if (newEmployees.length > 0) {
                        const finModelPayload = {
                            name: finModelName,
                            employees: [...currentEmployees, ...newEmployees],
                            fin_model: finModelId
                        };
    
                        await api.put(`/api/v1/finmodel/update/${finModelId}/`, finModelPayload, {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });
                    }
                } catch (error) {
                    console.error('Error updating financial model:', error);
                    // Продолжаем выполнение, так как основные изменения блока уже сохранены
                }
    
                await fetchGroups();
                await fetchRoles(selectedGroupId);
            } catch (error) {
                console.error('Error updating group', error);
            }
        } else {
            // Create new group
            try {
                const supervisorId = typeof selectedManager === 'object' 
                    ? selectedManager.id 
                    : selectedManager;
    
                const employeeIds = selectedBlockRoles.map(role => role.user.id);
    
                const blockPayload = {
                    name: newGroupName,
                    parent: parentGroupId || null,
                    supervisor: supervisorId,
                    employees: employeeIds,
                    project: typeOfBlock === "project" ? project?.id || project : null,
                    connection: typeOfBlock === "organization" ? connection?.id || connection : null,
                    is_info_block: typeOfBlock === "informational",
                    fin_model: finModelId
                };
    
                const response = await api.post('/api/v1/finmodel/block/create/', blockPayload);
                const newBlockId = response.data.id;
    
                // Создаем роли для нового блока
                for (const pendingRole of selectedBlockRoles) {
                    await createUserRoles(pendingRole.user.id, [pendingRole.roleId], newBlockId);
                }
    
                // Обновляем финансовую модель для нового блока
                try {
                    const finModelResponse = await api.get(`/api/v1/finmodel/${finModelId}/`);
                    const currentEmployees = finModelResponse.data.employees || [];
    
                    // Находим новых сотрудников
                    const newEmployees = employeeIds.filter(
                        empId => !currentEmployees.includes(empId)
                    );
    
                    // Если есть новые сотрудники, обновляем модель
                    if (newEmployees.length > 0) {
                        const finModelPayload = {
                            name: finModelName,
                            employees: [...currentEmployees, ...newEmployees],
                            fin_model: finModelId
                        };
    
                        await api.put(`/api/v1/finmodel/update/${finModelId}/`, finModelPayload, {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });
                    }
                } catch (error) {
                    console.error('Error updating financial model:', error);
                }
    
                await fetchGroups();
            } catch (error) {
                console.error('Error creating group', error);
            }
        }
    
        // Сбрасываем все формы
        resetForm();
        setSelectedBlockRoles([]);
        setShowModal(false);
    };
    const handleOpenModal = (group, isEdit = false) => {
        const groupId = isEdit ? group.id : null;
        setSelectedGroupId(groupId);
        setNewGroupName(isEdit ? group.name : "");
        setParentGroupId(isEdit ? (group.parent || "") : group.id);
        setConnection(isEdit ? group.connection : null);
        setProject(isEdit ? group.project : null);
        setTypeOfBlock(isEdit ? (group.connection ? "organization" : group.project ? "project" : "informational") : "");
        if (isEdit && groupId) {
            fetchRoles(groupId);
        }
        // Обработка руководителя при открытии модального окна
        if (isEdit) {
            const supervisorData = users.find(user => user.id === group.supervisor);
            setSelectedManager(supervisorData || "");
        } else {
            setSelectedManager("");
        }

        if (isEdit && group.employees) {
            const employeesData = group.employees.map(empId => {
                const employee = users.find(user => user.id === empId);
                return employee || empId;
            });
            setSelectedEmployees(employeesData);
            
        } else {
            setSelectedEmployees([]);
        }
        setIsMainGroup(group.parent === null);
        setShowModal(true);
    };
    const resetErrors = () => {
        setFieldErrors({
            name: '',
            parent: '',
            blockType: '',
            project: '',
            connection: '',
            employees: ''
        });
    };
    const resetForm = () => {
        setNewGroupName("");
        setParentGroupId("");
        setSelectedManager(null);
        setSelectedEmployees([]);
        setSelectedGroupId(null);
        setIsMainGroup(false);
        setConnection(null);
        setProject(null);
        setRoles([]);
        setActiveTab("info");
        setSelectedBlockRoles([]);
        resetErrors();
    };

    const handleDeleteGroup = async () => {
        if (groupToDelete) {
            setIsDeleting(true);
            try {
                await api.delete(`/api/v1/finmodel/block/${groupToDelete.id}/delete/`);
                await fetchGroups();
                setShowDeleteModal(false);
                setGroupToDelete(null);
            } catch (error) {
                console.error('Error deleting group', error);
            } finally {
                setIsDeleting(false);
            }
        }
    };
    
    const mainGroup = groups.find(group => group.parent === null);
    if (!mainGroup) return null;
    
    const tree = buildTree(groups);
    const selectedEmployeeIds = selectedEmployees.map(emp => 
        typeof emp === 'object' ? emp.id : emp
    );
    return (
        <div className="container-fluid">
            <UnauthorizedModal 
                show={showUnauthorizedModal} 
                onClose={() => setShowUnauthorizedModal(false)} 
            />
            <h2 className="text-center">{finModelName}</h2>
            <div className="tree-container">
                <div className="main-group-wrapper">
                    <Tree                
                        lineWidth={"2px"}
                        lineColor={"#bbb"}
                        lineBorderRadius={"10px"}
                        className="organization-tree"
                    >
                        {tree.map(rootNode => (
                            rootNode.parent === null && (
                                <TreeNodeComponent
                                    key={rootNode.id}
                                    node={rootNode}
                                    users={users}
                                    setShowModal={setShowModal}
                                    handleOpenModal={handleOpenModal}
                                    setShowDeleteModal={setShowDeleteModal}
                                    setGroupToDelete={setGroupToDelete}
                                />
                            )
                        ))}
                    </Tree>
                </div>
            </div>
            <Modal show={showModal} onHide={() => { resetForm(); setShowModal(false); }} centered dialogClassName="fixed-size-modal">
            <Modal.Header closeButton>
                <Modal.Title className="w-100 text-center">{selectedGroupId ? "Редактировать блок" : "Добавить блок"}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ minHeight: '400px' }}>
            <UnauthorizedModal 
                show={showUnauthorizedModal} 
                onClose={() => setShowUnauthorizedModal(false)} 
            />
                <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
                    <Tab eventKey="info" title="Общая информация">
                        <form>
                            <div className="mb-3">
                                <label htmlFor="groupName" className="form-label">Наименование *</label>
                                <input type="text" className={`form-control ${fieldErrors.name ? 'is-invalid' : ''}`} placeholder="Введите наименование" id="groupName" value={newGroupName} onChange={(e) => setNewGroupName(e.target.value)} />
                                {fieldErrors.name && (
                                        <div className="invalid-feedback">{fieldErrors.name}</div>
                                    )}
                            </div>
                            {!isMainGroup && (
                                <div className="mb-3">
                                    <label htmlFor="parentGroup" className="form-label">Вышестоящее подразделение *</label>
                                    <select className="form-select" id="parentGroup" value={parentGroupId} onChange={(e) => setParentGroupId(e.target.value)}>
                                        <option value="">Выберите подразделение</option>
                                        {groups.filter(group => group.id !== selectedGroupId).map(group => (
                                            <option key={group.id} value={group.id}>{group.name}</option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            <div className="d-flex justify-content-evenly mb-3">
                                {["organization", "project", "informational"].map(type => (
                                    <div key={type}>
                                        <input
                                            type="radio"
                                            className="btn-check"
                                            name="typeOfBlock"
                                            id={`btnradio-${type}`}
                                            value={type}
                                            checked={typeOfBlock === type}
                                            onChange={(e) => setTypeOfBlock(e.target.value)}
                                        />
                                        <label className="btn btn-light" htmlFor={`btnradio-${type}`}>{type === "organization" ? "Организация" : type === "project" ? "Проект" : "Информативный блок"}</label>
                                    </div>
                                ))}
                            </div>
                            {typeOfBlock === "project" && (
                                <div className="mb-3">
                                    <label htmlFor="project" className="form-label">Проект *</label>
                                    <APISearchableSelect endpoint="/api/v1/organization/projects/search/" setShowUnauthorizedModal={setShowUnauthorizedModal} value={project} onChange={setProject} placeholder="Выберите проект" />
                                </div>
                            )}
                            {typeOfBlock === "organization" && (
                                <div className="mb-3">
                                    <label htmlFor="connection" className="form-label">Подключение *</label>
                                    <APISearchableSelect endpoint="/api/v1/connection/search/" value={connection} onChange={setConnection} setShowUnauthorizedModal={setShowUnauthorizedModal} placeholder="Выберите подключение" />
                                </div>
                            )}
                            <div className="mb-3">
                            <label htmlFor="manager" className="form-label">Руководитель</label>
                            <EmployeeSearchableSelect
                                value={selectedManager}
                                onChange={setSelectedManager}
                                placeholder="Выберите руководителя"
                                excludeIds={selectedEmployeeIds}
                                key={`manager-select-${showModal}`} 
                            />
                        </div>
                        </form>
                    </Tab>
                    <Tab eventKey="employees" title="Сотрудники">
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>Пользователь</th>
                                    <th>Роль</th>
                                    <th>Действия</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr><td colSpan="3" className="text-center">Загрузка...</td></tr>
                                ) : (
                                    <>
                                        {/* Existing roles */}
                                        {roles.map(role => (
                                            <tr key={role.id}>
                                                <td>{role.user.first_name} {role.user.last_name}</td>
                                                <td>{role.custom_group.name}</td>
                                                <td>
                                                    <button 
                                                        className="btn btn-danger btn-sm"
                                                        onClick={() => handleDeleteRole(role.id)}
                                                    >
                                                        <i className="bi bi-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                        {selectedBlockRoles.map((blockRole, index) => (
                                            <tr key={`new-role-${index}`}>
                                                <td>
                                                    <EmployeeSearchableSelect
                                                        value={blockRole.user || null}
                                                        onChange={(selectedUser) => handleEmployeeSelect(index, selectedUser)}
                                                        placeholder="Выберите сотрудника"
                                                        excludeIds={[
                                                            ...roles.map(role => role.user.id),
                                                            ...selectedBlockRoles
                                                                .filter((_, i) => i !== index)
                                                                .map(role => role.user?.id)
                                                                .filter(Boolean)
                                                        ]}
                                                    />
                                                </td>
                                                <td>
                                                    <select 
                                                        className="form-select"
                                                        value={blockRole.roleId || ''}
                                                        onChange={(e) => handleBlockRoleChange(index, 'roleId', e.target.value)}
                                                    >
                                                        <option value="">Выберите роль</option>
                                                        {availableGroups.map(group => (
                                                            <option key={group.id} value={group.id}>
                                                                {group.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </td>
                                                <td>
                                                    <button 
                                                        className="btn btn-danger btn-sm"
                                                        onClick={() => handleRemoveBlockRole(index)}
                                                        type="button"
                                                    >
                                                        <i className="bi bi-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                )}
                            </tbody>
                        </table>
                        {fieldErrors.employees && (
                                <div className="alert alert-danger mt-3">
                                    {Array.isArray(fieldErrors.employees) 
                                        ? fieldErrors.employees.map((error, index) => (
                                            <div key={index}>{error}</div>
                                        ))
                                        : fieldErrors.employees
                                    }
                                </div>
                            )}
                        <div className="text-center mt-3">
                            <button className="btn btn-success btn-sm" onClick={handleAddBlockRole}>
                            <i className="bi bi-plus-lg"></i>
                            </button>
                        </div>
                    </div>
                </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer className="justify-content-center gap-2">
                <Button bType="secondary" size="medium" fixed type="button" onClick={() => { resetForm(); setShowModal(false); }}>
                    Закрыть
                </Button>
                <Button bType="primary" size="medium" fixed onClick={handleSave}>
                    {selectedGroupId ? "Сохранить" : "Добавить"}
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Подтверждение удаления</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-body">
                    <p>Вы уверены, что хотите удалить группу "{groupToDelete?.name}"?</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button bType="secondary" size="medium" fixed type="button" onClick={() => setShowDeleteModal(false)}>
                    Отмена
                </Button>
                <Button bType="primary" size="medium" fixed onClick={handleDeleteGroup}>
                {isDeleting ? (
                <>
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                Удаление...
                </>
                ) : 'Удалить'}
                </Button>
            </Modal.Footer>
        </Modal>
        </div>
    );
};

// Компонент TreeNode для отображения узла дерева
const TreeNodeComponent = ({ node, users, setShowModal, handleOpenModal, setShowDeleteModal, setGroupToDelete }) => {
    const supervisor = users.find(u => u.id === node.supervisor);
    const isMainGroup = node.parent === null; 
    return (
        <TreeNode
            label={
                <div className="d-flex justify-content-center">
                    <Card className={`card block-tree text-center ${isMainGroup ? 'main-group' : ''}`}>                       
                         <div className="card-header block-tree-header">
                            <span className={`badge rounded-pill ${node.connection ? 'bg-success' : node.project ? 'bg-primary' : 'bg-secondary'} text-white`}>
                              {node.project ? "Проект" : node.connection ? "Организация" : "Информативный блок"}
                            </span>
                            <Card.Body>
                                <Card.Title>{node.name}</Card.Title>
                                {supervisor ? (
                                    <Card.Subtitle className="mb-2 text-muted">
                                        Руководитель: {supervisor.first_name} {supervisor.last_name}
                                    </Card.Subtitle>
                                ) : (
                                    <Card.Subtitle className="mb-2 text-muted">Нет руководителя</Card.Subtitle>
                                )}
                                        {node.employees?.length > 0 ? `Сотрудники: ${node.employees.map(
                                            userObj => { const employeeUser = users.find(
                                                u => u.id === userObj); 
                                                return employeeUser ? `${employeeUser.first_name} ${employeeUser.last_name}` : ''}).join(', ')}` : 'Нет сотрудников'}
                                        <div className="d-flex justify-content-center gap-2 mt-2">
                                        <button className="btn btn-light me-1" onClick={() => handleOpenModal(node)}>
                                            <i className="bi bi-plus-circle"></i>
                                        </button>
                                        <button className="btn btn-light me-1" onClick={() => handleOpenModal(node, true)}>
                                            <i className="bi bi-pencil"></i>
                                        </button>
                                    {node.parent !== null && (
                                        <button className="btn btn-light" onClick={() => {
                                            setGroupToDelete(node);
                                            setShowDeleteModal(true);
                                        }}>
                                            <i className="bi bi-trash"></i>
                                        </button>
                                    )}
                                </div>
                            </Card.Body>
                        </div>
                    </Card>
                 </div>
            }
        >          
            {/* Рекурсивный рендеринг дочерних узлов */}
            {node.children?.length > 0 &&
                node.children.map(child => (
                    <TreeNodeComponent
                        key={child.id}
                        node={child}
                        users={users}
                        setShowModal={setShowModal}
                        handleOpenModal={handleOpenModal}
                        setShowDeleteModal={setShowDeleteModal}
                        setGroupToDelete={setGroupToDelete}
                    />
                ))}
        </TreeNode>
    );
};

export default GroupTable;