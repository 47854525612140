import React, { useState, useEffect, useRef, useCallback } from "react";
import api from "../../api";
import { useLocation, useNavigate } from "react-router-dom";
import SideBar from "../SideBar";
import Header from "../Header";
import ReactLoading from "react-loading";
import APIMultipleSearchableSelect from "../payments/SearchableTableMultiSelect";
import SearchableSelect from "../payments/SearchableSelect";
import { Modal } from "react-bootstrap";
import PageHeading from "../ui/PageHeading/PageHeading";
import Heading from "../ui/Heading/Heading";
import Button from "../ui/Button/Button";
import Input from "../ui/Input/Input";
import UnauthorizedModal from "../Unauthorized"; 
import { Alert } from 'react-bootstrap';
const ALL_COLUMNS = [
    "Наименование вида операции",
    "Тип документа",
    "Дата создания",
    "Архивный",
];
const DEFAULT_COLUMN_WIDTHS = {
    "Наименование вида операции": 300,
    "Тип документа": 200,
    "Дата создания": 200,
    "Архивный": 100,
};

const OperationTypes = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [operationstypes, setOperationTypes] = useState([]);
    const [checkedOperationTypes, setCheckedOperationTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const containerRef = useRef(null);
    const limit = 25;

    const [filtersVisible, setFiltersVisible] = useState(false);
    const [nameFilter, setNameFilter] = useState("");
    const [archivedFilterNotArchive, setArchivedFilterNotActive] = useState(false);
    const [archivedFilterActive, setArchivedFilterActive] = useState(false);
    const [documentTypeFilter, setDocumentTypeFilter] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const initialFormData = { name: "", document_type: "" };
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});
    const searchIDofelem = location.search.replace("?", "");
    const [errorMessage, setErrorMessage] = useState(""); // 🔴 Храним текст ошибки
    const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false);
    
    const [selectedColumns, setSelectedColumns] = useState(() => {
        const saved = localStorage.getItem("OperationTypesSelectedColumns");
        return saved ? JSON.parse(saved) : [...ALL_COLUMNS];
    });

    const [columnWidths, setColumnWidths] = useState(() => {
        const saved = localStorage.getItem("OperationTypesColumnWidths");
        if (saved) return { ...DEFAULT_COLUMN_WIDTHS, ...JSON.parse(saved) };
        return { ...DEFAULT_COLUMN_WIDTHS };
    });

    useEffect(() => {
        localStorage.setItem(
            "OperationTypesSelectedColumns",
            JSON.stringify(selectedColumns)
        );
    }, [selectedColumns]);

    const resizingInfoRef = useRef({
        isResizing: false,
        startX: 0,
        columnKey: null,
        initialWidth: 0,
    });

    const onMouseDown = (e, column) => {
        e.preventDefault();
        e.stopPropagation();
        resizingInfoRef.current.isResizing = true;
        resizingInfoRef.current.startX = e.clientX;
        resizingInfoRef.current.columnKey = column;
        resizingInfoRef.current.initialWidth =
            columnWidths[column] || DEFAULT_COLUMN_WIDTHS[column] || 100;
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };

    const onMouseMove = (e) => {
        if (!resizingInfoRef.current.isResizing) return;
        const { startX, columnKey, initialWidth } = resizingInfoRef.current;
        const delta = e.clientX - startX;
        const newWidth = Math.max(50, initialWidth + delta);
        setColumnWidths((prev) => ({
            ...prev,
            [columnKey]: newWidth,
        }));
    };

    const onMouseUp = () => {
        resizingInfoRef.current.isResizing = false;
        // document.removeEventListener("mousemove", onMouseMove);
        // document.removeEventListener("mouseup", onMouseUp);
        setColumnWidths((prev) => {
            localStorage.setItem("OperationTypesColumnWidths", JSON.stringify(prev));
            return prev;
        });
    };

    const columnsInUse = ALL_COLUMNS.filter((col) => selectedColumns.includes(col));
    const totalTableWidth = columnsInUse.reduce((acc, col) => {
        return acc + (columnWidths[col] || DEFAULT_COLUMN_WIDTHS[col]);
    }, 0);
    const openModal = (open) => {
        if (open) { 
            setShowModal(false);
            setShowUnauthorizedModal(true);
        } else { 
            setShowUnauthorizedModal(false);
        }

    };

    const fetchOperationTypes = async () => {
        setLoading(true);
        try {
            const res = await api.get(
                `api/v1/bills/operation-types/?limit=${limit}&offset=${offset}`
            );
            setOperationTypes((prev) => {
                const newItems = res.data.results.filter(
                    (item) => !prev.some((p) => p.id === item.id)
                );
                return [...prev, ...newItems];
            });
            if (!res.data.next) setHasMore(false);
        } catch (error) {
            console.error("Ошибка при загрузке операции:", error);
    
            if (error.response) {
                console.error("Ответ сервера:", error.response.status, error.response.data);

                if (error.response.status === 401) {
                    console.log("401 ошибка! Перезайдите, пожалуйста.");
                    openModal();
                } else if (error.response.status === 403) {
                    setErrorMessage("Доступ запрещен! У вас недостаточно прав на просмотр видов операций.");
                } else if (error.response.status === 400) {
                    setErrorMessage(error.response.data.detail || "Ошибка запроса.");
                } else if (error.response.status === 500) {
                    setErrorMessage("Ошибка сервера! Попробуйте позже.");
                } else {
                    setErrorMessage(`Неизвестная ошибка: ${error.response.status}`);
                }
            } else {
                console.error("Ошибка сети или сервера:", error);
                setErrorMessage("Ошибка соединения с сервером. Проверьте интернет.");
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setOperationTypes([]);
        setOffset(0);
        setHasMore(true);
        fetchOperationTypes();
    }, [location]);

    useEffect(() => {
        if (hasMore && !loading && offset > 0) {
            fetchOperationTypes();
        }
    }, [offset, hasMore]);

    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
                setOffset((prev) => prev + limit);
            }
        }
    }, [hasMore, loading]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener("scroll", handleScroll);
            // return () => container.removeEventListener("scroll", handleScroll);
        }
    }, [handleScroll]);

    useEffect(() => {
        handleSearch();
    }, [nameFilter, documentTypeFilter, archivedFilterNotArchive, archivedFilterActive]);

    const handleSearch = async () => {
        try {
            const res = await api.post("/api/v1/bills/operation-typesfilter/", {
                name: nameFilter.toLowerCase(),
                is_active: [!archivedFilterNotArchive, archivedFilterActive],
                document_type: documentTypeFilter,
            });
            setOperationTypes(res.data);
        } catch {}
    };

    const handleReset = () => {
        const search = location.search;
        navigate(location.search.replace(search, ""));
        window.location.reload();
    };

    const handleCheckboxChange = (id) => {
        setCheckedOperationTypes((prev) => {
            if (prev.includes(id)) return prev.filter((p) => p !== id);
            return [...prev, id];
        });
    };

    const handleSelectAllChange = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            const allIds = operationstypes.map((o) => o.id);
            setCheckedOperationTypes(allIds);
        } else {
            setCheckedOperationTypes([]);
        }
    };

    const allChecked =
        operationstypes.length > 0 &&
        operationstypes.every((o) => checkedOperationTypes.includes(o.id));

    const handleCloseModal = () => {
        setFormData(initialFormData);
        setIsEditing(false);
        setErrors({});
        setShowModal(false);
    };

    const handleRowClick = (o) => {
        setIsEditing(true);
        setFormData({
            id: o.id,
            name: o.name,
            origin: o.origin,
            document_type: o.document_type,
        });
        setShowModal(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((p) => ({ ...p, [name]: value }));
        if (errors[name]) setErrors((x) => ({ ...x, [name]: "" }));
    };

    const handleDocumentTypeChange = (value) => {
        setFormData((p) => ({ ...p, document_type: value }));
        if (errors.document_type) setErrors((x) => ({ ...x, document_type: "" }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const fields = ["name", "document_type"];
        const newErr = {};
        fields.forEach((k) => {
            if (!formData[k]) newErr[k] = "Заполните поле";
        });
        if (Object.keys(newErr).length > 0) {
            setErrors(newErr);
            return;
        }
        setLoading(true);
        try {
            if (isEditing) {
                if (typeof formData.document_type === "object") {
                    const updated = {
                        ...formData,
                        document_type: formData.document_type.id,
                    };
                    await api.patch(
                        `/api/v1/bills/operation-types/${updated.id}/update/`,
                        updated
                    );
                } else {
                    await api.patch(
                        `/api/v1/bills/operation-types/${formData.id}/update/`,
                        formData
                    );
                }
            } else {
                await api.post(`api/v1/bills/operation-types-create/`, formData);
            }
            setOperationTypes([]);
            setOffset(0);
            handleCloseModal();
            fetchOperationTypes();
        } catch (error) {
            alert("Редактирование данного вида операции недоступно." + error);
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (iso) => {
        const d = new Date(iso);
        const day = String(d.getDate()).padStart(2, "0");
        const month = String(d.getMonth() + 1).padStart(2, "0");
        const year = d.getFullYear();
        return `${day}.${month}.${year}`;
    };

    const title = isEditing ? "Редактирование вида операции" : "Создание вида операции";

    const handleArchiving = async (isActive) => {
        setLoading(true);
        try {
            await Promise.all(
                checkedOperationTypes.map((id) =>
                    api.patch(`api/v1/bills/operation-types/${id}/update/`, {
                        is_active: isActive,
                    })
                )
            );
            setOperationTypes([]);
            setOffset(0);
            setHasMore(true);
            setCheckedOperationTypes([]);
            fetchOperationTypes();
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const handleArchive = () => {
        handleArchiving(false);
    };

    const handleUnarchive = () => {
        handleArchiving(true);
    };

    const [showSettings, setShowSettings] = useState(false);

    const toggleColumn = (column) => {
        setSelectedColumns((prev) =>
            prev.includes(column) ? prev.filter((c) => c !== column) : [...prev, column]
        );
    };

    const selectAllColumns = () => {
        setSelectedColumns([...ALL_COLUMNS]);
    };

    const cancelSettings = () => {
        setShowSettings(false);
    };

    const saveSettings = () => {
        setShowSettings(false);
    };

    return (
        <div className="container-fluid" translate="no">
            <Header />
            <div className="row flex-nowrap">
                <SideBar />
                <div className="col py-3">
                    <div className="management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between mb-3">
                                    <div className="d-flex gap-2">
                                        <PageHeading>Виды операции</PageHeading>
                                        <Button
                                            bType="secondary"
                                            size="small"
                                            fixed
                                            onClick={() => setFiltersVisible(!filtersVisible)}
                                        >
                                            <i className="bi bi-funnel"></i>
                                        </Button>
                                        <Button
                                            bType="primary"
                                            size="small"
                                            fixed
                                            onClick={() => setShowModal(true)}
                                        >
                                            <i className="bi bi-plus-circle"></i>
                                        </Button>
                                    </div>

                                    <Button
                                        bType="secondary"
                                        size="small"
                                        fixed
                                        onClick={() => setShowSettings(true)}
                                    >
                                        <i className="bi bi-gear"></i>
                                    </Button>
                                </div>
                                {errorMessage && (
                                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                                        {errorMessage}
                                    </Alert>
                                )}
                                <UnauthorizedModal 
                                    show={showUnauthorizedModal} 
                                    onClose={() => setShowUnauthorizedModal(false)} 
                                />
                                {filtersVisible && (
                                    <div className="mb-3 p-3 border rounded">
                                        <Heading level={6} style={{ marginBottom: "10px" }}>
                                            Фильтры:
                                        </Heading>
                                        <div className="row g-3">
                                            <div className="col-md-3">
                                                <label className="form-label">Наименование вида операции</label>
                                                <input
                                                    type="text"
                                                    placeholder="Введите вид операции"
                                                    value={nameFilter}
                                                    onChange={(e) => setNameFilter(e.target.value)}
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label className="form-label">Тип документа</label>
                                                <APIMultipleSearchableSelect
                                                    endpoint="api/v1/bills/document-types/search/"
                                                    value={documentTypeFilter}
                                                    onChange={setDocumentTypeFilter}
                                                    placeholder="Выберите тип документа"
                                                    setShowUnauthorizedModal={setShowUnauthorizedModal}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label>Архивный</label>
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="archivedCheckNotActive"
                                                        checked={archivedFilterNotArchive}
                                                        onChange={(e) => setArchivedFilterNotActive(e.target.checked)}
                                                    />
                                                    <label className="form-check-label" htmlFor="archivedCheckNotActive">
                                                        Да
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="archivedCheckActive"
                                                        checked={archivedFilterActive}
                                                        onChange={(e) => setArchivedFilterActive(e.target.checked)}
                                                    />
                                                    <label className="form-check-label" htmlFor="archivedCheckActive">
                                                        Нет
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 align-self-end">
                                                <Button onClick={handleReset} bType="secondary" size="medium" fixed>
                                                    Сбросить
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {checkedOperationTypes.length > 0 && (
                                    <div className="d-flex gap-2 mb-3">
                                        <Button bType="secondary" size="medium" fixed onClick={handleArchive}>
                                            Архивировать
                                        </Button>
                                        <Button bType="secondary" size="medium" fixed onClick={handleUnarchive}>
                                            Разархивировать
                                        </Button>
                                    </div>
                                )}
                                <div
                                    ref={containerRef}
                                    className="Payments__wrapper"
                                    style={{ height: "70vh", minHeight: "70vh", overflowY: "scroll", position: "relative" }}
                                >
                                    <table
                                        className="Payments__table"
                                        style={{
                                            tableLayout: "fixed"
                                        }}
                                    >
                                        <thead>
                                        <tr>
                                            <th style={{ width: 50 }}>
                                                <input
                                                    type="checkbox"
                                                    checked={allChecked}
                                                    onChange={handleSelectAllChange}
                                                />
                                            </th>
                                            {columnsInUse.map((column) => {
                                                const width = columnWidths[column] || DEFAULT_COLUMN_WIDTHS[column];
                                                return (
                                                    <th
                                                        key={column}
                                                        style={{
                                                            width: width,
                                                            minWidth: width,
                                                            position: "relative",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <span>{column}</span>
                                                            <div
                                                                style={{
                                                                    cursor: "col-resize",
                                                                    padding: "0 4px",
                                                                    marginRight: "-4px",
                                                                    userSelect: "none",
                                                                    zIndex: 999
                                                                }}
                                                                onMouseDown={(e) => onMouseDown(e, column)}
                                                            >
                                                                ||
                                                            </div>
                                                        </div>
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {operationstypes.map((o) => (
                                            <tr key={o.id} onClick={() => handleRowClick(o)}>
                                                <td style={{ width: 50 }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={checkedOperationTypes.includes(o.id)}
                                                        onChange={(e) => {
                                                            e.stopPropagation();
                                                            handleCheckboxChange(o.id);
                                                        }}
                                                    />
                                                </td>
                                                {selectedColumns.includes("Наименование вида операции") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Наименование вида операции"] ||
                                                                DEFAULT_COLUMN_WIDTHS["Наименование вида операции"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "normal",
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        {o.name}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Тип документа") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Тип документа"] ||
                                                                DEFAULT_COLUMN_WIDTHS["Тип документа"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        {o.document_type ? o.document_type.name : ""}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Дата создания") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Дата создания"] ||
                                                                DEFAULT_COLUMN_WIDTHS["Дата создания"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "normal",
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        {formatDate(o.created)}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Архивный") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Архивный"] ||
                                                                DEFAULT_COLUMN_WIDTHS["Архивный"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                    >
                                                        {o.is_active ? "Нет" : "Да"}
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    {loading && (
                                        <div className="position-absolute top-50 start-50 translate-middle">
                                            <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {errorMessage && (
                                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                                        {errorMessage}
                                    </Alert>
                                )}
                                <UnauthorizedModal 
                                    show={showUnauthorizedModal} 
                                    onClose={() => setShowUnauthorizedModal(false)} 
                                />
                    <form onSubmit={handleSubmit}>
                        <div className="row g-1">
                            <div className="col-md-12">
                                <label className="form-label">Наименование вида операции*</label>
                                <Input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    placeholder="Введите наименование"
                                    onChange={handleChange}
                                    disabled={formData.origin !== "webupr" && isEditing}
                                />
                                {errors.name && <span className="invalid-feedback">{errors.name}</span>}
                            </div>
                            <div className="col-md-12">
                                <label className="form-label">Тип документа*</label>
                                <SearchableSelect
                                    endpoint="/api/v1/bills/document-types/"
                                    value={formData.document_type || ""}
                                    onChange={handleDocumentTypeChange}
                                    placeholder="Выберите тип документа"
                                    className={errors.document_type ? "is-invalid" : ""}
                                    isClearable
                                    isMulti={false}
                                    disabled={formData.origin !== "webupr" && isEditing}
                                    setShowUnauthorizedModal={setShowUnauthorizedModal}
                                />
                                {errors.document_type && (
                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                        {errors.document_type}
                                    </div>
                                )}
                                {formData.origin !== "webupr" && isEditing && (
                                    <p style={{ color: "red" }}>
                                        Данный вид операции запрещено редактировать, т.к. это типовой параметр
                                    </p>
                                )}
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button bType="secondary" size="medium" fixed onClick={handleCloseModal}>
                        Закрыть
                    </Button>
                    <Button bType="primary" size="medium" fixed onClick={handleSubmit}>
                        {isEditing ? "Сохранить" : "Создать"}
                    </Button>
                </Modal.Footer>
            </Modal>

            {showSettings && (
                <div className="modal" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}>
                    <div className="modal-dialog" style={{ maxWidth: "700px" }}>
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div className="modal-header" style={{ borderBottom: "2px solid #eee" }}>
                                <h5 className="modal-title">Настройка столбцов</h5>
                                <button className="btn-close" onClick={cancelSettings}></button>
                            </div>
                            <div className="modal-body" style={{ padding: "20px" }}>
                                <div className="container-fluid">
                                    <div className="row" style={{ gap: "10px", justifyContent: "space-evenly" }}>
                                        {ALL_COLUMNS.map((column) => (
                                            <div
                                                key={column}
                                                className={`col-4 p-2 d-flex align-items-center justify-content-start ${
                                                    selectedColumns.includes(column) ? "selected-column" : ""
                                                }`}
                                                onClick={() => toggleColumn(column)}
                                                style={{
                                                    cursor: "pointer",
                                                    border: selectedColumns.includes(column)
                                                        ? "2px solid #007BFF"
                                                        : "1px solid #ddd",
                                                    padding: "8px",
                                                    transition: "0.3s",
                                                    borderRadius: "5px",
                                                    width: "30%",
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={selectedColumns.includes(column)}
                                                    readOnly
                                                    className="me-2"
                                                />
                                                <span
                                                    className={selectedColumns.includes(column) ? "text-primary" : ""}
                                                >
                          {column}
                        </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer" style={{ borderTop: "2px solid #eee" }}>
                                <Button bType="secondary" size="medium" fixed onClick={selectAllColumns}>
                                    Выбрать все
                                </Button>
                                <Button bType="secondary" size="medium" fixed onClick={cancelSettings}>
                                    Отмена
                                </Button>
                                <Button bType="primary" size="medium" fixed onClick={saveSettings}>
                                    Сохранить
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OperationTypes;
