import Header from "../Header";
import SideBar from "../SideBar";
import PageHeading from "../ui/PageHeading/PageHeading";
import Button from "../ui/Button/Button";
import React from "react";
import Heading from "../ui/Heading/Heading";

const content = [
    {
        "type": "heading",
        "level": 5,
        "content": "Справочник «Проекты»"
    },
    {
        "type": "br"
    },
    {
        "type": "text",
        "content": "Справочник «Проекты» необходим для создания, редактирования и просмотра всех проектов по своей организации. Чтобы перейти в раздел «Проекты» необходимо в боковом разделе меню нажать на раздел «Справочники», далее раскроются остальные вкладки справочников и нажать на вкладку «Проекты»."
    },
    {
        "type": "image",
        "content": "https://i.imgur.com/P0Ie7nS.jpeg"
    },
    {
        "type": "br"
    },
    {
        "type": "text",
        "content": "Отроется страница «Проекты» и отобразится список всех созданных ранее проектов. В верхней части страницы имеются фильтры, где можно искать проекты. Чтобы создать проекты необходимо нажать на кнопку «Добавить», далее отобразится окно, в которое необходимо ввести нужные значения. Для редактирования проекта необходимо кликнуть 2 раза на нужный проект и изменить значения."
    },
    {
        "type": "image",
        "content": "https://i.imgur.com/PQ8qegT.jpeg"
    }
]

const Knowledge = () => {
    return (
        <>
            <div className="container-fluid" translate="no">
                <Header />
                <div className="row flex-nowrap">
                    <SideBar />
                    <div className="col py-3">
                        <div className="management container-fluid">
                            <div className="row">
                                <div className="col-md">

                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <div className="d-flex gap-2">
                                            <PageHeading>Инструкция</PageHeading>
                                        </div>
                                    </div>

                                    {content.map((item, index) => {
                                        return (
                                            <div className="Knowledge__Item">
                                                {item.type === "heading" && (
                                                    <Heading level={item.level}>{item.content}</Heading>
                                                    )}

                                                {item.type === "br" && <br />}

                                                {item.type === "image" && <img className="Knowledge__Image" src={item.content} alt="" />}

                                                {item.type === "text" && <p>{item.content}</p>}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Knowledge;