import SideBar from "../SideBar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import api from "../../api";
import Header from "../Header";
import { useState, useEffect, useRef, useCallback } from "react";
import ReactLoading from "react-loading";
import APIMultipleSearchableSelect from "../payments/SearchableTableMultiSelect";
import SearchableSelect from "../payments/SearchableSelect";
import { Modal } from "react-bootstrap";
import PageHeading from "../ui/PageHeading/PageHeading";
import Heading from "../ui/Heading/Heading";
import Button from "../ui/Button/Button";
import Input from "../ui/Input/Input";
import { Alert } from 'react-bootstrap';
import UnauthorizedModal from "../Unauthorized";
const ALL_COLUMNS = [
    "Наименование",
    "Описание",
    "Тип Статьи",
    "Родитель",
    "Организация",
    "Архивный",
];

const DEFAULT_COLUMN_WIDTHS = {
    "Наименование": 300,
    "Описание": 300,
    "Тип Статьи": 200,
    "Родитель": 200,
    "Организация": 200,
    "Архивный": 100,
};

const CaptionManagement = () => {
    const [captions, setCaptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const containerRef = useRef(null);
    const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(""); 

    const limit = 25;
    const [checkedCaptions, setCheckedCaptions] = useState([]);
    const [nameFilter, setNameFilter] = useState("");
    const [captiontype, setselectedcaptiontype] = useState("");
    const [archivedFilterNotArchive, setArchivedFilterNotActive] = useState(false);
    const [archivedFilterActive, setArchivedFilterActive] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const searchIDofelem = location.search.replace("?", "");
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const initialFormData = {
        name: "",
        type_of_caption: null,
        parent_caption: null,
        company: null,
        description: "",
    };
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});
    const [filtersVisible, setFilterVisible] = useState(false);

    const [showSettings, setShowSettings] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState(() => {
        const saved = localStorage.getItem("captionsSelectedColumns");
        if (saved) return JSON.parse(saved);
        return [...ALL_COLUMNS];
    });
    const [columnWidths, setColumnWidths] = useState(() => {
        const saved = localStorage.getItem("captionsColumnWidths");
        if (saved) return { ...DEFAULT_COLUMN_WIDTHS, ...JSON.parse(saved) };
        return { ...DEFAULT_COLUMN_WIDTHS };
    });

    useEffect(() => {
        localStorage.setItem("captionsSelectedColumns", JSON.stringify(selectedColumns));
    }, [selectedColumns]);

    const resizingInfoRef = useRef({
        isResizing: false,
        startX: 0,
        columnKey: null,
        initialWidth: 0,
    });

    const onMouseDown = (e, column) => {
        e.preventDefault();
        e.stopPropagation();
        resizingInfoRef.current.isResizing = true;
        resizingInfoRef.current.startX = e.clientX;
        resizingInfoRef.current.columnKey = column;
        resizingInfoRef.current.initialWidth =
            columnWidths[column] || DEFAULT_COLUMN_WIDTHS[column] || 150;
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };

    const onMouseMove = (e) => {
        if (!resizingInfoRef.current.isResizing) return;
        const { startX, columnKey, initialWidth } = resizingInfoRef.current;
        const delta = e.clientX - startX;
        const newWidth = Math.max(50, initialWidth + delta);
        setColumnWidths((prev) => ({
            ...prev,
            [columnKey]: newWidth,
        }));
    };

    const onMouseUp = () => {
        resizingInfoRef.current.isResizing = false;
        // document.removeEventListener("mousemove", onMouseMove);
        // document.removeEventListener("mouseup", onMouseUp);
        setColumnWidths((prev) => {
            localStorage.setItem("captionsColumnWidths", JSON.stringify(prev));
            return prev;
        });
    };

    const columnsInUse = ALL_COLUMNS.filter((col) => selectedColumns.includes(col));
    const totalTableWidth = columnsInUse.reduce((acc, col) => {
        return acc + (columnWidths[col] || DEFAULT_COLUMN_WIDTHS[col] || 100);
    }, 0);

    const fetchCaptions = async () => {
        setLoading(true);
        try {
            let response;
            
            if (searchIDofelem) {
                response = await api.post("/api/v1/bills/captionfilter/", { id: searchIDofelem });
                setCaptions(response.data);
            } else {
                response = await api.get(`api/v1/bills/caption/?limit=${limit}&offset=${offset}`);
                setCaptions((prev) => {
                    const newItems = response.data.results.filter(
                        (item) => !prev.some((p) => p.id === item.id)
                    );
                    return [...prev, ...newItems];
                });
                if (!response.data.next) setHasMore(false);
            }
        } catch (error) {
            console.error("Ошибка при загрузке статей затрат:", error);
    
            if (error.response) {
                const { status, data } = error.response;
    
                if (status === 401) {
                    console.log("Ошибка 401: Требуется повторный вход.");
                    setShowUnauthorizedModal(true);
                } else if (status === 403) {
                    setErrorMessage("Доступ запрещен! У вас недостаточно прав на просмотр статей.");
                } else if (status === 400) {
                    setErrorMessage(data.detail || "Некорректные данные. Проверьте введенные данные.");
                } else if (status === 500) {
                    setErrorMessage("Ошибка сервера! Попробуйте позже.");
                } else {
                    setErrorMessage(`Неизвестная ошибка: ${status}`);
                }
            } else {
                setErrorMessage("Ошибка соединения с сервером. Проверьте интернет.");
            }
    
            setCaptions([]); // Очищаем список при ошибке
        } finally {
            setLoading(false);
        }
    };
    
    // Вызовы useEffect остаются без изменений
    useEffect(() => {
        setCaptions([]);
        setOffset(0);
        setHasMore(true);
        fetchCaptions();
    }, [location]);
    
    useEffect(() => {
        if (hasMore) fetchCaptions();
    }, [offset, hasMore]);
    
    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
                setOffset((prev) => prev + limit);
            }
        }
    }, [hasMore, loading]);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.addEventListener("scroll", handleScroll);
            // return () => containerRef.current.removeEventListener("scroll", handleScroll);
        }
    }, [handleScroll]);

    useEffect(() => {
        const fetchCaptionById = async () => {
            if (searchIDofelem) {
                try {
                    const response = await api.post("/api/v1/bills/captionfilter/", {
                        id: searchIDofelem,
                    });
                    if (response.data.length > 0) {
                        setNameFilter(response.data[0].name);
                        setCaptions(response.data);
                    }
                } catch (error) {}
            } else {
                setNameFilter("");
                setCaptions([]);
                setOffset(0);
                setHasMore(true);
                fetchCaptions();
            }
        };
        fetchCaptionById();
    }, [searchIDofelem]);

    const handleUpdateCaptions = async (isActive) => {
        setLoading(true);
        try {

            await Promise.all(
                checkedCaptions.map((id) =>
                    api.patch(`api/v1/bills/caption/${id}/update`, { is_active: isActive })
                )
            );
            setCaptions([]);
            fetchCaptions();
            setOffset(0);
            setHasMore(true);
            setCheckedCaptions([]);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const handleArchiveCaptions = () => handleUpdateCaptions(false);
    const handleUnarchiveCaptions = () => handleUpdateCaptions(true);

    const handleCheckboxChange = (captionId) => {
        setCheckedCaptions((prev) =>
            prev.includes(captionId) ? prev.filter((id) => id !== captionId) : [...prev, captionId]
        );
    };

    const handleSelectAllChange = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            const allIds = captions.map((c) => c.id);
            setCheckedCaptions(allIds);
        } else {
            setCheckedCaptions([]);
        }
    };

    const allChecked = captions.length > 0 && captions.every((c) => checkedCaptions.includes(c.id));

    const handleReset = () => {
        const search = location.search;
        navigate(location.search.replace(search, ""));
        window.location.reload();
    };

    const handleSearch = async () => {
        try {
            const response = await api.post("/api/v1/bills/captionfilter/", {
                name: nameFilter.toLowerCase(),
                type: captiontype,
                is_active: [!archivedFilterNotArchive, archivedFilterActive],
            });
            setCaptions(response.data);
        } catch (error) {}
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const requiredFields = ["name", "type_of_caption"];
        const errs = requiredFields.reduce((acc, key) => {
            if (!formData[key]) {
                if (key === "type_of_caption") acc[key] = "Выберите значение";
                else acc[key] = "Заполните поле";
            }
            return acc;
        }, {});
        if (Object.keys(errs).length > 0) {
            setErrors(errs);
            return;
        }
        setLoading(true);
        const getIdOrValue = (obj) => obj?.id || obj || "";
        const preparedFormData = {
            ...formData,
            company: getIdOrValue(formData.company),
            parent_caption: getIdOrValue(formData.parent_caption),
            type_of_caption: getIdOrValue(formData.type_of_caption),
        };
        try {
            if (isEditing) {
                await api.patch(`/api/v1/bills/caption/${formData.id}/update/`, preparedFormData);
            } else {
                await api.post("/api/v1/bills/caption-create/", formData);
            }
            setCaptions([]);
            setOffset(0);
            handleCloseModal();
            fetchCaptions();
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
        if (errors[name]) setErrors((prevErr) => ({ ...prevErr, [name]: "" }));
    };

    const resetForm = () => {
        setFormData(initialFormData);
        setIsEditing(false);
    };

    const handleCloseModal = () => {
        resetForm();
        setShowModal(false);
        setErrors({});
    };

    const handleRowClick = (caption) => {
        setIsEditing(true);
        setFormData({
            id: caption.id,
            name: caption.name,
            type_of_caption: caption.type_of_caption,
            description: caption.description || "",
            parent_caption: caption.parent_caption,
            company: caption.company,
        });
        setShowModal(true);
    };

    const handleTypeChange = (value) => {
        setFormData((prev) => ({ ...prev, type_of_caption: value }));
        if (errors.type_of_caption) setErrors((prevErr) => ({ ...prevErr, type_of_caption: "" }));
    };

    const handleCompanyChange = (value) => {
        setFormData((prev) => ({ ...prev, company: value }));
    };

    const handleParentCaptionChange = (value) => {
        setFormData((prev) => ({ ...prev, parent_caption: value }));
        if (errors.parent_caption) setErrors((prevErr) => ({ ...prevErr, parent_caption: "" }));
    };

    const [showType, setShowType] = useState(false);

    const toggleColumn = (column) => {
        setSelectedColumns((prev) =>
            prev.includes(column) ? prev.filter((col) => col !== column) : [...prev, column]
        );
    };

    const selectAllColumns = () => {
        setSelectedColumns([...ALL_COLUMNS]);
    };

    const cancelSettings = () => {
        setShowSettings(false);
    };

    const saveSettings = () => {
        setShowSettings(false);
    };

    const title = isEditing ? "Редактирование статьи" : "Создание статьи";

    return (
        <div className="container-fluid" translate="no">
            <Header />
            <div className="row flex-nowrap">
                <SideBar />
                <div className="col py-3">
                    <div className="management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between mb-3">
                                    <div className="d-flex gap-2">
                                        <PageHeading>Статьи</PageHeading>
                                        <Button
                                            bType="secondary"
                                            size="small"
                                            fixed
                                            onClick={() => setFilterVisible(!filtersVisible)}
                                        >
                                            <i className="bi bi-funnel"></i>
                                        </Button>
                                        <Button bType="primary" size="small" fixed onClick={() => setShowModal(true)}>
                                            <i className="bi bi-plus-circle"></i>
                                        </Button>
                                    </div>
                                    <Button
                                        bType="secondary"
                                        size="small"
                                        fixed
                                        onClick={() => setShowSettings(true)}
                                    >
                                        <i className="bi bi-gear"></i>
                                    </Button>
                                </div>
                                {errorMessage && (
                                        <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                                            {errorMessage}
                                        </Alert>
                                        )}
                                            <UnauthorizedModal 
                                            show={showUnauthorizedModal} 
                                            onClose={() => setShowUnauthorizedModal(false)} 
                                        />
                                {filtersVisible && (
                                    <div className="mb-3 p-3 border rounded">
                                        <Heading level={6} style={{ marginBottom: "10px" }}>
                                            Фильтры:
                                        </Heading>
                                        <div className="d-flex gap-3">
                                            <div className="col">
                                                <label className="form-label">Наименование статьи</label>
                                                <Input
                                                    type="text"
                                                    placeholder="Введиет наименование статьи"
                                                    value={nameFilter}
                                                    onChange={(e) => setNameFilter(e.target.value)}
                                                />
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Тип статьи</label>
                                                <APIMultipleSearchableSelect
                                                    endpoint="api/v1/bills/caption-types/search/"
                                                    value={captiontype}
                                                    onChange={(value) => setselectedcaptiontype(value)}
                                                    placeholder="Выберите тип статьи"
                                                />
                                            </div>
                                            <div className="col">
                                                <label>Архивный</label>
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="archivedCheckNotActive"
                                                        checked={archivedFilterNotArchive}
                                                        onChange={(e) => setArchivedFilterNotActive(e.target.checked)}
                                                    />
                                                    <label className="form-check-label" htmlFor="archivedCheckNotActive">
                                                        Да
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="archivedCheckActive"
                                                        checked={archivedFilterActive}
                                                        onChange={(e) => setArchivedFilterActive(e.target.checked)}
                                                    />
                                                    <label className="form-check-label" htmlFor="archivedCheckActive">
                                                        Нет
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center gap-3 mt-2">
                                            <Button onClick={handleSearch} bType="primary" size="medium" fixed>
                                                Поиск
                                            </Button>
                                            <Button onClick={handleReset} bType="secondary" size="medium" fixed>
                                                Сбросить
                                            </Button>
                                        </div>
                                    </div>
                                )}
                                {checkedCaptions.length > 0 && (
                                    <div className="d-flex gap-2 align-items-center mb-3">
                                        <Button
                                            onClick={handleArchiveCaptions}
                                            bType="secondary"
                                            size="medium"
                                            fixed
                                            disabled={checkedCaptions.length === 0}
                                        >
                                            Архивировать выбранные
                                        </Button>
                                        <Button
                                            onClick={handleUnarchiveCaptions}
                                            bType="secondary"
                                            size="medium"
                                            fixed
                                            disabled={checkedCaptions.length === 0}
                                        >
                                            Разархивировать выбранные
                                        </Button>
                                    </div>
                                )}
                                <div
                                    ref={containerRef}
                                    className="Payments__wrapper"
                                    style={{ height: "69vh", minHeight: "69vh", overflowY: "scroll", position: "relative" }}
                                >
                                    <table
                                        className="Payments__table"
                                        style={{
                                            tableLayout: "fixed",
                                        }}
                                    >
                                        <thead>
                                        <tr>
                                            <th style={{ width: 50 }}>
                                                <input type="checkbox" checked={allChecked} onChange={handleSelectAllChange} />
                                            </th>
                                            {columnsInUse.map((col) => {
                                                const w = columnWidths[col] || DEFAULT_COLUMN_WIDTHS[col];
                                                return (
                                                    <th
                                                        key={col}
                                                        style={{
                                                            width: w,
                                                            minWidth: w,
                                                            position: "relative",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <span>{col}</span>
                                                            <div
                                                                style={{
                                                                    cursor: "col-resize",
                                                                    padding: "0 4px",
                                                                    marginRight: "-4px",
                                                                    userSelect: "none",
                                                                    zIndex: 999
                                                                }}
                                                                onMouseDown={(e) => onMouseDown(e, col)}
                                                            >
                                                                ||
                                                            </div>
                                                        </div>
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {captions.map((caption) => (
                                            <tr key={caption.id} onClick={() => handleRowClick(caption)}>
                                                <td style={{ width: 50 }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={checkedCaptions.includes(caption.id)}
                                                        onChange={(e) => {
                                                            e.stopPropagation();
                                                            handleCheckboxChange(caption.id);
                                                        }}
                                                    />
                                                </td>
                                                {selectedColumns.includes("Наименование") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Наименование"] || DEFAULT_COLUMN_WIDTHS["Наименование"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "normal",
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        {caption.name}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Описание") && (
                                                    <td
                                                        style={{
                                                            width: columnWidths["Описание"] || DEFAULT_COLUMN_WIDTHS["Описание"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "normal",
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        {caption.description}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Тип Статьи") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Тип Статьи"] || DEFAULT_COLUMN_WIDTHS["Тип Статьи"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "normal",
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        {caption.type_of_caption?.name}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Родитель") && (
                                                    <td
                                                        style={{
                                                            width: columnWidths["Родитель"] || DEFAULT_COLUMN_WIDTHS["Родитель"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "normal",
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        {caption.parent_caption?.name}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Организация") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Организация"] || DEFAULT_COLUMN_WIDTHS["Организация"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "normal",
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        {caption.company?.name}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Архивный") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Архивный"] || DEFAULT_COLUMN_WIDTHS["Архивный"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                    >
                                                        {caption.is_active ? "Нет" : "Да"}
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                {loading && (
                                    <div className="position-absolute top-50 start-50 translate-middle">
                                        <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>  
                </Modal.Header>
                <Modal.Body>
                {errorMessage && (
                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                        {errorMessage}
                    </Alert>
                    )}
                        <UnauthorizedModal 
                        show={showUnauthorizedModal} 
                        onClose={() => setShowUnauthorizedModal(false)} 
                    />
                    <form className="bankaccount-form" onSubmit={handleSubmit}>
                        <div className="form_input">
                            <div className="row g-1">
                                <div className="col-md-12">
                                    <label className="form-label">Наименование статьи*</label>
                                    <Input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        placeholder="Наименование"
                                        onChange={handleChange}
                                    />
                                    {errors.name && <span className="invalid-feedback">{errors.name}</span>}
                                </div>
                                <div className="col-md-12">
                                    <label className="form-label">Тип статьи*</label>
                                    <SearchableSelect
                                        endpoint="api/v1/bills/caption-types/search/"
                                        value={formData.type_of_caption || ""}
                                        onChange={handleTypeChange}
                                        placeholder="Выберите тип статьи"
                                        className={`form-control ${errors.type_of_caption ? "is-invalid" : ""}`}
                                        isClearable
                                        isMulti={false}
                                    />
                                    {errors.type_of_caption && (
                                        <div className="invalid-feedback" style={{ display: "block" }}>
                                            {errors.type_of_caption}
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-12">
                                    <label className="form-label">Родительская статья</label>
                                    <SearchableSelect
                                        endpoint="api/v1/bills/caption/search/"
                                        value={formData.parent_caption || ""}
                                        onChange={handleParentCaptionChange}
                                        placeholder="Выберите родительскую статью"
                                        className={`form-control ${errors.parent_caption ? "is-invalid" : ""}`}
                                        isClearable
                                        isMulti={false}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <label className="form-label">Организация</label>
                                    <SearchableSelect
                                        endpoint="api/v1/organization/search/"
                                        value={formData.company || ""}
                                        onChange={handleCompanyChange}
                                        placeholder="Выберите компанию"
                                        isClearable
                                        isMulti={false}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <label className="form-label">Описание</label>
                                    <Input
                                        type="text"
                                        name="description"
                                        value={formData.description}
                                        onChange={handleChange}
                                        placeholder="Введите описание"
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button bType="secondary" size="medium" fixed onClick={handleCloseModal}>
                        Закрыть
                    </Button>
                    <Button bType="primary" size="medium" fixed onClick={handleSubmit}>
                        {isEditing ? "Сохранить" : "Создать"}
                    </Button>
                </Modal.Footer>
            </Modal>
            {showSettings && (
                <div className="modal" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}>
                    <div className="modal-dialog" style={{ maxWidth: "700px" }}>
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div className="modal-header" style={{ borderBottom: "2px solid #eee" }}>
                                <h5 className="modal-title">Настройка столбцов</h5>
                                <button className="btn-close" onClick={cancelSettings}></button>
                            </div>
                            <div className="modal-body" style={{ padding: "20px" }}>
                                <div className="container-fluid">
                                    <div className="row" style={{ gap: "10px", justifyContent: "space-evenly" }}>
                                        {ALL_COLUMNS.map((column) => (
                                            <div
                                                key={column}
                                                className={`col-4 p-2 d-flex align-items-center justify-content-start ${
                                                    selectedColumns.includes(column) ? "selected-column" : ""
                                                }`}
                                                onClick={() => toggleColumn(column)}
                                                style={{
                                                    cursor: "pointer",
                                                    border: selectedColumns.includes(column)
                                                        ? "2px solid #007BFF"
                                                        : "1px solid #ddd",
                                                    padding: "8px",
                                                    transition: "0.3s",
                                                    borderRadius: "5px",
                                                    width: "30%",
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={selectedColumns.includes(column)}
                                                    readOnly
                                                    className="me-2"
                                                />
                                                <span className={selectedColumns.includes(column) ? "text-primary" : ""}>
                          {column}
                        </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer" style={{ borderTop: "2px solid #eee" }}>
                                <Button bType="secondary" size="medium" fixed onClick={selectAllColumns}>
                                    Выбрать все
                                </Button>
                                <Button bType="secondary" size="medium" fixed onClick={cancelSettings}>
                                    Отмена
                                </Button>
                                <Button bType="primary" size="medium" fixed onClick={saveSettings}>
                                    Сохранить
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CaptionManagement;
