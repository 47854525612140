import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";

const UnauthorizedModal = ({ show, onClose }) => {
    const navigate = useNavigate();
    const { logout } = useAuth(); // ✅ Хук вызывается внутри компонента

    const handleLogout = () => {
        onClose();  // Закрываем модалку перед выходом
        logout();   // Вызываем выход
        navigate("/logout"); // Перенаправляем на страницу логина
    };

    return (
        <Modal show={show} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Сессия истекла</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Ваша сессия истекла. Пожалуйста, войдите заново, чтобы продолжить работу.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Закрыть
                </Button>
                <Button variant="primary" onClick={handleLogout}>
                    Войти заново
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UnauthorizedModal;
