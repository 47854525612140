import React, { useState, useEffect, useRef, useCallback } from "react";
import api from "../../api";
import { useNavigate, useLocation } from "react-router-dom";
import SideBar from "../SideBar";
import Header from "../Header";
import ReactLoading from "react-loading";
import APIMultipleSearchableSelect from "../payments/SearchableTableMultiSelect";
import SearchableSelect from "../payments/SearchableSelect";
import { Modal } from "react-bootstrap";
import PageHeading from "../ui/PageHeading/PageHeading";
import Heading from "../ui/Heading/Heading";
import Button from "../ui/Button/Button";
import Input from "../ui/Input/Input";
import UnauthorizedModal from "../Unauthorized"; 
import { Alert } from 'react-bootstrap';
const ALL_COLUMNS = ["Наименование", "БИН/ИИН", "Организация", "Архивный"];
const DEFAULT_COLUMN_WIDTHS = {
    "Наименование": 300,
    "БИН/ИИН": 120,
    "Организация": 300,
    "Архивный": 100,
};

const Counterparties = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [counterparties, setCounterparties] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const containerRef = useRef(null);
    const limit = 25;
    const [checkedCounterparties, setCheckedCounterparties] = useState([]);
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState(""); // 🔴 Храним текст ошибки
    const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false);

    const [filters, setFilters] = useState({
        nameFilter: "",
        organFilter: "",
        iinbinFilter: "",
        archivedFilterNotArchive: false,
        archivedFilterActive: false,
    });

    const searchIDofelem = location.search.replace("?", "");

    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const initialFormData = {
        name: "",
        bin_iin: "",
        code: "",
        company: "",
    };
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});

    const [showSettings, setShowSettings] = useState(false);

    const [selectedColumns, setSelectedColumns] = useState(() => {
        const saved = localStorage.getItem("counterpartiesSelectedColumns");
        return saved ? JSON.parse(saved) : [...ALL_COLUMNS];
    });

    const [columnWidths, setColumnWidths] = useState(() => {
        const saved = localStorage.getItem("counterpartiesColumnWidths");
        if (saved) return { ...DEFAULT_COLUMN_WIDTHS, ...JSON.parse(saved) };
        return { ...DEFAULT_COLUMN_WIDTHS };
    });

    useEffect(() => {
        localStorage.setItem(
            "counterpartiesSelectedColumns",
            JSON.stringify(selectedColumns)
        );
    }, [selectedColumns]);

    const resizingInfoRef = useRef({
        isResizing: false,
        startX: 0,
        columnKey: null,
        initialWidth: 0,
    });

    const onMouseDown = (e, column) => {
        e.preventDefault();
        e.stopPropagation();
        resizingInfoRef.current.isResizing = true;
        resizingInfoRef.current.startX = e.clientX;
        resizingInfoRef.current.columnKey = column;
        resizingInfoRef.current.initialWidth =
            columnWidths[column] || DEFAULT_COLUMN_WIDTHS[column] || 150;
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };

    const onMouseMove = (e) => {
        if (!resizingInfoRef.current.isResizing) return;
        const { startX, columnKey, initialWidth } = resizingInfoRef.current;
        const delta = e.clientX - startX;
        const newWidth = Math.max(50, initialWidth + delta);
        setColumnWidths((prev) => ({
            ...prev,
            [columnKey]: newWidth,
        }));
    };

    const onMouseUp = () => {
        resizingInfoRef.current.isResizing = false;
        // document.removeEventListener("mousemove", onMouseMove);
        // document.removeEventListener("mouseup", onMouseUp);
        setColumnWidths((prev) => {
            localStorage.setItem("counterpartiesColumnWidths", JSON.stringify(prev));
            return prev;
        });
    };

    const columnsInUse = ALL_COLUMNS.filter((col) => selectedColumns.includes(col));
    const totalTableWidth = columnsInUse.reduce((acc, col) => {
        return acc + (columnWidths[col] || DEFAULT_COLUMN_WIDTHS[col] || 100);
    }, 0);

    const handleFilterChange = (key, value) => {
        const newValue =
            key === "organFilter"
                ? value
                    ? value.id || value
                    : ""
                : key.includes("archivedFilter")
                    ? typeof value === "object"
                        ? value.target.checked
                        : value
                    : value;
        setCounterparties([]);
        setOffset(0);
        setHasMore(true);
        setFilters((prev) => ({
            ...prev,
            [key]: newValue,
        }));
    };

    const openModal = (open) => {
        if (open) { 
            setShowModal(false);
            setShowUnauthorizedModal(true);
        } else { 
            setShowUnauthorizedModal(false);
        }

    };

    const fetchCounterparties = async (currentOffset = 0, isNewSearch = false) => {
        setLoading(true);
        try {
            const isFiltering =
                Object.values(filters).some((val) => val !== "" && val !== false) ||
                searchIDofelem;
    
            const filterParams = {
                id: searchIDofelem || undefined,
                name: filters.nameFilter ? filters.nameFilter.toLowerCase() : undefined,
                iinbin: filters.iinbinFilter ? filters.iinbinFilter.toLowerCase() : undefined,
                is_active: [!filters.archivedFilterNotArchive, filters.archivedFilterActive],
                company: filters.organFilter || undefined,
                limit,
                offset: currentOffset,
            };
    
            const response = isFiltering
                ? await api.post("/api/v1/bills/counterpartyfilter/", filterParams)
                : await api.get(`/api/v1/bills/counterparty/?limit=${limit}&offset=${currentOffset}`);
    
            const newData = response.data.results || response.data;
    
            if (isNewSearch || currentOffset === 0) {
                setCounterparties(newData);
            } else {
                setCounterparties((prev) => [
                    ...prev,
                    ...newData.filter((item) => !prev.some((existing) => existing.id === item.id)),
                ]);
            }
            setHasMore(newData.length === limit);
        } catch (error) {
            console.error("Ошибка при загрузке контрагентов:", error);
    
            if (error.response) {
                console.error("Ответ сервера:", error.response.status, error.response.data);

                if (error.response.status === 401) {
                    console.log("401 ошибка! Перезайдите, пожалуйста.");
                    openModal();
                } else if (error.response.status === 403) {
                    setErrorMessage("Доступ запрещен! У вас недостаточно прав на просмотр контрагентов.");
                } else if (error.response.status === 400) {
                    setErrorMessage(error.response.data.detail || "Ошибка запроса.");
                } else if (error.response.status === 500) {
                    setErrorMessage("Ошибка сервера! Попробуйте позже.");
                } else {
                    setErrorMessage(`Неизвестная ошибка: ${error.response.status}`);
                }
            } else {
                console.error("Ошибка сети или сервера:", error);
                setErrorMessage("Ошибка соединения с сервером. Проверьте интернет.");
            }
    
            setHasMore(false);
        } finally {
            setLoading(false);
        }
    };
    
    useEffect(() => {
        const delayedSearch = setTimeout(() => {
            setCounterparties([]);
            setOffset(0);
            setHasMore(true);
            fetchCounterparties(0, true);
        }, 300);
        return () => clearTimeout(delayedSearch);
    }, [filters, searchIDofelem]);
    
    useEffect(() => {
        setCounterparties([]);
        setOffset(0);
        setHasMore(true);
        fetchCounterparties();
    }, [location]);
    
    useEffect(() => {
        if (offset > 0) {
            fetchCounterparties(offset);
        }
    }, [offset]);
    

    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 50 && hasMore && !loading) {
                setOffset((prevOffset) => prevOffset + limit);
            }
        }
    }, [hasMore, loading]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener("scroll", handleScroll);
            return () => container.removeEventListener("scroll", handleScroll);
        }
    }, [handleScroll]);

    useEffect(() => {
        const fetchCounterpartiesById = async () => {
            if (searchIDofelem) {
                try {
                    const response = await api.post("/api/v1/bills/counterpartyfilter/", {
                        id: searchIDofelem,
                        is_active: [true, false],
                    });
                    if (response.data.length > 0) {
                        setFilters((prev) => ({ ...prev, nameFilter: response.data[0].name }));
                        setCounterparties(response.data);
                    }
                } catch (error) {}
            } else {
                setFilters({
                    nameFilter: "",
                    organFilter: "",
                    iinbinFilter: "",
                    archivedFilterNotArchive: false,
                    archivedFilterActive: false,
                });
                setCounterparties([]);
                setOffset(0);
                setHasMore(true);
                fetchCounterparties();
            }
        };
        fetchCounterpartiesById();
    }, [searchIDofelem]);

    const handleUpdateCounterparties = async (isActive) => {
        setLoading(true);
        try {
            await Promise.all(
                checkedCounterparties.map((id) =>
                    api.patch(`/api/v1/bills/counterparty/${id}/update/`, { is_active: isActive })
                )
            );
            setFilters({
                nameFilter: "",
                organFilter: "",
                iinbinFilter: "",
                archivedFilterNotArchive: false,
                archivedFilterActive: false,
            });
            setCounterparties([]);
            setOffset(0);
            setHasMore(true);
            setCheckedCounterparties([]);
            await fetchCounterparties(0, true);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const handleCheckboxChange = (counterpartyId) => {
        setCheckedCounterparties((prev) =>
            prev.includes(counterpartyId)
                ? prev.filter((id) => id !== counterpartyId)
                : [...prev, counterpartyId]
        );
    };

    const handleSelectAllChange = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            const allIds = counterparties.map((item) => item.id);
            setCheckedCounterparties(allIds);
        } else {
            setCheckedCounterparties([]);
        }
    };

    const allChecked =
        counterparties.length > 0 &&
        counterparties.every((item) => checkedCounterparties.includes(item.id));

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "bin_iin") {
            const cleanValue = value.replace(/\D/g, "");
            setFormData((prev) => ({
                ...prev,
                [name]: cleanValue,
            }));
        } else {
            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    useEffect(() => {
        const binIinValue = formData.bin_iin;
        if (binIinValue) {
            if (binIinValue.length !== 12) {
                setErrors((prev) => ({
                    ...prev,
                    bin_iin: "БИН/ИИН должен содержать 12 цифр",
                }));
            } else {
                setErrors((prev) => ({
                    ...prev,
                    bin_iin: "",
                }));
            }
        } else {
            setErrors((prev) => ({
                ...prev,
                bin_iin: "",
            }));
        }
    }, [formData.bin_iin]);

    const handleCloseModal = () => {
        resetForm();
        setShowModal(false);
        setErrors({});
    };

    const resetForm = () => {
        setFormData(initialFormData);
        setIsEditing(false);
    };

    const handleRowClick = (counterparty) => {
        setIsEditing(true);
        setFormData({
            id: counterparty.id,
            name: counterparty.name,
            bin_iin: counterparty.bin_iin || "",
            code: counterparty.code || "",
            company: counterparty.company,
        });
        setShowModal(true);
    };

    const handleCompanyChange = (value) => {
        setFormData((prev) => ({
            ...prev,
            company: value,
        }));
        if (errors.company) {
            setErrors((prev) => ({ ...prev, company: "" }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const requiredFields = ["name", "bin_iin", "company"];
        const newErrors = {};

        requiredFields.forEach((key) => {
            if (!formData[key]) {
                if (key === "company") {
                    newErrors[key] = "Выберите значение";
                } else {
                    newErrors[key] = "Заполните поле";
                }
            }
        });

        if (formData.bin_iin && formData.bin_iin.length !== 12) {
            newErrors.bin_iin = "БИН/ИИН должен содержать ровно 12 цифр";
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setLoading(true);
        const getIdOrValue = (obj) => obj?.id || obj || "";
        const preparedFormData = {
            ...formData,
            company: getIdOrValue(formData.company),
        };

        try {
            if (isEditing) {
                await api.patch(
                    `/api/v1/bills/counterparty/${formData.id}/update/`,
                    preparedFormData
                );
            } else {
                await api.post("/api/v1/bills/counterparty-create/", preparedFormData);
            }
            setCounterparties([]);
            setOffset(0);
            handleCloseModal();
            fetchCounterparties();
        } catch (error) {
            alert("Ошибка при сохранении. Проверьте заполнение полей.");
        } finally {
            setLoading(false);
        }
    };

    const handleReset = () => {
        const search = location.search;
        navigate(location.search.replace(search, ""));
        window.location.reload();
    };

    const toggleColumn = (column) => {
        setSelectedColumns((prev) =>
            prev.includes(column) ? prev.filter((col) => col !== column) : [...prev, column]
        );
    };

    const selectAllColumns = () => {
        setSelectedColumns([...ALL_COLUMNS]);
    };

    const cancelSettings = () => {
        setShowSettings(false);
    };

    const saveSettings = () => {
        setShowSettings(false);
    };

    const title = isEditing ? "Редактирование контрагента" : "Создание контрагента";

    return (
        <div className="container-fluid" translate="no">
            <Header />
            <div className="row flex-nowrap">
                <SideBar />
                <div className="col py-3">
                    <div className="management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between mb-3">
                                    <div className="d-flex gap-2">
                                        <PageHeading>Контрагенты</PageHeading>
                                        <Button
                                            bType="secondary"
                                            size="small"
                                            fixed
                                            onClick={() => setFiltersVisible(!filtersVisible)}
                                        >
                                            <i className="bi bi-funnel"></i>
                                        </Button>
                                        <Button bType="primary" size="small" fixed onClick={() => setShowModal(true)}>
                                            <i className="bi bi-plus-circle"></i>
                                        </Button>
                                    </div>
                                    <Button
                                        bType="secondary"
                                        size="small"
                                        fixed
                                        onClick={() => setShowSettings(true)}
                                    >
                                        <i className="bi bi-gear"></i>
                                    </Button>
                                </div>
                                {errorMessage && (
                                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                                        {errorMessage}
                                    </Alert>
                                )}
                                <UnauthorizedModal 
                                    show={showUnauthorizedModal} 
                                    onClose={() => setShowUnauthorizedModal(false)} 
                                />
                                {filtersVisible && (
                                    <div className="mb-3 p-3 border rounded">
                                        <Heading level={6} style={{ marginBottom: "10px" }}>
                                            Фильтры:
                                        </Heading>
                                        <div className="row g-3">
                                            <div className="col-md-3">
                                                <label className="form-label">Наименование</label>
                                                <Input
                                                    type="text"
                                                    placeholder="Введите контрагента"
                                                    value={filters.nameFilter}
                                                    onChange={(e) => handleFilterChange("nameFilter", e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label className="form-label">БИН/ИИН</label>
                                                <Input
                                                    type="text"
                                                    placeholder="Введите БИН/ИИН"
                                                    value={filters.iinbinFilter}
                                                    onChange={(e) => handleFilterChange("iinbinFilter", e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label className="form-label">Организация</label>
                                                <APIMultipleSearchableSelect
                                                    endpoint="/api/v1/organization/search/"
                                                    value={filters.organFilter}
                                                    onChange={(value) => handleFilterChange("organFilter", value)}
                                                    placeholder="Выберите организацию"
                                                    setShowUnauthorizedModal={openModal}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label>Архивный</label>
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="archivedCheckNotActive"
                                                        checked={filters.archivedFilterNotArchive}
                                                        onChange={(e) => handleFilterChange("archivedFilterNotArchive", e)}
                                                    />
                                                    <label className="form-check-label" htmlFor="archivedCheckNotActive">
                                                        Да
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="archivedCheckActive"
                                                        checked={filters.archivedFilterActive}
                                                        onChange={(e) => handleFilterChange("archivedFilterActive", e)}
                                                    />
                                                    <label className="form-check-label" htmlFor="archivedCheckActive">
                                                        Нет
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center gap-3 mt-3">
                                            <Button onClick={handleReset} bType="secondary" size="medium" fixed>
                                                Сбросить
                                            </Button>
                                        </div>
                                    </div>
                                )}
                                {checkedCounterparties.length > 0 && (
                                    <div className="d-flex align-items-center mb-3">
                                        <Button
                                            onClick={() => handleUpdateCounterparties(false)}
                                            bType="secondary"
                                            size="medium"
                                            fixed
                                            disabled={checkedCounterparties.length === 0}
                                            style={{ marginRight: "10px" }}
                                        >
                                            Архивировать выбранные
                                        </Button>
                                        <Button
                                            onClick={() => handleUpdateCounterparties(true)}
                                            bType="secondary"
                                            size="medium"
                                            fixed
                                            disabled={checkedCounterparties.length === 0}
                                            style={{ marginRight: "10px" }}
                                        >
                                            Разархивировать выбранные
                                        </Button>
                                    </div>
                                )}
                                <div
                                    ref={containerRef}
                                    className="Payments__wrapper"
                                    style={{ height: "70vh", minHeight: "70vh", overflowY: "scroll", position: "relative" }}
                                >
                                    <table
                                        className="Payments__table"
                                        style={{
                                            tableLayout: "fixed",
                                        }}
                                    >
                                        <thead>
                                        <tr>
                                            <th style={{ width: 50 }}>
                                                <input
                                                    type="checkbox"
                                                    checked={allChecked}
                                                    onChange={handleSelectAllChange}
                                                />
                                            </th>
                                            {columnsInUse.map((col) => {
                                                const w = columnWidths[col] || DEFAULT_COLUMN_WIDTHS[col];
                                                return (
                                                    <th
                                                        key={col}
                                                        style={{
                                                            width: w,
                                                            minWidth: w,
                                                            position: "relative",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <span>{col}</span>
                                                            <div
                                                                style={{
                                                                    cursor: "col-resize",
                                                                    padding: "0 4px",
                                                                    marginRight: "-4px",
                                                                    userSelect: "none",
                                                                    zIndex: 999
                                                                }}
                                                                onMouseDown={(e) => onMouseDown(e, col)}
                                                            >
                                                                ||
                                                            </div>
                                                        </div>
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {counterparties.map((counterparty) => (
                                            <tr key={counterparty.id} onClick={() => handleRowClick(counterparty)}>
                                                <td style={{ width: 50 }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={checkedCounterparties.includes(counterparty.id)}
                                                        onChange={(e) => {
                                                            e.stopPropagation();
                                                            handleCheckboxChange(counterparty.id);
                                                        }}
                                                    />
                                                </td>
                                                {selectedColumns.includes("Наименование") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Наименование"] ||
                                                                DEFAULT_COLUMN_WIDTHS["Наименование"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "normal",
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        {counterparty.name}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("БИН/ИИН") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["БИН/ИИН"] || DEFAULT_COLUMN_WIDTHS["БИН/ИИН"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        {counterparty.bin_iin}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Организация") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Организация"] ||
                                                                DEFAULT_COLUMN_WIDTHS["Организация"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "normal",
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        {counterparty.company?.name}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Архивный") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Архивный"] ||
                                                                DEFAULT_COLUMN_WIDTHS["Архивный"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        {counterparty.is_active ? "Нет" : "Да"}
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    {loading && (
                                        <div className="position-absolute top-50 start-50 translate-middle">
                                            <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {errorMessage && (
                <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                    {errorMessage}
                </Alert>
                )}
                <UnauthorizedModal 
                    show={showUnauthorizedModal} 
                    onClose={() => setShowUnauthorizedModal(false)} 
                />
                    <form className="bankaccount-form" onSubmit={handleSubmit}>
                        <div className="form_input">
                            <div className="row g-1">
                                <div className="col-md-12">
                                    <label className="form-label">Наименование контрагента*</label>
                                    <Input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        placeholder="Введите наименование"
                                        onChange={handleChange}
                                    />
                                    {errors.name && <span className="invalid-feedback">{errors.name}</span>}
                                </div>
                                <div className="col-md-12">
                                    <label className="form-label">БИН/ИИН*</label>
                                    <Input
                                        type="text"
                                        name="bin_iin"
                                        value={formData.bin_iin}
                                        placeholder="Введите БИН/ИИН"
                                        maxLength="12"
                                        onChange={handleChange}
                                    />
                                    {errors.bin_iin && <span className="invalid-feedback">{errors.bin_iin}</span>}
                                </div>
                                <div className="col-md-12">
                                    <label className="form-label">Организация*</label>
                                    <SearchableSelect
                                        endpoint="/api/v1/organization/"
                                        value={formData.company || ""}
                                        onChange={handleCompanyChange}
                                        placeholder="Выберите организацию"
                                        className={errors.company ? "is-invalid" : ""}
                                        isClearable
                                        isMulti={false}
                                        setShowUnauthorizedModal={openModal}
                                    />
                                    {errors.company && (
                                        <div className="invalid-feedback" style={{ display: "block" }}>
                                            {errors.company}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button bType="secondary" size="medium" fixed onClick={handleCloseModal}>
                        Закрыть
                    </Button>
                    <Button bType="primary" size="medium" fixed onClick={handleSubmit}>
                        {isEditing ? "Сохранить" : "Создать"}
                    </Button>
                </Modal.Footer>
            </Modal>

            {showSettings && (
                <div className="modal" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}>
                    <div className="modal-dialog" style={{ maxWidth: "700px" }}>
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div className="modal-header" style={{ borderBottom: "2px solid #eee" }}>
                                <h5 className="modal-title">Настройка столбцов</h5>
                                <button className="btn-close" onClick={cancelSettings}></button>
                            </div>
                            <div className="modal-body" style={{ padding: "20px" }}>
                                <div className="container-fluid">
                                    <div className="row" style={{ gap: "10px", justifyContent: "space-evenly" }}>
                                        {ALL_COLUMNS.map((column) => (
                                            <div
                                                key={column}
                                                className={`col-4 p-2 d-flex align-items-center justify-content-start ${
                                                    selectedColumns.includes(column) ? "selected-column" : ""
                                                }`}
                                                onClick={() => toggleColumn(column)}
                                                style={{
                                                    cursor: "pointer",
                                                    border: selectedColumns.includes(column)
                                                        ? "2px solid #007BFF" : "1px solid #ddd",
                                                    padding: "8px",
                                                    transition: "0.3s",
                                                    borderRadius: "5px",
                                                    width: "30%",
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={selectedColumns.includes(column)}
                                                    readOnly
                                                    className="me-2"
                                                />
                                                <span className={selectedColumns.includes(column) ? "text-primary" : ""}>
                          {column}
                        </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer" style={{ borderTop: "2px solid #eee" }}>
                                <Button bType="secondary" size="medium" fixed onClick={selectAllColumns}>
                                    Выбрать все
                                </Button>
                                <Button bType="secondary" size="medium" fixed onClick={cancelSettings}>
                                    Отмена
                                </Button>
                                <Button bType="primary" size="medium" fixed onClick={saveSettings}>
                                    Сохранить
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Counterparties;
