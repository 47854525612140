import "../styles/sidebar.css"

import { useNavigate } from 'react-router-dom';

const sidebarItems = [
    {
        title: "Рабочий стол",
        icon: "bi-house",
        href: "/",
    },
    {
        title: "PRO-отчеты",
        icon: "bi-graph-down",
        href: "/analytics",
    },
    {
        title: "Power BI",
        icon: "bi-graph-up",
        href: "/powerBI",
    },
    {
        title: "Платежи",
        icon: "bi-currency-dollar",
        href: "/payment-registry",
        protected: true,
    },
    {
        title: "Документы",
        icon: "bi bi-file-earmark-post",
        href: "//",
        items: [
            {
                title: "Основные средства",
                protected: true,
                href: "/docOs",
            },
            {
                title: "Нематериальные активы",
                protected: true,
                href: "/docNMA",
            },
            {
                title: "ТМЗ",
                protected: true,
                href: "/docTMZ",
            }
            
        ]
    },
    {
        title: "Заработные платы",
        icon: "bi-cash-stack",
        href: "/salaries",
        protected: true,
    },
    {
        title: "Движение денег",
        icon: "bi-cash-stack",
        href: "/budgeting",
        protected: true,
    },
    {
        title: "Справочники",
        icon: "bi-grid",
        href: "//",
        items: [
            {
                title: "Банковские счета",
                protected: true,
                href: "/bankacc",
            },
            {
                title: "Статьи",
                protected: true,
                href: "/captions",
            },
            {
                title: "Контрагенты",
                protected: true,
                href: "/counterparty",
            },
            {
                title: "Проекты",
                protected: true,
                href: "/projects",
            },
            {
                title: "Договора",
                protected: true,
                href: "/agreements",
            },
            {
                title: "Физические лица",
                protected: true,
                href: "/employees",
            },
            {
                title: "Организации",
                protected: true,
                href: "/organizations",

            },
            {
                title: "Бюджеты",
                protected: true,
                href: "/budgets",
            },
            {
                title: "Типы документов",
                protected: true,
                href: "/documentTypes",
            },
            {
                title: "Виды операций",
                protected: true,
                href: "/operationTypes",
            },
            {
                title: "Номенклатура",
                protected: true,
                href: "/nomenclature",
            },
            {
                title: "Счета учета БУ",
                protected: true,
                href: "/accounting-accounts",
            }
        ]
    }
]

const SideBar = ({
    current
                 }) => {
    const nav = useNavigate()

    return (
        <>
            <div className="SideBar">
                <div className="SideBar__wrapper">
                    {sidebarItems.map((item, i) => (
                        <>
                        <div className={`SideBar__Item ${current === item.href ? "SideBar__current" : ""}`} key={i} onClick={() => {
                            if (!item.items) {
                                nav(item.href)
                            } else {
                                const el = document.getElementsByClassName("SideBar__SubItems" + i)
                                console.log(el)
                                el[0].style.display = "block"
                            }
                        }}>
                            {item.protected ? (
                                <>
                                    <div className="SideBar__icon">
                                    <i className={`fs-5 ${item.icon}`}>

                                    </i>
                                    </div>

                                    <div className="SideBar__title">
                                        {item.title}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="SideBar__icon">
                                    <i className={`fs-5 ${item.icon}`}>

                                    </i>
                                    </div>

                                    <div className="SideBar__title">
                                        {item.title}
                                    </div>
                                </>
                            )}
                        </div>

                            <div className={"SideBar__SubItems" + i} style={{
                                display: "none"
                            }}>
                                {item?.items?.map((item, i) => (
                                    <>
                                        <div className="SideBar__SubItem" onClick={() => {
                                            nav(item.href)
                                        }}>
                                            {item.title}
                                        </div>
                                    </>
                                ))}
                            </div>
                        </>
                    ))}
                </div>
            </div>
        </>
    );
}

export default SideBar;