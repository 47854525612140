import { useState, useEffect } from "react";
import useFetchData from "../../functions/UprData";
import { Link, useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';
import UnauthorizedModal from "../Unauthorized"; 
import { Alert } from 'react-bootstrap';

const RolesTable = () => {
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false);
    const [isAccessDenied, setIsAccessDenied] = useState(false); // 🔴 Флаг 403

    
    // Используем `useFetchData`, чтобы отслеживать 403 ошибку
    const fetchRoles = useFetchData(
        'api/v1/role/groups/', 
        setRoles, 
        () => setShowUnauthorizedModal(true), // ✅ Правильный вариант
        (error) => {
            setErrorMessage(error);
            if (error === "Доступ запрещен!") {
                setIsAccessDenied(true);
            }
        }
    );    

    useEffect(() => {
        setLoading(true);
        fetchRoles();
        setLoading(false);
    }, []);

    const navigate = useNavigate();
    const handleRowClick = (group) => {
        navigate(`/edit-group/${group.id}`);
    };

    if (loading) {
        return (
            <div className="position-absolute top-50 start-50 translate-middle">
                <ReactLoading type="spin" color="#0000FF" height={50} width={50} />
            </div>
        );
    }

    return (
        <>
        <div className="d-flex mb-4 mt-2">
            {/* Теперь кнопка снова работает! */}
            <Link 
                to={isAccessDenied ? "#" : "/create-group"} 
                className={`btn ${isAccessDenied ? "btn-secondary disabled" : "btn-primary"}`} 
                onClick={(e) => isAccessDenied && e.preventDefault()} 
            >
                + Создать группу
            </Link>
        </div>

        {errorMessage && (
            <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                {errorMessage}
            </Alert>
        )}
        
        <UnauthorizedModal 
            show={showUnauthorizedModal} 
            onClose={() => setShowUnauthorizedModal(false)} 
        />

        <table className="table table-striped">
            <thead className="table-light">
                <tr>
                    <th>ID</th>
                    <th>Наименование</th>
                    <th>Типовые</th>
                </tr>
            </thead>
            <tbody>
                {roles.map((role) => (
                    <tr key={role.id} onClick={() => handleRowClick(role)}>
                        <td>{role.id}</td>
                        <td>{role.name}</td>
                        <td>{role.typical ? "Да" : "Нет" }</td>
                    </tr>
                ))}
            </tbody>
        </table>
        </>
    );
}

export default RolesTable;
