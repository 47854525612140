import { Modal  } from "react-bootstrap";
import ReactLoading from 'react-loading';
import api from '../../api';
import { useState, useEffect } from "react";
import Button from "../ui/Button/Button";

const ConfirmModal = ({ showModal, setShowModal, budgets, setBudgets, setLoading, isOpening }) => {
    const [isSaving, setIsSaving] = useState(false);
    const [selectedBudgets, setSelectedBudgets] = useState([]);

    useEffect(() => {
        if (Array.isArray(budgets)) {
          setSelectedBudgets(budgets.map(budget => budget?.budget_id));
        }
    }, [budgets]);

    const handleOpening = async () => {
        setLoading(true);
        try {
            const response = await api.patch('api/v1/budgets/open/', { 'budget_ids': selectedBudgets });
            if (response.status === 200) {
                const updatedIsOpen = response.data.is_open;
                const updatedBudgets = budgets.map(budget =>
                    selectedBudgets.includes(budget.budget_id)
                        ? { ...budget, is_open: updatedIsOpen }
                        : budget
                );
            setBudgets(updatedBudgets);
            handleModalClose();
            }
        } catch (error) {
            console.error('Error opening budgets:', error);
        } finally {
            setLoading(false);
        }
    };
  
      const handleClosing = async () => {
        setLoading(true);
        try {
            const response = await api.patch('api/v1/budgets/close/', { 'budget_ids': selectedBudgets });
            if (response.status === 200) {
                const updatedIsOpen = response.data.is_open;
                const updatedBudgets = budgets.map(budget =>
                    selectedBudgets.includes(budget.budget_id)
                        ? { ...budget, is_open: updatedIsOpen }
                        : budget
                );
            setBudgets(updatedBudgets);
            handleModalClose();
            }
        } catch (error) {
            console.error('Error closing budgets:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleModalClose = () => {
        setShowModal(false);
        if (Array.isArray(budgets)) {
            setSelectedBudgets(budgets.map(budget => budget?.budget_id));
        }
    }

    const handleSubmit = async () => {
        if (isOpening) {
            setIsSaving(true);
            try {
                handleOpening()
            } catch (error) {
                console.log(error);
            } finally {
                setIsSaving(false);
            }
        } else {
            setIsSaving(true);
            try {
                handleClosing()
            } catch (error) {
                console.log(error);
            } finally {
                setIsSaving(false);
            }
        }
    }

    const handleCheckboxChange = (budgetId, isChecked) => {
        setSelectedBudgets(prev => {
            if (isChecked) {
                return [...prev, budgetId]; // Add the budget ID if checked
            } else {
                return prev.filter(id => id !== budgetId); // Remove the budget ID if unchecked
            }
        });
    };

    const handleCheckAllChange = (isChecked) => {
        if (isChecked) {
            setSelectedBudgets(budgets.map(budget => budget.budget_id)); // Select all
        } else {
            setSelectedBudgets([]); // Deselect all
        }
    };

    const isChecked = (budgetId) => selectedBudgets.includes(budgetId);

    return (
        <>
            <Modal show={showModal} onHide={handleModalClose} centered>
                <Modal.Header>
                    <Modal.Title className="text-center">{isOpening ? 'Открыть редактирование этих бюджетов' : 'Закрыть редактирование этих бюджетов'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <input 
                                            type="checkbox" 
                                            name="check_all"
                                            onChange={(e) => handleCheckAllChange(e.target.checked)}
                                            checked={selectedBudgets.length === budgets.length}/>
                                    </th>
                                    <th>Бюджет</th>
                                </tr>
                            </thead>
                            <tbody>
                                {budgets?.map(budget => (
                                    <tr key={budget?.budget_id}>
                                        <td>
                                            <input 
                                            type="checkbox" 
                                            checked={isChecked(budget?.budget_id)}
                                            onChange={(e) => handleCheckboxChange(budget.budget_id, e.target.checked)}/>
                                        </td>
                                        <td>{budget?.budget_name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button 
                        bType="secondary"
                        size="medium"
                        fixed
                        onClick={() => handleModalClose()}
                        disabled={isSaving}
                    >
                        Отмена
                    </Button>
                    <Button
                        bType="primary"
                        size="medium"
                        fixed
                        onClick={handleSubmit}
                        disabled={isSaving}
                    >
                        {isSaving ? (
                            <div className="d-flex align-items-center">
                                <span className="me-2">Сохранение</span>
                                <ReactLoading 
                                    type="spin" 
                                    color="#FFFFFF" 
                                    height={16} 
                                    width={16} 
                                />
                            </div>
                        ) : 'Сохранить'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ConfirmModal;