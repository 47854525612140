import { useState, useEffect } from "react";
import api from "../api";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import UnauthorizedModal from "./Unauthorized"; // Импортируем модалку

const UserListTable = () => {
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const [isAccessDenied, setIsAccessDenied] = useState(false); // 🔴 Флаг 403
    const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false); // 🔴 Флаг 401

    const fetchUsers = async () => {
        try {
            const response = await api.get('/api/v1/users/');
            setUsers(response.data);
        } catch (error) {
            console.error('Ошибка при загрузке пользователей', error);

            if (error.response) {
                if (error.response.status === 401) {
                    console.log("401 ошибка! Требуется авторизация.");
                    setShowUnauthorizedModal(true); // Показываем модалку
                } else if (error.response.status === 403) {
                    setErrorMessage("Доступ запрещен!");
                    setIsAccessDenied(true); // Если 403, отключаем кнопку
                }
            } else {
                setErrorMessage("Ошибка соединения с сервером.");
            }
        }
    };

    const handleRowClick = (user_item) => {
        window.open(`/edit-user/${user_item.id}`, "_blank");
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    return (
        <>  
            <div className="d-flex mb-4 mt-2">
                {/* Кнопка теперь снова работает! */}
                <Link 
                    to={isAccessDenied ? "#" : "/create-user"} 
                    className={`btn ${isAccessDenied ? "btn-secondary disabled" : "btn-primary"}`} 
                    onClick={(e) => isAccessDenied && e.preventDefault()} 
                >
                    + Добавить пользователя
                </Link>
            </div>

            {errorMessage && (
                <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                    {errorMessage}
                </Alert>
            )}

            {/* Модалка для 401 ошибки */}
            <UnauthorizedModal 
                show={showUnauthorizedModal} 
                onClose={() => setShowUnauthorizedModal(false)} 
            />

            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>Фамилия</th>
                        <th>Имя</th>
                        <th>Отчество</th>
                        <th>Почта</th>
                        <th>Телефон</th>
                        <th>Должность</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <tr key={user.username} onClick={() => handleRowClick(user)}>
                            <td>{user.username}</td>
                            <td>{user.first_name}</td>
                            <td>{user.last_name}</td>
                            <td>{user.middle_name}</td>
                            <td>{user.email}</td>
                            <td>{user.phone_number}</td>
                            <td>{user.position ? user.position.name : ''}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default UserListTable;
