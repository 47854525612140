import { useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import api from "../../api";
import SideBar from "../SideBar";
import useFetchData from "../../functions/UprData";
import { CaptionForm } from "../captions/CaptionForm";
import Header from "../Header";
import APISearchableSelect from './SearchableSelect';
import "../../styles/createPayment.css";
import ReactLoading from 'react-loading';
import { Alert } from 'react-bootstrap';
import PageHeading from "../ui/PageHeading/PageHeading";
import Input from "../ui/Input/Input";
import Button from "../ui/Button/Button";
import Checkbox from "../ui/Checkbox/Checkbox";
import Card from "../ui/Card/Card";
import Heading from "../ui/Heading/Heading";
import { ProjectForm } from "../projects/ProjectForm";
import UnauthorizedModal from "../Unauthorized"; // ✅ Импортируем модалку
import { useAuth } from "../../AuthContext"; // ✅ Импортируем контекст авторизации

const PaymentEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState(""); // 🔴 Храним текст ошибки
    const [payment, setPayment] = useState({});
    const [captions, setCaptions] = useState([]);
    const { logout } = useAuth();
    const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false); 
    const [caption_types, setCaptionTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [showDecipher, setShowDecipher] = useState(false);

    const [decipherFields, setDecipherFields] = useState({
        sum: true,
        nds_sum: false,
        nds_rate: false,
        counterparty: false,
        caption: false,
        project: false,
        employee: false,
        agreement: false
    });

    const fetchPayment = async () => {
        try {
            const response = await api.get(`api/v1/bills/payments/${id}/`);
            setPayment(response.data);

            // Показываем «Расшифровку», если в ответе уже есть decipher
            const hasDecipher = response.data.decipher && response.data.decipher.length > 0;
            setShowDecipher(hasDecipher);
            updateDecipherFields(response.data.decipher);

        } catch (error) {
            console.error('Error fetching payment', error);

            if (error.response && error.response.status === 401) {
                setShowUnauthorizedModal(true); // ✅ Показываем модальное окно при 401 ошибке
            }
        } finally {
            setLoading(false);
        }
    };

    // Загружаем данные при изменении ID
    useEffect(() => {
        fetchPayment();
    }, [id]);

    const fetchCaption = useFetchData('/api/v1/bills/caption/', setCaptions);
    const fetchCaptionTypes = useFetchData('/api/v1/bills/caption-types/', setCaptionTypes);
    // Если нужно подгружать валюты (пример):
    // const [currencies, setCurrencies] = useState([]);
    // const fetchCurrencies = useFetchData('/api/v1/bank/currencies/', setCurrencies);

    const fetchData = async () => {
        setLoading(true);
        try {
            await Promise.all([
                fetchPayment(),
                fetchCaption(),
                fetchCaptionTypes(),
                // fetchCurrencies() // Если нужно
            ]);
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]);

    // Определение, какие чекбоксы «Расшифровки» нужно выставить в true на основе первой строки decipher
    const updateDecipherFields = (decipher) => {
        if (!decipher.length) return;
        const newFields = { ...decipherFields };

        Object.keys(newFields).forEach((key) => {
            // Если в decipher[0][key] есть что-то осмысленное (не null, не 0), включаем
            newFields[key] = decipher[0][key] !== null && decipher[0][key] !== 0;
        });
        setDecipherFields(newFields);
    };

    // Обработчик изменений формы (верхний уровень)
    const handleChange = (e) => {
        const { name, value } = e.target;
        setPayment((prevPayment) => ({
            ...prevPayment,
            [name]: value,
        }));
    };

    // Формат и парсинг суммы
    const formatCurrency = (value) =>
        new Intl.NumberFormat('ru-RU', { style: 'decimal' }).format(value || 0);

    const parseCurrency = (value) =>
        parseFloat(value.replace(/\s+/g, '').replace(',', '.')) || 0;

    // Установка флажков для расшифровки
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        // При отключении — сбрасываем связанные поля, чтобы не остались «зависшие» значения
        if (name === 'counterparty') {
            handleChange({ target: { name: "counterparty", value: '' } });
            handleChange({ target: { name: "counterparty_account", value: '' } });
        } else if (name === 'caption') {
            handleChange({ target: { name: "caption", value: '' } });
            handleChange({ target: { name: "project", value: '' } });
        } else if (name === 'agreement') {
            handleChange({ target: { name: "agreement", value: '' } });
        }
        setDecipherFields((prev) => ({
            ...prev,
            [name]: checked
        }));
    };

    // Добавить строку в массив decipher
    const handleAddDecipher = () => {
        const draftDecipher = {
            employee: null,
            counterparty: null,
            caption: null,
            project: null,
            sum: 0,
            nds_sum: 0,
            nds_rate: 0,
            type_of_debt: null,
            accountable: null,
            agreement: null,
            payment: id,
            author: null
        };
        setPayment((prevPayment) => ({
            ...prevPayment,
            decipher: [...(prevPayment.decipher || []), draftDecipher],
        }));
    };

    // Удалить строку decipher
    const handleRemoveDecipher = (index) => {
        setPayment((prevPayment) => ({
            ...prevPayment,
            decipher: prevPayment.decipher.filter((_, i) => i !== index),
        }));
    };

    // Переключатель чекбокса «Разнести»
    const handleShowDecipher = (checkboxValue) => {
        if (checkboxValue) {
            // Если до этого расшифровки не было, сразу добавим одну строку
            if (!payment.decipher?.length) {
                handleAddDecipher();
            }
            setShowDecipher(true);
        } else {
            // Отключаем — удаляем все decipher
            setPayment((prev) => ({
                ...prev,
                decipher: []
            }));
            setDecipherFields({
                sum: true,
                nds_sum: false,
                nds_rate: false,
                counterparty: false,
                caption: false,
                project: false,
                employee: false,
                agreement: false
            });
            setShowDecipher(false);
        }
    };

    // Изменения внутри decipher
    const handleSelectChange = (index, field) => {
        const updatedDecipher = payment.decipher.map((param, i) => {
            if (i === index) {
                const updatedParam = { ...param, [field.name]: field.value };

                // Автоматически пересчитываем nds_sum, если изменились sum или nds_rate
                if (field.name === "sum" || field.name === "nds_rate") {
                    updatedParam.nds_sum =
                        ((updatedParam.sum || 0) * (updatedParam.nds_rate || 0)) / 100;
                }
                return updatedParam;
            }
            return param;
        });

        // Проверка, чтобы сумма расшифровок не превышала payment.total_sum
        const decipherSumTotal = updatedDecipher.reduce((sum, p) => sum + (p.sum || 0), 0);
        if (decipherSumTotal > payment.total_sum) {
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }

        setPayment((prev) => ({
            ...prev,
            decipher: updatedDecipher,
        }));
    };

    // Форматирование даты
    const formatDate = (isoDateString) => {
        if (!isoDateString) return '';
        const date = new Date(isoDateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    // Сабмит формы
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Проверка, что итог расшифровки не превышает payment.total_sum
        const decipherSumTotal = payment.decipher?.reduce(
            (sum, param) => sum + (parseFloat(param.sum) || 0), 0
        ) || 0;
    
        if (decipherSumTotal > payment.total_sum) {
            setShowAlert(true);
            return;
        }
    
        // Функция получения ID или значения
        const getIdOrValue = (obj) => obj?.id || obj || '';
    
        try {
            // Подготовка данных для отправки
            const preparedPayment = {
                ...payment,
                company: getIdOrValue(payment.company),
                company_account: getIdOrValue(payment.company_account),
                document_type: getIdOrValue(payment.document_type),
                type_of_operation: getIdOrValue(payment.type_of_operation),
                project: getIdOrValue(payment.project),
                caption: getIdOrValue(payment.caption),
                counterparty: getIdOrValue(payment.counterparty),
                counterparty_account: getIdOrValue(payment.counterparty_account),
                agreement: getIdOrValue(payment.agreement),
                accounting_account: getIdOrValue(payment.accounting_account),
                author: getIdOrValue(payment.author),
                currency: getIdOrValue(payment.currency),
                decipher: (payment.decipher || []).map((param) => ({
                    ...param,
                    caption: getIdOrValue(param.caption),
                    project: getIdOrValue(param.project),
                    counterparty: getIdOrValue(param.counterparty),
                    employee: getIdOrValue(param.employee),
                    agreement: getIdOrValue(param.agreement),
                })),
            };
    
            // Отправка запроса
            const response = await api.patch(`/api/v1/bills/payments/${id}/update/`, preparedPayment);
    
            console.log('✅ Успешное обновление платежа:', response.data);
            navigate('/payment-registry');
        } catch (error) {
            console.log("Ошибка при обновлении платежа:", error);
    
            if (error.response) {
                console.error("Ответ сервера:", error.response.status, error.response.data);
    
                if (error.response.status === 401) {
                    console.log("401 ошибка! Показываем UnauthorizedModal.");
                    setShowUnauthorizedModal(true);
                } else if (error.response.status === 403) {
                    setErrorMessage("Доступ запрещен!");
                } else if (error.response.status === 400) {
                    setErrorMessage(error.response.data.detail || "Ошибка запроса.");
                } else if (error.response.status === 500) {
                    setErrorMessage("Ошибка сервера! Попробуйте позже.");
                } else {
                    setErrorMessage(`Неизвестная ошибка: ${error.response.status}`);
                }
            } else {
                console.error("🌐 Ошибка сети или сервера:", error);
                setErrorMessage("🌐 Ошибка соединения с сервером. Проверьте интернет.");
            }
        } finally {
            setLoading(false);
        }
    };    

    return (
        <div className="container-fluid" translate="no">
            <Header />
            <div className="row flex-nowrap">
                <SideBar current="/payment-registry" />
                <div className="col py-3">
                    <center>
                        <PageHeading>
                            {payment?.document_type?.name} №{payment?.number} от{' '}
                            {payment?.date ? new Date(payment.date).toLocaleDateString() : ''}
                        </PageHeading>
                    </center>
                    {errorMessage && (
                            <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                                {errorMessage}
                            </Alert>
                        )}
                    <br />
                    <br />
                    {loading ? (
                        <div className="position-absolute top-50 start-50 translate-middle">
                            <ReactLoading type="spin" color="#0000FF" height={50} width={50} />
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                {/* Левая колонка */}
                                <div className="col-md-6">

                                    {/* 1. Документ */}
                                    <div className="mb-3">
                                        <label className="form-label">Документ</label>
                                        <APISearchableSelect
                                            endpoint="/api/v1/bills/document-types/search/"
                                            value={payment?.document_type}
                                            onChange={(value) =>
                                                handleChange({ target: { name: 'document_type', value } })
                                            }
                                            placeholder="Выберите тип документа"
                                            setShowUnauthorizedModal={setShowUnauthorizedModal}
                                        />
                                    </div>

                                    {/* 2. Тип операции */}
                                    <div className="mb-3">
                                        <label className="form-label">Тип операции</label>
                                        <APISearchableSelect
                                            endpoint={`/api/v1/bills/operation-types/search/?doc_type=${payment.document_type.id}`}
                                            value={payment?.type_of_operation}
                                            onChange={(value) =>
                                                handleChange({ target: { name: 'type_of_operation', value } })
                                            }
                                            placeholder="Выберите вид операции"
                                            setShowUnauthorizedModal={setShowUnauthorizedModal}
                                        />
                                    </div>

                                    {/* 3. Дата платежа */}
                                    <div className="mb-3 d-flex flex-column">
                                        <label className="form-label">Дата платежа</label>
                                        <Input
                                            type="date"
                                            value={formatDate(payment.date)}
                                            onChange={(e) =>
                                                handleChange({ target: { name: 'date', value: e.target.value } })
                                            }
                                        />
                                    </div>

                                    {/* 4. Номер документа */}
                                    <div className="mb-3 d-flex flex-column">
                                        <label className="form-label">Номер документа</label>
                                        <Input
                                            type="text"
                                            value={payment.number || ''}
                                            onChange={(e) =>
                                                handleChange({ target: { name: 'number', value: e.target.value } })
                                            }
                                        />
                                    </div>

                                    {/* 5. Сумма */}
                                    <div className="mb-3">
                                        <label className="form-label">Сумма</label>
                                        <Input
                                            type="text"
                                            name="total_sum"
                                            placeholder="Введите сумму"
                                            value={formatCurrency(payment.total_sum)}
                                            onChange={(e) =>
                                                handleChange({
                                                    target: {
                                                        name: 'total_sum',
                                                        value: parseCurrency(e.target.value),
                                                    },
                                                })
                                            }
                                        />
                                    </div>

                                    {/* 6. Организация */}
                                    <div className="mb-3">
                                        <label className="form-label">Организация</label>
                                        <APISearchableSelect
                                            endpoint="/api/v1/organization/search/"
                                            value={payment?.company}
                                            onChange={(value) =>
                                                handleChange({ target: { name: 'company', value } })
                                            }
                                            placeholder="Выберите организацию"
                                            setShowUnauthorizedModal={setShowUnauthorizedModal}
                                        />
                                    </div>

                                    {/* 7. Банковский счёт (перенесён сюда) */}
                                    <div className="mb-3">
                                        <label className="form-label">Банковский счет</label>
                                        <APISearchableSelect
                                            endpoint="/api/v1/bank/bankaccounts/search/"
                                            value={payment?.company_account}
                                            onChange={(value) =>
                                                handleChange({ target: { name: 'company_account', value } })
                                            }
                                            placeholder="Выберите счет организации"
                                            setShowUnauthorizedModal={setShowUnauthorizedModal}
                                        />
                                    </div>

                                    {/* 8. Сумма комиссии (новое поле) */}
                                    <div className="mb-3">
                                        <label className="form-label">Сумма комиссии</label>
                                        <Input
                                            type="text"
                                            name="commission_sum"
                                            placeholder="Введите сумму комиссии"
                                            value={formatCurrency(payment.commission_sum || 0)}
                                            onChange={(e) =>
                                                handleChange({
                                                    target: {
                                                        name: 'commission_sum',
                                                        value: parseCurrency(e.target.value),
                                                    },
                                                })
                                            }
                                        />
                                    </div>

                                    {/* 9. Валюта (новое поле) */}
                                    <div className="mb-3">
                                        <label className="form-label">Валюта</label>
                                        <APISearchableSelect
                                            endpoint="/api/v1/bank/currencies/search/"
                                            value={payment?.currency}
                                            onChange={(value) =>
                                                handleChange({ target: { name: 'currency', value } })
                                            }
                                            placeholder="Выберите валюту"
                                            setShowUnauthorizedModal={setShowUnauthorizedModal}
                                        />
                                    </div>

                                    {/* 10. Проект */}
                                    <div className={payment.decipher?.length > 0 ? 'd-none' : 'mb-3'}>
                                        {/* <label className="form-label">Проект</label>
                                        <APISearchableSelect
                                            endpoint="/api/v1/organization/projects/search/"
                                            value={payment?.project}
                                            onChange={(value) =>
                                                handleChange({ target: { name: 'project', value } })
                                            }
                                            placeholder="Выберите проект"
                                        /> */}
                                        <ProjectForm  
                                                param={payment?.project || ''}
                                                handleSelectChange={(index, selectedOption) =>                                                         
                                                    handleChange({ target: { name: "project", value: selectedOption.value } })                                                     
                                                }
                                            />
                                    </div>

                                    {/* 11. Статья */}
                                    <div className={payment.decipher?.length > 0 ? 'd-none' : 'mb-3'}>
                                        {/* <label className="form-label">Статья</label>
                                        <APISearchableSelect
                                            endpoint="/api/v1/bills/caption/search/"
                                            value={payment?.caption}
                                            onChange={(value) =>
                                                handleChange({ target: { name: 'caption', value } })
                                            }
                                            placeholder="Выберите статью"
                                        /> */}
                                        <CaptionForm
                                                captions={captions}
                                                setCaptions={setCaptions}
                                                param={payment?.caption}
                                                handleSelectChange={(index, selectedOption) =>                                                         
                                                    handleChange({ target: { name: "caption", value: selectedOption.value } })                                                     
                                                }
                                                caption_types={caption_types}
                                            />
                                    </div>

                                    {/* 12. Договор (теперь после Статьи) */}
                                    <div className={decipherFields.agreement ? 'd-none' : 'mb-3'}>
                                        <label className="form-label">Договор</label>
                                        <APISearchableSelect
                                            endpoint="/api/v1/organization/agreements/search/"
                                            value={payment?.agreement}
                                            onChange={(value) =>
                                                handleChange({ target: { name: 'agreement', value } })
                                            }
                                            placeholder="Выберите договор"
                                            setShowUnauthorizedModal={setShowUnauthorizedModal}
                                        />
                                    </div>

                                </div>

                                {/* Правая колонка */}
                                <div className="col-md-6">
                                    {/* Контрагент */}
                                    <div className={decipherFields.counterparty ? 'd-none' : 'mb-3'}>
                                        <label className="form-label">Контрагент</label>
                                        <APISearchableSelect
                                            endpoint="/api/v1/bills/counterparty/search/"
                                            value={payment?.counterparty}
                                            onChange={(value) =>
                                                handleChange({ target: { name: 'counterparty', value } })
                                            }
                                            placeholder="Выберите контрагента"
                                            setShowUnauthorizedModal={setShowUnauthorizedModal}
                                        />
                                    </div>

                                    {/* Банковский счет контрагента */}
                                    <div className={decipherFields.counterparty ? 'd-none' : 'mb-3'}>
                                        <label className="form-label">Банковский счет контрагента</label>
                                        <APISearchableSelect
                                            endpoint="/api/v1/bank/counterparty-accounts/search/"
                                            value={payment?.counterparty_account}
                                            onChange={(value) =>
                                                handleChange({
                                                    target: { name: 'counterparty_account', value },
                                                })
                                            }
                                            placeholder="Выберите счет контрагента"
                                            setShowUnauthorizedModal={setShowUnauthorizedModal}
                                        />
                                    </div>

                                    {/* Назначение платежа */}
                                    <div className="mb-3">
                                        <label className="form-label">Назначение платежа</label>
                                        <Input
                                            type="text"
                                            name="purpose_of_payment"
                                            value={payment.purpose_of_payment || ''}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    {/* Комментарий */}
                                    <div className="mb-3">
                                        <label className="form-label">Комментарий</label>
                                        <Input
                                            type="text"
                                            name="comment"
                                            value={payment.comment || ''}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Счет БУ</label>
                                        <APISearchableSelect
                                            endpoint="/api/v1/bills/accounting-accounts/search/"
                                            value={payment?.accounting_account}
                                            displayKey="code"
                                            onChange={(value) =>
                                                handleChange({ target: { name: 'accounting_account', value } })
                                            }
                                            placeholder="Выберите Счет БУ"
                                            setShowUnauthorizedModal={setShowUnauthorizedModal}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Автор</label>
                                        <input 
                                            type="text"
                                            className="form-control"
                                            value={payment?.author ? 
                                                `${payment.author.first_name || ''} ${payment.author.last_name || ''}`.trim() : 
                                                ''}
                                            disabled
                                            placeholder="1C - Автор"
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Раздел «Разнести» */}
                            <div className="decipher-form">
                                <div>
                                    <label
                                        className="d-flex align-items-center gap-2"
                                        style={{ maxWidth: 'fit-content' }}
                                    >
                                        <Checkbox
                                            checked={showDecipher}
                                            onChange={(e) => handleShowDecipher(e.target.checked)}
                                        />
                                        Разнести
                                    </label>
                                </div>

                                {showDecipher && (
                                    <div className="deciphercontent">
                                        <Heading level={5} style={{ marginBottom: '10px' }}>
                                            Выберите параметр распределения
                                        </Heading>
                                        <div className="d-flex align-items-center gap-4 mb-3">
                                            <label className="d-flex align-items-center gap-2">
                                                <Checkbox
                                                    type="checkbox"
                                                    name="employee"
                                                    checked={decipherFields.employee}
                                                    onChange={handleCheckboxChange}
                                                />
                                                Физ. лицо
                                            </label>

                                            <label className="d-flex align-items-center gap-2">
                                                <Checkbox
                                                    type="checkbox"
                                                    name="counterparty"
                                                    checked={decipherFields.counterparty}
                                                    onChange={handleCheckboxChange}
                                                />
                                                Контрагент
                                            </label>

                                            <label className="d-flex align-items-center gap-2">
                                                <Checkbox
                                                    type="checkbox"
                                                    name="nds_rate"
                                                    checked={decipherFields.nds_rate}
                                                    onChange={handleCheckboxChange}
                                                />
                                                Ставка НДС
                                            </label>

                                            <label
                                                className="d-flex align-items-center gap-2"
                                                style={{ maxWidth: 'fit-content' }}
                                            >
                                                <Checkbox
                                                    type="checkbox"
                                                    name="agreement"
                                                    checked={decipherFields.agreement}
                                                    onChange={handleCheckboxChange}
                                                />
                                                Договор
                                            </label>
                                        </div>

                                        <div className="deciphers">
                                            {payment.decipher?.map((param, index) => (
                                                <Card key={index} index={index} param={param} style={{ marginBottom: '10px' }}>
                                                    <div className="col-md-11 row">
                                                        {/* Сумма */}
                                                        <div className="col-md-4">
                                                            <label className="form-label">Сумма</label>
                                                            <Input
                                                                type="text"
                                                                name="sum"
                                                                placeholder="Введите сумму"
                                                                value={formatCurrency(param.sum)}
                                                                onChange={(e) =>
                                                                    handleSelectChange(index, {
                                                                        name: 'sum',
                                                                        value: parseCurrency(e.target.value),
                                                                    })
                                                                }
                                                            />
                                                        </div>

                                                        {/* Физ. лицо */}
                                                        {decipherFields.employee && (
                                                            <div className="col-md-4">
                                                                <label className="form-label">Физ. лицо</label>
                                                                <APISearchableSelect
                                                                    endpoint="/api/v1/bills/individual/search/"
                                                                    value={param?.employee}
                                                                    size=""
                                                                    onChange={(value) =>
                                                                        handleSelectChange(index, { name: 'employee', value })
                                                                    }
                                                                    placeholder="Выберите сотрудника"
                                                                />
                                                            </div>
                                                        )}

                                                        {/* Контрагент */}
                                                        {decipherFields.counterparty && (
                                                            <div className="col-md-4">
                                                                <label className="form-label">Контрагент</label>
                                                                <APISearchableSelect
                                                                    endpoint="/api/v1/bills/counterparty/"
                                                                    value={param?.counterparty}
                                                                    size=""
                                                                    onChange={(value) =>
                                                                        handleSelectChange(index, { name: 'counterparty', value })
                                                                    }
                                                                    placeholder="Выберите контрагента"
                                                                />
                                                            </div>
                                                        )}

                                                        {/* Ставка НДС */}
                                                        {decipherFields.nds_rate && (
                                                            <div className="col-md-4">
                                                                <label className="form-label">Ставка НДС</label>
                                                                <Input
                                                                    type="number"
                                                                    name="nds_rate"
                                                                    min="0"
                                                                    step="any"
                                                                    value={param.nds_rate || ''}
                                                                    onChange={(e) => handleSelectChange(index, e.target)}
                                                                />
                                                            </div>
                                                        )}

                                                        {/* Сумма НДС */}
                                                        {decipherFields.nds_rate && (
                                                            <div className="col-md-4">
                                                                <label className="form-label">Сумма НДС</label>
                                                                <Input
                                                                    type="text"
                                                                    name="commission_sum" // или "nds_sum", если нужно
                                                                    placeholder="Введите сумму"
                                                                    value={formatCurrency(param.nds_sum)}
                                                                    onChange={(e) => handleSelectChange(index, e.target)}
                                                                />
                                                            </div>
                                                        )}

                                                        {/* Статья (CaptionForm) */}
                                                        <CaptionForm
                                                            key={index}
                                                            captions={captions}
                                                            setCaptions={setCaptions}
                                                            param={param?.caption}
                                                            index={index}
                                                            handleSelectChange={handleSelectChange}
                                                            caption_types={caption_types}
                                                        />

                                                        {/* Проект */}
                                                        {/* <div className="col-md-4">
                                                            <label className="form-label">Проект</label>
                                                            <APISearchableSelect
                                                                endpoint="/api/v1/organization/projects/search/"
                                                                value={param?.project}
                                                                size=""
                                                                onChange={(value) =>
                                                                    handleSelectChange(index, { name: 'project', value })
                                                                }
                                                                placeholder="Выберите проект"
                                                            />
                                                        </div> */}
                                                        <ProjectForm  
                                                                param={param?.project || ''}
                                                                index={index}
                                                                handleSelectChange={handleSelectChange}
                                                            />

                                                        {/* Договор */}
                                                        {decipherFields.agreement && (
                                                            <div className="col-md-4">
                                                                <label className="form-label">Договор</label>
                                                                <APISearchableSelect
                                                                    endpoint="/api/v1/organization/agreements/search/"
                                                                    value={param?.agreement}
                                                                    size=""
                                                                    onChange={(value) =>
                                                                        handleSelectChange(index, { name: 'agreement', value })
                                                                    }
                                                                    placeholder="Выберите договор"
                                                                />
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="md-4">
                                                        <Button
                                                            bType="destructive"
                                                            size="medium"
                                                            fixed
                                                            style={{
                                                                marginTop: '10px',
                                                                marginRight: 'calc(var(--bs-gutter-x) * .5)',
                                                                marginLeft: 'calc(var(--bs-gutter-x) * .5)',
                                                            }}
                                                            onClick={() => handleRemoveDecipher(index)}
                                                        >
                                                            Удалить
                                                        </Button>
                                                    </div>
                                                </Card>
                                            ))}
                                        </div>

                                        <div className="d-flex mt-4">
                                            <Alert
                                                show={showAlert}
                                                variant="danger"
                                                onClose={() => setShowAlert(false)}
                                                dismissible
                                            >
                                                <p>
                                                    Общая сумма элементов превышает общую сумму платежа.
                                                    Пожалуйста, скорректируйте суммы.
                                                </p>
                                            </Alert>
                                        </div>

                                        <div className="mb-3 d-flex align-items-center">
                                            <Button bType="secondary" size="medium" fixed type="button" onClick={handleAddDecipher}>
                                                Добавить платеж
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <Button bType={payment.has_edit_perm ? "primary" : "secondary"} size="medium" type="submit" fixed disabled={!payment?.has_edit_perm}>
                                {!payment?.has_edit_perm ? 'Редактирование запрещено' : 'Сохранить'}
                            </Button>
                        </form>
                    )}
                </div>
            </div>
            <UnauthorizedModal 
            show={showUnauthorizedModal} 
            onClose={() => setShowUnauthorizedModal(false)} 
        />
        </div>
    );
};

export default PaymentEdit;
