import { createContext, useContext, useState } from "react";
import api from './api';
const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {



    const login = (userData) => {
        localStorage.setItem('access', userData.access);
        localStorage.setItem('refresh', userData.refresh);
    };

    const logout = () => {
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
    };    
    


    return (
        <AuthContext.Provider value={{ login, logout }}>
            {children}
        </AuthContext.Provider>
    );
}