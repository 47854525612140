import { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import api from "../../api";
import Button from "../ui/Button/Button";
import SearchableSelect from "../payments/SearchableSelectB";

export const ProjectForm = ({ param, index, handleSelectChange }) => {
  const [showModal, setShowModal] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const finmodel= JSON.parse(localStorage.getItem('selectedFinModel'))
  const finModelId = finmodel.id;
  const [newProject, setNewProject] = useState({
    name: '',
    full_name: "",
    description: '',
    company: "",
    fin_model: finModelId
  });
  const [selectedProject, setSelectedProject] = useState({
    value: param?.id,
    label: param?.name
  });
  const [loading, setLoading] = useState(false);
  const fetchCompanies = async () => {
    try {
        const response = await api.get("/api/v1/organization/");
        setCompanies(response.data);
    } catch (error) {}
  };
  const fetchBlocks = async () => {
    try {
        const response = await api.get(`/api/v1/finmodel/getBlockByFinModel/${finModelId}/`);
        setBlocks(response.data);
    } catch (error) {}
};
  useEffect(() => {
          fetchCompanies();
          fetchBlocks();
    }, []);
  const handleModalClose = () => {
    setShowModal(false);
    setNewProject({
      name: "",
      full_name: "",
      description: "",
      company: "",
      fin_model: finModelId
    });
  };

  const handleModalSave = async () => {
    try {
      const response = await api.post('/api/v1/organization/projects/create/', newProject);
      if (response.status === 201) {
        const newProjectData = response.data;
        const connection = companies.find(company => company.id === newProject.company)
        const connectionId = connection ? connection.connection : null
        const block = blocks.find(block => block.connection?.id === connectionId);
        const blockID = block ? block.id : null;
        const blockPayload = {
            name: newProject.name,
            parent: blockID,
            project: newProjectData.id,
            is_info_block: false,
            fin_model: finModelId
        };
        await api.post('/api/v1/finmodel/block/create/', blockPayload);
        // Create the new option object
        const newOption = {
          value: newProjectData.id,
          label: newProjectData.name
        };
        
        // Set the newly created project as selected
        setSelectedProject(newOption);
        handleSelectChange(index, { name: "project", value: newProjectData.id });
        
        handleModalClose();
      } else {
        console.error('Failed to create new Project', response);
      }
    } catch (error) {
      console.error('An error occurred while creating new Project', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewProject({
      ...newProject,
      [name]: value
    });
  };
  const handleCompanyChange = (value) => {
    setNewProject((prev) => ({ ...prev, company: value }));
  };

  const projectSearchApiCall = (inputValue) => {
    return api
      .get(`/api/v1/organization/projects/search/?search=${inputValue}`)
      .then((response) => {
        const options = response.data.map((project) => ({
          value: project.id,
          label: project.name,
        }));
    
        return [{ value: "create-new", label: "Создать новый проект..." }, ...options];
      })
      .catch((error) => {
        console.error("Error fetching search results:", error);
        return [];
      });
  };

  const handleSelectChangeInternal = (selectedOption) => {
    if (!selectedOption) {
      setSelectedProject(null);
      handleSelectChange(index, { name: "project", value: null });
      return;
    }
  
    if (selectedOption.value === "create-new") {
      setShowModal(true);
    } else {
      setSelectedProject(selectedOption);
      handleSelectChange(index, { name: "project", value: selectedOption.value });
    }
  };

  return (
    <>
      <div className="col-md-4">
        <label className="form-label">Проект</label>
        <AsyncSelect
          loadOptions={projectSearchApiCall}
          defaultOptions
          value={selectedProject}
          onChange={handleSelectChangeInternal}
          placeholder="Выберите проект"
          isClearable
          isLoading={loading}
          styles={{
            control: (provided) => ({
              ...provided,
              fontSize: '15.8px',
            }),
            option: (provided) => ({
              ...provided,
              color: 'black',
              fontSize: '15.8px',
            }),
            singleValue: (provided) => ({
              ...provided,
              color: 'black',
              fontSize: '15.8px',
            }),
            placeholder: (provided) => ({
              ...provided,
              color: 'black',
              fontSize: '15.8px',
            }),
            input: (provided) => ({
              ...provided,
              color: 'black',
              fontSize: '15.8px',
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              fontSize: '15.8px',
            }),
            menuList: (provided) => ({
              ...provided,
              maxHeight: '200px',
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#f1f1f1',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#888',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#555',
              },
            }),
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 6,
            colors: {
              ...theme.colors,
              neutral80: 'black',
              neutral90: 'black',
            },
          })}
        />
      </div>

      <Modal show={showModal} onHide={handleModalClose} dialogClassName={"modal-dialog-centered modal-dialog-scrollable"}>
        <Modal.Header closeButton>
          <Modal.Title>Создать новый проект</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formNewProject">
              <Form.Label>Название нового проекта</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={newProject.name}
                onChange={handleChange}
                placeholder="Введите название"
              />
            </Form.Group>
            <Form.Group controlId="formCaptionType">
              <Form.Label>Полное наименование проекта</Form.Label>
              <Form.Control
                type="text"
                name="full_name"
                value={newProject.full_name}
                onChange={handleChange}
                placeholder="Введите полное наименование"
              />
            </Form.Group>
            <Form.Group controlId="formParentCaption">
              <Form.Label>Организация</Form.Label>
              <SearchableSelect
                endpoint="/api/v1/organization/"
                value={newProject.company || ""}
                onChange={handleCompanyChange}
                placeholder="Выберите организацию"
                isClearable
                isMulti={false}
              />
            </Form.Group>
            <Form.Group controlId="formDescription">
              <Form.Label>Описание</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={newProject.description}
                placeholder="Введите описание"
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button bType="secondary" size="medium" fixed onClick={handleModalClose}>
            Отмена
          </Button>
          <Button bType="primary" size="medium" fixed onClick={handleModalSave}>
            Сохранить
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};