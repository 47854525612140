import api from "../api";

const useFetchData = (endpoint, setter, openModal, setErrorMessage) => {
    const fetchData = async () => {
        try {
            const response = await api.get(endpoint);
            setter(response.data)
        } catch (error) {
            console.error(`Ошибка при загрузке ${endpoint}`, error);
            if (error.response) {
                console.error("Ответ сервера:", error.response.status, error.response.data);

                if (error.response.status === 401) {
                    console.log("401 ошибка! Перезайдите, пожалуйста.");
                    openModal();
                } else if (error.response.status === 403) {
                    setErrorMessage("Доступ запрещен!");
                } else if (error.response.status === 400) {
                    setErrorMessage(error.response.data.detail || "Ошибка запроса.");
                } else if (error.response.status === 500) {
                    setErrorMessage("Ошибка сервера! Попробуйте позже.");
                } else {
                    setErrorMessage(`Неизвестная ошибка: ${error.response.status}`);
                }
            } else {
                console.error("Ошибка сети или сервера:", error);
                setErrorMessage("Ошибка соединения с сервером. Проверьте интернет.");
            }
        }
    };

    return fetchData;
};

export default useFetchData;