import React, { useState, useEffect, useRef, useCallback } from "react";
import api from "../../api";
import SideBar from "../SideBar";
import ReactLoading from "react-loading";
import { Modal } from "react-bootstrap";
import Heading from "../ui/Heading/Heading";
import Button from "../ui/Button/Button";
import Header from "../Header";
import PageHeading from "../ui/PageHeading/PageHeading";
import { Alert } from 'react-bootstrap';
import UnauthorizedModal from "../Unauthorized";

const ALL_COLUMNS = [
    "Номер счета учета БУ",
    "Наименование счета учета БУ",
    "Валютный",
    "Количественный",
    "Забалансовый",
    "Вид счета",
    "Архивный",
];
const DEFAULT_COLUMN_WIDTHS = {
    "Номер счета учета БУ": 240,
    "Наименование счета учета БУ": 280,
    "Валютный": 150,
    "Количественный": 200,
    "Забалансовый": 200,
    "Вид счета": 200,
    "Архивный": 200,
};

const AccountingAccounts = () => {

    const [errorMessage, setErrorMessage] = useState(""); 
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [accounts, setAccounts] = useState([]);
    const containerRef = useRef(null);
    const limit = 25;
    const [checkedAccountingAccounts, setCheckedAccountingAccounts] = useState([]);
    const [nameCheck, setNameCheck] = useState("");
    const [archivedFilterActive, setArchivedFilterActive] = useState(false);
    const [archivedFilterNotArchive, setArchivedFilterNotActive] = useState(false);
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [numFilter, SetNumFilter] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const initialFormData = {
        name: "",
        code: "",
        currency: false,
        quantitative: false,
        off_balance: false,
        type_of_account: null,
        comment: ""
    };
    
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});

    const [selectedColumns, setSelectedColumns] = useState(() => {
        const saved = localStorage.getItem("AccountingAccountsSelectedColumns");
        return saved ? JSON.parse(saved) : [...ALL_COLUMNS];
    });

    const [columnWidths, setColumnWidths] = useState(() => {
        const saved = localStorage.getItem("AccountingAccountsColumnWidths");
        return saved ? { ...DEFAULT_COLUMN_WIDTHS, ...JSON.parse(saved) } : { ...DEFAULT_COLUMN_WIDTHS };
    });

    useEffect(() => {
        localStorage.setItem(
            "AccountingAccountsSelectedColumns",
            JSON.stringify(selectedColumns)
        );
    }, [selectedColumns]);

    useEffect(() => {
        localStorage.setItem(
            "AccountingAccountsColumnWidths", 
            JSON.stringify(columnWidths)
        );
    }, [columnWidths]);

    const resizingInfoRef = useRef({
        isResizing: false,
        startX: 0,
        columnKey: null,
        initialWidth: 0,
    });

    const onMouseDown = (e, column) => {
        e.preventDefault();
        e.stopPropagation();
        resizingInfoRef.current.isResizing = true;
        resizingInfoRef.current.startX = e.clientX;
        resizingInfoRef.current.columnKey = column;
        resizingInfoRef.current.initialWidth =
            columnWidths[column] || DEFAULT_COLUMN_WIDTHS[column] || 100;
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };

    const onMouseMove = (e) => {
        if (!resizingInfoRef.current.isResizing) return;
        const { startX, columnKey, initialWidth } = resizingInfoRef.current;
        const delta = e.clientX - startX;
        const newWidth = Math.max(50, initialWidth + delta);
        setColumnWidths((prev) => ({
            ...prev,
            [columnKey]: newWidth,
        }));
    };

    const onMouseUp = () => {
        resizingInfoRef.current.isResizing = false;
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
    };

    const handleCheckboxChange = (ids) => {
        if (Array.isArray(ids)) {
            setCheckedAccountingAccounts(ids);
        } else {
            setCheckedAccountingAccounts(prev => {
                if (prev.includes(ids)) {
                    return prev.filter(id => id !== ids);
                }
                return [...prev, ids];
            });
        }
    };

    const fetchAccounts = async (offset = 0) => {
        setLoading(true);
        try {
            let response;
            
            if (numFilter || nameCheck || archivedFilterActive || archivedFilterNotArchive) {
                response = await api.post(`api/v1/bills/accounting-accounts/filter/?limit=${limit}&offset=${offset}`, {
                    code: numFilter?.trim() || undefined,
                    name: nameCheck?.toLowerCase() || undefined,
                    is_active: [!archivedFilterNotArchive, archivedFilterActive],
                });
            } else {
                response = await api.get(`api/v1/bills/accounting-accounts/?limit=${limit}&offset=${offset}`);
            }
    
            const newAccounts = response.data.results;
            setAccounts((prev) => (offset === 0 ? newAccounts : [...prev, ...newAccounts]));
            setHasMore(response.data.next !== null);
        } catch (error) {
            console.error("Ошибка при загрузке счетов:", error);
    
            if (error.response) {
                const { status, data } = error.response;
    
                if (status === 401) {
                    console.log("Ошибка 401: Требуется повторный вход.");
                    setShowUnauthorizedModal(true); // Показываем модальное окно для входа
                } else if (status === 403) {
                    setErrorMessage("Доступ запрещен! У вас недостаточно прав на просмотр счетов учета БУ");
                } else if (status === 400) {
                    setErrorMessage(data.detail || "Некорректные данные. Проверьте фильтры.");
                } else if (status === 500) {
                    setErrorMessage("Ошибка сервера! Попробуйте позже.");
                } else {
                    setErrorMessage(`Неизвестная ошибка: ${status}`);
                }
            } else {
                setErrorMessage("Ошибка соединения с сервером. Проверьте интернет.");
            }
    
            setAccounts([]); // Очищаем список при ошибке
        } finally {
            setLoading(false);
        }
    };
    
    
    // Загрузка данных при изменении `offset`
    useEffect(() => {
        if (hasMore) {
            fetchAccounts(offset);
        }
    }, [offset, hasMore]);
    

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
    
        const errors = {};
        if (!formData.name) {
            setErrorMessage('Некорректные данные. Проверьте заполненные поля.');
            errors.name = "Поле обязательно для заполнения";
        }
        if (!formData.code) { 
            errors.code = "Поле обязательно для заполнения";
            setErrorMessage('Некорректные данные. Проверьте заполненные поля.');
        }
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }
    
        setLoading(true);
        setErrorMessage(null); // Очистка предыдущих ошибок перед запросом
    
        try {
            let response;
            
            if (isEditing) {
                response = await api.put(
                    `/api/v1/bills/accounting-accounts/${formData.id}/update/`,
                    formData,
                    { headers: { 'Content-Type': 'application/json' } }
                );
            } else {
                response = await api.post(
                    `/api/v1/bills/accounting-accounts/create/`,
                    formData,
                    { headers: { 'Content-Type': 'application/json' } }
                );
            }
    
            console.log('Response:', response.data);
    
            handleCloseModal();
            setAccounts([]);
            setOffset(0);
            setHasMore(true);
            fetchAccounts(); 
    
        } catch (error) {
            if (error.response) {
                const { status, data } = error.response;
                console.error('Ошибка API:', error.response);
    
                if (status === 401) {
                    setErrorMessage("Ошибка авторизации! Пожалуйста, войдите заново.");
                    setShowUnauthorizedModal(true); // Показываем модалку для повторного входа
                } else if (status === 403) {
                    setErrorMessage("Доступ запрещен! У вас недостаточно прав для создания платежа.");
                } else if (status === 400) {
                    setErrorMessage(`${data.detail || 'Некорректные данные. Проверьте заполненные поля.'}`);
                } else if (status === 500) {
                    setErrorMessage("Ошибка сервера! Попробуйте позже.");
                } else {
                    setErrorMessage(`Неизвестная ошибка: ${status}`);
                }
            } else {
                console.error('Ошибка сети или сервера:', error);
                setErrorMessage("Ошибка соединения с сервером. Проверьте интернет.");
            }
        } finally {
            setLoading(false);
        }
    }, [formData, isEditing]);
                 
    const handleCloseModal = () => {
        setFormData(initialFormData); // Сбрасываем данные формы
        setIsEditing(false); // Выключаем режим редактирования
        setErrors({}); // Очищаем ошибки
        setShowModal(false); // Закрываем модальное окно
    };

    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
                setOffset((prev) => prev + limit);
            }
        }
    }, [hasMore, loading]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener("scroll", handleScroll);
        }
    }, [handleScroll]);

    const handleSearch = () => {
        console.log("Фильтр по имени счета:", 
            nameCheck, "Фильтр по номеру счета:", numFilter);
        fetchAccounts(0).then(() => {
            // Данные загрузились, их можно отобразить
            setOffset(0);
            setHasMore(true);
        });
    };
    
    // Update handleReset:
    const handleReset = () => {
        SetNumFilter("");
        setNameCheck(""); // Change null to empty string for consistency
        setArchivedFilterActive(false);
        setArchivedFilterNotActive(false);
        setOffset(0);
        setHasMore(true);
        fetchAccounts(0);
    };

    const allChecked = accounts.length > 0 && accounts.every((acc) => checkedAccountingAccounts.includes(acc.id));

    const handleRowClick = (o) => {
        if (!o) return;
        setIsEditing(true);
        setFormData({
            id: o.id,
            name: o.name,
            code: o.code,
            origin: o.origin || "" 
        });
        setShowModal(true);
    };
    
    
    const handleArchiving = async (isActive) => {
        if (checkedAccountingAccounts.length === 0) return;
        setLoading(true);
        try {
            await api.patch("api/v1/bills/accounting-accounts/archive/", {
                accounts: checkedAccountingAccounts,
                activity: isActive,
            });
            setCheckedAccountingAccounts([]);
            setAccounts([]);
            setOffset(0);
            setHasMore(true);
            fetchAccounts();
        } catch (error) {
            console.error("Error archiving accounting accounts", error);
        } finally {
            setLoading(false);
        }
    };
    
    const handleArchiveAccounts = (e) => {
        e && e.preventDefault();
        handleArchiving(false);
    };
    
    const handleUnarchiveAccounts = (e) => {
        e && e.preventDefault();
        handleArchiving(true);
    };

    const [showSettings, setShowSettings] = useState(false);

    const handleSelectAllChange = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            const allIds = accounts.map((acc) => acc.id);
            setCheckedAccountingAccounts(allIds);
        } else {
            setCheckedAccountingAccounts([]);
        }
    };
    const toggleColumn = (column) => {
        setSelectedColumns((prev) =>
            prev.includes(column) ? prev.filter((c) => c !== column) : [...prev, column]
        );
    };


    const cancelSettings = () => {
        setShowSettings(false);
    };


    const AccountingAccountsTable = ({
        accounts,
        loading,
        onRowClick,
        checkedAccounts,
        onCheckboxChange,
        selectedColumns,
        columnWidths,
        onColumnResize
    }) => {
        const renderCell = (account, column) => {
        if (!account) return "";
        
        switch (column) {
            case "Номер счета учета БУ":
            return account.code || "";
            case "Наименование счета учета БУ":
            return account.name || "";
            case "Валютный":
            return account.currency ? "Да" : "Нет";
            case "Количественный":
            return account.quantitative ? "Да" : "Нет";
            case "Забалансовый":
            return account.off_balance ? "Да" : "Нет";
            case "Вид счета":
            return account.type_of_account?.name || "";
            default:
            return "";
        }
        };
        return (    
            <div className="w-full overflow-x-auto">
            <table className="w-full border-collapse">
                <thead>
                <tr>
                    <th className="p-2 border text-left w-12">
                    <input
                        type="checkbox"
                        checked={accounts.length > 0 && accounts.every(acc => checkedAccounts.includes(acc.id))}
                        onChange={(e) => {
                        const isChecked = e.target.checked;
                        const allIds = accounts.map(acc => acc.id);
                        onCheckboxChange(isChecked ? allIds : []);
                        }}
                        className="rounded border-gray-300"
                    />
                    </th>
                    {selectedColumns.map((column) => (
                    <th
                        key={column}
                        className="p-2 border text-left relative"
                        style={{ width: columnWidths[column] }}
                    >
                        <div className="flex justify-between items-center">
                        <span>{column}</span>
                        <div
                            className="cursor-col-resize px-1 select-none"
                            onMouseDown={(e) => onColumnResize(e, column)}
                        >
                            ||
                        </div>
                        </div>
                    </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {accounts.map((account) => (
                    <tr
                    key={account.id}
                    onClick={() => onRowClick(account)}
                    className="hover:bg-gray-50 cursor-pointer"
                    >
                    <td className="p-2 border">
                        <input
                        type="checkbox"
                        checked={checkedAccounts.includes(account.id)}
                        onChange={(e) => {
                            e.stopPropagation();
                            onCheckboxChange(
                            e.target.checked
                                ? [...checkedAccounts, account.id]
                                : checkedAccounts.filter(id => id !== account.id)
                            );
                        }}
                        className="rounded border-gray-300"
                        />
                    </td>
                    {selectedColumns.map((column) => (
                        <td
                        key={column}
                        className="p-2 border"
                        style={{
                            width: columnWidths[column],
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                        }}
                        >
                        {renderCell(account, column)}
                        </td>
                    ))}
                    </tr>
                ))}
                </tbody>
            </table>
            {loading && (
                <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
                </div>
            )}
            </div>
        );
        };

        return (
            <div className="container-fluid" translate="no">
                <Header />
                <div className="row flex-nowrap">
                    <SideBar current="/accounting/accounts" />
                    <div className="col py-3">
                        <div className="accounting-accounts container-fluid">
                            <div className="row">
                                <div className="col-md">
                                    <div className="d-flex justify-content-between mb-3">
                                        <div className="d-flex gap-2">
                                            <PageHeading>Счета учета БУ</PageHeading>
                                            <Button
                                                bType="secondary"
                                                size="small"
                                                fixed
                                                onClick={() => setFiltersVisible(!filtersVisible)}
                                            >
                                                <i className="bi bi-funnel"></i>
                                            </Button>
                                            <Button bType="primary" size="small" fixed onClick={() => setShowModal(true)}>
                                                <i className="bi bi-plus-circle"></i>
                                            </Button>
                                        </div>
                                        <Button
                                            bType="secondary"
                                            size="small"
                                            fixed
                                            onClick={() => setShowSettings(true)}
                                        >
                                            <i className="bi bi-gear"></i>
                                        </Button>
                                    </div>
                                    {errorMessage && (
                                        <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                                            {errorMessage}
                                        </Alert>
                                    )}
                                    {filtersVisible && (
                                        <div className="mb-3 p-3 border rounded">
                                            <Heading level={6} style={{ marginBottom: "10px" }}>
                                                Фильтры:
                                            </Heading>
                                            <div className="row g-3">
                                                <div className="col-md-3">
                                                    <label className="form-label">Номер счета</label>
                                                    <input
                                                        type="text"
                                                        placeholder="Введите номер счета"
                                                        value={numFilter}
                                                        onChange={(e) => {
                                                            let value = e.target.value;
                                                            if (value.length > 5) {
                                                                value = value.slice(0, 5);
                                                            }
                                                            SetNumFilter(value);
                                                        }}
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <label className="form-label">Наименование счета</label>
                                                    <input
                                                        type="text"
                                                        placeholder="Введите наименование счета"
                                                        value={nameCheck}
                                                        onChange={(e) => setNameCheck(e.target.value)}
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <label>Архивный</label>
                                                    <div className="form-check">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="archivedCheckNotActive"
                                                            checked={archivedFilterNotArchive}
                                                            onChange={(e) => setArchivedFilterNotActive(e.target.checked)}
                                                        />
                                                        <label className="form-check-label" htmlFor="archivedCheckNotActive">
                                                            Да
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="archivedCheckActive"
                                                            checked={archivedFilterActive}
                                                            onChange={(e) => setArchivedFilterActive(e.target.checked)}
                                                        />
                                                        <label className="form-check-label" htmlFor="archivedCheckActive">
                                                            Нет
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center gap-3 mt-3">
                                                <Button onClick={handleSearch} bType="primary" size="medium" fixed>
                                                    Поиск
                                                </Button>
                                                <Button onClick={handleReset} bType="secondary" size="medium" fixed>
                                                    Сбросить
                                                </Button>
                                                <UnauthorizedModal 
                                                    show={showUnauthorizedModal} 
                                                    onClose={() => setShowUnauthorizedModal(false)} 
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                    {checkedAccountingAccounts.length > 0 && (
                                        <div className="dropdown">
                                            <Button
                                                bType="secondary"
                                                size="medium"
                                                fixed
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{ marginRight: "10px" }}
                                            >
                                                Действие <i className="bi bi-caret-down-fill"></i>
                                            </Button>
                                            <ul className="dropdown-menu" style={{ minWidth: "160px" }}>
                                                <li>
                                                    <span className="dropdown-item" onClick={handleArchiveAccounts}>
                                                        Архивировать счета
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className="dropdown-item" onClick={handleUnarchiveAccounts}>
                                                        Разархивировать счета
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                    </div>
                                    <div 
                                        ref={containerRef} 
                                        className="Payments__wrapper"
                                        style={{ height: "70vh", minHeight: "70vh", overflowY: "scroll", position: "relative" }}
                                    >
                                        <table
                                            className="Payments__table"
                                            style={{
                                                tableLayout: "fixed"
                                            }}
                                        >
                                            <thead>
                                            <tr>
                                                <th style={{ width: 50 }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={allChecked}
                                                        onChange={handleSelectAllChange}
                                                    />
                                                </th>
                                                {selectedColumns.includes("Номер счета учета БУ") && (
                                                    <th
                                                        style={{
                                                            width: columnWidths["Номер счета учета БУ"] || DEFAULT_COLUMN_WIDTHS["Номер счета учета БУ"],
                                                            position: "relative",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <span>Номер счета учета БУ</span>
                                                            <div
                                                                style={{
                                                                    cursor: "col-resize",
                                                                    padding: "0 4px",
                                                                    marginRight: "-4px",
                                                                    userSelect: "none",
                                                                    zIndex: 999
                                                                }}
                                                                onMouseDown={(e) => onMouseDown(e, "Номер счета учета БУ")}
                                                            >
                                                                ||
                                                            </div>
                                                        </div>
                                                    </th>
                                                )}
                                                {selectedColumns.includes("Наименование счета учета БУ") && (
                                                    <th
                                                        style={{
                                                            width: columnWidths["Наименование счета учета БУ"] || DEFAULT_COLUMN_WIDTHS["Наименование счета учета БУ"],
                                                            position: "relative",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <span>Наименование счета учета БУ</span>
                                                            <div
                                                                style={{
                                                                    cursor: "col-resize",
                                                                    padding: "0 4px",
                                                                    marginRight: "-4px",
                                                                    userSelect: "none",
                                                                    zIndex: 999
                                                                }}
                                                                onMouseDown={(e) => onMouseDown(e, "Наименование счета учета БУ")}
                                                            >
                                                                ||
                                                            </div>
                                                        </div>
                                                    </th>
                                                )}
                                                {selectedColumns.includes("Валютный") && (
                                                    <th
                                                        style={{
                                                            width: columnWidths["Валютный"] || DEFAULT_COLUMN_WIDTHS["Валютный"],
                                                            position: "relative",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <span>Валютный</span>
                                                            <div
                                                                style={{
                                                                    cursor: "col-resize",
                                                                    padding: "0 4px",
                                                                    marginRight: "-4px",
                                                                    userSelect: "none",
                                                                    zIndex: 999
                                                                }}
                                                                onMouseDown={(e) => onMouseDown(e, "Валютный")}
                                                            >
                                                                ||
                                                            </div>
                                                        </div>
                                                    </th>
                                                )}
                                                {selectedColumns.includes("Количественный") && (
                                                    <th
                                                        style={{
                                                            width: columnWidths["Количественный"] || DEFAULT_COLUMN_WIDTHS["Количественный"],
                                                            position: "relative",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <span>Количественный</span>
                                                            <div
                                                                style={{
                                                                    cursor: "col-resize",
                                                                    padding: "0 4px",
                                                                    marginRight: "-4px",
                                                                    userSelect: "none",
                                                                    zIndex: 999
                                                                }}
                                                                onMouseDown={(e) => onMouseDown(e, "Количественный")}
                                                            >
                                                                ||
                                                            </div>
                                                        </div>
                                                    </th>
                                                )}
                                                {selectedColumns.includes("Забалансовый") && (
                                                    <th
                                                        style={{
                                                            width: columnWidths["Забалансовый"] || DEFAULT_COLUMN_WIDTHS["Забалансовый"],
                                                            position: "relative",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <span>Забалансовый</span>
                                                            <div
                                                                style={{
                                                                    cursor: "col-resize",
                                                                    padding: "0 4px",
                                                                    marginRight: "-4px",
                                                                    userSelect: "none",
                                                                    zIndex: 999
                                                                }}
                                                                onMouseDown={(e) => onMouseDown(e, "Забалансовый")}
                                                            >
                                                                ||
                                                            </div>
                                                        </div>
                                                    </th>
                                                )}
                                                {selectedColumns.includes("Вид счета") && (
                                                    <th
                                                        style={{
                                                            width: columnWidths["Вид счета"] || DEFAULT_COLUMN_WIDTHS["Вид счета"],
                                                            position: "relative",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <span>Вид счета</span>
                                                            <div
                                                                style={{
                                                                    cursor: "col-resize",
                                                                    padding: "0 4px",
                                                                    marginRight: "-4px",
                                                                    userSelect: "none",
                                                                    zIndex: 999
                                                                }}
                                                                onMouseDown={(e) => onMouseDown(e, "Вид счета")}
                                                            >
                                                                ||
                                                            </div>
                                                        </div>
                                                    </th>
                                                )}
                                                {selectedColumns.includes("Архивный") && (
                                                    <th
                                                        style={{
                                                            width: columnWidths["Архивный"] || DEFAULT_COLUMN_WIDTHS["Архивный"],
                                                            position: "relative",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <span>Архивный</span>
                                                            <div
                                                                style={{
                                                                    cursor: "col-resize",
                                                                    padding: "0 4px",
                                                                    marginRight: "-4px",
                                                                    userSelect: "none",
                                                                    zIndex: 999
                                                                }}
                                                                onMouseDown={(e) => onMouseDown(e, "Архивный")}
                                                            >
                                                                ||
                                                            </div>
                                                        </div>
                                                    </th>
                                                )}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {accounts.map((account) => (
                                                <tr key={account.id} onClick={() => handleRowClick(account)}>
                                                    <td style={{ width: 50 }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={checkedAccountingAccounts.includes(account.id)}
                                                            onChange={(e) => {
                                                                e.stopPropagation();
                                                                handleCheckboxChange(account.id);
                                                            }}
                                                        />
                                                    </td>
                                                    {selectedColumns.includes("Номер счета учета БУ") && (
                                                        <td
                                                            style={{
                                                                width: columnWidths["Номер счета учета БУ"] || DEFAULT_COLUMN_WIDTHS["Номер счета учета БУ"],
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                whiteSpace: "nowrap",
                                                            }}
                                                        >
                                                            {account.code || ""}
                                                        </td>
                                                    )}
                                                    {selectedColumns.includes("Наименование счета учета БУ") && (
                                                        <td
                                                            style={{
                                                                width: columnWidths["Наименование счета учета БУ"] || DEFAULT_COLUMN_WIDTHS["Наименование счета учета БУ"],
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                whiteSpace: "normal",
                                                                wordWrap: "break-word",
                                                            }}
                                                        >
                                                            {account.name || ""}
                                                        </td>
                                                    )}
                                                    {selectedColumns.includes("Валютный") && (
                                                        <td
                                                            style={{
                                                                width: columnWidths["Валютный"] || DEFAULT_COLUMN_WIDTHS["Валютный"],
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                            }}
                                                        >
                                                            {account.currency ? "Да" : "Нет"}
                                                        </td>
                                                    )}
                                                    {selectedColumns.includes("Количественный") && (
                                                        <td
                                                            style={{
                                                                width: columnWidths["Количественный"] || DEFAULT_COLUMN_WIDTHS["Количественный"],
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                            }}
                                                        >
                                                            {account.quantitative ? "Да" : "Нет"}
                                                        </td>
                                                    )}
                                                    {selectedColumns.includes("Забалансовый") && (
                                                        <td
                                                            style={{
                                                                width: columnWidths["Забалансовый"] || DEFAULT_COLUMN_WIDTHS["Забалансовый"],
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                            }}
                                                        >
                                                            {account.off_balance ? "Да" : "Нет"}
                                                        </td>
                                                    )}
                                                    {selectedColumns.includes("Вид счета") && (
                                                        <td
                                                            style={{
                                                                width: columnWidths["Вид счета"] || DEFAULT_COLUMN_WIDTHS["Вид счета"],
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                whiteSpace: "nowrap",
                                                            }}
                                                        >
                                                            {account.type_of_account?.name || ""}
                                                        </td>
                                                    )}
                                                    {selectedColumns.includes("Архивный") && (
                                                        <td
                                                            style={{
                                                                width: columnWidths["Архивный"] || DEFAULT_COLUMN_WIDTHS["Архивный"],
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                            }}
                                                        >
                                                            {account.is_active ? "Нет" : "Да"}
                                                        </td>
                                                    )}
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                        {loading && (
                                            <div className="position-absolute top-50 start-50 translate-middle">
                                                <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                            </div>
                                        )}
                                                <UnauthorizedModal 
                                                    show={showUnauthorizedModal} 
                                                    onClose={() => setShowUnauthorizedModal(false)} 
                                                />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        {showSettings && (
            <Modal show={showSettings} onHide={cancelSettings} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Настройка столбцов</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row" style={{ gap: "10px", justifyContent: "space-evenly" }}>
                            {ALL_COLUMNS.map((column) => (
                                <div
                                    key={column}
                                    className={`col-4 p-2 d-flex align-items-center justify-content-start ${
                                        selectedColumns.includes(column) ? "selected-column" : ""
                                    }`}
                                    onClick={() => toggleColumn(column)}
                                    style={{
                                        cursor: "pointer",
                                        border: selectedColumns.includes(column)
                                            ? "2px solid #007BFF"
                                            : "1px solid #ddd",
                                        padding: "8px",
                                        transition: "0.3s",
                                        borderRadius: "5px",
                                        width: "30%",
                                    }}
                                >
                                    <input
                                        type="checkbox"
                                        checked={selectedColumns.includes(column)}
                                        readOnly
                                        className="me-2"
                                    />
                                    <span className={selectedColumns.includes(column) ? "text-primary" : ""}>
                                        {column}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )}
        <Modal show={showModal} onHide={handleCloseModal} centered>
    <Modal.Header closeButton>
        <Modal.Title>{isEditing ? "Редактирование счета" : "Создание счета"}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        {/* ✅ Показываем алерт только для ошибок */}
        {errorMessage && (
            <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                {errorMessage}
            </Alert>
        )}

        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label className="form-label">Номер счета*</label>
                <input
                    type="text"
                    className="form-control"
                    name="code"
                    value={formData.code}
                    onChange={(e) => {
                        let value = e.target.value;
                        if (value.length > 5) {
                            value = value.slice(0, 5);
                        }
                        setFormData({ ...formData, code: value });
                    }}
                    placeholder="Введите номер счета (до 5 символов)"
                    disabled={formData.origin !== "webupr" && isEditing}
                />
                {errors.code && <div className="text-danger">{errors.code}</div>}
            </div>
            <div className="mb-3">
                <label className="form-label">Наименование счета*</label>
                <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    placeholder="Введите наименование счета"
                    disabled={formData.origin !== "webupr" && isEditing}
                />
                {errors.name && (
                    <div className="invalid-feedback" style={{ display: "block" }}>
                        {errors.name}
                    </div>
                )}
            </div>
            {isEditing && formData.origin && formData.origin !== "webupr" && (
                <p style={{ color: "red" }}>
                    Данный счет запрещено редактировать, т.к. это типовой параметр.
                </p>
            )}
        </form>
    </Modal.Body>
    <Modal.Footer>
        <Button bType="secondary" size="medium" fixed onClick={handleCloseModal}>
            Закрыть
        </Button>
        {(formData.origin === "webupr" || !isEditing) && (
            <Button bType="primary" size="medium" fixed onClick={handleSubmit}>
                {isEditing ? "Сохранить" : "Создать"}
            </Button>
        )}
    </Modal.Footer>
</Modal>
        <UnauthorizedModal 
            show={showUnauthorizedModal} 
            onClose={() => setShowUnauthorizedModal(false)} 
        />
    </div>
    );
};


export default AccountingAccounts;
