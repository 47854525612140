import React, { useState, useMemo, useEffect } from 'react';
import ReactLoading from 'react-loading';
import Header from "../Header";
import api from '../../api';
import SideBar from '../SideBar';
import APIMultipleSearchableSelect from "../payments/SearchableTableMultiSelect";
import SlidingPanel from './SlideOutPanel';
import APISearchableSelect from '../payments/SearchableSelect';
import { Modal, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { CaptionForm } from "../captions/CaptionForm";
import useFetchData from "../../functions/UprData";
import ConfirmModal from './ConfirmModal';
import formatCurrency from '../../functions/FormatNum';
import PageHeading from "../ui/PageHeading/PageHeading";
import Input from "../ui/Input/Input";
import Button from "../ui/Button/Button";
import "../../styles/budget.css"

const TableRow = ({ item, depth = 0, onToggle, isEditing, months, openCaption, typeName }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [captions, setCaptions] = useState([]);
    const [caption_types, setCaptionTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isExpanded, setIsExpanded] = useState(false);
    
    const hasChildren = item.children_caption && item.children_caption.length > 0;
    const fetchCaption = useFetchData('/api/v1/bills/caption/', setCaptions);
    const fetchCaptionTypes = useFetchData('/api/v1/bills/caption-types/', setCaptionTypes);
    
    const hideModal = () => {
      setIsOpen(false);
      setSelectedItem(null);
    };
    
    const showModal = () => {
      setIsOpen(true);
      setSelectedItem(item);
    };
  
    const fetchData = async () => {
      setLoading(true);
      try {
        await Promise.all([
          fetchCaption(),
          fetchCaptionTypes(),
        ]);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      fetchData();
    }, []);
  
    const handleSelectChange = () => {
      // Handle select change logic here
    };  
    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
      if (onToggle) onToggle();
    };
  
    return (
      <>
        {depth === 0 && typeName && (
                <tr className="bg-light fw-bold">
                    <td className="fw-bold">
                        {typeName.name}
                    </td>
                    <td>{typeName.total.plan}</td>
                    <td>{typeName.total.fact}</td>
                    <td>{typeName.total.deviation}</td>
                </tr>
            )}
        <tr className={depth === 0 ? 'fw-bold' : ''}>
          <td>
            <div className="d-flex align-items-center">
              <span style={{ marginLeft: `${depth * 20}px` }}></span>
              {hasChildren && (
                <button onClick={toggleExpand} className="btn btn-sm btn-link p-0 me-2">
                  <i className={`bi ${isExpanded ? 'bi-chevron-down' : 'bi-chevron-right'}`}></i>
                </button>
              )}
              <p onClick={() => openCaption(item.caption_id)}>{item.caption_name}</p>
              {isEditing && (
                <>
                  <button onClick={showModal} className="btn btn-sm btn-link p-0 me-2">
                    <i className='bi bi-plus-circle ms-2'></i>
                  </button>
                  <i className='bi bi-pencil ms-2'></i>
                  <i className='bi bi-trash3 ms-2'></i>
                </>
              )}
            </div>
          </td>
          <td className="text-end">{item.total_with_children.plan.toLocaleString()}</td>
          <td className="text-end">{item.total_with_children.fact.toLocaleString()}</td>
          <td className="text-end">{item.total_with_children.deviation.toLocaleString()}</td>
          {months.map((month, index) => (
            <React.Fragment key={month}>
              <td className="text-end">{item.months[index]?.[month]?.plan.toLocaleString() || '-'}</td>
              <td className="text-end">{item.months[index]?.[month]?.fact.toLocaleString() || '-'}</td>
              <td className="text-end">{item.months[index]?.[month]?.deviation.toLocaleString() || '-'}</td>
            </React.Fragment>
          ))}
        </tr>
        {isExpanded && item.children_caption && item.children_caption.map(child => (
          <TableRow 
            key={child.caption_id} 
            item={child} 
            depth={depth + 1} 
            onToggle={onToggle} 
            months={months} 
            isEditing={isEditing}
            openCaption={openCaption}
          />
        ))}
        <Modal 
          show={isOpen} 
          onHide={hideModal} 
          dialogClassName="modal-lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Добавление статьи {selectedItem && `в "${selectedItem.caption_name}"`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <CaptionForm 
              captions={captions} 
              setCaptions={setCaptions}
              handleSelectChange={handleSelectChange}
              caption_types={caption_types}
              parentCaption={selectedItem?.caption_id}
              className="w-100"
            />
          </Modal.Body>
          <Modal.Footer className='justify-content-between px-4'>
            <Button variant="secondary" onClick={hideModal}>
              Отмена
            </Button>
            <Button variant="primary">
              Сохранить
            </Button>
          </Modal.Footer>
        </Modal>
        {loading && 
          <div className="position-absolute top-50 start-50 translate-middle">
              <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
          </div>}
      </>
    );
  };
  

const BudgetPlanPage = () => {
    const [loading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [projects, setProjects] = useState([]); // Selected project
    const [dateFrom, setDateFrom] = useState(""); // Start date
    const [dateTo, setDateTo] = useState(""); // End date
    const [data, setData] = useState([]); // Table data
    const [isOpen, setIsOpen] = useState(false);
    const [captionPlan, setCaptionPlan] = useState([]);
    const [budgets, setBudgets] = useState([]);
    const [confirmModal, setConfirmModal] = useState(false);
    const [isOpening, setIsOpening] = useState(false);
    const [isEmployeeSalaryView, setIsEmployeeSalaryView] = useState(false);
    const months = useMemo(() => {
      const monthMap = new Map();
  
      const extractMonths = (item) => {
          if (Array.isArray(item.months)) {
              item.months.forEach((monthObj) => {
                  const period = monthObj.period; // Extract the period (date)
                  Object.keys(monthObj).forEach((key) => {
                      if (key !== "period") {
                          monthMap.set(key, period); // Map the month name to its period
                      }
                  });
              });
          }
          if (Array.isArray(item.children_caption) && item.children_caption.length > 0) {
              item.children_caption.forEach(extractMonths);
          }
      };
  
      data.forEach((group) => {
          group.captions.forEach(extractMonths);
      });
  
      // Sort the months by their periods (chronological order)
      return Array.from(monthMap.entries())
          .sort(([, periodA], [, periodB]) => new Date(periodA) - new Date(periodB))
          .map(([month]) => month); // Return only the sorted month names
  }, [data]);
  
  const renderTooltip = (props) => {
    if (budgets.length === 1) {
      return <></>;
    }
    let message =
      budgets.length > 1
        ? "Вы не можете редактировать несколько бюджетов."
        : "Нужно загрузить бюджет для редактирования.";
    return <Tooltip {...props}>{message}</Tooltip>
  };

    const handleEditClick = () => {
      if (projects.length !== 1) {
        return; // Tooltip will show automatically due to OverlayTrigger
      }
      if (projects.length === 1) {
        setIsEditing(!isEditing);
      }
    };

    const closePanel = () => {
      setIsOpen(false);
    }

    const openPanel = async (caption, isEmployees) => {
      console.log('openpanel', caption, isEmployees)
      try {
        setLoading(true);
        const response = await api.post(`/api/v1/budgets/budget-plan/`, {
          projects: projects,
          period_start: dateFrom,
          period_end: dateTo,
          caption: caption,
        });
        if (response.status === 200) {
          const updatedData = response.data.map((item) => ({
            ...item,
            monthlyplan: item.monthlyplan.map((month) => ({
              ...month,
              isEditing: false, // Add isEditing field here
            })),
            is_employees: isEmployees,
          }));
          console.log('setCaptionPlan', updatedData)
          setCaptionPlan(updatedData); // Set transformed data to state
          setIsOpen(true);
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false);
      }
    }


    const handleSearch = async () => {
      if (projects.length === 0 || !dateFrom || !dateTo) {
        alert("Please fill in all the fields before searching.");
        return;
      }
  
      setLoading(true);
  
      try {
        const response = await api.post("/api/v1/budgets/budget_report/", {
          projects,
          period_start: dateFrom,
          period_end: dateTo,
        });
        setData(response.data[0]?.data); // Update table data with API response
        console.log('data', response.data[0]?.data[0]?.captions)
        setBudgets(response.data[0]?.budget_ids)
      } catch (error) {
        console.error("Error fetching budget data:", error);
        alert("Failed to fetch data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    const handleAddRow = () => {
      const newRow = {
        id: Date.now(), // Unique ID for the new row
        caption: captionPlan[0]?.caption,
        budget: captionPlan[0]?.budget,
        project: captionPlan[0]?.project,
        period_start: captionPlan[0]?.period_start,
        period_end: captionPlan[0]?.period_end,
        company: captionPlan[0]?.company,
        counterparty: null,
        individual: null,
        is_salary: captionPlan[0]?.is_salary,
        total: 0,
        monthlyplan: captionPlan[0]?.monthlyplan.map((month) => ({
          ...month,
          plan_sum: 0,
          fact_sum: 0,
        })) || [],
        isNew: true,
      };
      setCaptionPlan((prev) => [...prev, newRow]);
    };

    const handleChange = (rowId, { name, value }) => {
      setCaptionPlan((prev) =>
        prev.map((item) =>
          item.id === rowId ? { ...item, [name]: value } : item
        )
      );
    };

    const handleSaveAllRows = async () => {
        const newRows = captionPlan.filter((item) => item.isNew);
    
        const getIdOrValue = (obj) => obj?.id || obj || '';

        const isDuplicate = (row) => {
          return captionPlan.some((existingRow) => {
            if (existingRow.isNew) return false; // Ignore new rows
        
            const existingCounterparty = getIdOrValue(existingRow.counterparty) || null;
            const existingIndividual = getIdOrValue(existingRow.individual) || null;
            const newCounterparty = getIdOrValue(row.counterparty) || null;
            const newIndividual = getIdOrValue(row.individual) || null;
        
            // If both counterparty and individual are null, allow duplicates
            if (!newCounterparty && !newIndividual) {
              return false;
            }
        
            // Ensure uniqueness based only on counterparty and individual
            return existingCounterparty === newCounterparty && existingIndividual === newIndividual;
          });
        };

        const uniqueNewRows = newRows.filter((row) => !isDuplicate(row));


        if (uniqueNewRows.length === 0) {
            alert("Такая строка уже существуют.");
            return;
        }

        
        const preparedRows = newRows.map((row) => ({
            caption: getIdOrValue(row.caption), // Send only the ID
            budget: getIdOrValue(row.budget),
            project: getIdOrValue(row.project),
            company: getIdOrValue(row.company),
            counterparty: getIdOrValue(row.counterparty),
            individual: getIdOrValue(row.individual),
            total: row.total,
            is_salary: row.is_salary,
            period_start: row.period_start,
            period_end: row.period_end,
            monthlyplan: row.monthlyplan.map(({ id, budget_plan, ...rest }) => ({ ...rest })), // Exclude `id` from monthlyplan
        }));      
    
        try {
            const response = await api.post("/api/v1/budgets/create-plan/", preparedRows);

            if (response.status === 201) {
                const savedRows = response.data;
                setCaptionPlan((prev) => [
                ...prev.filter((item) => !item.isNew), // Remove rows that have `isNew`
                ...savedRows, // Add the saved rows
                ]);
            } else {
                alert("Ошибка при сохранении.");
            }
        } catch (error) {
                console.error("Error saving new rows:", error);
                alert("Ошибка при сохранении.");
        }
        };
    

        const enableEditing = (itemId, monthId) => {
            if (budgets.length !== 1 || projects.length !== 1) {
                return;
            }
            if (budgets.length === 1 && !budgets[0]?.is_open) {
                return;
            }
            setCaptionPlan((prevCaptionPlan) =>
            prevCaptionPlan.map((item) =>
                item.id === itemId
                ? {
                    ...item,
                    monthlyplan: item.monthlyplan.map((month) =>
                        month.id === monthId ? { ...month, isEditing: true } : month
                    ),
                    }
                : item
            )
            );
        };

        const handlePlanSumChange = (itemId, monthId, field, newValue) => {
          setCaptionPlan((prevCaptionPlan) =>
            prevCaptionPlan.map((item) =>
              item.id === itemId
                ? {
                    ...item,
                    monthlyplan: item.monthlyplan.map((month) =>
                      month.id === monthId 
                        ? { 
                            ...month, 
                            [field]: Number(newValue) 
                          } 
                        : month
                    ),
                  }
                : item
            )
          );
        };

        const savePlanSum = async (itemId, monthId, updatedValues) => {
          const updatedItem = captionPlan.find((item) => item.id === itemId);
          const updatedMonth = updatedItem?.monthlyplan.find((month) => month.id === monthId);
        
          if (updatedMonth) {
            try {
              // Prepare payload with all potential updatable fields
              const payload = {
                percent: updatedMonth.percent,
                salary: updatedMonth.salary,
                plan_sum: updatedMonth.plan_sum,
              };
        
              // Send patch request with the updated values
              await api.patch(`/api/v1/budgets/update-monthlyplan/${monthId}/`, payload);
              console.log("Plan sum updated successfully");
        
              // Update local state with new values and exit edit mode
              setCaptionPlan((prevCaptionPlan) =>
                prevCaptionPlan.map((item) =>
                  item.id === itemId
                    ? {
                        ...item,
                        monthlyplan: item.monthlyplan.map((month) =>
                          month.id === monthId
                            ? { 
                                ...month, 
                                ...updatedValues, // Merge in any specific updates
                                isEditing: false 
                              }
                            : month
                        ),
                      }
                    : item
                )
              );
            } catch (error) {
              console.error("Error updating plan sum:", error);
              // Optional: Add error handling to revert changes or show user feedback
            }
          }
        };

    const handleOpenConfirm = () => {
      if (budgets.length > 0) {
        setConfirmModal(true);
      }
    }

    const getIsOpen = () => {
      if (budgets.length !== 1 || projects.length !== 1) {
        return true;
      }
      if (budgets.length === 1 && !budgets[0]?.is_open) {
        return true;
      }

      return false;
    }

    const parseCurrency = (value) => {
      return parseFloat(value.replace(/\s+/g, '').replace(',', '.')) || 0;
    };

    return (
        <>
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar current="/budgeting"/>
                <div className="col py-3">
                    <div className="budgets-management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between align-items-center mb-3"><PageHeading>Движение
                                    денег</PageHeading></div>
                                <div className="d-flex align-items-center mb-3">
                                    <div className="filter row flex-fill justify-content-center border rounded p-3">
                                        <div className="col">
                                            <label className="form-label">Проект</label>
                                            <APIMultipleSearchableSelect
                                                endpoint="/api/v1/organization/projects/search/"
                                                placeholder="Выберите проект"
                                                value={projects}
                                                onChange={(selected) => setProjects(selected)}
                                            />
                                        </div>
                                        <div className="col">
                                            <label className="form-label">Период активности проекта</label>
                                            <div
                                                className="d-flex justify-content-center align-items-center align-self-center">
                                                <Input
                                                    type="date"
                                                    name="date_from"
                                                    value={dateFrom}
                                                    onChange={(e) => setDateFrom(e.target.value)}/>

                                                —
                                                <Input
                                                    type="date"
                                                    name="date_to"
                                                    value={dateTo}
                                                    onChange={(e) => setDateTo(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="col d-flex ms-auto align-items-end">
                                            <Button
                                                bType="secondary"
                                                size="medium"
                                                fixed
                                                onClick={() => {
                                                    setProjects([]);
                                                    setDateFrom("");
                                                    setDateTo("");
                                                    setData([]);
                                                }}>
                                                Сбросить
                                            </Button>
                                            <Button bType="primary" size="medium" fixed style={{
                                                marginLeft: "10px"
                                            }} onClick={handleSearch}>Поиск</Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <Button bType="secondary" size="medium" fixed data-bs-toggle="dropdown">Действие</Button>
                                    <ul className="dropdown-menu">
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{show: 250, hide: 100}}
                                            overlay={renderTooltip}
                                            trigger={["hover", "focus"]}
                                        >
                                            <li>
                                                <a
                                                    className={`dropdown-item ${getIsOpen() ? 'disabled' : ''}`}
                                                    onClick={handleEditClick}
                                                    style={{pointerEvents: getIsOpen() ? 'none' : 'auto'}}
                                                >
                                                    Редактировать бюджет
                                                </a>
                                            </li>
                                        </OverlayTrigger>
                                        <li><span className="dropdown-item"
                                                  onClick={() => (handleOpenConfirm(), setIsOpening(true))}>Открыть редактирование</span>
                                        </li>
                                        <li><span className="dropdown-item"
                                                  onClick={() => (handleOpenConfirm(), setIsOpening(false))}>Закрыть редактирование</span>
                                        </li>
                                    </ul>
                                </div>
                                {isEditing && <div>
                                    <Button bType="primary" size="medium" fixed onClick={handleEditClick} style={{
                                        marginBottom: "10px"
                                    }}>
                                        {isEditing ? 'Закончить редактирование' : 'Редактировать бюджет'}
                                    </Button>
                                </div>}
                                {loading ? (
                                    <div className="position-absolute top-50 start-50 translate-middle">
                                        <ReactLoading type="spin" color="#0000FF" height={30} width={30}/>
                                    </div>
                                ) : (
                                    <div className="Budgets__table"
                                         style={{height: '75vh', overflowY: 'scroll'}}>
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr>
                                                <th rowSpan="2">Статьи</th>
                                                <th colSpan="3">План итого</th>
                                                {months.map(month => (
                                                    <th key={month} colSpan="3">{month}</th>
                                                ))}
                                            </tr>
                                            <tr>
                                                <th>План</th>
                                                <th>Факт</th>
                                                <th>Отклонение</th>
                                                {months.map(month => (
                                                    <React.Fragment key={`header-${month}`}>
                                                        <th>План</th>
                                                        <th>Факт</th>
                                                        <th>Отклонение</th>
                                                    </React.Fragment>
                                                ))}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {data.length > 0 ? (
                                                data.map((group) => (
                                                    <React.Fragment key={group.type_of_caption_id}>
                                                        {group.captions.map((item) => (
                                                            <TableRow
                                                                key={item.caption_id}
                                                                item={item}
                                                                months={months}
                                                                isEditing={isEditing}
                                                                openCaption={() => openPanel(item.caption_id, item.is_employees || false)}
                                                                typeName={item === group.captions[0] ? {
                                                                    name: group.type_of_caption_name,
                                                                    total: group.total_with_children
                                                                } : null} // Show only once per group
                                                            />
                                                        ))}
                                                    </React.Fragment>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="100%" className="text-center">
                                                        Нет данных
                                                    </td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>)}
                            </div>
                            <SlidingPanel isOpen={isOpen} onClose={closePanel}>
  <div className='position-relative h-100 d-flex flex-column'>
    {captionPlan.length > 0 ? (
      captionPlan[0].is_employees ? (
        <div className='caption-table-container d-flex flex-column h-100'>
  <div className='mb-4'>
    <div className='d-flex justify-content-center'>
      <p className='fs-2'>{captionPlan[0]?.caption.name}</p>
    </div>
    <Button bType="primary" size="medium" fixed onClick={handleAddRow}
            disabled={getIsOpen()}>Заполнить диапазон
    </Button>
  </div>
  <div className='overflow-auto flex-grow-1'>
    <table className="table table-bordered border-separate" style={{ borderCollapse: 'separate', borderSpacing: '0' }}>
      <thead>
        <tr className="table-light">
          <th className="sticky-col sticky-col-1 border" rowSpan={3}>
            №
          </th>
          <th className="sticky-col sticky-col-2 border" style={{ width: "200px", minWidth: "200px" }} rowSpan={3}>
            Источник выплат
          </th>
          <th className="sticky-col sticky-col-3 border" style={{ width: "200px", minWidth: "200px" }} rowSpan={3}>
            Сотрудник
          </th>
          <th className="sticky-col sticky-col-4 border" rowSpan={3}>
            Итого
          </th>
          {captionPlan[0]?.monthlyplan?.map((month) => (
            <React.Fragment key={month.id}>
              <th colSpan={6} className="border">{month?.period_str}</th>
            </React.Fragment>
          ))}
        </tr>
        <tr>{captionPlan[0]?.monthlyplan?.map((month) => (
            <React.Fragment key={month.id}>
              <th colSpan={3} className="border text-center">План</th>
              <th colSpan={3} className="border text-center">Факт</th>
            </React.Fragment>
          ))}</tr>
          <tr>{captionPlan[0]?.monthlyplan?.map((_, index) => (
            <React.Fragment key={index}>
              <th className="border">Оклад</th>
              <th className="border">Процент</th>
              <th className="border">Сумма</th>
              <th className="border">Оклад</th>
              <th className="border">Процент</th>
              <th className="border">Сумма</th>
            </React.Fragment>
          ))}
          </tr>
        
        <tr>
          <th colSpan={3} className="sticky-col sticky-col-1 border">Распределение</th>
          <th className="sticky-col sticky-col-4 border">{captionPlan[0]?.total_percent || 0} из 100</th>
          {captionPlan[0]?.monthlyplan?.map((month, index) => (
            <React.Fragment key={index}>
              <th className="border" colSpan={3}>
                <div className="bg-success bg-opacity-25 text-center rounded">
                  {month.plan_percent || 100}%
                </div>
              </th>
              <th className="border" colSpan={3}>
                <div className="bg-success bg-opacity-25 text-center rounded">
                  {month.fact_percent || 100}%
                </div>
              </th>
            </React.Fragment>
          ))}
        </tr>
      </thead>
      <tbody>
        {captionPlan.map((item, index) => (
          <tr key={item.id}>
            <td className="sticky-col sticky-col-1 border">{index + 1}</td>
            <td className="sticky-col sticky-col-2 border">{item?.company?.name}</td>
            <td className="sticky-col sticky-col-3 border">
              {item.isNew ? (
                <APISearchableSelect
                  endpoint="/api/v1/bills/individual/search/"
                  size="sm"
                  value={item?.individual}
                  onChange={(value) => handleChange(item.id, { name: "individual", value })}
                  placeholder="Выберите сотрудника"
                />
              ) : (
                item?.individual?.name
              )}
            </td>
            <td className="sticky-col sticky-col-4 border">
              {Number(item.total).toLocaleString()}
            </td>
            {item.monthlyplan.map((month, idx) => (
              <React.Fragment key={idx}>
                {/* План */}
                <td className='cell-width border'>
                  {month.isEditing ? (
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      min={0}
                      value={formatCurrency(month?.salary)}
                      onChange={(e) => handlePlanSumChange(item.id, month.id, 'salary', parseCurrency(e.target.value))}
                      onBlur={() => savePlanSum(item.id, month.id)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") savePlanSum(item.id, month.id);
                      }}
                      
                    />
                  ) : (
                    <span onClick={() => enableEditing(item.id, month.id)}>
                      {Number(month.salary || 0).toLocaleString()}
                    </span>
                  )}
                </td>
                <td className='cell-width border'>
                  {month.isEditing ? (
                    <input
                      type="number"
                      className="form-control form-control-sm"
                      min={0}
                      max={100}
                      value={formatCurrency(month?.percent)}
                      onChange={(e) => handlePlanSumChange(item.id, month.id, 'percent', e.target.value)}
                      onBlur={() => savePlanSum(item.id, month.id)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") savePlanSum(item.id, month.id);
                      }}
                      
                    />
                  ) : (
                    <span onClick={() => enableEditing(item.id, month.id)}>
                      {month.percent || 0}
                    </span>
                  )}
                </td>
                <td className='cell-width border'>
                  {month.isEditing ? (
                    <input
                      type="number"
                      className="form-control form-control-sm"
                      min={0}
                      value={month?.plan_sum || 0}
                      onChange={(e) => handlePlanSumChange(item.id, month.id, 'plan_sum', e.target.value)}
                      onBlur={() => savePlanSum(item.id, month.id)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") savePlanSum(item.id, month.id);
                      }}
                      
                    />
                  ) : (
                    <span onClick={() => enableEditing(item.id, month.id)}>
                      {month.plan_sum || 0}
                    </span>
                  )}
                </td>
                {/* Факт */}
                <td className='cell-width border'>
                  <span >
                    {Number(month.fact_sum.salary_sum || 0).toLocaleString()}
                  </span>
                </td>
                <td className='cell-width border'>
                  <span >
                    {month.fact_sum.percent || 0}
                  </span>
                </td>
                <td className='cell-width border'>
                  {Number(month.fact_sum?.payment_sum || 0).toLocaleString()}
                </td>
              </React.Fragment>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</div>
      ) : (
        <div className='caption-table-container d-flex flex-column h-100'>
            <div className='mb-4'>
              <div className='d-flex justify-content-center'>
                <p className='fs-2'>{captionPlan[0]?.caption.name}</p>
              </div>
              <Button bType="primary" size="medium" fixed onClick={handleAddRow}
                      disabled={getIsOpen()}>Заполнить диапазон
              </Button>
            </div>
          <div className='overflow-auto flex-grow-1'>
            <table className="table table-bordered border-separate" style={{ borderCollapse: 'separate', borderSpacing: '0' }}>
              <thead>
                <tr className="table-light">
                  <th className="sticky-col sticky-col-1 border" rowSpan={2}>
                    №
                  </th>
                  <th className="sticky-col sticky-col-2 border" style={{ width: "200px", minWidth: "200px" }} rowSpan={2}>
                    Организация
                  </th>
                  <th className="sticky-col sticky-col-3 border" style={{ width: "200px", minWidth: "200px" }} rowSpan={2}>
                    Контрагент
                  </th>
                  <th className="sticky-col sticky-col-4 border" rowSpan={2}>
                    Итого
                  </th>
                  {captionPlan[0]?.monthlyplan?.map((month) => (
                    <React.Fragment key={month.id}>
                      <th colSpan={2} className="border">{month?.period_str}</th>
                    </React.Fragment>
                  ))}
                </tr>
                <tr className="table-light">
                  {captionPlan[0]?.monthlyplan?.map((_, index) => (
                    <React.Fragment key={index}>
                      <th className="border">План</th>
                      <th className="border">Факт</th>
                    </React.Fragment>
                  ))}
                </tr>
                <tr>
                  <th colSpan={3} className="sticky-col sticky-col-1 border">Распределения</th>
                  <th className="sticky-col sticky-col-4 border">30/100</th>
                  {captionPlan[0]?.monthlyplan?.map((month, index) => (
                    <th colSpan={2} className="border" key={index}>
                      {month.percent}%
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {captionPlan.map((item, index) => (
                  <tr key={item.id}>
                    <td className="sticky-col sticky-col-1 border">{index + 1}</td>
                    <td className="sticky-col sticky-col-2 border">{item?.company?.name}</td>
                    <td className="sticky-col sticky-col-3 border">
                      {item.isNew ? (
                        <APISearchableSelect
                          endpoint="/api/v1/bills/counterparty/search/"
                          size="sm"
                          value={item?.counterparty}
                          onChange={(value) => handleChange(item.id, { name: "counterparty", value })}
                          placeholder="Выберите контрагента"
                        />
                      ) : (
                        item?.counterparty?.name
                      )}
                    </td>
                    <td className="sticky-col sticky-col-4 border">
                      {Number(item.total).toLocaleString()}
                    </td>
                    {item.monthlyplan.map((month, idx) => (
                      <React.Fragment key={idx}>
                        <td className='cell-width border'>
                          {month.isEditing ? (
                            <input
                              type="number"
                              className="form-control form-control-sm"
                              min={0}
                              value={month?.plan_sum || 0}
                              onChange={(e) => handlePlanSumChange(item.id, month.id, 'plan_sum', e.target.value)}
                              onBlur={() => savePlanSum(item.id, month.id)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") savePlanSum(item.id, month.id);
                              }}
                              
                            />
                          ) : (
                            <span onClick={() => enableEditing(item.id, month.id)}>
                              {month.plan_sum || 0}
                            </span>
                          )}
                        </td>
                        <td className='cell-width border'>{Number(month.fact_sum ? month.fact_sum : 0).toLocaleString()}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )
    ) : (
      <div className="flex justify-center items-center h-full p-8">
        <p className="text-center text-gray-500">Нет данных для отображения</p>
      </div>
    )}
    <div className="d-flex justify-content-end mt-3">
      {captionPlan.some(plan => plan.isNew) && (
        <Button bType="primary" size="medium" fixed onClick={handleSaveAllRows}>
          Сохранить
        </Button>
      )}
    </div>
  </div>
</SlidingPanel>
                        </div>
                        <ConfirmModal 
                          showModal={confirmModal} 
                          setShowModal={setConfirmModal} 
                          budgets={budgets}
                          setBudgets={setBudgets} 
                          setLoading={setLoading}
                          isOpening={isOpening}/>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default BudgetPlanPage