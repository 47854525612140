import { useState, useEffect } from "react";
import api from "../api";
import SideBar from "./SideBar";
import ReactLoading from 'react-loading';
import Header from "./Header";
import { useNavigate, useParams } from 'react-router-dom';
import Button from "../components/ui/Button/Button";


const UserEditPage = () => {
    const { id } = useParams();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState({});
    const navigate = useNavigate();
    const finmodel= JSON.parse(localStorage.getItem('selectedFinModel'))
    const finModelId = finmodel.id;
    const finModelName = finmodel.name;
    const [selectedBlockRoles, setSelectedBlockRoles] = useState([]);
    const [availableRoles, setAvailableRoles] = useState([]);
    const [blocks, setBlocks] = useState([]);
    const [roles, setRoles] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    
    useEffect(() => {
        const loadInitialData = async () => {
            setLoading(true);
            try {
                await Promise.all([
                    fetchUserData(),
                    fetchBlocks(),
                    fetchRoles(),
                    fetchUserRoles()
                ]);
            } catch (error) {
                console.error('Error loading initial data:', error);
            } finally {
                setLoading(false);
            }
        };
        
        loadInitialData();
    }, [id]);
    const fetchUserData = async () => {
        try {
            setLoading(true);
            const response = await api.get(`api/v1/users/${id}/`);
            setUserData(response.data);
        } catch (error) {
            console.error('Error fetching user data', error);
        } finally {
            setLoading(false);
        }
    }
    const fetchUserRoles = async () => {
        try {
            const response = await api.get(`/api/v1/finmodel/user_role/getby_user_id/${id}/`);
            setRoles(response.data);
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    };
    const fetchRoles = async () => {
        try {
            const response = await api.get('/api/v1/role/groups/');
            setAvailableRoles(response.data);
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    };
    const fetchBlocks = async () => {
        try {
            const response = await api.get(`/api/v1/finmodel/getBlockByFinModel/${finModelId}/`);
            const groupsData = response.data;
            setBlocks(groupsData);
            return groupsData;
        } catch (error) {
            console.error('Error fetching groups', error);
            return [];
        }
    };
    const handleInputChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };
    const handleDeleteRole = async (roleId) => {
        try {
            setLoading(true);
            await api.delete(`/api/v1/finmodel/user_role/${roleId}/delete/`);
            await fetchUserRoles();
        } catch (error) {
            console.error('Error deleting role:', error);
            alert('Ошибка при удалении роли');
        } finally {
            setLoading(false);
        }
    }
    const createUserRoles = async (employeeId, blockRoles) => {
        try {
            const promises = blockRoles.map(({ blockId, roleId }) => {
                return api.post('/api/v1/finmodel/user_role/create/', {
                    user: employeeId,
                    fin_model: finModelId,
                    custom_group: roleId,
                    block: blockId
                });
            });
            await Promise.all(promises);
        } catch (error) {
            console.error('Error creating user roles:', error);
            throw error;
        }
    };
    const updateBlockEmployees = async (employeeId, blockRoles) => {
        try {
            const uniqueBlocks = [...new Set(blockRoles.map(br => br.blockId))];
            
            const promises = uniqueBlocks.map(async (blockId) => {
                // Получаем текущий блок
                const blockResponse = await api.get(`/api/v1/finmodel/block/${blockId}/`);
                const currentBlock = blockResponse.data;
                const numericEmployeeId = Number(employeeId);
            
                // Преобразуем все существующие ID в числа
                const numericEmployees = currentBlock.employees.map(id => Number(id));
                
                // Проверяем, есть ли уже сотрудник в списке
                if (numericEmployees.includes(numericEmployeeId)) {
                    return;
                }
                
                // Добавляем нового сотрудника к существующим, все ID как числа
                const updatedEmployees = [...numericEmployees, numericEmployeeId];
                // Создаем payload для обновления блока
                const blockPayload = {
                    ...currentBlock,
                    employees: updatedEmployees,
                    fin_model: finModelId
                };

                // Обновляем блок
                return api.put(`/api/v1/finmodel/block/update/${blockId}/`, blockPayload);
            });

            await Promise.all(promises);
        } catch (error) {
            console.error('Error updating block employees:', error);
            throw error;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { username, first_name, last_name, phone_number, email, password, confirmPassword } = userData;
        const errors = {};
        if (!username) {
            errors.username = 'Username is required';
        }
        if (!email) {
            errors.email = 'Email is required';
        }
        if (password !== confirmPassword) {
            errors.confirmPassword = 'Passwords do not match';
        }
        if (!first_name) {
            errors.first_name = 'First name is required';
        }
        if (!last_name) {
            errors.last_name = 'Last name is required';
        }
        if (!phone_number) {
            errors.phone_number = 'Phone number is required'
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }
        try {
            const response = api.put(`api/v1/users/${id}/update/`, userData);
            const updatedUser = response.data;
            await createUserRoles(id, selectedBlockRoles);
            await updateBlockEmployees(id, selectedBlockRoles);
            const finModelResponse = await api.get(`/api/v1/finmodel/${finModelId}/`);
            const currentEmployees = finModelResponse.data.employees || [];
            const updatedEmployees = [...new Set([...currentEmployees, id])];
            
            const finModelPayload = {
                name: finModelName,
                employees: updatedEmployees,
                fin_model: finModelId
            };
            
            await api.put(`/api/v1/finmodel/update/${finModelId}/`, finModelPayload);
            console.log('Updated user:', updatedUser);
            navigate('/finmodel')
        } catch (error) {
            console.log(error)
        }
    }
    const handleAddBlockRole = () => {
        setSelectedBlockRoles([...selectedBlockRoles, { blockId: '', roleId: '' }]);
    };

    const handleRemoveBlockRole = (index) => {
        const updatedBlockRoles = selectedBlockRoles.filter((_, i) => i !== index);
        setSelectedBlockRoles(updatedBlockRoles);
    };
    const handleBlockRoleChange = (index, field, value) => {
        const updatedBlockRoles = [...selectedBlockRoles];
        updatedBlockRoles[index] = {
            ...updatedBlockRoles[index],
            [field]: value
        };
        setSelectedBlockRoles(updatedBlockRoles);
    };
    if (loading) {
        return (
            <div className="position-absolute top-50 start-50 translate-middle">
                <ReactLoading type="spin" color="#0000FF" height={50} width={50} />
            </div>);
    }

    return (
        <>
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col">
                    <div className="edit-user-header mt-3">
                        <h2>Редактирование пользователя</h2>
                    </div>
                    <div className="edit-user-content mt-5">
                        <form className="user-form">
                            <div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mb-5">
                                            <label className='form-label'>Email</label>
                                            <input className="form-control" type="email" name="email" placeholder='Введите email' value={userData.email} onChange={handleInputChange} />
                                            {errors.email && <span>{errors.email}</span>}
                                        </div>
                                        <div className="mb-5">
                                            <label className='form-label'>ИИН</label>
                                            <input className="form-control" type="text" name="username" placeholder='Введите ИИН' value={userData.username} onChange={handleInputChange} />
                                            {errors.username && <span>{errors.username}</span>}
                                        </div>
                                        <div className="mb-5">
                                            <label className="form-label">Фамилия</label>
                                            <input className="form-control" type="text" name="first_name" placeholder='Введите фамилию' value={userData.first_name} onChange={handleInputChange} />
                                            {errors.first_name && <span>{errors.first_name}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-5">
                                            <label className="form-label">Имя</label>
                                            <input className="form-control" type="text" name="last_name" placeholder='Введите имя' value={userData.last_name} onChange={handleInputChange} />
                                            {errors.last_name && <span>{errors.last_name}</span>}
                                        </div>
                                        <div className="mb-5">
                                            <label className="form-label">Отчество</label>
                                            <input className="form-control" type="text" name="middle_name" placeholder='Введите отчество' value={userData.middle_name} onChange={handleInputChange} />
                                            {errors.middle_name && <span>{errors.middle_name}</span>}
                                        </div>
                                        <div className="mb-5">
                                            <label className="form-label">Номер телефона</label>
                                            <input className="form-control" type="text" name="phone_number" placeholder='+7 777 77 77 777' value={userData.phone_number} onChange={handleInputChange} />
                                            {errors.phone_number && <span>{errors.phone_number}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className='mb-5'>
                                            <label className='form-label'>Должность</label>
                                            <input className="form-control" type="text" name="position" placeholder='Введите должность' value={userData.position} onChange={handleInputChange} />
                                            {errors.position && <span>{errors.position}</span>}
                                        </div>
                                        <div className='mb-5'>
                                            <label className='form-label'>Новый пароль</label>
                                            <input className="form-control" type="password" name="password" value={userData.password} onChange={handleInputChange} />
                                            {errors.password && <span>{errors.password}</span>}
                                        </div>
                                        <div className='mb-5'>
                                            <label className='form-label'>Подтверждение нового пароля</label>
                                            <input className="form-control" type="password" name="confirmPassword" value={userData.confirmPassword} onChange={handleInputChange} />
                                            {errors.confirmPassword && <span>{errors.confirmPassword}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center mt-4">
                                    <div className="col-md-8">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Блок фин.модели</th>
                                                                <th>Роль</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {roles.map(role => (
                                                            <tr key={role.id}>
                                                                <td>{role.block?.name || "Null"}</td>
                                                                <td>{role.custom_group.name}</td>
                                                                <td>
                                                                    <button 
                                                                        className="btn btn-danger btn-sm"
                                                                        onClick={() => handleDeleteRole(role.id)}
                                                                    >
                                                                        <i className="bi bi-trash"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                            {selectedBlockRoles.map((blockRole, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <select 
                                                                            className="form-select"
                                                                            value={blockRole.blockId}
                                                                            onChange={(e) => handleBlockRoleChange(index, 'blockId', e.target.value)}
                                                                        >
                                                                            <option value="">Выберите блок</option>
                                                                            {blocks.map(block => (
                                                                                <option key={block.id} value={block.id}>
                                                                                    {block.name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <select 
                                                                            className="form-select"
                                                                            value={blockRole.roleId}
                                                                            onChange={(e) => handleBlockRoleChange(index, 'roleId', e.target.value)}
                                                                        >
                                                                            <option value="">Выберите роль</option>
                                                                            {availableRoles.map(role => (
                                                                                <option key={role.id} value={role.id}>
                                                                                    {role.name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <button 
                                                                            className="btn btn-danger btn-sm"
                                                                            onClick={() => handleRemoveBlockRole(index)}
                                                                            type="button"
                                                                        >
                                                                            <i className="bi bi-trash"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    {errors.blockRoles && (
                                                        <div className="alert alert-danger mt-2" role="alert">
                                                            {errors.blockRoles}
                                                        </div>
                                                    )}
                                                    <div className="text-center mt-3">
                                                        <button 
                                                            className="btn btn-success btn-sm" 
                                                            onClick={handleAddBlockRole}
                                                            type="button"
                                                        >
                                                            <i className="bi bi-plus-lg"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Button bType="primary" size="medium" fixed onClick={handleSubmit}>
                            Сохранить
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default UserEditPage;