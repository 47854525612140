import React, { useState, useEffect, useRef, useCallback } from "react";
import api from "../../api";
import { useLocation, useNavigate } from "react-router-dom";
import SideBar from "../SideBar";
import Header from "../Header";
import ReactLoading from "react-loading";
import APIMultipleSearchableSelect from "../payments/SearchableTableMultiSelect";
import { Modal } from "react-bootstrap";
import SearchableSelect from "../payments/SearchableSelectB";
import PageHeading from "../ui/PageHeading/PageHeading";
import Heading from "../ui/Heading/Heading";
import Button from "../ui/Button/Button";
import Input from "../ui/Input/Input";
import UnauthorizedModal from "../Unauthorized"; // ✅ Импортируем модалку
import { Alert } from 'react-bootstrap';
const ALL_COLUMNS = [
    "Наименование проекта",
    "Организация",
    "Дата создания",
    "Архивный",
];
const DEFAULT_COLUMN_WIDTHS = {
    "Наименование проекта": 250,
    "Организация": 200,
    "Дата создания": 200,
    "Архивный": 80,
};

const Projects = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [errorMessage, setErrorMessage] = useState(""); // 🔴 Храним текст ошибки
    const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false); // ✅ Состояние модалки
    const [projects, setProjects] = useState([]);
    const [companies, setCompanies] = useState([]);
    // const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const containerRef = useRef(null);
    const limit = 25;
    const [checkedProjects, setCheckedProjects] = useState([]);
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [nameFilter, setNameFilter] = useState("");
    // const [finModelFilter, setSelectedFinModel] = useState("");
    const [archivedFilterYES, setArchivedFilterYES] = useState(false);
    const [archivedFilterNO, setArchivedFilterNO] = useState(false);
    const [startDateFilterOne, setStartOneDateFilter] = useState("");
    const [startDateFilterTwo, setStartTwoDateFilter] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const finmodel= JSON.parse(localStorage.getItem('selectedFinModel'))
    const finModelId = finmodel.id;
    const initialFormData = {
        name: "",
        full_name: "",
        description: "",
        company: "",
        fin_model: finModelId
    };
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});
    const searchIDofelem = location.search.replace("?", "");
    const [blocks, setBlocks] = useState([]);

    const [selectedColumns, setSelectedColumns] = useState(() => {
        const savedCols = localStorage.getItem("projectsSelectedColumns");
        return savedCols ? JSON.parse(savedCols) : [...ALL_COLUMNS];
    });
    const [columnWidths, setColumnWidths] = useState(() => {
        const saved = localStorage.getItem("projectsColumnWidths");
        if (saved) return { ...DEFAULT_COLUMN_WIDTHS, ...JSON.parse(saved) };
        return { ...DEFAULT_COLUMN_WIDTHS };
    });
    const [showSettings, setShowSettings] = useState(false);

    const resizingInfoRef = useRef({
        isResizing: false,
        startX: 0,
        columnKey: null,
        initialWidth: 0,
    });

    const onMouseDown = (e, column) => {
        e.preventDefault();
        e.stopPropagation();
        resizingInfoRef.current.isResizing = true;
        resizingInfoRef.current.startX = e.clientX;
        resizingInfoRef.current.columnKey = column;
        resizingInfoRef.current.initialWidth =
            columnWidths[column] || DEFAULT_COLUMN_WIDTHS[column] || 100;
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };

    const onMouseMove = (e) => {
        if (!resizingInfoRef.current.isResizing) return;
        const { startX, columnKey, initialWidth } = resizingInfoRef.current;
        const delta = e.clientX - startX;
        const newWidth = Math.max(50, initialWidth + delta);
        setColumnWidths((prev) => ({
            ...prev,
            [columnKey]: newWidth,
        }));
    };

    const onMouseUp = () => {
        resizingInfoRef.current.isResizing = false;
        // document.removeEventListener("mousemove", onMouseMove);
        // document.removeEventListener("mouseup", onMouseUp);
        setColumnWidths((prev) => {
            localStorage.setItem("projectsColumnWidths", JSON.stringify(prev));
            return prev;
        });
    };

    useEffect(() => {
        localStorage.setItem("projectsSelectedColumns", JSON.stringify(selectedColumns));
    }, [selectedColumns]);

    const columnsInUse = ALL_COLUMNS.filter((col) => selectedColumns.includes(col));
    const totalTableWidth = columnsInUse.reduce((acc, col) => {
        return acc + (columnWidths[col] || DEFAULT_COLUMN_WIDTHS[col]);
    }, 50);

    const openModal = (open) => {
        if (open) { 
            setShowModal(false);
            setShowUnauthorizedModal(true);
        } else { 
            setShowUnauthorizedModal(false);
        }

    };

    const fetchProjects = async () => {
        setLoading(true);
        try {
            if (searchIDofelem) {
                const response = await api.post("/api/v1/organization/projectsfilter/", {
                    id: searchIDofelem,
                    is_active: [true, false],
                });
                setProjects(response.data);
                setHasMore(false);
            } else {
                const response = await api.get(
                    `/api/v1/organization/projects/?limit=${limit}&offset=${offset}`
                );
    
                setProjects((prev) => {
                    const newProjects = response.data.results.filter(
                        (proj) => !prev.some((p) => p.id === proj.id)
                    );
                    return [...prev, ...newProjects];
                });
    
                setHasMore(!!response.data.next);
            }
        } catch (error) {
            console.error("Ошибка при загрузке проектов:", error);
    
            if (error.response) {
                console.error("Ответ сервера:", error.response.status, error.response.data);

                if (error.response.status === 401) {
                    console.log("401 ошибка! Перезайдите, пожалуйста.");
                    openModal();
                } else if (error.response.status === 403) {
                    setErrorMessage("Доступ запрещен! У вас недостаточно прав на просмотр проектов.");
                } else if (error.response.status === 400) {
                    setErrorMessage(error.response.data.detail || "Ошибка запроса.");
                } else if (error.response.status === 500) {
                    setErrorMessage("Ошибка сервера! Попробуйте позже.");
                } else {
                    setErrorMessage(`Неизвестная ошибка: ${error.response.status}`);
                }
            } else {
                console.error("Ошибка сети или сервера:", error);
                setErrorMessage("Ошибка соединения с сервером. Проверьте интернет.");
            }
        } finally {
            setLoading(false);
        }
    };
    

    const fetchCompanies = async () => {
        try {
            const response = await api.get("/api/v1/organization/");
            setCompanies(response.data);
        } catch (error) {}
    };

    const fetchBlocks = async () => {
        try {
            const response = await api.get(`/api/v1/finmodel/getBlockByFinModel/${finModelId}/`);
            setBlocks(response.data);
        } catch (error) {}
    };

    useEffect(() => {
        setProjects([]);
        setOffset(0);
        setHasMore(true);
        fetchProjects();
        fetchCompanies();
        fetchBlocks();
    }, [location]);

    useEffect(() => {
        if (offset > 0 && hasMore) {
            fetchProjects();
        }
    }, [offset, hasMore]);

    useEffect(() => {
        const fetchProjectsById = async () => {
            if (searchIDofelem) {
                try {
                    const response = await api.post("/api/v1/organization/projectsfilter/", {
                        id: searchIDofelem,
                        is_active: [true, false],
                    });
                    if (response.data.length > 0) {
                        setNameFilter(response.data[0].name);
                        setProjects(response.data);
                    }
                } catch (error) {
                    console.error("Error fetching by ID", error);
                }
            } else {
                setNameFilter("");
                setProjects([]);
                setOffset(0);
                setHasMore(true);
                fetchProjects();
            }
        };
        fetchProjectsById();
    }, [searchIDofelem]);

    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
                setOffset((prevOffset) => prevOffset + limit);
            }
        }
    }, [hasMore, loading]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener("scroll", handleScroll);
            // return () => container.removeEventListener("scroll", handleScroll);
        }
    }, [handleScroll]);

    const handleUpdateProjects = async (isActive) => {
        setLoading(true);
        try {
            await Promise.all(
                checkedProjects.map((id) =>
                    api.patch(`/api/v1/organization/projects/${id}/update/`, { is_active: isActive })
                )
            );
            setProjects([]);
            setOffset(0);
            setHasMore(true);
            setCheckedProjects([]);
            fetchProjects();
        } catch (error) {
            console.error(`Error ${isActive ? "unarchiving" : "archiving"} Projects`, error);
        } finally {
            setLoading(false);
        }
    };

    const handleArchiveProjects = () => handleUpdateProjects(false);
    const handleUnarchiveProjects = () => handleUpdateProjects(true);

    const handleCheckboxChange = (projectId) => {
        setCheckedProjects((prev) => {
            if (prev.includes(projectId)) {
                return prev.filter((id) => id !== projectId);
            } else {
                return [...prev, projectId];
            }
        });
    };

    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const allIds = projects.map((p) => p.id);
            setCheckedProjects(allIds);
        } else {
            setCheckedProjects([]);
        }
    };

    const allChecked =
        projects.length > 0 && projects.every((proj) => checkedProjects.includes(proj.id));

    const handleSearch = async () => {
        setLoading(true);
        try {
            const response = await api.post("/api/v1/organization/projectsfilter/", {
                name: nameFilter.toLowerCase(),
                is_active: [!archivedFilterYES, archivedFilterNO],
                // finmodel: finModelFilter,
                startDateOne: startDateFilterOne,
                startDateTwo: startDateFilterTwo,
            });
            setProjects(response.data);
            setHasMore(false);
        } catch (error) {
            console.error("Error fetching project:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleReset = () => {
        const search = location.search;
        navigate(location.search.replace(search, ""));
        window.location.reload();
    };

    const handleRowClick = (project) => {
        setIsEditing(true);
        setFormData({
            id: project.id,
            name: project.name,
            full_name: project.full_name || "",
            description: project.description || "",
            // finmodel: project.finmodel
            //     ? groups.find((g) => g.id === project.finmodel)?.id
            //     : "",
            company: project.company
                ? companies.find((c) => c.id === project.company)?.id
                : "",
        });
        setShowModal(true);
    };

    const handleCloseModal = () => {
        resetForm();
        setShowModal(false);
        setErrors({});
    };

    const resetForm = () => {
        setFormData(initialFormData);
        setIsEditing(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
        if (errors[name]) {
            setErrors((prevErr) => ({ ...prevErr, [name]: "" }));
        }
    };

    const handleFinmodelChange = (value) => {
        setFormData((prev) => ({ ...prev, finmodel: value }));
        if (errors.finmodel) {
            setErrors((prev) => ({ ...prev, finmodel: "" }));
        }
    };

    const handleCompanyChange = (value) => {
        setFormData((prev) => ({ ...prev, company: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const requiredFields = ["name", "full_name", "company"];
        const newErrors = {};
        requiredFields.forEach((key) => {
            if (!formData[key]) {
                if (key === "company") {
                    newErrors[key] = "Выберите значение";
                } else {
                    newErrors[key] = "Заполните поле";
                }
            }
        });
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        setLoading(true);
        try {
            const getIdOrValue = (obj) => (typeof obj === "object" && obj?.id ? obj.id : obj);
            const connection = companies.find(company => company.id === formData.company)
            const connectionId = connection ? connection.connection : null
            const block = blocks.find(block => block.connection?.id === connectionId);
            const blockID = block ? block.id : null;
            // const payload = {
            //     ...formData,
            //     company: getIdOrValue(formData.company),
            // };
            if (isEditing) {
                await api.patch(`/api/v1/organization/projects/${formData.id}/update/`, formData);
            } else {
                const response = await api.post("/api/v1/organization/projects/create/", formData);
                const newProject = response.data.id;
                const blockPayload = {
                    name: formData.name,
                    parent: blockID,
                    project: newProject,
                    is_info_block: false,
                    fin_model: finModelId
                };
                await api.post('/api/v1/finmodel/block/create/', blockPayload);
            }
            setProjects([]);
            setOffset(0);
            setHasMore(true);
            handleCloseModal();
            fetchProjects();
        } catch (error) {
            console.log(error);
            alert("Заполните все поля");
        } finally {
            setLoading(false);
        }
    };

    const modalTitle = isEditing ? "Редактирование проекта" : "Создание проекта";

    // ======== Настройка столбцов и их ширины ========

    const [settingsVisible, setSettingsVisible] = useState(false);
    const toggleColumn = (column) => {
        setSelectedColumns((prev) =>
            prev.includes(column) ? prev.filter((col) => col !== column) : [...prev, column]
        );
    };

    const selectAllColumns = () => {
        setSelectedColumns([...ALL_COLUMNS]);
    };

    const cancelSettings = () => {
        setSettingsVisible(false);
    };

    const saveSettings = () => {
        setSettingsVisible(false);
    };

    const handleMouseDown = (e, column) => {
        e.preventDefault();
        e.stopPropagation();
        resizingInfoRef.current.isResizing = true;
        resizingInfoRef.current.startX = e.clientX;
        resizingInfoRef.current.columnKey = column;
        resizingInfoRef.current.initialWidth =
            columnWidths[column] || DEFAULT_COLUMN_WIDTHS[column] || 100;
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
    };

    const handleMouseMove = (e) => {
        if (!resizingInfoRef.current.isResizing) return;
        const { startX, columnKey, initialWidth } = resizingInfoRef.current;
        const delta = e.clientX - startX;
        const newWidth = Math.max(50, initialWidth + delta);
        setColumnWidths((prev) => ({
            ...prev,
            [columnKey]: newWidth,
        }));
    };

    const handleMouseUp = () => {
        resizingInfoRef.current.isResizing = false;
        // document.removeEventListener("mousemove", handleMouseMove);
        // document.removeEventListener("mouseup", handleMouseUp);
        setColumnWidths((prev) => {
            localStorage.setItem("projectsColumnWidths", JSON.stringify(prev));
            return prev;
        });
    };

    return (
        <div className="container-fluid" translate="no">
            <Header />
            <div className="row flex-nowrap">
                <SideBar />
                <div className="col py-3">
                    <div className="management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between mb-3">
                                    <div className="d-flex gap-2">
                                        <PageHeading>Проекты</PageHeading>
                                        <Button
                                            bType="secondary"
                                            size="small"
                                            fixed
                                            onClick={() => setFiltersVisible(!filtersVisible)}
                                        >
                                            <i className="bi bi-funnel"></i>
                                        </Button>
                                        <Button bType="primary" size="small" fixed onClick={() => setShowModal(true)}>
                                            <i className="bi bi-plus-circle"></i>
                                        </Button>
                                    </div>

                                    <Button
                                        bType="secondary"
                                        size="small"
                                        fixed
                                        onClick={() => setSettingsVisible(true)}
                                    >
                                        <i className="bi bi-gear"></i>
                                    </Button>
                                </div>
                                {errorMessage && (
                                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                                        {errorMessage}
                                    </Alert>
                                )}
                                <UnauthorizedModal 
                                    show={showUnauthorizedModal} 
                                    onClose={() => setShowUnauthorizedModal(false)} 
                                />
                                {filtersVisible && (
                                    <div className="mb-3 p-3 border rounded">
                                        <Heading level={6} style={{ marginBottom: "10px" }}>
                                            Фильтры:
                                        </Heading>
                                        <div className="row g-3">
                                            <div className="col-md-3">
                                                <label className="form-label">Наименование проекта</label>
                                                <input
                                                    type="text"
                                                    placeholder="Введите наименование проекта"
                                                    value={nameFilter}
                                                    onChange={(e) => setNameFilter(e.target.value)}
                                                    className="form-control"
                                                />
                                            </div>
                                            {/* <div className="col-md-3">
                                                <label className="form-label">Родительская группа</label>
                                                <APIMultipleSearchableSelect
                                                    endpoint="/api/v1/finmodel/block/search/"
                                                    value={finModelFilter}
                                                    onChange={(value) => setSelectedFinModel(value)}
                                                    placeholder="Выберите группу"
                                                />
                                            </div> */}
                                            <div className="col-md-3">
                                                <label className="form-label">Дата создания</label>
                                                <div className="d-flex align-items-center">
                                                    <span style={{ marginRight: "5px" }}>с</span>
                                                    <input
                                                        type="date"
                                                        value={startDateFilterOne}
                                                        onChange={(e) => setStartOneDateFilter(e.target.value)}
                                                        className="form-control"
                                                        style={{ maxWidth: "130px" }}
                                                    />
                                                    <span style={{ margin: "0 8px" }}>—</span>
                                                    <span style={{ marginRight: "5px" }}>до</span>
                                                    <input
                                                        type="date"
                                                        value={startDateFilterTwo}
                                                        onChange={(e) => setStartTwoDateFilter(e.target.value)}
                                                        className="form-control"
                                                        style={{ maxWidth: "130px" }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label>Архивный</label>
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="archivedCheckYES"
                                                        checked={archivedFilterYES}
                                                        onChange={(e) => setArchivedFilterYES(e.target.checked)}
                                                    />
                                                    <label className="form-check-label" htmlFor="archivedCheckYES">
                                                        Да
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="archivedCheckNO"
                                                        checked={archivedFilterNO}
                                                        onChange={(e) => setArchivedFilterNO(e.target.checked)}
                                                    />
                                                    <label className="form-check-label" htmlFor="archivedCheckNO">
                                                        Нет
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center gap-3 mt-3">
                                            <Button onClick={handleSearch} bType="primary" size="medium" fixed>
                                                Поиск
                                            </Button>
                                            <Button onClick={handleReset} bType="secondary" size="medium" fixed>
                                                Сбросить
                                            </Button>
                                        </div>
                                    </div>
                                )}

                                {checkedProjects.length > 0 && (
                                    <div className="d-flex gap-2 align-items-center mb-3">
                                        <Button
                                            onClick={handleArchiveProjects}
                                            bType="secondary"
                                            size="medium"
                                            fixed
                                            disabled={checkedProjects.length === 0}
                                        >
                                            Архивировать выбранные
                                        </Button>
                                        <Button
                                            onClick={handleUnarchiveProjects}
                                            bType="secondary"
                                            size="medium"
                                            fixed
                                            disabled={checkedProjects.length === 0}
                                        >
                                            Разархивировать выбранные
                                        </Button>
                                    </div>
                                )}

                                <div
                                    ref={containerRef}
                                    className="Payments__wrapper"
                                    style={{ height: "65vh", overflowY: "scroll", position: "relative" }}
                                >
                                    <table
                                        className="Payments__table"
                                        style={{
                                            tableLayout: "fixed",
                                        }}
                                    >
                                        <thead>
                                        <tr>
                                            <th style={{ width: 50 }}>
                                                <input
                                                    type="checkbox"
                                                    checked={allChecked}
                                                    onChange={handleSelectAllChange}
                                                />
                                            </th>
                                            {columnsInUse.map((column) => {
                                                const w = columnWidths[column] || DEFAULT_COLUMN_WIDTHS[column];
                                                return (
                                                    <th
                                                        key={column}
                                                        style={{
                                                            position: "relative",
                                                            width: w,
                                                            minWidth: w,
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <span>{column}</span>
                                                            <div
                                                                style={{
                                                                    cursor: "col-resize",
                                                                    padding: "0 4px",
                                                                    marginRight: "-4px",
                                                                    userSelect: "none",
                                                                    zIndex: 999
                                                                }}
                                                                onMouseDown={(e) => handleMouseDown(e, column)}
                                                            >
                                                                ||
                                                            </div>
                                                        </div>
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {projects.map((project) => {
                                            // const groupName = groups.find((g) => g.id === project.finmodel)?.name;
                                            const companyName = companies.find((c) => c.id === project.company)?.name;
                                            return (
                                                <tr 
                                                    key={project.id} 
                                                    onClick={(e) => {
                                                        const isCheckboxClick = e.target.type === 'checkbox' || 
                                                                                e.target.closest('td')?.querySelector('input[type="checkbox"]');
                                                        if (!isCheckboxClick) {
                                                        handleRowClick(project);
                                                        }
                                                    }}
                                                    >
                                                    <td style={{ width: 50 }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={checkedProjects.includes(project.id)}
                                                            onChange={(e) => {
                                                                e.stopPropagation();
                                                                handleCheckboxChange(project.id);
                                                            }}
                                                        />
                                                    </td>
                                                    {selectedColumns.includes("Наименование проекта") && (
                                                        <td
                                                            style={{
                                                                width:
                                                                    columnWidths["Наименование проекта"] ||
                                                                    DEFAULT_COLUMN_WIDTHS["Наименование проекта"],
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                whiteSpace: "normal",
                                                                wordWrap: "break-word",
                                                            }}
                                                        >
                                                            {project.name}
                                                        </td>
                                                    )}
                                                    
                                                    {selectedColumns.includes("Организация") && (
                                                        <td
                                                            style={{
                                                                width:
                                                                    columnWidths["Организация"] ||
                                                                    DEFAULT_COLUMN_WIDTHS["Организация"],
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                whiteSpace: "nowrap",
                                                            }}
                                                        >
                                                            {companyName || ""}
                                                        </td>
                                                    )}
                                                    {selectedColumns.includes("Дата создания") && (
                                                        <td
                                                            style={{
                                                                width:
                                                                    columnWidths["Дата создания"] ||
                                                                    DEFAULT_COLUMN_WIDTHS["Дата создания"],
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                whiteSpace: "nowrap",
                                                            }}
                                                        >
                                                            {new Date(project.created).toLocaleString("ru-RU", {
                                                                year: "numeric",
                                                                month: "long",
                                                                day: "numeric",
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                                timeZone: "Asia/Almaty",
                                                            })}
                                                        </td>
                                                    )}
                                                    {selectedColumns.includes("Архивный") && (
                                                        <td
                                                            style={{
                                                                width:
                                                                    columnWidths["Архивный"] ||
                                                                    DEFAULT_COLUMN_WIDTHS["Архивный"],
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                            }}
                                                        >
                                                            {project.is_active ? "Нет" : "Да"}
                                                        </td>
                                                    )}
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    </table>
                                    {loading && (
                                        <div className="position-absolute top-50 start-50 translate-middle">
                                            <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {errorMessage && (
                <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                    {errorMessage}
                </Alert>
                )}
                <UnauthorizedModal 
                    show={showUnauthorizedModal} 
                    onClose={() => setShowUnauthorizedModal(false)} 
                />
                    <form onSubmit={handleSubmit}>
                        <div className="row g-1">
                            <div className="col-md-12">
                                <label className="form-label">Наименование проекта*</label>
                                <Input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    placeholder="Введите наименование"
                                    onChange={handleChange}
                                />
                                {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                            </div>
                            <div className="col-md-12">
                                <label className="form-label">Полное наименование проекта*</label>
                                <Input
                                    type="text"
                                    name="full_name"
                                    value={formData.full_name}
                                    onChange={handleChange}
                                    placeholder="Введите полное наименование"
                                />
                                {errors.full_name && <div className="invalid-feedback">{errors.full_name}</div>}
                            </div>
                            {/* <div className="col-md-12">
                                <label className="form-label">Родительская группа в фин.моделе*</label>
                                <SearchableSelect
                                    endpoint="/api/v1/finmodel/block/search/"
                                    value={formData.finmodel || ""}
                                    onChange={handleFinmodelChange}
                                    placeholder="Выберите группу в фин. моделе"
                                    isClearable
                                    isMulti={false}
                                />
                                {errors.finmodel && (
                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                        {errors.finmodel}
                                    </div>
                                )}
                            </div> */}
                            <div className="col-md-12">
                                <label className="form-label">Организация*</label>
                                <SearchableSelect
                                    endpoint="/api/v1/organization/"
                                    value={formData.company || ""}
                                    onChange={handleCompanyChange}
                                    placeholder="Выберите организацию"
                                    className={errors.company ? "is-invalid" : ""}
                                    isClearable
                                    isMulti={false}
                                    setShowUnauthorizedModal={openModal}
                                />
                                {errors.company && (
                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                        {errors.company}
                                    </div>
                                )}
                            </div>
                            <div className="col-md-12">
                                <label className="form-label">Описание</label>
                                <Input
                                    type="text"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                    placeholder="Введите описание"
                                />
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button bType="secondary" size="medium" fixed onClick={handleCloseModal}>
                        Закрыть
                    </Button>
                    <Button bType="primary" size="medium" fixed onClick={handleSubmit}>
                        {isEditing ? "Сохранить" : "Создать"}
                    </Button>
                </Modal.Footer>
            </Modal>

            {settingsVisible && (
                <div className="modal" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}>
                    <div className="modal-dialog" style={{ maxWidth: "700px" }}>
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div className="modal-header" style={{ borderBottom: "2px solid #eee" }}>
                                <h5 className="modal-title">Настройка столбцов</h5>
                                <button className="btn-close" onClick={cancelSettings}></button>
                            </div>
                            <div className="modal-body" style={{ padding: "20px" }}>
                                <div className="container-fluid">
                                    <div className="row" style={{ gap: "10px", justifyContent: "space-evenly" }}>
                                        {ALL_COLUMNS.map((column) => (
                                            <div
                                                key={column}
                                                className={`col-4 p-2 d-flex align-items-center justify-content-start ${
                                                    selectedColumns.includes(column) ? "selected-column" : ""
                                                }`}
                                                onClick={() => toggleColumn(column)}
                                                style={{
                                                    cursor: "pointer",
                                                    border: selectedColumns.includes(column)
                                                        ? "2px solid #007BFF"
                                                        : "1px solid #ddd",
                                                    padding: "8px",
                                                    transition: "0.3s",
                                                    borderRadius: "5px",
                                                    width: "30%",
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={selectedColumns.includes(column)}
                                                    readOnly
                                                    className="me-2"
                                                />
                                                <span
                                                    className={selectedColumns.includes(column) ? "text-primary" : ""}
                                                >
                          {column}
                        </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="modal-footer"
                                style={{ borderTop: "2px solid #eee" }}
                            >
                                <Button bType="secondary" size="medium" fixed onClick={selectAllColumns}>
                                    Выбрать все
                                </Button>
                                <Button bType="secondary" size="medium" fixed onClick={cancelSettings}>
                                    Отмена
                                </Button>
                                <Button bType="primary" size="medium" fixed onClick={saveSettings}>
                                    Сохранить
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Projects;
