import React, { useState, useEffect, useRef, useCallback } from "react";
import api from "../../api";
import { useLocation, useNavigate } from "react-router-dom";
import SideBar from "../SideBar";
import Header from "../Header";
import ReactLoading from "react-loading";
import APIMultipleSearchableSelect from "../payments/SearchableTableMultiSelect";
import SearchableSelect from "../payments/SearchableSelect";
import PageHeading from "../ui/PageHeading/PageHeading";
import Heading from "../ui/Heading/Heading";
import Button from "../ui/Button/Button";
import Input from "../ui/Input/Input";
import UnauthorizedModal from "../Unauthorized"; 
import { Alert } from 'react-bootstrap';

const ALL_COLUMNS = ["Наименование типа документа", "Дата создания", "Архивный"];
const DEFAULT_COLUMN_WIDTHS = {
    "Наименование типа документа": 300,
    "Дата создания": 300,
    "Архивный": 100,
};

const DocumentTypes = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [documenttypes, setDocumentTypes] = useState([]);
    const [checkedDocumentTypes, setCheckedDocumentTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const containerRef = useRef(null);
    const limit = 25;

    const [filtersVisible, setFiltersVisible] = useState(false);
    const [nameFilter, setNameFilter] = useState("");
    const [archivedFilterNotArchive, setArchivedFilterNotActive] = useState(false);
    const [archivedFilterActive, setArchivedFilterActive] = useState(false);
    const [errorMessage, setErrorMessage] = useState(""); // 🔴 Храним текст ошибки
    const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const initialFormData = { name: "" };
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});
    const searchIDofelem = location.search.replace("?", "");

    const [selectedColumns, setSelectedColumns] = useState(() => {
        const saved = localStorage.getItem("documentTypesSelectedColumns");
        return saved ? JSON.parse(saved) : [...ALL_COLUMNS];
    });

    const [columnWidths, setColumnWidths] = useState(() => {
        const saved = localStorage.getItem("documentTypesColumnWidths");
        if (saved) return { ...DEFAULT_COLUMN_WIDTHS, ...JSON.parse(saved) };
        return { ...DEFAULT_COLUMN_WIDTHS };
    });

    const resizingInfoRef = useRef({
        isResizing: false,
        startX: 0,
        columnKey: null,
        initialWidth: 0,
    });

    const onMouseDown = (e, column) => {
        e.preventDefault();
        e.stopPropagation();
        resizingInfoRef.current.isResizing = true;
        resizingInfoRef.current.startX = e.clientX;
        resizingInfoRef.current.columnKey = column;
        resizingInfoRef.current.initialWidth =
            columnWidths[column] || DEFAULT_COLUMN_WIDTHS[column] || 100;
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };

    const onMouseMove = (e) => {
        if (!resizingInfoRef.current.isResizing) return;
        const { startX, columnKey, initialWidth } = resizingInfoRef.current;
        const delta = e.clientX - startX;
        const newWidth = Math.max(50, initialWidth + delta);
        setColumnWidths((prev) => ({
            ...prev,
            [columnKey]: newWidth,
        }));
    };

    const onMouseUp = () => {
        resizingInfoRef.current.isResizing = false;
        // document.removeEventListener("mousemove", onMouseMove);
        // document.removeEventListener("mouseup", onMouseUp);
        setColumnWidths((prev) => {
            localStorage.setItem("documentTypesColumnWidths", JSON.stringify(prev));
            return prev;
        });
    };

    useEffect(() => {
        localStorage.setItem(
            "documentTypesSelectedColumns",
            JSON.stringify(selectedColumns)
        );
    }, [selectedColumns]);

    const columnsInUse = ALL_COLUMNS.filter((col) => selectedColumns.includes(col));
    const totalTableWidth = columnsInUse.reduce((acc, col) => {
        return acc + (columnWidths[col] || DEFAULT_COLUMN_WIDTHS[col]);
    }, 0);

    const openModal = (open) => {
        if (open) { 
            setShowModal(false);
            setShowUnauthorizedModal(true);
        } else { 
            setShowUnauthorizedModal(false);
        }

    };

    const fetchDocumentTypes = async () => {
        setLoading(true);
        try {
            const response = await api.get(
                `api/v1/bills/document-types/?limit=${limit}&offset=${offset}`
            );
            setDocumentTypes((prev) => {
                const newItems = response.data.results.filter(
                    (item) => !prev.some((dt) => dt.id === item.id)
                );
                return [...prev, ...newItems];
            });
            if (!response.data.next) {
                setHasMore(false);
            }
        } catch (error) {
            console.error("Ошибка при загрузке типов документов:", error);
    
            if (error.response) {
                console.error("Ответ сервера:", error.response.status, error.response.data);

                if (error.response.status === 401) {
                    console.log("401 ошибка! Перезайдите, пожалуйста.");
                    openModal();
                } else if (error.response.status === 403) {
                    setErrorMessage("Доступ запрещен! У вас недостаточно прав на просмотр типов документов.");
                } else if (error.response.status === 400) {
                    setErrorMessage(error.response.data.detail || "Ошибка запроса.");
                } else if (error.response.status === 500) {
                    setErrorMessage("Ошибка сервера! Попробуйте позже.");
                } else {
                    setErrorMessage(`Неизвестная ошибка: ${error.response.status}`);
                }
            } else {
                console.error("Ошибка сети или сервера:", error);
                setErrorMessage("Ошибка соединения с сервером. Проверьте интернет.");
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setDocumentTypes([]);
        setOffset(0);
        setHasMore(true);
        fetchDocumentTypes();
    }, [location]);

    useEffect(() => {
        const loadMore = async () => {
            if (hasMore && !loading && offset > 0) {
                setLoading(true);
                try {
                    await fetchDocumentTypes();
                } catch (error) {
                    console.error("Error fetching data", error);
                } finally {
                    setLoading(false);
                }
            }
        };
        loadMore();
    }, [offset, hasMore]);

    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
                setOffset((prevOffset) => prevOffset + limit);
            }
        }
    }, [hasMore, loading]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener("scroll", handleScroll);
            // return () => container.removeEventListener("scroll", handleScroll);
        }
    }, [handleScroll]);

    useEffect(() => {
        handleSearch();
    }, [nameFilter, archivedFilterNotArchive, archivedFilterActive]);

    const handleSearch = async () => {
        try {
            const response = await api.post("/api/v1/bills/document-typesfilter/", {
                name: nameFilter.toLowerCase(),
                is_active: [!archivedFilterNotArchive, archivedFilterActive],
            });
            setDocumentTypes(response.data);
        } catch (error) {
            console.error("Error fetching DocumentTypes:", error);
        }
    };

    const handleUpdateDocumentTypes = async (isActive) => {
        setLoading(true);
        try {
            await Promise.all(
                checkedDocumentTypes.map((id) =>
                    api.patch(`api/v1/bills/document-types/${id}/update/`, { is_active: isActive })
                )
            );
            setDocumentTypes([]);
            fetchDocumentTypes();
            setOffset(0);
            setHasMore(true);
            setCheckedDocumentTypes([]);
        } catch (error) {
            console.error(
                `Error ${isActive ? "unarchiving" : "archiving"} DocumentTypes`,
                error
            );
        } finally {
            setLoading(false);
        }
    };

    const handleCheckboxChange = (documenttypeId) => {
        setCheckedDocumentTypes((prev) => {
            if (prev.includes(documenttypeId)) {
                return prev.filter((id) => id !== documenttypeId);
            } else {
                return [...prev, documenttypeId];
            }
        });
    };

    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const allIds = documenttypes.map((dt) => dt.id);
            setCheckedDocumentTypes(allIds);
        } else {
            setCheckedDocumentTypes([]);
        }
    };

    const allChecked =
        documenttypes.length > 0 &&
        documenttypes.every((dt) => checkedDocumentTypes.includes(dt.id));

    const handleReset = () => {
        const search = location.search;
        navigate(location.search.replace(search, ""));
        window.location.reload();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const requiredFields = ["name"];
        const newErrors = {};
        requiredFields.forEach((key) => {
            if (!formData[key]) {
                newErrors[key] = "Заполните поле";
            }
        });
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        setLoading(true);
        try {
            if (isEditing) {
                await api.patch(`/api/v1/bills/document-types/${formData.id}/update/`, formData);
            } else {
                await api.post("api/v1/bills/document-types-create/", formData);
            }
            setDocumentTypes([]);
            setOffset(0);
            handleCloseModal();
            fetchDocumentTypes();
        } catch (error) {
            alert("Редактирование данного типа документа недоступно.");
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
        if (errors[name]) {
            setErrors((prevErr) => ({ ...prevErr, [name]: "" }));
        }
    };

    const resetForm = () => {
        setFormData(initialFormData);
        setIsEditing(false);
    };

    const formatDate = (isoDateString) => {
        if (!isoDateString) return "";
        const date = new Date(isoDateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };

    const handleCloseModal = () => {
        resetForm();
        setShowModal(false);
        setErrors({});
    };

    const handleRowClick = (documenttype) => {
        setIsEditing(true);
        setFormData({
            id: documenttype.id,
            name: documenttype.name,
            origin: documenttype.origin,
        });
        setShowModal(true);
    };

    // === Archive / Unarchive actions ===
    const handleArchiveDocuments = () => {
        handleUpdateDocumentTypes(false);
    };
    const handleUnarchiveDocuments = () => {
        handleUpdateDocumentTypes(true);
    };

    const [showSettings, setShowSettings] = useState(false);

    const toggleColumn = (column) => {
        setSelectedColumns((prev) =>
            prev.includes(column) ? prev.filter((c) => c !== column) : [...prev, column]
        );
    };
    const selectAllColumns = () => {
        setSelectedColumns([...ALL_COLUMNS]);
    };
    const cancelSettings = () => {
        setShowSettings(false);
    };
    const saveSettings = () => {
        setShowSettings(false);
    };

    return (
        <div className="container-fluid" translate="no">
            <Header />
            <div className="row flex-nowrap">
                <SideBar />
                <div className="col py-3">
                    <div className="management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between mb-3">
                                    <div className="d-flex align-items-center gap-2">
                                        <PageHeading>Типы документа</PageHeading>
                                        <Button
                                            bType="secondary"
                                            size="small"
                                            fixed
                                            onClick={() => setFiltersVisible(!filtersVisible)}
                                        >
                                            <i className="bi bi-funnel"></i>
                                        </Button>
                                        <Button
                                            bType="primary"
                                            size="small"
                                            fixed
                                            onClick={() => setShowModal(true)}
                                        >
                                            <i className="bi bi-plus-circle"></i>
                                        </Button>
                                    </div>

                                    <Button
                                        bType="secondary"
                                        size="small"
                                        fixed
                                        onClick={() => setShowSettings(true)}
                                    >
                                        <i className="bi bi-gear"></i>
                                    </Button>
                                </div>
                                {errorMessage && (
                                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                                        {errorMessage}
                                    </Alert>
                                )}
                                <UnauthorizedModal 
                                    show={showUnauthorizedModal} 
                                    onClose={() => setShowUnauthorizedModal(false)} 
                                />
                                {filtersVisible && (
                                    <div className="mb-3 p-3 border rounded">
                                        <Heading level={6} style={{ marginBottom: "10px" }}>
                                            Фильтры:
                                        </Heading>
                                        <div className="row g-3">
                                            <div className="col-md-3">
                                                <label className="form-label">Наименование типа документа</label>
                                                <input
                                                    type="text"
                                                    placeholder="Введите тип документа"
                                                    value={nameFilter}
                                                    onChange={(e) => setNameFilter(e.target.value)}
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label>Архивный</label>
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="archivedCheckNotActive"
                                                        checked={archivedFilterNotArchive}
                                                        onChange={(e) => setArchivedFilterNotActive(e.target.checked)}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="archivedCheckNotActive"
                                                    >
                                                        Да
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="archivedCheckActive"
                                                        checked={archivedFilterActive}
                                                        onChange={(e) => setArchivedFilterActive(e.target.checked)}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="archivedCheckActive"
                                                    >
                                                        Нет
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 align-self-end">
                                                <Button onClick={handleReset} bType="secondary" size="medium" fixed>
                                                    Сбросить
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {checkedDocumentTypes.length > 0 && (
                                    <div className="d-flex gap-2 mb-3">
                                        <Button
                                            onClick={handleArchiveDocuments}
                                            bType="secondary"
                                            size="medium"
                                            fixed
                                        >
                                            Архивировать
                                        </Button>
                                        <Button
                                            onClick={handleUnarchiveDocuments}
                                            bType="secondary"
                                            size="medium"
                                            fixed
                                        >
                                            Разархивировать
                                        </Button>
                                    </div>
                                )}
                                <div
                                    ref={containerRef}
                                    className="Payments__wrapper"
                                    style={{ height: "55vh", overflowY: "scroll", position: "relative" }}
                                >
                                    <table
                                        className="Payments__table"
                                        style={{
                                            tableLayout: "fixed",
                                        }}
                                    >
                                        <thead>
                                        <tr>
                                            <th style={{ width: 50 }}>
                                                <input
                                                    type="checkbox"
                                                    checked={allChecked}
                                                    onChange={handleSelectAllChange}
                                                />
                                            </th>
                                            {columnsInUse.map((column) => {
                                                const w = columnWidths[column] || DEFAULT_COLUMN_WIDTHS[column];
                                                return (
                                                    <th
                                                        key={column}
                                                        style={{
                                                            width: w,
                                                            minWidth: w,
                                                            position: "relative",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <span>{column}</span>
                                                            <div
                                                                style={{
                                                                    cursor: "col-resize",
                                                                    padding: "0 4px",
                                                                    marginRight: "-4px",
                                                                    userSelect: "none",
                                                                    zIndex: 999
                                                                }}
                                                                onMouseDown={(e) => onMouseDown(e, column)}
                                                            >
                                                                ||
                                                            </div>
                                                        </div>
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {documenttypes.map((documenttype) => (
                                            <tr
                                                key={documenttype.id}
                                                onClick={() => handleRowClick(documenttype)}
                                            >
                                                <td style={{ width: 50 }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={checkedDocumentTypes.includes(documenttype.id)}
                                                        onChange={(e) => {
                                                            e.stopPropagation();
                                                            handleCheckboxChange(documenttype.id);
                                                        }}
                                                    />
                                                </td>
                                                {selectedColumns.includes("Наименование типа документа") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Наименование типа документа"] ||
                                                                DEFAULT_COLUMN_WIDTHS["Наименование типа документа"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "normal",
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        {documenttype.name}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Дата создания") && (
                                                    <td
                                                        style={{
                                                            width: columnWidths["Дата создания"] || DEFAULT_COLUMN_WIDTHS["Дата создания"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "normal",
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        {formatDate(documenttype.created)}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Архивный") && (
                                                    <td
                                                        style={{
                                                            width: columnWidths["Архивный"] || DEFAULT_COLUMN_WIDTHS["Архивный"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                    >
                                                        {documenttype.is_active ? "Нет" : "Да"}
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    {loading && (
                                        <div className="position-absolute top-50 start-50 translate-middle">
                                            <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showModal && (
                <div className="modal d-block" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header d-flex justify-content-center position-relative">
                                <h5 className="modal-title">
                                    {isEditing ? "Редактирование типа документа" : "Создание типа документа"}
                                </h5>
                                <button type="button" className="btn-close" onClick={handleCloseModal} />
                            </div>
                            <div className="modal-body">
                            {errorMessage && (
                                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                                        {errorMessage}
                                    </Alert>
                                )}
                                <UnauthorizedModal 
                                    show={showUnauthorizedModal} 
                                    onClose={() => setShowUnauthorizedModal(false)} 
                                />
                                <form className="bankaccount-form" onSubmit={handleSubmit}>
                                    <div className="row g-1">
                                        <div className="col-md-12">
                                            <label className="form-label">Наименование типа документа*</label>
                                            <Input
                                                type="text"
                                                name="name"
                                                value={formData.name}
                                                placeholder="Введите наименование"
                                                onChange={handleChange}
                                                disabled={formData.origin !== "webupr" && isEditing}
                                            />
                                            {errors.name && <span className="invalid-feedback">{errors.name}</span>}
                                            {formData.origin !== "webupr" && isEditing && (
                                                <p style={{ color: "red" }}>
                                                    Данный тип документа запрещено редактировать, т.к. это типовой параметр
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer d-flex justify-content-center position-relative">
                                <Button bType="secondary" size="medium" fixed onClick={handleCloseModal}>
                                    Закрыть
                                </Button>
                                <Button bType="primary" size="medium" fixed onClick={handleSubmit}>
                                    {isEditing ? "Сохранить" : "Создать"}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showSettings && (
                <div className="modal" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}>
                    <div className="modal-dialog" style={{ maxWidth: "700px" }}>
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div className="modal-header" style={{ borderBottom: "2px solid #eee" }}>
                                <h5 className="modal-title">Настройка столбцов</h5>
                                <button className="btn-close" onClick={cancelSettings}></button>
                            </div>
                            <div className="modal-body" style={{ padding: "20px" }}>
                                <div className="container-fluid">
                                    <div className="row" style={{ gap: "10px", justifyContent: "space-evenly" }}>
                                        {ALL_COLUMNS.map((column) => (
                                            <div
                                                key={column}
                                                className={`col-4 p-2 d-flex align-items-center justify-content-start ${
                                                    selectedColumns.includes(column) ? "selected-column" : ""
                                                }`}
                                                onClick={() => toggleColumn(column)}
                                                style={{
                                                    cursor: "pointer",
                                                    border: selectedColumns.includes(column)
                                                        ? "2px solid #007BFF"
                                                        : "1px solid #ddd",
                                                    padding: "8px",
                                                    transition: "0.3s",
                                                    borderRadius: "5px",
                                                    width: "30%",
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={selectedColumns.includes(column)}
                                                    readOnly
                                                    className="me-2"
                                                />
                                                <span
                                                    className={
                                                        selectedColumns.includes(column) ? "text-primary" : ""
                                                    }
                                                >
                          {column}
                        </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer" style={{ borderTop: "2px solid #eee" }}>
                                <Button bType="secondary" size="medium" fixed onClick={selectAllColumns}>
                                    Выбрать все
                                </Button>
                                <Button bType="secondary" size="medium" fixed onClick={cancelSettings}>
                                    Отмена
                                </Button>
                                <Button bType="primary" size="medium" fixed onClick={saveSettings}>
                                    Сохранить
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DocumentTypes;
