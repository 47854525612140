import { useState, useCallback } from "react";
import api from "../../api";
import SearchableRow from "./SearchableRow";
import EditableCell from "./EditableCell";


const Matrix = ({matrix, setMatrix, onItemClick, onItemDelete, month, project, selectedItemId}) => {
    const [showSearchRow, setShowSearchRow] = useState(false);

    const handleSave = useCallback(async (item, field, newValue) => {
        try {
            const response = await api.patch(`/api/v1/salary/matrix/${item.id}/update`, { [field]: newValue, is_new: false });
            const updatedData = response.data;
            setMatrix(prevMatrix => prevMatrix.map(matrixItem => 
                matrixItem.id === item.id ? updatedData : matrixItem
            ));
        } catch (error) {
            console.error(`Error updating ${field}`, error);
        }
    }, [setMatrix]);

    const handleAddPerson = async (person) => {
        try {
          const response = await api.post('/api/v1/salary/matrix/create/', {
            individual: person.id,
            percent: 0,
            month: month,
            project: project
          });
          const individual = response.data;
          individual.individual = {
            "id": response.data.individual,
            "name": person.name
          }
          setMatrix(prevMatrix => [...prevMatrix, individual]);
          setShowSearchRow(false);
        } catch (error) {
          console.error('Error adding new person', error);
        }
      };

    const existingIndividuals = matrix.map(item => item.individual.id);

    return (
        <>
            <table className="table table-hover">
                <thead class="table-light">
                    <tr>
                        <th>ФИО</th>
                        <th>Процент, %</th>
                        <th>Оклад</th>
                        <th>ОПВ</th>
                        <th>ОПВР</th>
                        <th>ОСМС</th>
                        <th>ВОСМС</th>
                        <th>СО</th>
                        <th>ИПН</th>
                        <th>СН</th>
                        <th>Удалить</th>
                    </tr>
                </thead>
                <tbody>
                    {matrix.map((item) => (
                        <tr key={item.id} onClick={() => onItemClick(item)} style={{cursor: 'pointer'}} className={selectedItemId === item.id ? 'table-primary' : item.is_active === false ? 'table-secondary' : item.is_new === true ? 'table-success' : ''}>
                            <td>{item.individual ? item.individual.name : ''}</td>
                            <td>
                                <EditableCell 
                                    value={item.percent} 
                                    onSave={(newValue) => handleSave(item, 'percent', newValue)} 
                                />
                            </td>
                            <td>
                                <EditableCell 
                                    value={item.salary_sum} 
                                    onSave={(newValue) => handleSave(item, 'salary_sum', newValue)} 
                                />
                            </td>
                            <td>
                                <EditableCell 
                                    value={item.pension_sum} 
                                    onSave={(newValue) => handleSave(item, 'pension_sum', newValue)} 
                                />
                            </td>
                            <td>
                                <EditableCell 
                                    value={item.employer_pension_sum} 
                                    onSave={(newValue) => handleSave(item, 'employer_pension_sum', newValue)} 
                                />
                            </td>
                            <td>
                                <EditableCell 
                                    value={item.health_insurance_sum} 
                                    onSave={(newValue) => handleSave(item, 'health_insurance_sum', newValue)} 
                                />
                            </td>
                            <td>
                                <EditableCell 
                                    value={item.health_insurance_contributions_sum} 
                                    onSave={(newValue) => handleSave(item, 'health_insurance_contributions_sum', newValue)} 
                                />
                            </td>
                            <td>
                                <EditableCell 
                                    value={item.social_contributions_sum} 
                                    onSave={(newValue) => handleSave(item, 'social_contributions_sum', newValue)} 
                                />
                            </td>
                            <td>
                                <EditableCell 
                                    value={item.individual_income_tax_sum} 
                                    onSave={(newValue) => handleSave(item, 'individual_income_tax_sum', newValue)} 
                                />
                            </td>
                            <td>
                                <EditableCell 
                                    value={item.social_tax_sum} 
                                    onSave={(newValue) => handleSave(item, 'social_tax_sum', newValue)} 
                                />
                            </td>
                            <td>
                                <i 
                                    className="bi bi-trash3"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onItemDelete(item.id);
                                    }}
                                ></i>
                            </td>
                        </tr>
                    ))}
                    {showSearchRow && (
                        <SearchableRow 
                        onAdd={handleAddPerson}
                        onCancel={() => setShowSearchRow(false)}
                        existingIndividuals={existingIndividuals}
                        />
                    )}
                </tbody>
            </table>
                {!showSearchRow && (
                    <i className='fs-4 bi-plus-circle' style={{cursor: 'pointer'}} onClick={() => setShowSearchRow(true)}></i>
                )}
        </>
    )
}

export default Matrix;