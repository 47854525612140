import React, { useState, useEffect, useRef, useCallback } from "react";
import api from "../../api";
import { useLocation, useNavigate } from "react-router-dom";
import SideBar from "../SideBar";
import Header from "../Header";
import ReactLoading from "react-loading";
import APISearchableSelect from "../payments/SearchableSelect";
import APIMultipleSearchableSelect from "../payments/SearchableTableMultiSelect";
import PageHeading from "../ui/PageHeading/PageHeading";
import Heading from "../ui/Heading/Heading";
import Button from "../ui/Button/Button";
import Input from "../ui/Input/Input";
import UnauthorizedModal from "../Unauthorized"; // ✅ Импортируем модалку
import { Alert } from 'react-bootstrap';
const ALL_COLUMNS = ["Наименование", "Контрагент", "Организация", "Валюта", "Архивный"];

const DEFAULT_COLUMN_WIDTHS = {
    "Наименование": 250,
    "Контрагент": 180,
    "Организация": 180,
    "Валюта": 80,
    "Архивный": 100,
};

const Agreements = () => {
    const [agreements, setAgreements] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const limit = 25;
    const containerRef = useRef(null);
    const [errorMessage, setErrorMessage] = useState(""); // 🔴 Храним текст ошибки
    const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false);

    const [checkedAgreements, setCheckedAgreements] = useState([]);

    const [filtersVisible, setFiltersVisible] = useState(false);
    const [nameFilter, setNameFilter] = useState("");
    const [counterpartyFilter, setCounterpartyFilter] = useState("");
    const [orgFilter, setOrgFilter] = useState("");
    const [currencyFilter, setCurrencyFilter] = useState("");
    const [archivedFilterYes, setArchivedFilterYes] = useState(false);
    const [archivedFilterNo, setArchivedFilterNo] = useState(false);
    const [isFiltering, setIsFiltering] = useState(false);

    const [showModal, setShowModal] = useState(false);

    const [editId, setEditId] = useState(null);

    const [errors, setErrors] = useState({});

    const [formData, setFormData] = useState({
        name: "",
        counterparty: "",
        company: "",
        currency: "",
    });

    const [showSettings, setShowSettings] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState(() => {
        const saved = localStorage.getItem("agreementsSelectedColumns");
        return saved ? JSON.parse(saved) : [...ALL_COLUMNS];
    });
    const [columnWidths, setColumnWidths] = useState(() => {
        const saved = localStorage.getItem("agreementsColumnWidths");
        if (saved) return { ...DEFAULT_COLUMN_WIDTHS, ...JSON.parse(saved) };
        return { ...DEFAULT_COLUMN_WIDTHS };
    });

    const resizingInfoRef = useRef({
        isResizing: false,
        startX: 0,
        columnKey: null,
        initialWidth: 0,
    });

    const navigate = useNavigate();
    const location = useLocation();
    const searchIDofelem = location.search.replace("?", "");

    const onMouseDown = (e, column) => {
        e.preventDefault();
        e.stopPropagation();
        resizingInfoRef.current.isResizing = true;
        resizingInfoRef.current.startX = e.clientX;
        resizingInfoRef.current.columnKey = column;
        resizingInfoRef.current.initialWidth =
            columnWidths[column] || DEFAULT_COLUMN_WIDTHS[column] || 150;
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };

    const onMouseMove = (e) => {
        if (!resizingInfoRef.current.isResizing) return;
        const { startX, columnKey, initialWidth } = resizingInfoRef.current;
        const delta = e.clientX - startX;
        const newWidth = Math.max(50, initialWidth + delta);
        setColumnWidths((prev) => ({
            ...prev,
            [columnKey]: newWidth,
        }));
    };

    const onMouseUp = () => {
        resizingInfoRef.current.isResizing = false;
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);

        setColumnWidths((prev) => {
            localStorage.setItem("agreementsColumnWidths", JSON.stringify(prev));
            return prev;
        });
    };

    useEffect(() => {
        localStorage.setItem(
            "agreementsSelectedColumns",
            JSON.stringify(selectedColumns)
        );
    }, [selectedColumns]);

    const columnsInUse = ALL_COLUMNS.filter((col) => selectedColumns.includes(col));
    const totalTableWidth = columnsInUse.reduce((acc, col) => {
        return acc + (columnWidths[col] || DEFAULT_COLUMN_WIDTHS[col] || 100);
    }, 0);

    const buildQueryParams = (currentOffset) => {
        const params = new URLSearchParams();
        params.set("limit", limit);
        params.set("offset", currentOffset);

        if (nameFilter) {
            params.set("name__icontains", nameFilter);
        }
        if (counterpartyFilter) {
            params.set("counterparty", counterpartyFilter);
        }
        if (orgFilter) {
            params.set("organization", orgFilter);
        }
        if (currencyFilter) {
            params.set("currency", currencyFilter);
        }
        if (archivedFilterYes && !archivedFilterNo) {
            params.set("is_active", "false");
        } else if (!archivedFilterYes && archivedFilterNo) {
            params.set("is_active", "true");
        }

        return params;
    };

    const openModal = (open) => {
        if (open) { 
            setShowModal(false);
            setShowUnauthorizedModal(true);
        } else { 
            setShowUnauthorizedModal(false);
        }

    };
    const fetchAgreements = async (currentOffset = 0, isNewSearch = false) => {
        setLoading(true);
        try {
            const params = buildQueryParams(currentOffset);
            const response = await api.get(`/api/v1/organization/agreements/?${params.toString()}`);
    
            const results = response.data.results || response.data || [];
    
            if (isNewSearch) {
                setAgreements(results);
            } else {
                setAgreements((prev) => [...prev, ...results]);
            }
    
            setHasMore(Boolean(response.data.next));
        } catch (error) {
            console.error("Ошибка при загрузке договоров:", error);
    
            setHasMore(false);
    
            if (error.response) {
                console.error("Ответ сервера:", error.response.status, error.response.data);

                if (error.response.status === 401) {
                    console.log("401 ошибка! Перезайдите, пожалуйста.");
                    openModal();
                } else if (error.response.status === 403) {
                    setErrorMessage("Доступ запрещен! У вас недостаточно прав на просмотр договоров.");
                } else if (error.response.status === 400) {
                    setErrorMessage(error.response.data.detail || "Ошибка запроса.");
                } else if (error.response.status === 500) {
                    setErrorMessage("Ошибка сервера! Попробуйте позже.");
                } else {
                    setErrorMessage(`Неизвестная ошибка: ${error.response.status}`);
                }
            } else {
                console.error("Ошибка сети или сервера:", error);
                setErrorMessage("Ошибка соединения с сервером. Проверьте интернет.");
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setAgreements([]);
        setOffset(0);
        setHasMore(true);
        fetchAgreements(0, true);
    }, [location]);

    useEffect(() => {
        if (offset > 0) {
            fetchAgreements(offset, false);
        }
    }, [offset]);

    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            const scrollThreshold = 50;
            if (
                scrollHeight - (scrollTop + clientHeight) <= scrollThreshold &&
                hasMore &&
                !loading
            ) {
                setOffset((prevOffset) => prevOffset + limit);
            }
        }
    }, [hasMore, loading]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener("scroll", handleScroll);
            return () => container.removeEventListener("scroll", handleScroll);
        }
    }, [handleScroll]);

    const handleUpdateAgreements = async (isActive) => {
        setLoading(true);
        try {
            await Promise.all(
                checkedAgreements.map((id) =>
                    api.put(`/api/v1/organization/agreements/${id}/update/`, {
                        is_active: isActive,
                    })
                )
            );
            setAgreements([]);
            setOffset(0);
            setHasMore(true);
            setCheckedAgreements([]);
            fetchAgreements(0, true);
        } catch (error) {
            console.error("Update error:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleArchiveAgreements = () => handleUpdateAgreements(false);
    const handleUnarchiveAgreements = () => handleUpdateAgreements(true);

    const handleCheckboxChange = (agreementId) => {
        setCheckedAgreements((prev) =>
            prev.includes(agreementId)
                ? prev.filter((id) => id !== agreementId)
                : [...prev, agreementId]
        );
    };

    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const allIds = agreements.map((ag) => ag.id);
            setCheckedAgreements(allIds);
        } else {
            setCheckedAgreements([]);
        }
    };

    const allChecked =
        agreements.length > 0 &&
        agreements.every((ag) => checkedAgreements.includes(ag.id));

    const handleSearch = async () => {
        setIsFiltering(true);
        setOffset(0);
        setHasMore(true);
        await fetchAgreements(0, true);
    };

    const handleReset = () => {
        setIsFiltering(false);
        setNameFilter("");
        setCounterpartyFilter("");
        setOrgFilter("");
        setCurrencyFilter("");
        setArchivedFilterYes(false);
        setArchivedFilterNo(false);
        setOffset(0);
        setHasMore(true);
        setAgreements([]);
        fetchAgreements(0, true);
    };

    const handleModalClose = () => {
        setShowModal(false);
        setFormData({
            name: "",
            counterparty: "",
            company: "",
            currency: "",
        });
        setErrors({});
        setEditId(null);
    };

    const handleOpenCreateModal = () => {
        setEditId(null);
        setFormData({
            name: "",
            counterparty: "",
            company: "",
            currency: "",
        });
        setErrors({});
        setShowModal(true);
    };

    const handleOpenEditModal = (agreement) => {
        setEditId(agreement.id);
        setFormData({
            name: agreement?.name || "",
            counterparty: agreement?.counterparty || "",
            company: agreement?.company || "",
            currency: agreement?.currency || "",
        });
        setErrors({});
        setShowModal(true);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name?.trim()) {
            newErrors.name = "Наименование обязательно";
        }
        if (!formData.counterparty) {
            newErrors.counterparty = "Контрагент обязателен";
        }
        if (!formData.company) {
            newErrors.company = "Организация обязательна";
        }
        if (!formData.currency) {
            newErrors.currency = "Валюта обязательна";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const isFormValid = () => {
        if (
            !formData.name?.trim() ||
            !formData.counterparty ||
            !formData.company ||
            !formData.currency
        ) {
            return false;
        }
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async () => {
        if (loading) return;
        if (!validateForm()) return;
        const getIdOrValue = (obj) => obj?.id || obj || '';
        try {
            setLoading(true);
            if (editId) {
                await api.put(`/api/v1/organization/agreements/${editId}/update/`, {
                    name: formData.name,
                    counterparty: getIdOrValue(formData.counterparty),
                    company: getIdOrValue(formData.company),
                    currency: getIdOrValue(formData.currency),
                });
            } else {
                await api.post("/api/v1/organization/agreements/create/", {
                    name: formData.name,
                    counterparty: getIdOrValue(formData.counterparty),
                    company: getIdOrValue(formData.company),
                    currency: getIdOrValue(formData.currency),
                    code: "123",
                });
            }
            handleModalClose();
            fetchAgreements(0, true);
        } catch (error) {
            console.error("Create/Update agreement error:", error);
            setErrors((prev) => ({
                ...prev,
                apiError: "Ошибка при сохранении договора",
            }));
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
        if (errors[name]) {
            setErrors((prev) => ({ ...prev, [name]: undefined }));
        }
    };

    const handleSelectChange = (fieldName, value) => {
        console.log(fieldName, value)
        setFormData((prev) => ({ ...prev, [fieldName]: value }));
        if (errors[fieldName]) {
            setErrors((prev) => ({ ...prev, [fieldName]: undefined }));
        }
    };

    const toggleColumn = (column) => {
        setSelectedColumns((prev) =>
            prev.includes(column)
                ? prev.filter((col) => col !== column)
                : [...prev, column]
        );
    };

    const selectAllColumns = () => {
        setSelectedColumns([...ALL_COLUMNS]);
    };

    const cancelSettings = () => {
        setShowSettings(false);
    };

    const saveSettings = () => {
        setShowSettings(false);
    };

    return (
        <div className="container-fluid" translate="no">
            <Header />
            <div className="row flex-nowrap">
                <SideBar />
                <div className="col py-3">
                    <div className="management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between mb-3">
                                    <div className="d-flex gap-2">
                                        <PageHeading>Договоры</PageHeading>
                                        <Button
                                            bType="secondary"
                                            size="small"
                                            fixed
                                            onClick={() => setFiltersVisible(!filtersVisible)}
                                        >
                                            <i className="bi bi-funnel"></i>
                                        </Button>
                                        {/* Кнопка «Создать» */}
                                        <Button
                                            bType="primary"
                                            size="small"
                                            fixed
                                            onClick={handleOpenCreateModal}
                                        >
                                            <i className="bi bi-plus-circle"></i>
                                        </Button>
                                    </div>
                                    <Button
                                        bType="secondary"
                                        size="small"
                                        fixed
                                        onClick={() => setShowSettings(true)}
                                    >
                                        <i className="bi bi-gear"></i>
                                    </Button>
                                </div>
                                {errorMessage && (
                                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                                        {errorMessage}
                                    </Alert>
                                )}
                                <UnauthorizedModal 
                                    show={showUnauthorizedModal} 
                                    onClose={() => setShowUnauthorizedModal(false)} 
                                />
                                {/* Блок фильтров */}
                                {filtersVisible && (
                                    <div className="mb-3 p-3 border rounded">
                                        <Heading level={6} style={{ marginBottom: "10px" }}>
                                            Фильтры:
                                        </Heading>
                                        <div className="row g-3">
                                            <div className="col-md-3">
                                                <label className="form-label">Наименование</label>
                                                <input
                                                    type="text"
                                                    placeholder="Введите наименование"
                                                    value={nameFilter}
                                                    onChange={(e) => setNameFilter(e.target.value)}
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label className="form-label">Контрагент</label>
                                                <APISearchableSelect
                                                    endpoint="api/v1/bills/counterparty/search/"
                                                    value={counterpartyFilter}
                                                    onChange={(value) => setCounterpartyFilter(value)}
                                                    placeholder="Выберите контрагента"
                                                    setShowUnauthorizedModal={openModal}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label className="form-label">Организация</label>
                                                <APIMultipleSearchableSelect
                                                    endpoint="/api/v1/organization/search/"
                                                    value={orgFilter}
                                                    onChange={(value) => setOrgFilter(value)}
                                                    placeholder="Выберите организацию"
                                                    setShowUnauthorizedModal={openModal}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label className="form-label">Валюта</label>
                                                <APISearchableSelect
                                                    endpoint="/api/v1/bank/currencies/"
                                                    value={currencyFilter}
                                                    onChange={(value) => setCurrencyFilter(value)}
                                                    placeholder="Выберите валюту"
                                                    setShowUnauthorizedModal={openModal}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label>Архивный</label>
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="archivedYes"
                                                        checked={archivedFilterYes}
                                                        onChange={(e) => setArchivedFilterYes(e.target.checked)}
                                                    />
                                                    <label className="form-check-label" htmlFor="archivedYes">
                                                        Да
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="archivedNo"
                                                        checked={archivedFilterNo}
                                                        onChange={(e) => setArchivedFilterNo(e.target.checked)}
                                                    />
                                                    <label className="form-check-label" htmlFor="archivedNo">
                                                        Нет
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center gap-3 mt-3">
                                            <Button onClick={handleSearch} bType="primary" size="medium" fixed>
                                                Поиск
                                            </Button>
                                            <Button onClick={handleReset} bType="secondary" size="medium" fixed>
                                                Сбросить
                                            </Button>
                                        </div>
                                    </div>
                                )}

                                {/* Кнопки архивирования/разархивирования */}
                                <div className="d-flex justify-content-start align-items-center mb-3 gap-2">
                                    {checkedAgreements.length > 0 && (
                                        <>
                                            <Button
                                                onClick={handleArchiveAgreements}
                                                bType="secondary"
                                                size="medium"
                                                fixed
                                            >
                                                Архивировать выбранные
                                            </Button>
                                            <Button
                                                onClick={handleUnarchiveAgreements}
                                                bType="success"
                                                size="medium"
                                                fixed
                                            >
                                                Разархивировать выбранные
                                            </Button>
                                        </>
                                    )}
                                </div>

                                {/* Таблица */}
                                <div
                                    ref={containerRef}
                                    className="Payments__wrapper"
                                    style={{
                                        height: "70vh",
                                        minHeight: "70vh",
                                        overflowY: "auto",
                                        position: "relative",
                                    }}
                                >
                                    <table
                                        className="Payments__table"
                                        style={{
                                            tableLayout: "fixed",
                                        }}
                                    >
                                        <thead>
                                        <tr>
                                            <th style={{ width: 50 }}>
                                                <input
                                                    type="checkbox"
                                                    checked={allChecked}
                                                    onChange={handleSelectAllChange}
                                                />
                                            </th>
                                            {columnsInUse.map((col) => {
                                                const w = columnWidths[col] || DEFAULT_COLUMN_WIDTHS[col];
                                                return (
                                                    <th
                                                        key={col}
                                                        style={{
                                                            width: w,
                                                            minWidth: w,
                                                            position: "relative",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <span>{col}</span>
                                                            <div
                                                                style={{
                                                                    cursor: "col-resize",
                                                                    padding: "0 4px",
                                                                    marginRight: "-4px",
                                                                    userSelect: "none",
                                                                    zIndex: 999,
                                                                }}
                                                                onMouseDown={(e) => onMouseDown(e, col)}
                                                            >
                                                                ||
                                                            </div>
                                                        </div>
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {agreements.map((ag) => (
                                            <tr key={ag.id} onClick={() => handleOpenEditModal(ag)}>
                                                <td style={{ width: 50 }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={checkedAgreements.includes(ag.id)}
                                                        onChange={() => handleCheckboxChange(ag.id)}
                                                    />
                                                </td>
                                                {selectedColumns.includes("Наименование") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Наименование"] ||
                                                                DEFAULT_COLUMN_WIDTHS["Наименование"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "normal",
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        {ag.name || ""}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Контрагент") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Контрагент"] ||
                                                                DEFAULT_COLUMN_WIDTHS["Контрагент"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "normal",
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        {ag?.counterparty?.name || ""}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Организация") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Организация"] ||
                                                                DEFAULT_COLUMN_WIDTHS["Организация"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "normal",
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        {ag?.company?.name || ""}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Валюта") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Валюта"] ||
                                                                DEFAULT_COLUMN_WIDTHS["Валюта"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        {ag?.currency?.name || ""}
                                                    </td>
                                                )}
                                                {selectedColumns.includes("Архивный") && (
                                                    <td
                                                        style={{
                                                            width:
                                                                columnWidths["Архивный"] ||
                                                                DEFAULT_COLUMN_WIDTHS["Архивный"],
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                    >
                                                        {ag.is_active ? "Нет" : "Да"}
                                                    </td>
                                                )}

                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    {loading && (
                                        <div className="position-absolute top-50 start-50 translate-middle">
                                            <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showModal && (
                <div className="modal d-block" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header d-flex justify-content-center position-relative">
                                <h5 className="modal-title">
                                    {editId ? "Редактировать договор" : "Добавить договор"}
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={handleModalClose}
                                />
                            </div>
                            <div className="modal-body">
                            {errorMessage && (
                                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                                        {errorMessage}
                                    </Alert>
                                )}
                                <UnauthorizedModal 
                                    show={showUnauthorizedModal} 
                                    onClose={() => setShowUnauthorizedModal(false)} 
                                />
                                {errors.apiError && (
                                    <div className="alert alert-danger mx-3 mt-3" role="alert">
                                        {errors.apiError}
                                    </div>
                                )}
                                <form className="add-agreement-form">
                                    <div className="mb-3">
                                        <label className="form-label">Наименование *</label>
                                        <Input
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                        />
                                        {errors.name && (
                                            <div className="invalid-feedback d-block">{errors.name}</div>
                                        )}
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Контрагент *</label>
                                        <APISearchableSelect
                                            name="counterparty"
                                            endpoint="api/v1/bills/counterparty/search/"
                                            value={formData.counterparty || ""}
                                            onChange={(value) => handleSelectChange("counterparty", {id: value})}
                                            placeholder="Выберите контрагента"
                                            className={errors.counterparty ? "is-invalid" : ""}
                                            setShowUnauthorizedModal={openModal}
                                        />
                                        {errors.counterparty && (
                                            <div className="invalid-feedback d-block">{errors.counterparty}</div>
                                        )}
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Организация *</label>
                                        <APISearchableSelect
                                            name="company"
                                            endpoint="/api/v1/organization/search/"
                                            value={formData.company || ""}
                                            onChange={(value) => handleSelectChange("company", {id: value})}
                                            placeholder="Выберите организацию"
                                            className={errors.company ? "is-invalid" : ""}
                                        />
                                        {errors.company && (
                                            <div className="invalid-feedback d-block">{errors.company}</div>
                                        )}
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Валюта *</label>
                                        <APISearchableSelect
                                            name="currency"
                                            endpoint="/api/v1/bank/currencies/"
                                            value={formData.currency || ""}
                                            onChange={(value) => handleSelectChange("currency", {id: value})}
                                            placeholder="Выберите валюту"
                                            className={errors.currency ? "is-invalid" : ""}
                                            setShowUnauthorizedModal={openModal}
                                        />
                                        {errors.currency && (
                                            <div className="invalid-feedback d-block">{errors.currency}</div>
                                        )}
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <Button bType="secondary" size="medium" fixed onClick={handleModalClose}>
                                    Закрыть
                                </Button>
                                <Button
                                    bType="primary"
                                    size="medium"
                                    fixed
                                    onClick={handleSubmit}
                                    disabled={loading || !isFormValid()}
                                >
                                    {loading ? "Сохранение..." : "Сохранить"}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showSettings && (
                <div
                    className="modal"
                    style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
                >
                    <div className="modal-dialog" style={{ maxWidth: "700px" }}>
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div
                                className="modal-header"
                                style={{ borderBottom: "2px solid #eee" }}
                            >
                                <h5 className="modal-title">Настройка столбцов</h5>
                                <button className="btn-close" onClick={cancelSettings}></button>
                            </div>
                            <div className="modal-body" style={{ padding: "20px" }}>
                                <div className="container-fluid">
                                    <div
                                        className="row"
                                        style={{ gap: "10px", justifyContent: "space-evenly" }}
                                    >
                                        {ALL_COLUMNS.map((column) => (
                                            <div
                                                key={column}
                                                className={`col-4 p-2 d-flex align-items-center justify-content-start ${
                                                    selectedColumns.includes(column) ? "selected-column" : ""
                                                }`}
                                                onClick={() => toggleColumn(column)}
                                                style={{
                                                    cursor: "pointer",
                                                    border: selectedColumns.includes(column)
                                                        ? "2px solid #007BFF"
                                                        : "1px solid #ddd",
                                                    padding: "8px",
                                                    transition: "0.3s",
                                                    borderRadius: "5px",
                                                    width: "30%",
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={selectedColumns.includes(column)}
                                                    readOnly
                                                    className="me-2"
                                                />
                                                <span
                                                    className={
                                                        selectedColumns.includes(column) ? "text-primary" : ""
                                                    }
                                                >
                                            {column}
                                            </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="modal-footer"
                                style={{ borderTop: "2px solid #eee" }}
                            >
                                <Button bType="secondary" size="medium" fixed onClick={selectAllColumns}>
                                    Выбрать все
                                </Button>
                                <Button bType="secondary" size="medium" fixed onClick={cancelSettings}>
                                    Отмена
                                </Button>
                                <Button bType="primary" size="medium" fixed onClick={saveSettings}>
                                    Сохранить
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Agreements;
