import { Modal } from "react-bootstrap";
import { useState } from "react";
import Button from "../ui/Button/Button";
import api from "../../api";

const UploadFileTableModal = ({ showModal, setShowModal, uploadedFileData }) => {
    const [selectedPayments, setSelectedPayments] = useState([]);

    // Toggle individual payment selection
    const handleCheckboxChange = (index) => {
        setSelectedPayments((prev) => {
            const isAlreadySelected = prev.some((p) => p.index === index);
            if (isAlreadySelected) {
                return prev.filter((p) => p.index !== index);
            } else {
                return [...prev, { ...uploadedFileData[index], index }];
            }
        });
    };

    // Toggle all checkboxes
    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedPayments(uploadedFileData.map((payment, index) => ({ ...payment, index })));
        } else {
            setSelectedPayments([]);
        }
    };

    const handleModalClose = () => {
        setShowModal(false);
    }

    const handleSave = async () => {
        if (selectedPayments.length === 0) {
            alert("Выберите хотя бы один платеж для сохранения.");
            return;
        }

        try {
            const getIdOrValue = (obj) => obj?.id || obj || '';

            const preparedPayments = selectedPayments.map(payment => ({
                ...payment,
                date: payment?.date || "",
                type_of_operation: getIdOrValue(payment.type_of_operation),
                company: getIdOrValue(payment.company),
                company_account: getIdOrValue(payment.company_account),
                total_sum: payment.total_sum || 0,
                currency: getIdOrValue(payment.currency),
                counterparty: getIdOrValue(payment.counterparty),
                counterparty_account: getIdOrValue(payment.counterparty_account),
                individuals: payment.individuals.map(ind => ({
                    individual: getIdOrValue(ind.individual), // Extract only the ID
                    sum: ind.sum, // Keep the sum
                })),
                purpose_of_payment: payment?.purpose_of_payment || "",
            }));

            const response = await api.post("/api/v1/bills/payments-create-swift/", { payments: preparedPayments });

            if (response.status === 201) {
                setShowModal(false);
                setSelectedPayments([]);
            } else {
                alert("Ошибка при сохранении платежей.");
            }
        } catch (error) {
            console.error("Ошибка при отправке данных:", error);
            alert("Не удалось сохранить платежи.");
        }
    };

    return (
        <>
            <Modal show={showModal} onHide={handleModalClose} size="xl" centered>
                <Modal.Header>
                    <Modal.Title>Предварительный просмотр</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div 
                        style={{
                            overflowY: 'scroll',
                            overflowX: 'scroll',
                            height: '65vh',
                            }}>
                    <table className="Payments__table" style={{
                        maxWidth: "100%",
                        minWidth: "600px",
                        width: "auto",
                    }}>
                        <thead>
                            <th>
                                <input
                                    type="checkbox"
                                    onChange={handleSelectAll}
                                    checked={selectedPayments.length === uploadedFileData?.length && uploadedFileData.length > 0}
                                />
                            </th>
                            <th>
                                Дата платежа
                            </th>
                            <th>
                                Тип операции
                            </th>
                            <th>
                                Организация
                            </th>
                            <th>
                                Счет организации
                            </th>
                            <th>
                                Сумма
                            </th>
                            <th>
                                Валюта
                            </th>
                            <th>
                               Контрагент
                            </th>
                            <th>
                               Счет контрагента
                            </th>
                            <th>
                                Назначение платежа
                            </th>
                        </thead>
                        <tbody>
                            {uploadedFileData?.map((payment, index) => (
                               <tr key={index}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={selectedPayments.some((p) => p.index === index)}
                                            onChange={() => handleCheckboxChange(index)}
                                        />
                                    </td>
                                    <td>{payment?.date}</td>
                                    <td>{payment?.type_of_operation?.name}</td>
                                    <td>{payment?.company?.name}</td>
                                    <td>{payment?.company_account?.name}</td>
                                    <td>{payment?.total_sum}</td>
                                    <td>{payment?.currency?.name}</td>
                                    <td>{payment?.counterparty?.name}</td>
                                    <td>{payment?.counterparty_account?.name}</td>
                                    <td>{payment?.purpose_of_payment}</td>
                               </tr> 
                            ))}
                        </tbody>
                    </table>
                    </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button
                        bType="secondary"
                        size="medium"
                        fixed
                        onClick={handleModalClose}
                    >
                        Отмена
                    </Button>
                    <Button
                        bType="primary"
                        size="medium"
                        fixed
                        onClick={handleSave}
                    >
                        Сохранить
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default UploadFileTableModal;