import "../../styles/payments.css"

import {useState, useEffect, useRef, useCallback} from 'react';
import PaymentsTable from './PaymentsTable';
import api from '../../api';
import {Link, useNavigate} from 'react-router-dom';
import SideBar from '../SideBar';
import Header from "../Header";
import {Modal} from 'react-bootstrap';
import ReactLoading from 'react-loading';
import CreateRuleModal from './CreateRuleModal';
import EditRuleModal from './EditRuleModal';
import APIMultipleSearchableSelect from '../payments/SearchableTableMultiSelect';
import MultiSelectFieldStatus from './PaymentDefultStatus';
import PageHeading from "../ui/PageHeading/PageHeading";
import Button from "../ui/Button/Button";
import UploadFileModal from "./UploadFileModal";
import UploadFileTableModal from "./UploadFileTableModal";
import UnauthorizedModal from "../Unauthorized";
import { Alert } from 'react-bootstrap';


const PaymentManagement = () => {
    const nav = useNavigate()
    const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false); 
    const [purpose_of_payment, setPurposeFilter] = useState('');
    const [pay_status, setSelectedPayStatus] = useState([]);
    const [beforesum, setBeforeSum] = useState('');
    const [beforecommission_sum, setBeforeСommission_sum] = useState('');
    const [errorMessage, setErrorMessage] = useState(""); 
    const [errorStatus, setErrorStatus] = useState(null);

    const [comments, setComFilter] = useState('');
    const [fromsum, setFromSum] = useState('');
    const [fromcommission_sum, setFromСommission_sum] = useState('');

    const [document_type, setSelectedDocType] = useState([]);
    const [opertype, setSelectedOperType] = useState([]);
    const [project, setSelectedProject] = useState([]);
    const [caption, setSelectedCaption] = useState([]);
    const [currencies, setSelectedCurrencies] = useState([]);

    const [number_payment_document, setNumberOfPayDoc] = useState('');
    const [startDateFilterOne, setStartOneDateFilter] = useState('');
    const [startDateFilterTwo, setStartTwoDateFilter] = useState('');
    const [company, setCompanyChange] = useState('');
    const [counterparty, setcounterpartyChange] = useState('');
    const [bankaccounts, setBankaccountsChange] = useState('');
    const [counterparty_bankaccounts, setCounterpartyBankaccountsChange] = useState('');
    const [payments, setPayments] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);
    const [openRuleModal, setOpenRuleModal] = useState(false);
    const [rules, setRules] = useState([]);
    const [selectedRuleId, setSelectedRuleId] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const containerRef = useRef(null);
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [accounting_account, setAccountingAccount] = useState(null);
    const [showSettings, setShowSettings] = useState(false);

    const [uploadFileModal, setUploadFileModal] = useState(false);
    const [uploadFileTableModal, setUploadFileTableModal] = useState(false);
    const [uploadedFileData, setUploadedFileData] = useState(null);
    const [isForbidden, setIsForbidden] = useState(false);

    const limit = 25;
    const filterFieldsExist = [
        pay_status, document_type, number_payment_document, opertype, project, 
        caption, currencies, startDateFilterOne, startDateFilterTwo, 
        fromcommission_sum, beforecommission_sum, company, bankaccounts, 
        counterparty, counterparty_bankaccounts, fromsum, beforesum, 
        purpose_of_payment, comments, accounting_account
    ].some(value => {
        if (Array.isArray(value)) {
            return value.length > 0; // Если массив (например, multi-select), то проверяем, есть ли выбранные элементы
        }
        return value !== '' && value !== null && value !== undefined; // Проверяем, что строка или число заполнены
    });

    const showModal = async () => {
        try {
            if (rules.length === 0) {
                setLoading(true);
                const response = await api.get('api/v1/bills/auto-spacing-rules/');
                if (response.status === 200) {
                    setRules(response.data);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
            setIsOpen(true);
        }
    };

    const handleRuleActivityChange = async (rule) => {
        try {
            const response = await api.patch(`api/v1/bills/auto-spacing-rule/${rule.id}/action/`, {'is_active': !rule.is_active});
            if (response.status === 200) {
                setRules((prevRules) =>
                    prevRules.map((b) =>
                        b.id === rule.id ? {...b, is_active: !b.is_active} : b
                    )
                );
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleDeleteRule = async (rule) => {
        try {
            const response = await api.patch(`api/v1/bills/auto-spacing-rule/${rule.id}/action/`, {'is_deleted': true});
            if (response.status === 200) {
                setRules((prevRules) =>
                    prevRules.filter((b) => b.id !== rule.id)
                );
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleRuleDoubleClick = (ruleId) => {
        setSelectedRuleId(ruleId);
        setShowEditModal(true);
    };

    const hideModal = () => {
        setIsOpen(false);
    };

    const handleOpenModal = () => {
        setOpenRuleModal(true);
    }

    const handleReset = () => {
        // const search = location.search
        // navigate(location.search.replace(search,'')); 
        window.location.reload();
    };
    const handleSearch = async (newOffset = 0) => {
        try {
            // console.log(nameFilter,archivedFilterYES,archivedFilterNO,finModelFilter,startDateFilterOne,startDateFilterTwo)
            const response = await api.post(`/api/v1/bills/paymentsfilter/?limit=${limit}&offset=${offset}`, {

                purpose_of_payment: purpose_of_payment.toLowerCase(),
                pay_status: pay_status,
                document_type: document_type,
                startDateOne: startDateFilterOne,
                startDateTwo: startDateFilterTwo,
                company: company, //?
                counterparty: counterparty,
                bankaccounts: bankaccounts,
                payFromSum: fromsum,
                payBeforeSum: beforesum,
                payComm: comments,
                // -----
                paydocnumber: number_payment_document,
                opertype: opertype,
                project: project,
                caption: caption,
                currencies: currencies,
                payFromCommissionSum: fromcommission_sum,
                payBeforeCommissionSum: beforecommission_sum,
                counterparty_bankaccounts: counterparty_bankaccounts,
                accounting_account: accounting_account

            });
            console.log('project fetched:', response.data);
            if (newOffset === 0) {
                setPayments(response.data.results);
            } else {
                // Фильтруем дубликаты перед добавлением новых данных
                setPayments(prev => {
                    const uniquePayments = response.data.results.filter(
                        newPayment => !prev.some(prevPayment => prevPayment.id === newPayment.id)
                    );
                    return [...prev, ...uniquePayments];
                });
            }
    
            setHasMore(response.data.results.length === limit);
        } catch (error) {
            console.error('Error fetching project:', error);
        }
    };

    const fetchPayments = async (offset) => {
        setLoading(true);
        try {
            const response = await api.get(`api/v1/bills/payments/?limit=${limit}&offset=${offset}`);
    
            setPayments(prevPayments => {
                const newPayments = response.data.results.filter(newPayment =>
                    !prevPayments.some(prevPayment => prevPayment.id === newPayment.id)
                );
                return [...prevPayments, ...newPayments];
            });
    
            if (!response.data.next) {
                setHasMore(false);
            }
        } catch (error) {
            console.error("Ошибка при загрузке платежей:", error);
    
            if (error.response) {
                console.error("Ответ сервера:", error.response.status, error.response.data);
    
                if (error.response.status === 401) {
                    console.log("401 ошибка! Перезайдите!.");
                    setShowUnauthorizedModal(true);
                } else if (error.response.status === 403) {
                    setErrorMessage("Доступ запрещен!");
                    setErrorStatus(403);  // ✅ Добавляем обновление состояния
                    setIsForbidden(true); // Блокируем кнопку
                } else if (error.response.status === 500) {
                    setErrorMessage("Ошибка сервера! Попробуйте позже.");
                } else {
                    setErrorMessage(`Неизвестная ошибка: ${error.response.status}`);
                }
            } else {
                console.error("Ошибка сети или сервера:", error);
                setErrorMessage("Ошибка соединения с сервером. Проверьте интернет.");
            }
        } finally {
            setLoading(false);
        }
    };
    

    useEffect(() => {
        setPayments([]); // Clear existing payments
        setOffset(0); // Reset offset
        setHasMore(true);
    }, []);

    useEffect(() => {
        
        if (filterFieldsExist) { 
            handleSearch(offset);
        }
        else if (hasMore) { 
            fetchPayments(offset);
        }
        
    }, [offset, hasMore, filterFieldsExist]);

    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const {scrollTop, scrollHeight, clientHeight} = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
                setOffset(prevOffset => prevOffset + limit);
            }
        }
    }, [hasMore, loading]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);

    useEffect(() => {
        setPayments([]); 
        setOffset(0); 
        setHasMore(true); 
        handleSearch(0); 
    }, [
        pay_status, document_type, project, caption, currencies, opertype, 
        number_payment_document, startDateFilterOne, startDateFilterTwo, 
        company, counterparty, counterparty_bankaccounts, bankaccounts, 
        fromsum, fromcommission_sum, beforecommission_sum, beforesum, 
        purpose_of_payment, comments
    ]);
    return (
        <div className="container-fluid" translate="no">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar current="/payment-registry"/>
                {/* Фильтр */}
                <div className={`filters-panel ${filtersVisible ? 'visible' : ''}`}>
                    <button className="btn-closeleft" onClick={() => setFiltersVisible(false)}>
                        <img src="closeleft.png" alt="Closeleft" className="filter-icon-close"/>
                    </button>
                    <PageHeading>Платежи</PageHeading>
                    <div className="form-group">
                        <label className='form-label'>Статус</label>
                        <MultiSelectFieldStatus
                            value={pay_status}
                            onChange={(value) => setSelectedPayStatus(value)} // Только обновляем состояние
                        />
                    </div>
                    <div className="form-group">
                        <label className='form-label'>Тип документа</label>
                        <APIMultipleSearchableSelect
                            endpoint="api/v1/bills/document-types/search/"
                            value={document_type}
                            onChange={(value) => setSelectedDocType(value)} // Только обновляем состояние
                            placeholder="Выберите тип документа"
                        />
                    </div>
                    <div className="form-group">
                        <label className='form-label'>Номер документа</label>
                        <input
                            type="text"
                            placeholder="Введите номер документа"
                            value={number_payment_document}
                            onChange={e => setNumberOfPayDoc(e.target.value)} // Только обновляем состояние
                            className="form-control form-control-sm"
                        />
                    </div>
                    <div className="form-group">
                        <label className='form-label'>Вид операции</label>
                        <APIMultipleSearchableSelect
                            endpoint="api/v1/bills/operation-types/search/"
                            value={opertype}
                            onChange={(value) => setSelectedOperType(value)} // Только обновляем состояние
                            placeholder="Выберите вид операции"
                        />
                    </div>
                    <div className="form-group">
                        <label className='form-label'>Проекты</label>
                        <APIMultipleSearchableSelect
                            endpoint="/api/v1/organization/projects/search/"
                            value={project}
                            onChange={(value) => setSelectedProject(value)} // Только обновляем состояние
                            placeholder="Выберите проект"
                        />
                    </div>
                    <div className="form-group">
                        <label className='form-label'>Статья</label>
                        <APIMultipleSearchableSelect
                            endpoint="api/v1/bills/caption/search/"
                            value={caption}
                            onChange={(value) => setSelectedCaption(value)} // Только обновляем состояние
                            placeholder="Выберите статью"
                        />
                    </div>
                    <div className="form-group">
                        <label className='form-label'>Валюта</label>
                        <APIMultipleSearchableSelect
                            endpoint="api/v1/bank/currenciessearch/"
                            value={currencies}
                            onChange={(value) => setSelectedCurrencies(value)} // Только обновляем состояние
                            placeholder="Выберите валюту"
                        />
                    </div>
                    <div className="form-group">
                        <label className='form-label'>Дата создания</label>
                        <div className="d-flex">
                            <span style={{marginTop: '7px', marginRight: '7px'}}>с</span>
                            <input
                                type="date"
                                value={startDateFilterOne}
                                onChange={e => setStartOneDateFilter(e.target.value)} // Только обновляем состояние
                                className="form-control form-control-sm"
                            />
                            <span className="mx-2" style={{marginTop: '7px'}}>—</span>
                            <span style={{margin: '7px', marginRight: '7px'}}>до</span>
                            <input
                                type="date"
                                value={startDateFilterTwo}
                                onChange={e => setStartTwoDateFilter(e.target.value)} // Только обновляем состояние
                                className="form-control form-control-sm"
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Сумма комиссии</label>
                        <div className="d-flex gap-2">
                            <input
                                type="number"
                                value={fromcommission_sum}
                                onChange={e => setFromСommission_sum(e.target.value)} // Только обновляем состояние
                                className="form-control"
                                placeholder="От"
                            />
                            <input
                                type="number"
                                value={beforecommission_sum}
                                onChange={e => setBeforeСommission_sum(e.target.value)} // Только обновляем состояние
                                className="form-control"
                                placeholder="До"
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className='form-label'>Организация</label>
                        <APIMultipleSearchableSelect
                            endpoint="/api/v1/organization/"
                            value={company}
                            onChange={(value) => setCompanyChange(value)} // Только обновляем состояние
                            placeholder="Выберите организацию"
                        />
                    </div>
                    <div className="form-group">
                        <label className='form-label'>Банковский счет организации</label>
                        <APIMultipleSearchableSelect
                            endpoint="api/v1/bank/bankaccounts/search/"
                            value={bankaccounts}
                            onChange={(value) => setBankaccountsChange(value)} // Только обновляем состояние
                            placeholder="Выберите банковский счет организации"
                        />
                    </div>
                    <div className="form-group">
                        <label className='form-label'>Контрагент</label>
                        <APIMultipleSearchableSelect
                            endpoint="api/v1/bills/counterparty/search/"
                            value={counterparty}
                            onChange={(value) => setcounterpartyChange(value)} // Только обновляем состояние
                            placeholder="Выберите организацию"
                        />
                    </div>
                    <div className="form-group">
                        <label className='form-label'>Банковский счет контрагента</label>
                        <APIMultipleSearchableSelect
                            endpoint="api/v1/bank/counterparty-accounts/search/"
                            value={counterparty_bankaccounts}
                            onChange={(value) => setCounterpartyBankaccountsChange(value)} // Только обновляем состояние
                            placeholder="Выберите банковский счет контрагента"
                        />
                    </div>
                    <div className="form-group">
                        <label className='form-label'>Счет БУ</label>
                        <APIMultipleSearchableSelect
                            endpoint="api/v1/bills/accounting-accounts/search/"
                            value={accounting_account}
                            onChange={(value) => setAccountingAccount(value)} // Только обновляем состояние
                            placeholder="Выберите Счет БУ"
                        />
                    </div>
                    <div className="form-group">
                        <label>Сумма платежа</label>
                        <div className="d-flex gap-2">
                            <input
                                type="number"
                                value={fromsum}
                                onChange={e => setFromSum(e.target.value)} // Только обновляем состояние
                                className="form-control"
                                placeholder="От"
                            />
                            <input
                                type="number"
                                value={beforesum}
                                onChange={e => setBeforeSum(e.target.value)} // Только обновляем состояние
                                className="form-control"
                                placeholder="До"
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className='form-label'>Назначение платежа</label>
                        <input
                            type="text"
                            placeholder="Введите назначение платежа"
                            value={purpose_of_payment}
                            onChange={e => setPurposeFilter(e.target.value)} // Только обновляем состояние
                            className="form-control form-control-sm"
                        />
                    </div>
                    <div className="form-group">
                        <label className='form-label'>Комментарий</label>
                        <input
                            type="text"
                            placeholder="Введите комментарий платежа"
                            value={comments}
                            onChange={e => setComFilter(e.target.value)} // Только обновляем состояние
                            className="form-control form-control-sm"
                        />
                    </div>
                    <button className="btn btn-outline-secondary w-100 apply" onClick={handleReset}>Сбросить</button>
                </div>
                <div className="col py-3">
                    <div className="payment-management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex gap-3 mb-2">
                                        <PageHeading>Платежи</PageHeading>
                                        <Button size="small" bType="secondary" onClick={() => {
                                            window.location.reload();
                                        }}>
                                            <i className="bi-5 bi-arrow-clockwise"></i>
                                        </Button>
                                    </div>

                                    <Button size="medium" bType="secondary" fixed onClick={() => {
                                        nav("/knowledge/payments")
                                    }}>Инструкция</Button>
                                </div>
                                <Button bType="secondary" size="medium" fixed style={{
                                    marginBottom: '10px',
                                }} onClick={() => setFiltersVisible(true)}><i className="bi-5 bi-sliders" style={{
                                    fontSize: '16px',
                                    marginRight: '10px',
                                }}></i>Фильтры</Button>
                                <div className="d-flex justify-content-between">
                                <div className="d-flex gap-2 align-items-center mb-4">

                                    <Button bType="primary" size="medium" fixed onClick={() => {
                                        nav("/create-payment")
                                    }}>Добавить платеж</Button>

                                    <Button bType="primary" size="medium" fixed onClick={() => {
                                        showModal()
                                    }}>Правила авторазнесения</Button>

                                    <Button bType="primary" size="medium" fixed onClick={() => {
                                        setUploadFileModal(true)
                                    }}>Загрузить файл</Button>

                                </div>
                                    <Button size="small" bType="secondary" onClick={() => {
                                        setShowSettings(true)
                                    }}>
                                        <i className="bi bi-gear"></i>
                                    </Button>
                                </div>
                                {errorMessage && (
                            <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                                {errorMessage}
                            </Alert>
                        )}
                                <div ref={containerRef} style={{
                                    height: '65vh',
                                    overflowY: 'scroll',
                                    overflowX: 'scroll',
                                    width: filtersVisible && "calc(100vw - 600px)"
                                }}>
                                    <PaymentsTable payments={payments} showSettings={showSettings}
                                                   setShowSettings={setShowSettings}/>
                                </div>
                                {loading &&
                                    <div className="position-absolute top-50 start-50 translate-middle">
                                        <ReactLoading type="spin" color="#0000FF" height={30} width={30}/>
                                    </div>}    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={isOpen} onHide={hideModal} dialogClassName={"modal-dialog-centered modal-dialog-scrollable"}>
                <Modal.Header>
                    <Modal.Title>Правила авторазнесения документов</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mt-3 mb-4'>
                        <p>Текущие правила</p>
                        {errorMessage && (
                            <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                                {errorMessage}
                            </Alert>
                        )}
                        <div>
                            <table className='table table-borderless table-sm'>
                                <tbody>
                                {rules.map((rule) => (
                                    <tr
                                        key={rule.id}
                                        style={{cursor: 'pointer'}}
                                    >
                                        <td className='w-10'>
                                            <div className='form-switch'>
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    checked={rule.is_active}
                                                    onChange={(e) => {
                                                        e.preventDefault()
                                                        handleRuleActivityChange(rule)
                                                    }}/>
                                            </div>
                                        </td>
                                        <td className='text-start' onClick={() => handleRuleDoubleClick(rule.id)}>{rule?.name}</td>

                                        <td className=""><i className='bi bi-trash3' onClick={() => {
                                            handleDeleteRule(rule)
                                        }}></i></td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Button 
                            className={`btn btn-${errorStatus === 403 ? "secondary" : "primary"} btn-sm rounded`}
                            style={{ width: "200px" }}
                            onClick={handleOpenModal}
                            disabled={errorStatus === 403}
                        >
                        + Добавить новое правило
                        </Button>

                </Modal.Body>
                {/* <Modal.Footer>
                <button onClick={hideModal}>Cancel</button>
                <button>Save</button>
                </Modal.Footer> */}
            </Modal>
            <CreateRuleModal
                showModal={openRuleModal}
                setShowModal={setOpenRuleModal}
                rules={rules}
                setRules={setRules}
            />
            <EditRuleModal
                showModal={showEditModal}
                setShowModal={setShowEditModal}
                rules={rules}
                setRules={setRules}
                ruleId={selectedRuleId}
            />

            <UploadFileModal
                showModal={uploadFileModal}
                setShowModal={setUploadFileModal}
                tableModal={setUploadFileTableModal}
                setUploadedFileData={setUploadedFileData}
                />

            <UploadFileTableModal
                showModal={uploadFileTableModal}
                setShowModal={setUploadFileTableModal}
                uploadedFileData={uploadedFileData}
                />
                            <UnauthorizedModal 
            show={showUnauthorizedModal} 
            onClose={() => setShowUnauthorizedModal(false)} 
        />
        </div>

    );
};

export default PaymentManagement;
