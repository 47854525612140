import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../styles/loginStyle.css";
import logo from '../assets/pp_logo.png'
import image from '../assets/login_image.png'
import axios from 'axios';
import ReactLoading from 'react-loading';
import { useAuth } from '../AuthContext';
import { Modal} from 'react-bootstrap';

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showFinModelsModal, setShowFinModelsModal] = useState(false);
  const [finModels, setFinModels] = useState([]);
  const { login } = useAuth();

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (errors[e.target.name]) {
      setErrors(prev => ({ ...prev, [e.target.name]: '' }));
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleFinModelSelection = (finModel) => {
    localStorage.setItem('selectedFinModel', JSON.stringify(finModel));
    localStorage.setItem('user_id', JSON.stringify(finModel));
    setShowFinModelsModal(false);
    navigate('/finmodel');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = Object.keys(formData).reduce((acc, key) => {
      if (!formData[key]) {
        acc[key] = 'Заполните поле';
      }
      return acc;
    }, {});

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post('https://expansion-hub.com/api/v1/auth/', formData);
      
      login({
        access: response.data.access,
        refresh: response.data.refresh,
        permissions: response.data.permissions,
        groups: response.data.groups,
        is_superuser: response.data.is_superuser
      });

      // Обработка финмоделей
      if (response.data.finmodel) {
        if (response.data.finmodel.length === 1) {
          // Если одна финмодель, сразу сохраняем в localStorage
          localStorage.setItem('selectedFinModel', JSON.stringify(response.data.finmodel[0]));
          localStorage.setItem('allFinModel', JSON.stringify(response.data.finmodel[0]));
          navigate('/finmodel');
        } else if (response.data.finmodel.length > 1) {
          // Если несколько финмоделей, показываем модальное окно
          localStorage.setItem('allFinModel', JSON.stringify(response.data.finmodel));
          setFinModels(response.data.finmodel);
          setShowFinModelsModal(true);
        } else {
          navigate('/')
        }
      }
      
    } catch (error) {
      const errorMessage =
      error.response?.data?.detail ||
      error.response?.data?.message || 
      JSON.stringify(error.response?.data) ||
      "Неизвестная ошибка";

      alert(`Ошибка авторизации: ${errorMessage}`);
      console.error('Login failed:', error.response?.data);
    } finally {
      setLoading(false);
    }
  };
  const closeModal = useCallback(() => {
    setShowFinModelsModal(false);
  }, []);

  if (loading) {
    return (
      <div className="position-absolute top-50 start-50 translate-middle">
        <ReactLoading type="spin" color="#0000FF" height={50} width={50} />
      </div>
    );
  }

  return (
    <>
      <div className="login_page_con">
        <div className="login_container">
          <div className="login_header">
            <img src={logo} alt="pplogo"></img>
            <Link to="/register" className='btn btn-outline-primary'>Регистрация</Link>
          </div>
          <div className="login_main">
            <h2>Авторизация</h2>
            <form onSubmit={handleSubmit}>
              <div className='mb-3'>
                <label className='form-label'>Логин:</label>
                <input
                  className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                  type="text"
                  name="email"
                  value={formData.email}
                  placeholder='Введите ИИН'
                  onChange={handleInputChange}
                />
                {errors.email && <span className="invalid-feedback">{errors.email}</span>}
              </div>
              <div className='mb-3'>
                <label className='form-label'>Пароль:</label>
                <div className={`input-group ${errors.password ? 'is-invalid' : ''}`}>
                  <input
                    className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={formData.password}
                    placeholder='********'
                    onChange={handleInputChange}
                  />
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={togglePasswordVisibility}
                  >
                    <i className={`bi ${showPassword ? 'bi-eye-slash' : 'bi-eye'}`}></i>
                  </button>
                </div>
                {errors.password && <span className="invalid-feedback">{errors.password}</span>}
              </div>
              <button type="submit" className="btn btn-primary w-100">Войти</button>
            </form>
          </div>
        </div>
        <div className="image_container">
          <img src={image} alt="login_image"></img>
        </div>
      </div>
      <Modal show={showFinModelsModal} onHide={closeModal} centered >
        <Modal.Header closeButton>
          <Modal.Title className="w-100 text-center">Выберите финансовую модель</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column gap-2">
            {finModels.map((model) => (
              <button
                key={model.id}
                className="btn btn-outline-primary text-start p-3"
                onClick={() => handleFinModelSelection(model)}
              >
                <h5 className="mb-1 text-center">{model.name}</h5>
                {model.description && (
                  <p className="text-muted mb-0 small text-center">{model.description}</p>
                )}
              </button>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginForm;