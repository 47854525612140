import { Modal, Form, Dropdown } from "react-bootstrap";
import {useRef, useState} from "react";
import api from "../../api";
import Button from "../ui/Button/Button";

const UploadFileModal = ({ showModal, setShowModal, tableModal, setUploadedFileData }) => {
    const uploadFileModalRef = useRef(null);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleModalClose = () => {
        setShowModal(false);
        setFile(null);
    }

    const handleFileUpload = async () => {
        if (!file) {
            alert("Выберите файл для загрузки");
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append("file", file); // Make sure the backend expects "file" key

        try {
            const response = await api.post("api/v1/bills/upload-swift/", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.status === 200) {
                setUploadedFileData(response.data);
                setShowModal(false);
                tableModal(true);
                setFile(null);
            } else {
                alert("Ошибка загрузки файла.");
            }
        } catch (error) {
            console.error("Ошибка при загрузке файла:", error);
            alert("Ошибка загрузки файла.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Modal show={showModal} onHide={handleModalClose} size="md" centered>
                <Modal.Header>
                    <Modal.Title>Загрузка файлов</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input type="file" ref={uploadFileModalRef} style={{
                        display: 'none'
                    }} onChange={e => {
                        setFile(e.target.files[0]);
                    }} />

                    {file ? (
                        <>
                            <p>
                                {file.name}
                            </p>

                            <Button bType="secondary" size="medium" fixed onClick={() => setFile(null)}>Удалить</Button>
                        </>
                    ) : (
                        <Button bType="primary"
                                size="medium"
                                fixed
                                onClick={() => {
                                    uploadFileModalRef.current.click();
                                }}
                        >
                            Загрузить файл
                        </Button>
                    )}

                    <p style={{
                        marginTop: 4,
                        fontSize: 15,
                        color: "rgba(0,0,0,0.6)",
                        marginBottom: 0
                    }}>
                        Требования по загрузке: кодировка windows/UTF, формат файла TXT
                    </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button
                        bType="secondary"
                        size="medium"
                        fixed
                        onClick={handleModalClose}
                    >
                        Отмена
                    </Button>
                    <Button
                        bType="primary"
                        size="medium"
                        fixed
                        onClick={handleFileUpload}
                        disabled={loading}
                    >
                        {loading ? "Загрузка..." : "Загрузить"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default UploadFileModal;