import React, { useState, useEffect, useRef } from 'react';
import api from '../../api';
import "../../styles/searchSelect.css";
import { useAuth } from "../../AuthContext";

const EmployeeSearchableSelect = ({ 
    value, 
    onChange, 
    placeholder = "Выберите сотрудника", 
    disabled = false, 
    size = 'sm',
    excludeIds = [], 
    setShowUnauthorizedModal,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const wrapperRef = useRef(null);
    const { logout } = useAuth(); 

    useEffect(() => {
        const initializeValue = async () => {
            if (value && typeof value === 'object' && value.id) {
                setSelectedOption(value);
                return;
            }

            if (value) {
                try {
                    const response = await api.get(`/api/v1/user/search/`);
                    const employee = response.data.find(emp => emp.id === value);
                    if (employee) {
                        setSelectedOption(employee);
                    }
                } catch (error) {
                    console.error('Error fetching employee:', error);
                }
            }
        };

        initializeValue();
    }, [value]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    const searchOptions = async (term) => {
        setLoading(true);
        try {
            const response = await api.get(`/api/v1/user/search/?search=${term}`);
            const filteredOptions = response.data.filter(employee => 
                !excludeIds.includes(employee.id)
            ).map(employee => ({
                ...employee,
                displayName: `${employee.first_name} ${employee.last_name}`,
                searchText: `${employee.first_name} ${employee.last_name}`.toLowerCase()
            }));

            setOptions(
                term 
                    ? filteredOptions.filter(employee => 
                        employee.searchText.includes(term.toLowerCase())
                    )
                    : filteredOptions
            );
        } catch (error) {
            console.error('Error searching options:', error);
            
            if (error.response && error.response.status === 401) {
              if (setShowUnauthorizedModal) { 
                setShowUnauthorizedModal(true); 
              } else { 
                logout(true); 
              }
            }
        } finally {
            setLoading(false);
        }
      };

    useEffect(() => {
        if (searchTerm) {
            const delayDebounceFn = setTimeout(() => {
                searchOptions(searchTerm);
            }, 500);
            return () => clearTimeout(delayDebounceFn);
        } else if (isOpen) {
            searchOptions('');
        } else {
            setOptions([]);
        }
    }, [searchTerm, isOpen]);

    const handleSelect = (employee) => {
        if (selectedOption && selectedOption.id === employee.id) {
            setSelectedOption(null);
            onChange(null);
        } else {
            setSelectedOption(employee);
            onChange(employee);
        }
        setIsOpen(false);
        setSearchTerm('');
    };

    const handleToggleDropdown = () => {
        if (disabled) return;
        setIsOpen((prev) => !prev);
        if (!isOpen) {
            searchOptions('');
        }
    };

    return (
        <div className="searchable-select" ref={wrapperRef}>
            <div 
                className={`form-select form-select-${size} d-flex justify-content-between align-items-center ${disabled ? 'disabled' : ''}`}
                onClick={handleToggleDropdown}
            >
                <span className='options'>
                    {selectedOption 
                        ? `${selectedOption.first_name} ${selectedOption.last_name}` 
                        : placeholder
                    }
                </span>
            </div>
            {isOpen && (
                <div className="select-dropdown">
                    <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Поиск сотрудников..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onClick={(e) => e.stopPropagation()}
                    />
                    {loading ? (
                        <div className="text-center">Загрузка...</div>
                    ) : (
                        <ul className="list-group">
                            {options.map((employee) => (
                                <li
                                    key={employee.id}
                                    className="list-group-item"
                                    onClick={() => handleSelect(employee)}
                                >
                                    {employee.first_name} {employee.last_name}
                                    {selectedOption && selectedOption.id === employee.id && (
                                        <span className="red-x">✖</span>
                                    )}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            )}
        </div>
    );
};

export default EmployeeSearchableSelect;