import "./Checkbox.css"

const generateRandomString = (len) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';

    for (let i = 0; i < len; i++) {
        // Pick a random index from the characters string
        const randomIndex = Math.floor(Math.random() * characters.length);
        // Append the corresponding character to the result
        result += characters.charAt(randomIndex);
    }

    return result;
};

const Checkbox = ({
                      ...props
                  }) => {
    const id = props.id || generateRandomString(6)

    return (
        <>
            <input
                id={id}
                type="checkbox"
                className="Checkbox"
                {...props}
            />
            <label for={id}></label>
        </>
    )
}

export default Checkbox