import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../styles/navStyle.css";
import logo from '../assets/pp_logo.png'


function Navigation () {
    return (
        <div className="navigation_page">
            <div className="navigation_main">
                <div className="background1">
                    <Link to="/register" className="butt">Регистрация</Link>
                </div>
                <div className="background2">
                    <Link to="/login" className="butt">Логин</Link>
                </div>
            </div>
            <img src={logo} alt="logo" className="nav_img"></img>
        </div>
    );
}

export default Navigation